import useStyles from "./styles";

export default function StagingTag({ style, className }) {
  const classes = useStyles();
  const isStagingEnviorment = process.env.REACT_APP_STAGING === "true";
  if (!isStagingEnviorment) {
    return <> </>;
  }

  return (
    <span className={`${classes.stagingTag} ${className}`} style={style}>
      NUEVO
    </span>
  );
}
