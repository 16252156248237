function getCurrentDv(rut) {
  return rut.charAt(rut.length - 1);
}

function getRutNumber(rut) {
  return rut.slice(0, rut.length - 1);
}

function getCorrectDv(rutNumber) {
  var M = 0,
    S = 1;
  for (; rutNumber; rutNumber = Math.floor(rutNumber / 10))
    S = (S + (rutNumber % 10) * (9 - (M++ % 6))) % 11;
  return S ? S - 1 : "K";
}

function isNumber(string) {
  let isnum = /^\d+$/.test(string);
  return isnum;
}

function verifyRut(rut) {
  if (rut.length > 9 || rut.length < 7) return false;

  var rutNumber = getRutNumber(rut);
  if (!isNumber(rutNumber)) return false;

  var correctDv = getCorrectDv(rutNumber);
  var dv = getCurrentDv(rut);
  return dv.localeCompare(correctDv) === 0;
}

export { verifyRut };
