import React, { useState } from "react";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";

// third-party
import Chart from "react-apexcharts";

// project imports
import MainCard from "./MainCard";
import SkeletonEarningCard from "./SkeletonCard";

// assets
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

const options = {
  chart: {
    sparkline: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#fff"],
  fill: {
    type: "solid",
    opacity: 1,
  },
  stroke: {
    curve: "smooth",
    width: 3,
  },

  tooltip: {
    theme: "dark",
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
      formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
        if (value !== "undefined")
          return (
            w.config.series[seriesIndex].data[dataPointIndex].x + ": " + value
          );
      },
    },
  },
};

const ChartCard = ({
  isLoading,
  label,
  CardWrapper,
  dataMonth,
  dataYear,
  totalMonth,
  totalYear,
  isSales,
  yearArrowUp,
  monthArrowUp,
}) => {
  const theme = useTheme();

  const [monthly, setMonthly] = useState(false);
  const handleChangeTime = (event, newValue) => {
    setMonthly(newValue);
  };
  const arrowDown = { transform: "rotate3d(1, 1, 1, 45deg)" };
  const arrowUp = { transform: "rotate3d(1, 1, -10, 140deg)" };

  return (
    <>
      {isLoading ? (
        <SkeletonEarningCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2.25 }}>
            <Grid container direction="column">
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Avatar
                      variant="rounded"
                      sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: isSales ? "#ED9ABD" : "#D8B3E6",
                        color: "#36023B",
                        mt: 1,
                      }}
                    >
                      {isSales ? (
                        <MonetizationOnOutlinedIcon fontSize="inherit" />
                      ) : (
                        <ConfirmationNumberOutlinedIcon fontSize="inherit" />
                      )}
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Button
                      disableElevation
                      variant={monthly ? "contained" : "text"}
                      size="small"
                      sx={{
                        color: "inherit",
                        backgroundColor: !monthly
                          ? isSales
                            ? "#ED9ABD"
                            : "#D8B3E6"
                          : "transparent",
                      }}
                      onClick={(e) => handleChangeTime(e, false)}
                    >
                      Mes
                    </Button>
                    <Button
                      disableElevation
                      variant={!monthly ? "contained" : "text"}
                      size="small"
                      sx={{
                        color: "inherit",
                        backgroundColor: monthly
                          ? isSales
                            ? "#ED9ABD"
                            : "#D8B3E6"
                          : "transparent",
                      }}
                      onClick={(e) => handleChangeTime(e, true)}
                    >
                      Año
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ mb: 0.75 }}>
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Grid container alignItems="center">
                      <Grid item>
                        {monthly ? (
                          <Typography
                            sx={{
                              fontSize: "2.125rem",
                              fontWeight: 500,
                              mr: 1,
                              mt: 1.75,
                              mb: 0.75,
                            }}
                          >
                            {totalYear}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: "2.125rem",
                              fontWeight: 500,
                              mr: 1,
                              mt: 1.75,
                              mb: 0.75,
                            }}
                          >
                            {totalMonth}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <Avatar
                          sx={{
                            ...theme.typography.smallAvatar,

                            backgroundColor: isSales ? "#ED9ABD" : "#D8B3E6",
                            color: "#36023B",
                          }}
                        >
                          {monthly ? (
                            <ArrowDownwardIcon
                              fontSize="inherit"
                              sx={yearArrowUp ? arrowUp : arrowDown}
                            />
                          ) : (
                            <ArrowDownwardIcon
                              fontSize="inherit"
                              sx={monthArrowUp ? arrowUp : arrowDown}
                            />
                          )}
                        </Avatar>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            fontWeight: 500,
                            color: theme.palette.primary[200],
                          }}
                        >
                          {label}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    {monthly ? (
                      <Chart
                        options={options}
                        series={[{ data: dataYear }]}
                        type="line"
                        height={100}
                      />
                    ) : (
                      <Chart
                        options={options}
                        series={[{ data: dataMonth }]}
                        type="line"
                        height={100}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

export default ChartCard;
