import React, { useMemo, useState } from "react";
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import SelectionForm from "components/SelectionForm/AuditSelectionForm";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import useStyles from "./styles";
import SelectField from "components/CustomFields/SelectField";
import { getCurrentDate, getNextMonthDate } from "utils/dateGetters";
import { format } from "date-fns";
import DateField from "components/CustomFields/DateField";
import DistributorsField from "components/CustomFields/DistributorsField";
import PageTitle from "components/PageTitle/PageTitle";
import logo from "images/logoPagesRS.jpg";

const getDateFormatted = (date) => {
  return format(date, "dd/MM/yy");
};

const blankFilters = {
  begin: getDateFormatted(
    new Date(getCurrentDate().getFullYear(), getCurrentDate().getMonth(), 1),
  ),
  beginDate: new Date(
    getCurrentDate().getFullYear(),
    getCurrentDate().getMonth(),
    1,
  ),
  end: getDateFormatted(
    new Date(
      getNextMonthDate().getFullYear(),
      getNextMonthDate().getMonth(),
      1,
    ),
  ),
  endDate: new Date(
    getNextMonthDate().getFullYear(),
    getNextMonthDate().getMonth(),
    1,
  ),
  distributor: "",
  program: "",
  group: "",
};

const allPrograms = [
  { id: 1, name: "Receta solidaria" },
  { id: 2, name: "Solo mujer" },
  { id: 3, name: "Ambos" },
];

const allGroups = [
  { id: 1, name: "Farmacia" },
  { id: 2, name: "Producto" },
];

export default function DigitalInscriptionPage() {
  const [filters, setFilters] = useState(blankFilters);
  var [isLoading, setIsLoading] = useState(false);
  const [selectedDistributor, setSelectedDistributor] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(""); // Farmacia
  var classes = useStyles();
  const [tableData, setTableData] = useState([]);

  // ACA LAS COLUMNAS SON DEPENDIENTES DE LOS PARAMETROS DE BUSQUEDA

  const columns = useMemo(
    () => [
      {
        name: "Farmacia",
        options: {
          filter: false,
          display: +selectedGroup === 1 ? true : "excluded",
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Sucursal",
        options: {
          filter: false,
          display: +selectedGroup === 1 ? true : "excluded",
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Producto",
        options: {
          filter: false,
          display: +selectedGroup === 2 ? true : "excluded",
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Cantidad",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
    ],
    [selectedGroup],
  );

  const handleDateChange = (date) => {
    var dateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var dateOnLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    setFilters({
      ...filters,
      beginDate: dateOnFirstDay,
      begin: getDateFormatted(dateOnLastDay),
      endDate: dateOnLastDay,
      end: getDateFormatted(dateOnLastDay),
    });
  };

  const handleDistributorChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
    setSelectedDistributor(event.target.name);
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const handleProgramChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
    setSelectedProgram(event.target.name);
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const handleApplyFilters = () => {};

  return (
    <>
      <div>
        <PageTitle
          title="Inscripciones digitales"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justifyContent="flex-start"
        spacing={2}
        xs={12}
      >
        <Grid
          style={{ display: "flex", margin: 0 }}
          container
          item
          direction="row"
          justifyContent="flex-start"
          spacing={2}
          xs={6}
        >
          <Grid item xs={12} style={{ marginBottom: "5px", marginLeft: "10%" }}>
            <Typography variant="h4" className={classes.alignedText}>
              Selección
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Período
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.alignLeft}>
            <DateField
              views={["year", "month"]}
              minDate={new Date("2013-10-01")} // TODO: es esta la fecha?
              maxDate={getNextMonthDate()}
              format={"MM/yyyy"}
              value={filters.beginDate ? filters.beginDate : getCurrentDate()}
              onChange={handleDateChange}
              fieldClassName={classes.dateSelectionField}
            />
          </Grid>

          <Grid item xs={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Cadena
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.alignLeft}>
            <DistributorsField
              fieldClassName={classes.generalSelectionField}
              value={selectedDistributor ? filters[selectedDistributor] : ""}
              onChange={handleDistributorChange}
            />
          </Grid>

          <Grid item xs={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Programa
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.alignLeft}>
            <SelectField
              fieldClassName={classes.generalSelectionField}
              id="programs"
              label="Seleccione"
              name="program"
              value={selectedProgram ? filters[selectedProgram] : ""}
              onChange={handleProgramChange}
              allValues={allPrograms ? allPrograms : []}
            />
          </Grid>
          <Grid item xs={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Agrupación
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.alignLeft}>
            <SelectField
              fieldClassName={classes.generalSelectionField}
              id="group"
              label="Seleccione"
              name="group"
              value={selectedGroup}
              onChange={handleGroupChange}
              allValues={allGroups ? allGroups : []}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttonSpacing}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyFilters}
              >
                Aplicar
              </Button>
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CustomMUIDataTable
            title="Empadronamientos"
            data={tableData ? tableData : undefined}
            columns={columns}
            filterStart={1}
          />
        </Grid>
      </Grid>
    </>
  );
}
