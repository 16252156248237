import { format } from "date-fns";
import { getLastMonthDate } from "utils/dateGetters";

const getDateFormatted = (date) => {
  return format(date, "yyyy/MM");
};

const mapObservations = {
  1: "RUT de médico inexistente", // "INEXISTENT_DOCTOR_RUT_WARNING"
  2: "Paciente inexistente", // "INEXISTENT_PATIENT_WARNING"
  3: "Vademecum inexistente", // "INEXISTENT_VADEMECUM_ERROR"
  4: "Código de barras inexistente", // "INEXISTENT_BARCODE_ERROR"
  5: "Presentacion inexistente", // "INEXISTENT_PRESENTATION_ERROR"
  6: "Código de barras usado", // "BARCODE ALREADY USED"
  7: "Paciente ya tiene el producto", // PATIENT_HAS_PRESENTATION_ERROR
  8: "Fecha de empadronamiento invalida", // INVALID_TRANSACTION_DATE_ERROR
  9: "Farmacia inexistente", // INEXISTENT_PHARMACY_ERROR
  10: "Descuento erróneo", // INCORRECT_DISCOUNT_ERROR
  11: "Paciente inexistente", // INEXISTENT_PATIENT_ERROR
};

const parseGetAllErrorsAndWarnings = (observations) => {
  var allObservations = observations.map((elem) =>
    elem.error_id in mapObservations
      ? mapObservations[elem.error_id]
      : elem.error_id,
  );
  return allObservations.join(" - ");
};

const blankFilters = {
  period: getDateFormatted(
    new Date(
      getLastMonthDate().getFullYear(),
      getLastMonthDate().getMonth(),
      1,
    ),
  ),
  distributor: "",
  program: "",
  status: "",
  observations: [],
};

const summaryHeader = [
  {
    title: "Estado",
    align: "left",
  },
  {
    title: "Empadronamientos",
    align: "right",
  },
];

const summaryRowHeader = [
  "Total de empadronamientos",
  "Empadronamientos con errores",
  "Empadronamientos con advertencias",
  "Cantidad  de empadronamientos a pagar",
  "Fee por empadronamiento [$]",
  "Total a pagar [$]",
];

// Color variables
let primaryOpacity = "0.7";
let secondaryOpacity = "0.5";
let tempColor = "255, 255, 255"; // white

/* COLUMNS THAT GOES TO THE MAIN TABLE */

function createColumns(filters, classes) {
  return [
    {
      name: "Nro. interno",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },

    {
      name: "Sucursal",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: () => ({
          style: {
            textAlign: "center",
            whiteSpace: "nowrap",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Período",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Fecha",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
        sortCompare: (order) => {
          return (a, b) => {
            var dateSplitA = a.data.split("/");
            var dateSplitB = b.data.split("/");
            const dateA = new Date(
              dateSplitA[2],
              dateSplitA[1],
              dateSplitA[0],
            ).getTime();
            const dateB = new Date(
              dateSplitB[2],
              dateSplitB[1],
              dateSplitB[0],
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === "desc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "Cód. barras cupón",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Cód. pres.",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Producto",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Zona",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Errores",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Advertencias",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Grupo",
      options: {
        filter: true,
        filterType: "dropdown",
        display: "excluded",
        filterOptions: {
          fullWidth: true,
        },
      },
    },
    {
      name: "ID Paciente",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
  ];
}

export {
  parseGetAllErrorsAndWarnings,
  blankFilters,
  summaryHeader,
  summaryRowHeader,
  createColumns,
};
