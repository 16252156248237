import { format } from "date-fns";
import {
  parseGetAllErrors,
  parseGetAllWarnings,
} from "pages/burnable/SalesListBurnablePage";

const defineCellColor = (errorsLength, warningsLength) => {
  if (errorsLength > 0) {
    return "red";
  } else if (warningsLength > 0) {
    return "yellow";
  } else {
    return "green";
  }
};

const mapBurnableTableData = (burnableTable) => {
  return burnableTable.map((row) => {
    return [
      {
        excluded: false,
        data: row.id,
      },
      {
        excluded: false,
        data: row.transaction_date,
      },
      {
        excluded: false,
        data: row.distributor?.name,
      },
      {
        excluded: false,
        data: row.pharmacy_code,
      },
      {
        excluded: false,
        data: row.pharmacy?.name,
      },

      {
        excluded: false,
        data: row.pharmacy?.rut,
      },
      {
        excluded: false,
        data: row.source === 2 ? "Archivo" : "Web",
      },
      {
        excluded: false,
        data: row.patient?.rut,
      },
      {
        excluded: false,
        data: row.patient?.id,
      },
      {
        excluded: false,
        data: row.informed_presentation?.name,
      },
      {
        excluded: false,
        data: row.informed_units,
      },
      {
        excluded: false,
        data: row.informed_exlab_price,
      },
      {
        excluded: false,
        data: row.informed_discount_percentage,
      },
      {
        excluded: false,
        data: row.informed_discount,
      },
      {
        excluded: false,
        data: row.calculated_presentation?.name,
      },
      {
        excluded: false,
        data: row.calculated_units,
      },
      {
        excluded: false,
        data: row.calculated_exlab_price,
      },
      {
        excluded: false,
        data: row.calculated_discount_percentage,
      },
      {
        excluded: false,
        data: row.calculated_discount,
      },
      {
        excluded: false,
        data:
          Math.abs(row.calculated_discount - row.informed_discount) <= 10
            ? 0
            : row.calculated_discount - row.informed_discount,
      }, // 20 Diferencia: si es menor o igual a 10 => es 0
      {
        excluded: false,
        data: row.errors?.length === 0 ? "-" : parseGetAllErrors(row.errors),
      },
      {
        excluded: false,
        data:
          row.warnings?.length === 0 ? "-" : parseGetAllWarnings(row.warnings),
      },
      {
        excluded: true,
        data: defineCellColor(row.errors?.length, row.warnings?.length),
      },
    ];
  });
};

const mapFormattedBurnableTableData = (table) => {
  return table.map((row) => {
    return [
      row[0].data,
      row[1].data,
      row[2].data,
      row[3].data,
      row[4].data,
      row[5].data,
      row[6].data,
      row[7].data,
      row[8].data,
      row[9].data,
      row[10].data,
      row[11].data,
      row[12].data,
      row[13].data,
      row[14].data,
      row[15].data,
      row[16].data,
      row[17].data,
      row[18].data,
      row[19].data,
      row[20].data,
      row[21].data,
      row[22].data,,
      // row.id,
      // row.transaction_date,
      // row.pharmacy?.code,
      // row.source === 2 ? "Archivo" : "Web",
      // row.patient?.rut,
      // row.patient?.id,
      // row.informed_presentation?.name,
      // row.informed_units,
      // row.informed_exlab_price,
      // row.informed_discount_percentage,
      // row.informed_discount,
      // row.calculated_presentation?.name,
      // row.calculated_units,
      // row.calculated_exlab_price,
      // row.calculated_discount_percentage,
      // row.calculated_discount,
      // "",
      // row.errors?.length < 1 || !row.errors ? "OK" : parseGetAllErrors(row.errors),
      // row.warnings?.length < 1 || !row.errors ? "OK" : parseGetAllWarnings(row.warnings),
      // cell color
    ];
  });
};

export { mapBurnableTableData, mapFormattedBurnableTableData };
