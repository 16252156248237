import { useEffect, useState } from "react";
import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import SearchIcon from "@material-ui/icons/Search";

import PageTitle from "../../components/PageTitle/PageTitle";
import RutField from "components/CustomFields/RutField";
import EmailField from "components/CustomFields/EmailField";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import useStyles from "./styles";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { Typography } from "components/Wrappers/Wrappers";
import dataGridTexts from "components/DataGrid/dataGridTexts.js";
import { dbGet } from "utils/DBFetchers.js";
import { verifyRut } from "utils/rutValidations.js";
import { verifyEmail } from "utils/emailValidation";
import { dbPost } from "utils/DBFetchers.js";
import { useHistory } from "react-router-dom";
import routes from "routes";

const blankForm = {
  patientRut: "",
  patientRutVerifier: "",
  patientEmail: "",
  medicRut: "",
  medicRutVerifier: "",
  selectedProducts: [],
};

const blankFormErrors = {
  patientRut: "",
  patientEmail: "",
  medicRut: "",
};

const availableProductsColumns = [
  { field: "name", headerName: "Producto", width: 500 },
];

const BURNABLE_PROGRAM_ID = 1000;
const MAX_SELECTED_PRODUCTS = 1;
const ERROR_MAP = {
  already_inscribed: "Paciente ya compró el producto",
  over_limit: "Paciente tiene mas de 5 inscripciones",
  no_coupons_left_1: "El sistema esta en mantenimiento, disculpe las molestias",
  inexistent_pharmacy: "No esta logueado como una farmacia",
};
const REDIRECT_TIMEOUT = 2000;

export default function NewSaleNoCouponBurnablePage() {
  const classes = useStyles();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [form, setForm] = useState(blankForm);
  const [formErrors, setFormErrors] = useState(blankFormErrors);
  const [searchFilter, setSearchFilter] = useState("");
  const [availableProducts, setAvailableProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailAutoFilled, setIsEmailAutoFilled] = useState(false);
  const history = useHistory();

  const totalWidth = availableProductsColumns
    .map((x) => (x.width ? x.width : 0))
    .reduce((accumulator, currentValue) => accumulator + currentValue);

  const fetchAvailableProducts = async () => {
    const data = await dbGet(`presentations/burnable/${BURNABLE_PROGRAM_ID}`);
    const dataWithoutDuplicates = new Set(data);
    const sortedData = Array.from(dataWithoutDuplicates).sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }); // products are sorted alphabetically

    setAvailableProducts(sortedData);
  };

  useEffect(() => {
    fetchAvailableProducts();
  }, []);

  const handleRutChange = (fieldId, isVerifier, e) => {
    if (isVerifier && e.target?.value?.length > 1) return "";
    const rut = isVerifier ? form[fieldId] : e.target.value;
    const verifier = isVerifier ? e.target.value : form[`${fieldId}Verifier`];
    const fullRut = rut + verifier;
    const isValid = verifyRut(fullRut);
    if (isValid && !verifier)
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [fieldId]: "Ingrese el dígito verificador en el campo respectivo",
      }));
    else if (!isValid)
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [fieldId]: "RUT inválido",
      }));
    else
      setFormErrors((prevFormErrors) => ({ ...prevFormErrors, [fieldId]: "" }));
    setForm((prevForm) => ({ ...prevForm, [e.target.id]: e.target.value }));
    return fullRut;
  };

  const handlePatientRutChange = async (isVerifier, e) => {
    const fullRut = handleRutChange("patientRut", isVerifier, e);
    const unchangedField = isVerifier
      ? form.patientRut
      : form.patientRutVerifier;
    if (!verifyRut(fullRut) || !unchangedField || !e.target?.value) {
      if (isEmailAutoFilled) {
        setIsEmailAutoFilled(false);
        setForm((prevForm) => ({ ...prevForm, patientEmail: "" }));
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          patientEmail: "",
        }));
      }
      return;
    }

    try {
      const res = await dbGet(`patients/${fullRut}`);
      setIsEmailAutoFilled(true);
      setForm((prevForm) => ({ ...prevForm, patientEmail: res.email }));
    } catch (error) {
      snackbarOpen(
        "error",
        error[0] ? error[0].description_es : "Error al buscar paciente",
      );
    }
  };

  const handleEmailChange = (e) => {
    setIsEmailAutoFilled(false);
    const isValid = verifyEmail(e.target.value);
    setForm((prevForm) => ({ ...prevForm, [e.target.id]: e.target.value }));
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [e.target.id]: isValid ? "" : "Email inválido",
    }));
  };

  const hasErrors = () => {
    return (
      formErrors.patientRut ||
      !form.patientRut ||
      formErrors.patientEmail ||
      !form.patientEmail ||
      formErrors.medicRut ||
      !form.medicRut ||
      form.selectedProducts.length > MAX_SELECTED_PRODUCTS ||
      form.selectedProducts.length === 0
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (hasErrors()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para continuar.",
      );
      return;
    }
    setIsLoading(true);

    const body = {
      patient_rut: form.patientRut + form.patientRutVerifier,
      email: form.patientEmail,
      medic_rut: form.medicRut + form.medicRutVerifier,
      presentation_id: form.selectedProducts[0],
    };

    try {
      const res = await dbPost("burnable/inscription", body);
      snackbarOpen("success", "Inscripción generada exitosamente");
      setTimeout(() => {
        history.push(routes.burnableNewSaleCouponPharmacies, {
          barcode: res.b_indep,
        });
      }, REDIRECT_TIMEOUT);
    } catch (error) {
      const error_code = error.error;
      snackbarOpen(
        "error",
        error_code && ERROR_MAP[error_code]
          ? ERROR_MAP[error_code]
          : "Error al añadir la inscripción",
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <PageTitle title="Nueva Venta Sin Cupón" />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12} xl={6}>
              <Typography variant="h5" className={classes.infoSubtitle}>
                Datos de la venta
              </Typography>
              <RutField
                labelName="RUT Paciente * (sin guión)"
                id="patientRut"
                id2="patientRutVerifier"
                value={form.patientRut}
                value2={form.patientRutVerifier}
                onChange={(e) => handlePatientRutChange(false, e)}
                onChange2={(e) => handlePatientRutChange(true, e)}
                divClassType={classes.smallItems2}
                divClassType2={classes.generalPurposeItems2}
                fieldClassType={classes.smallFieldValidation2}
                fieldClassType2={classes.smallFieldValidation3}
                error={formErrors.patientRut.length > 0}
                helperText={formErrors.patientRut}
                required
              />

              <EmailField
                labelName="Email Paciente *"
                id="patientEmail"
                value={form.patientEmail}
                onChange={handleEmailChange}
                divClassType={classes.smallItems}
                fieldClassType={classes.smallFieldValidation}
                error={formErrors.patientEmail.length > 0}
                required
              />
              <RutField
                labelName="RUT Médico * (sin guión)"
                id="medicRut"
                id2="medicRutVerifier"
                value={form.medicRut}
                value2={form.medicRutVerifier}
                onChange={(e) => handleRutChange("medicRut", false, e)}
                onChange2={(e) => handleRutChange("medicRut", true, e)}
                divClassType={classes.smallItems2}
                divClassType2={classes.generalPurposeItems2}
                fieldClassType={classes.smallFieldValidation2}
                fieldClassType2={classes.smallFieldValidation3}
                error={formErrors.medicRut.length > 0}
                helperText={formErrors.medicRut}
                required
              />
            </Grid>

            <Grid item xs={12} xl={6}>
              <Typography variant="h5" className={classes.productSubtitle}>
                Producto seleccionado
              </Typography>
              <div className={classes.generalPurposeItems}>
                <Typography className={classes.text} weight="bold">
                  Seleccionar (máximo {MAX_SELECTED_PRODUCTS}) *
                </Typography>
              </div>
              <div className={classes.generalPurposeField}>
                <TextField
                  className={classes.searchField}
                  label="Filtrar"
                  onChange={(e) => {
                    setSearchFilter(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {form.selectedProducts.length > MAX_SELECTED_PRODUCTS && (
                  <Typography
                    className={classes.text}
                    style={{ color: "red" }}
                    weight="bold"
                    size="xl"
                  >
                    No puedes seleccionar más de {MAX_SELECTED_PRODUCTS}
                  </Typography>
                )}

                <DataGrid
                  style={{ height: 360, width: totalWidth + 100 }}
                  rows={
                    searchFilter
                      ? availableProducts.filter((x) =>
                          x.name
                            .toLowerCase()
                            .includes(searchFilter.toLowerCase()),
                        )
                      : availableProducts
                  }
                  columns={availableProductsColumns}
                  showToolbar={true}
                  disableColumnSelector={true}
                  disableDensitySelector={true}
                  disableColumnMenu={true}
                  rowsPerPageOptions={[]}
                  pageSize={100}
                  localeText={dataGridTexts}
                  onSelectionModelChange={(selectedRowIds) => {
                    setForm({ ...form, selectedProducts: selectedRowIds });
                  }}
                  checkboxSelection
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                type="submit"
                startIcon={
                  isLoading && <CircularProgress size={16} color="inherit" />
                }
                disabled={hasErrors() || isLoading}
              >
                Ir a venta
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
