import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Grid,
  Paper,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import useStyles from "./styles";
import DateField from "components/CustomFields/DateField";
import { getCurrentDate } from "utils/dateGetters.js";
import PageTitle from "components/PageTitle/PageTitle";
import logo from "images/logoPagesRS.jpg";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { dbGet, dbPost } from "utils/DBFetchers";
import { Typography } from "components/Wrappers/Wrappers";
import SelectField from "components/CustomFields/SelectField.js";
import LinearProgressWithLabel from "components/LinearProgressWithLabel/LinearProgressWithLabel";
import FolderZipIcon from "@mui/icons-material/FolderZip";

const USUAL_REPORTS_WITH_GES = 1;
const USUAL_REPORTS_WITHOUT_GES = 2;
const USUAL_REPORTS_ONLY_GES = 3;
const SPECIAL_REPORTS = 4;

const REQUESTED_DATA_REPORTS = 1;
const REQUESTED_DATA_SUMMARY = 2;

const FILTERS = {
  beginYear: 0,
  beginCycle: 0,
};

export default function ReportCreationPage() {
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [toPeriod, setToPeriod] = useState(null);
  const [reminderToPeriod, setReminderToPeriod] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [progress, setProgress] = useState({ progress: 0, status: "" });
  const [selectedReportType, setSelectedReportType] = useState(-1);
  const [requestedDataType, setRequestedDataType] = useState(0);
  const [reportStatus, setReportStatus] = useState(null);
  const [allYears, setAllYears] = useState([]);
  const [allCycles, setAllCycles] = useState([]);
  const [filters, setFilters] = useState(FILTERS);

  const [isTraceLoading, setIsTraceLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [remindersIsLoading, setRemindersIsLoading] = useState(false);

  const classes = useStyles();

  const REPORT_TYPES = [
    { id: USUAL_REPORTS_WITH_GES, name: "Reporte mensual con GES" },
    { id: USUAL_REPORTS_WITHOUT_GES, name: "Reporte mensual sin GES" },
    { id: USUAL_REPORTS_ONLY_GES, name: "Reporte mensual (sólo GES)" },
    // { id: SPECIAL_REPORTS, name: "Reporte Trazabilidad" },
  ];

  const getYearsCycles = () => {
    let years_mapped = [];
    let cycles_mapped = [];
    dbGet("traceability/cycles")
      .then((data) => {
        years_mapped = data.years.map((year) => {
          return { id: parseInt(year), name: year };
        });
        cycles_mapped = data.cycles.map((cycle) => {
          return { id: parseInt(cycle), name: cycle.toString().slice(-2) };
        });
        setFilters({
          ...filters,
          beginYear: parseInt(data.cycles[0].toString().slice(0, 4)),
          beginCycle: data.cycles[0],
        });
        setAllYears(years_mapped);
        setAllCycles(cycles_mapped);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getYearsCycles();
  }, []);

  const createGeneratedOkMessage = () => {
    if (requestedDataType === REQUESTED_DATA_REPORTS) {
      return "Reportes generados correctamente";
    } else {
      return "Resumen generado correctamente";
    }
  };

  const creationErrorMesssage = () => {
    if (requestedDataType === REQUESTED_DATA_REPORTS) {
      return "Error al generar reportes";
    } else {
      return "Error al generar resumen";
    }
  };

  const updateProgress = (id, data) => {
    dbGet(`request_checker/status?id=${id}`)
      .then((data) => {
        let status = data.status.replace("Processing", "Procesando");
        setProgress({
          ...progress,
          progress: data.progress ? data.progress : 0,
          status: status,
        });
        if (data.status === "Completed") {
          setReportStatus(data.data);
          snackbarOpen(
            "success",
            data.error_message // We use error_message to send the message from the backend
              ? data.error_message
              : createGeneratedOkMessage(),
          );
          setIsLoading(false);
          setIsTraceLoading(false);
          setRemindersIsLoading(false);

          setProgress({ progress: 0, status: "" });
        } else if (!data.error_status && data.status !== "Failure") {
          setTimeout(updateProgress.bind(null, id, data), 2000);
        } else {
          snackbarOpen(
            "error",
            data.error_message
              ? data.error_message.description_es
              : creationErrorMesssage(),
          );
          setIsLoading(false);
          setIsTraceLoading(false);
          setRemindersIsLoading(false);
          setProgress({ progress: 0, status: "" });
        }
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error.error_message
            ? error.error_message.description_es
            : creationErrorMesssage(),
        );
        setIsLoading(false);
        setRemindersIsLoading(false);
        setProgress({ progress: 0, status: "" });
      });
  };

  const handleZipRequest = () => {
    let data = {
      period: selectedPeriod,
    };
    dbPost("control-panel/zip_reports", data);
    snackbarOpen(
      "success",
      "Solicitud de generación de zip enviada correctamente",
    );
  };

  const handleTraceReportRequest = () => {
    setReportStatus(null); // Reset report status
    setIsTraceLoading(true);
    setIsLoading(true);
    let mock = { progress: 10 };
    let postBody = {
      cycle: filters.beginCycle,
    };

    dbPost("control-panel/traceability", postBody)
      .then((response) => {
        setTimeout(updateProgress.bind(null, response.id, mock), 3000);
      })
      .catch((error) => {
        if (error.response) {
          snackbarOpen(
            "error",
            error.response && error.response.data
              ? error.response.data.description_es
              : creationErrorMesssage(),
          );
          setIsLoading(false);
        }
      });
  };

  const handleReportRequest = () => {
    setReportStatus(null); // Reset report status
    setIsLoading(true);
    setRequestedDataType(REQUESTED_DATA_REPORTS);
    let mock = { progress: 10 };
    let postBody = {
      period: toPeriod,
      report_type: selectedReportType,
      to_period: toPeriod,
      to_date: toDate, // full date as string, needed for backend in some queries
    };

    dbPost("control-panel/reports", postBody)
      .then((response) => {
        setTimeout(updateProgress.bind(null, response.id, mock), 3000);
      })
      .catch((error) => {
        if (error.response) {
          snackbarOpen(
            "error",
            error.response && error.response.data
              ? error.response.data.description_es
              : creationErrorMesssage(),
          );
          setIsLoading(false);
        }
      });
  };

  const handleSummaryRequest = () => {
    setIsLoading(true);
    setRequestedDataType(REQUESTED_DATA_SUMMARY);
    let mock = { progress: 10 };
    let postBody = {
      to_period: toPeriod,
      to_date: toDate, // full date as string, needed for backend in some queries
    };
    dbPost("control-panel/summary", postBody)
      .then((response) => {
        setTimeout(updateProgress.bind(null, response.id, mock), 3000);
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : creationErrorMesssage(),
        );
      });
  };

  const handleSelectorChange = (event, beginFilter) => {
    setFilters({
      ...filters,
      [beginFilter]: event.target.value,
    });
  };

  function formatDateToInt(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based, so add 1

    // Format year and month as a single integer (e.g., 202211)
    const formattedDate = year * 100 + month;

    return formattedDate;
  }

  const handlePeriodChange = (date) => {
    let toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let toDateStr = toDate.toISOString().split("T")[0];
    let toPeriodStr = toDate.toISOString().split("T")[0].substring(0, 7);
    let toPeriod = parseInt(toPeriodStr.replace("-", ""));
    setToDate(toDateStr);
    setToPeriod(toPeriod);
    setSelectedPeriod(date);
  };

  const generateReport = () => {
    const toPeriod = formatDateToInt(reminderToPeriod);
    setRemindersIsLoading(true);
    dbPost("control-panel/create_reminders", { to_period: toPeriod })
      .then((response) => {
        setTimeout(
          updateProgress.bind(null, response.id, { progress: 10 }),
          3000,
        );
      })
      .catch((error) => {
        if (error.response) {
          snackbarOpen(
            "error",
            error.response && error.response.data
              ? error.response.data.description_es
              : creationErrorMesssage(),
          );
          setRemindersIsLoading(false);
        }
      });
  };

  const sendReport = () => {
    setRemindersIsLoading(true);
    dbPost("control-panel/send_reminders")
      .then((response) => {
        setTimeout(
          updateProgress.bind(null, response.id, { progress: 10 }),
          3000,
        );
      })
      .catch((error) => {
        if (error.response) {
          snackbarOpen(
            "error",
            error.response && error.response.data
              ? error.response.data.description_es
              : creationErrorMesssage(),
          );
          setRemindersIsLoading(false);
        }
      });
  };

  const reportStatusDiv = () => {
    if (!reportStatus || Object.keys(reportStatus).length === 0) return;
    return (
      <Grid
        container
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Grid item style={{ marginRight: "100px", textAlign: "center" }}>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            Reportes creados correctamente RS
            {reportStatus[1].correct_reports.map((report) => (
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                {report}
              </Typography>
            ))}
          </Typography>
        </Grid>
        <Grid item style={{ marginRight: "100px", textAlign: "center" }}>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            Reportes con error de creación RS
            {Object.entries(reportStatus[1].creation_error_reports).map(
              (report) => (
                <Typography variant="body1" style={{ marginTop: "10px" }}>
                  {report[0]}: {report[1]}
                </Typography>
              ),
            )}
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            Reportes con error de subida RS
            {Object.entries(reportStatus[1].upload_error_reports).map(
              (report) => (
                <Typography variant="body1" style={{ marginTop: "10px" }}>
                  {report[0]}: {report[1]}
                </Typography>
              ),
            )}
          </Typography>
        </Grid>
        <Grid item style={{ marginRight: "100px", textAlign: "center" }}>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            Reportes creados correctamente SM
            {reportStatus[2].correct_reports.map((report) => (
              <Typography variant="body1" style={{ marginTop: "10px" }}>
                {report}
              </Typography>
            ))}
          </Typography>
        </Grid>
        <Grid item style={{ marginRight: "100px", textAlign: "center" }}>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            Reportes con error de creación SM
            {Object.entries(reportStatus[2].creation_error_reports).map(
              (report) => (
                <Typography variant="body1" style={{ marginTop: "10px" }}>
                  {report[0]}: {report[1]}
                </Typography>
              ),
            )}
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            Reportes con error de subida SM
            {Object.entries(reportStatus[2].upload_error_reports).map(
              (report) => (
                <Typography variant="body1" style={{ marginTop: "10px" }}>
                  {report[0]}: {report[1]}
                </Typography>
              ),
            )}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <div>
        <CustomizedSnackbars
          severity={snackbarStatus.severity}
          mssg={snackbarStatus.message}
          open={snackbarStatus.open}
          onClose={onCloseSnackbar}
          duration={0}
        />
      </div>
      <PageTitle
        title="Generar reportes mensuales"
        // button={<img src={logo} alt="logo" className={classes.logotypeImage} />}
      />
      <Paper
        elevation={3}
        style={{ borderRadius: "10px", marginBottom: "5vh" }}
      >
        <Grid
          container
          style={{
            minHeight: "250px",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
          justifyContent="flex-end"
        >
          <Grid
            container
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5vh",
            }}
          >
            <span style={{ paddingBottom: "50px", marginLeft: "3vw" }}>
              Período:
            </span>
            <DateField
              helperText="Fecha desde (mes y año)"
              views={["year", "month"]}
              value={selectedPeriod}
              minDate={new Date("2013-10-01")}
              maxDate={getCurrentDate()}
              format={"MM/yyyy"}
              name="begin"
              //value={filters.beginDate ? filters.beginDate : getCurrentDate()}
              onChange={handlePeriodChange}
              fieldClassName={classes.smallField}
            />
          </Grid>
          <Grid
            container
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "30px",
            }}
          >
            <span style={{ marginTop: ".5vh" }}>Tipo de reporte:</span>
            <SelectField
              fieldClassName={classes.generalPurposeItems}
              id="report_type"
              name="report_type"
              value={selectedReportType === -1 ? "" : selectedReportType}
              onChange={(event) => {
                setSelectedReportType(event.target.value);
              }}
              allValues={REPORT_TYPES}
            />
          </Grid>
          <Grid
            container
            style={{
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              marginBottom: "20px",
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={
                  isLoading || !selectedPeriod || selectedReportType === -1
                }
                style={{ marginRight: "15px" }}
                className={classes.sendField}
                color="primary"
                variant="contained"
                size="large"
                onClick={handleReportRequest}
              >
                Generar reportes
              </Button>
              <Button
                disabled={isLoading || !selectedPeriod}
                style={{ marginRight: "15px" }}
                color="secondary"
                variant="contained"
                size="large"
                onClick={handleSummaryRequest}
              >
                Generar resumen
              </Button>
              <Tooltip title="Zippear">
                <Button
                  style={{
                    position: "absolute",
                    right: "7.5%",
                  }}
                  size="large"
                  disabled={isLoading || !selectedPeriod}
                  color="warning"
                  aria-label="zip files"
                  variant="contained"
                  onClick={handleZipRequest}
                >
                  <FolderZipIcon /> &nbsp; Zip
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {isLoading && !isTraceLoading ? (
          <LinearProgressWithLabel
            value={progress.progress}
            label={progress.status}
            variant={
              progress.status?.includes("Saving")
                ? "indeterminate"
                : "determinate"
            }
            className={classes.linearProgressField}
          />
        ) : (
          <div style={{ marginBottom: "0.5%" }} />
        )}
      </Paper>
      {reportStatusDiv()}
      <PageTitle title="Generar reportes de trazabilidad mensuales" />
      <Paper
        elevation={3}
        style={{ borderRadius: "10px", marginBottom: "5vh" }}
      >
        <Grid
          container
          style={{
            borderRadius: "10px",
            justifyContent: "center",
            display: "flex",
            padding: 25,
          }}
          justifyContent="center"
        >
          <Stack spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography weight="bold">Ciclo</Typography>
              <SelectField
                divClassName={classes.generalPurposeItemsCycle}
                fieldClassName={classes.yearSelectionField}
                id="beginYear"
                label="Seleccione"
                name="beginYear"
                value={filters.beginYear}
                onChange={(event) => handleSelectorChange(event, "beginYear")}
                allValues={allYears}
              />
              <SelectField
                divClassName={classes.generalPurposeItemsCycle}
                fieldClassName={classes.yearSelectionField}
                id="beginCycle"
                label="Seleccione"
                name="beginCycle"
                value={filters.beginCycle}
                onChange={(event) => handleSelectorChange(event, "beginCycle")}
                allValues={allCycles.filter((cycleInfo) =>
                  cycleInfo.id.toString().includes(filters.beginYear),
                )}
              />
            </Stack>
          </Stack>
          <Grid
            container
            style={{
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              marginBottom: "20px",
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                marginTop: "2.5%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                className={classes.sendField}
                color="primary"
                variant="contained"
                size="large"
                onClick={handleTraceReportRequest}
              >
                Generar reportes trazabilidad
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {isLoading && isTraceLoading ? (
          <LinearProgressWithLabel
            value={progress.progress}
            label={progress.status}
            variant={
              progress.status?.includes("Saving")
                ? "indeterminate"
                : "determinate"
            }
            className={classes.linearProgressField}
          />
        ) : (
          <div style={{ marginBottom: "0.5%" }} />
        )}
      </Paper>
      <PageTitle title="Recordatorio cupones" />
      <Paper elevation={3} style={{ borderRadius: "10px" }}>
        <Grid
          container
          style={{
            borderRadius: "10px",
            justifyContent: "center",
            display: "flex",
            padding: 25,
          }}
          justifyContent="center"
        >
          <Stack spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Grid
                container
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "5vh",
                }}
              >
                <span style={{ paddingBottom: "50px", marginLeft: "3vw" }}>
                  Período:
                </span>
                <DateField
                  helperText="Seleccionar período para poder generar"
                  views={["year", "month"]}
                  value={reminderToPeriod}
                  minDate={new Date("2013-10-01")}
                  maxDate={getCurrentDate()}
                  format={"MM/yyyy"}
                  name="begin"
                  onChange={(value) => setReminderToPeriod(value)}
                  fieldClassName={classes.smallField}
                />
              </Grid>
            </Stack>
          </Stack>
          <Grid
            container
            style={{
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              marginBottom: "20px",
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                marginTop: "2.5%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={!reminderToPeriod || remindersIsLoading}
                style={{ marginRight: "15px" }}
                className={classes.sendField}
                color="primary"
                variant="contained"
                size="large"
                onClick={generateReport}
              >
                Generar recordatorios
              </Button>
              <Button
                disabled={remindersIsLoading}
                className={classes.sendField}
                color="secondary"
                variant="contained"
                size="large"
                onClick={sendReport}
              >
                Enviar recordatorios
              </Button>
            </Grid>
            {remindersIsLoading ? (
              <LinearProgressWithLabel
                value={progress.progress}
                label={progress.status}
                variant={
                  progress.status?.includes("Saving")
                    ? "indeterminate"
                    : "determinate"
                }
                className={classes.linearProgressField}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
