import React, { useState } from "react";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import routes from "routes";
import moment from "moment";

// components
import DateField from "components/CustomFields/DateField";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import LoaderBar from "components/LoaderBar/LoaderBar";
import PageTitle from "components/PageTitle/PageTitle";
import {
  Button,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import logo from "images/logoPagesRS.jpg";

// functions
import { dbGet } from "utils/DBFetchers.js";

// styles
import useStyles from "./styles";

const PRESENTATION_ID_POS = 1;

export default function ABMSalcobrandPage() {
  // Table columns definition
  const columns = [
    {
      name: " ",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", minWidth: 50 },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    redirectToEditSalcobrand(
                      tableMeta.rowData[PRESENTATION_ID_POS],
                    );
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "Presentation id",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "Producto",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "SKU",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Descuento válido hasta",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        sortCompare: (order) => {
          return (a, b) => {
            var dateSplitA = a.data.split("/");
            var dateSplitB = b.data.split("/");
            const dateA = new Date(
              dateSplitA[2],
              dateSplitA[1],
              dateSplitA[0],
            ).getTime();
            const dateB = new Date(
              dateSplitB[2],
              dateSplitB[1],
              dateSplitB[0],
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === "desc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "1° compra",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",

            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>1° compra (%)</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>SB</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "1° ABT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table stickyHeader={true}>
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ABT</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "2° compra",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",

            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>2° compra (%)</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>SB</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "2° ABT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table stickyHeader={true}>
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ABT</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "3° compra",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",

            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>3° compra (%)</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>SB</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "3° ABT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table stickyHeader={true}>
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ABT</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "4° compra",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",

            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>4° compra (%)</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>SB</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "4° ABT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table stickyHeader={true}>
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ABT</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "5° compra",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",

            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>5° compra (%)</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>SB</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "5° ABT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table stickyHeader={true}>
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ABT</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "6° compra",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",

            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>6° compra (%)</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>SB</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "6° ABT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table stickyHeader={true}>
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ABT</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "7° compra",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",

            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>7° compra (%)</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>SB</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "7° ABT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table stickyHeader={true}>
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ABT</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "8° compra",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",

            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>8° compra (%)</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>SB</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "8° ABT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table stickyHeader={true}>
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ABT</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "9° compra",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",

            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>9° compra (%)</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>SB</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "9° ABT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table stickyHeader={true}>
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ABT</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "10° compra",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",

            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>10° compra (%)</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>SB</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "10° ABT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table stickyHeader={true}>
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ABT</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "11° compra",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",

            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>11° compra (%)</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>SB</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "11° ABT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table stickyHeader={true}>
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ABT</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "12° compra",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",

            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>12° compra (%)</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>SB</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "12° ABT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table stickyHeader={true}>
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ABT</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
  ];

  var classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  var [isLoading, setIsLoading] = useState(false);
  const [dateFilter, setDateFilter] = React.useState(null);

  const redirectToEditSalcobrand = (id) => {
    history.push(routes.abmSalcobrandEdit, {
      presentationId: id,
    });
  };

  const RedirectToNewVademecum = () => {
    history.push(routes.abmSalcobrandNew);
  };

  const handleApplyDateFilter = () => {
    if (!dateFilter) return;
    setTableData([]);
    getResults(
      `presentations/program/4?on_date=${format(
        new Date(dateFilter),
        "dd/MM/yy",
      )}`,
    );
  };

  /* Fills table data */
  const fillTable = (data) => {
    if (!data) return;
    var tableData = data.filter((item) => item.mi_salcobrand);
    tableData = tableData.map((row) => {
      let splitFlattened = row.mi_salcobrand.discount
        .split("/")
        .map((discount) => discount.split(","))
        .flat(); // Makes an array of array of 2 elemtents and flattens it to make an array of all discounts
      return [
        "",
        row.id,
        row.name,
        row.sku,
        row[`next_mi_salcobrand`]
          ? row[`next_mi_salcobrand`].from_date
            ? format(
                moment(row[`next_mi_salcobrand`].from_date, "DD/MM/YY")
                  .subtract(1, "days")
                  .toDate(),
                "dd/MM/yyyy",
              )
            : "Sin expiración"
          : "",
      ].concat(splitFlattened);
    });
    setTableData(tableData);
  };

  /* Get all data */
  const getResults = (category) => {
    setIsLoading(true);
    dbGet(category)
      .then((data) => {
        setIsLoading(false);
        fillTable(data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getResults(`presentations`);
  }, []);

  return (
    <>
      <div>
        <PageTitle
          title="Descuentos Salcobrand"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={RedirectToNewVademecum}
          startIcon={<AddIcon />}
        >
          Añadir
        </Button>
      </div>
      <div className={classes.divDateFilter}>
        <DateField
          name="dateFilter"
          format={"dd/MM/yyyy"}
          value={dateFilter}
          onChange={setDateFilter}
          fieldClassName={classes.dateFilterField}
          placeholder="Fecha"
        />
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonDateFilter}
          onClick={handleApplyDateFilter}
        >
          Ver en fecha
        </Button>
        <Button
          color="secondary"
          variant="contained"
          className={classes.buttonDateFilter}
          onClick={() => {
            setDateFilter(null);
            setTableData([]);
            getResults(`presentations`);
          }}
        >
          Limpiar
        </Button>
      </div>
      {isLoading ? (
        <LoaderBar />
      ) : (
        <Grid
          style={{ display: "flex", margin: 0 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
        >
          <Grid container spacing={3} xs={12}>
            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <CustomMUIDataTable
                title="Descuentos Salcobrand"
                data={tableData}
                columns={columns}
                filterStart={1}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
