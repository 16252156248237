import { saveAs } from "file-saver";
import { write, utils } from "xlsx";

// https://github.com/gregnb/mui-datatables/issues/232#issuecomment-724072649

const excelDownload = (
  columns,
  values,
  start = undefined,
  end = undefined,
  sheetName = "Planilla",
) => {
  columns = columns.slice(start, end); // slice for unwanted columns
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  // build the json, this is one way to do it
  const json = values.reduce((result, val) => {
    const temp = {};
    val.data.slice(start, end).forEach((v, idx) => {
      temp[columns[idx].name] = v;
    });
    result.push(temp);
    return result;
  }, []);
  const fileName = sheetName;
  const ws = utils.json_to_sheet(json);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = write(wb, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });
  saveAs(data, fileName + fileExtension);
  // cancel default  CSV download from table
  return false;
};

export { excelDownload };
