import React from "react";
import Field from "components/CustomFields/Field.js";


/**
 * @component
 * 
 * @param {object} props
 * @param {string} props.rut - El valor actual del RUT que será mostrado en el campo.
 * @param {function} props.onChange - Función que se ejecuta cuando el valor del RUT cambia. Recibe el valor preprocesado.
 * @param {object} props.isRutValid - Objeto que indica si el RUT es válido y un mensaje relacionado.
 * @param {boolean} props.isRutValid.patient - Indicador de si el RUT es válido (true) o no (false).
 * @param {string} props.isRutValid.message - Mensaje que se muestra cuando el RUT no es válido.
 * @param {object} props.classes - Clases de estilo aplicadas a los elementos del componente.
 * @param {string} props.classes.generalPurposeItems - Clase CSS para el contenedor del campo.
 * @param {string} props.classes.generalPurposeField - Clase CSS aplicada al campo de entrada.
 * 
 * @returns {React.Element} El campo de entrada para el RUT con validación.
 */
const SimpleRutInput = ({ rut, onChange, isRutValid, classes }) => {
  const handleRutChange = (e) => {
    // Eliminate any ',', '.' or '-' from the RUT and capitalize any letter
    const cleanedRut = e.target.value
      .replace(/[.,-]/g, '')
      .toUpperCase();

    // Llamo al onChange recibido con el RUT limpio
    onChange({ ...e, target: { ...e.target, value: cleanedRut }});
  };
  
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "54px 1fr",
        columnGap: "1rem",
        rowGap: "0.75rem",
      }}
    >
      <label htmlFor="rut" className={classes.label}>
        RUT
      </label>
      <Field
        required={true}
        id="rut"
        value={rut}
        divClassType={classes.generalPurposeItems3}
        fieldClassType={classes.generalPurposeField}
        onChange={handleRutChange}
        helperText={isRutValid.message}
        error={!isRutValid.patient}
        InputProps={{ autoComplete: "off" }}
      />
    </div>
  );
};

export default SimpleRutInput;