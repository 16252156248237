import React, { useState } from "react";
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";

import useStyles from "./styles";
import DateField from "components/CustomFields/DateField";
import SelectField from "components/CustomFields/SelectField";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

import { getCurrentDate, getNextMonthDate } from "utils/dateGetters.js";
import { format } from "date-fns";
import { dbGet } from "utils/DBFetchers";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import PageTitle from "components/PageTitle/PageTitle";
import logo from "images/logoPagesRS.jpg";

const getDateFormatted = (date) => {
  return format(date, "dd/MM/yy");
};

const blankFilters = {
  begin: getDateFormatted(
    new Date(getCurrentDate().getFullYear(), getCurrentDate().getMonth(), 1),
  ),
  beginDate: new Date(
    getCurrentDate().getFullYear(),
    getCurrentDate().getMonth(),
    1,
  ),
  end: getDateFormatted(
    new Date(
      getNextMonthDate().getFullYear(),
      getNextMonthDate().getMonth(),
      1,
    ),
  ),
  endDate: new Date(
    getNextMonthDate().getFullYear(),
    getNextMonthDate().getMonth(),
    1,
  ),
  distributor: "",
  program: "",
  pattern: "",
};
const allPrograms = [
  { id: 1, name: "Receta solidaria" },
  { id: 2, name: "Solo mujer" },
  { id: 3, name: "Ambos" },
];

const allPatterns = [
  { id: 1, name: "Insc. cupones por RUT de Paciente" },
  { id: 2, name: "Insc. cupones por RUT de Médico" },
  { id: 3, name: "Código de barras del cupón con más de un empadronamiento" },
  { id: 4, name: "Insc. Asociadas a un mismo RUT de Paciente" },
  { id: 5, name: "Insc. Asociadas a un mismo RUT de Médico" },
  { id: 6, name: "Cant. Paciente con compras que exceden beneficio" },
  { id: 7, name: "Cant. Insc. mayores al promedio" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// FALTAN LAS COLUMNAS SEGUN PATRON (SON TODAS DIFERENTES)

export default function PatternsPage() {
  var [isLoading, setIsLoading] = useState(false);
  var classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [filters, setFilters] = useState(blankFilters);
  const [selectedDistributor, setSelectedDistributor] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedPattern, setSelectedPattern] = useState("");
  const [allDistributors, setAllDistributors] = useState([]);
  const [observationName, setObservationName] = React.useState([]);
  const ERRORS_POS = 8;
  const WARNING_POS = 9;

  const columns = {
    1: [
      {
        name: "RUT",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Paciente",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "SKU",
        options: {
          filter: false,
          //display: +selectedGroup === 2 ? true : "excluded",
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Programa",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Cadena",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Cantidad",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
    ],

    2: [
      {
        name: "RUT Médico",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Paciente",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "SKU",
        options: {
          filter: false,
          //display: +selectedGroup === 2 ? true : "excluded",
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Programa",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Cadena",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Cantidad",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
    ],

    3: [
      {
        name: "Cód. barras cupón",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Cantidad",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
    ],

    4: [
      {
        name: "Cód. barras cupón",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "RUT Paciente",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Cantidad",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
    ],

    5: [
      {
        name: "Cód. barras cupón",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "RUT Médico",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Cantidad",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
    ],

    6: [
      {
        name: "RUT Paciente",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "SKU",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
      {
        name: "Cantidad",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
    ],

    7: [
      {
        name: "Sucursal",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },

      {
        name: "Cantidad",
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: { fontWeight: "bold", whiteSpace: "nowrap" },
          }),
          setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        },
      },
    ],
  };

  const handleDateChange = (date) => {
    var dateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var dateOnLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    setFilters({
      ...filters,
      beginDate: dateOnFirstDay,
      begin: getDateFormatted(dateOnLastDay),
      endDate: dateOnLastDay,
      end: getDateFormatted(dateOnLastDay),
    });
  };

  const handleBeginDateChange = (date) => {
    var dateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setFilters({
      ...filters,
      beginDate: dateOnFirstDay,
      begin: getDateFormatted(dateOnFirstDay),
    });
  };

  const handleEndDateChange = (date) => {
    var dateOnFirstDayNext = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      1,
    );
    var dateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setFilters({
      ...filters,
      endDate: dateOnFirstDay,
      end: getDateFormatted(dateOnFirstDayNext),
    });
  };

  const validDateFilters = () => {
    let validDate =
      filters.beginDate &&
      filters.endDate &&
      filters.beginDate > filters.endDate
        ? false
        : true;
    return validDate;
  };

  const handleApplyFilters = () => {
    /* if (!validDateFilters()) {
      snackbarOpen("error", "Rango de fechas inválido");
      return;
    } */

    let pharmacyId = `${
      filters.pharmacies ? `&pharmacy_id=${filters.pharmacies}` : ""
    }`;
    let distributorId = `${
      filters.distributors ? `&distributor_id=${filters.distributors}` : ""
    }`;

    let programId = `&program_id=${localStorage.getItem("programId")}`;

    setIsLoading(true);
    //?from_date=<dd/mm/yy>&to_date=<dd/mm/yy>
    dbGet(
      `inscriptions?from_date=${filters.begin}&to_date=${filters.end}${programId}${pharmacyId}${distributorId}`,
    )
      .then((data) => {
        let tableData = [];
        tableData = data.map((row) => [
          row.id,
          row.pharmacy?.code,
          row.presentation.name,
          format(new Date(row.transaction_date + " EDT"), "dd/MM/yyyy"),
          row.source === 2 ? "Archivo" : "Web",
          "", //row.barcode_value,
          row.patient.rut,
          row.remaining_units,
          row.errors.length === 0 ? "OK" : "parseGetAllErrors(row.errors)",
        ]);
        setTableData(tableData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleDistributorChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
    setSelectedDistributor(event.target.name);
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const handleProgramChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
    setSelectedProgram(event.target.name);
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const handlePatternChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
    setSelectedPattern(event.target.value);
  };

  function getStyles(name, personName) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? classes.fontWeightRegular
          : classes.fontWeightMedium,
    };
  }

  const getDistributors = () => {
    dbGet("distributors").then((data) => {
      data = data.filter((x) =>
        x.programs.find((y) => y.id === +localStorage.getItem("programId")),
      );
      data = data.filter((x) => x.works_with_independents);
      let mapped = data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          isAuthoritarian: item.is_authoritarian,
        };
      });
      setAllDistributors(mapped);
    });
  };

  React.useEffect(() => {
    getDistributors();
  }, []);

  return (
    <>
      <div>
        <PageTitle
          title="Patrones"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        xs={12}
        spacing={4}
      >
        <Grid
          container
          xs={6}
          layout={"row"}
          justify="flex-start"
          spacing={"1"}
        >
          <Grid
            item
            xs={12}
            style={{
              marginLeft: "140px",
              marginBottom: "20px",
            }}
          >
            <Typography variant="h4" className={classes.alignedText}>
              Selección
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={4} className={classes.alignRight}>
            <Typography className={classes.text} weight="bold">
              Desde
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.alignLeft}>
            <div>
              <DateField
                helperText="Fecha desde (mes y año)"
                name="begin"
                views={["year", "month"]}
                minDate={new Date("2013-10-01")}
                maxDate={getCurrentDate()}
                format={"MM/yyyy"}
                value={filters.beginDate ? filters.beginDate : getCurrentDate()}
                onChange={handleBeginDateChange}
                fieldClassName={classes.dateSelectionField}
              />
            </div>
          </Grid>
          <Grid item xs={4} className={classes.alignRight}>
            <Typography className={classes.text} weight="bold">
              Hasta
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.alignLeft}>
            <div>
              <DateField
                helperText={
                  !validDateFilters()
                    ? "Fecha hasta no puede ser menor a fecha desde"
                    : "Fecha hasta (mes y año)"
                }
                name="end"
                views={["year", "month"]}
                minDate={new Date("2013-10-01")}
                maxDate={getCurrentDate()}
                format={"MM/yyyy"}
                value={filters.endDate ? filters.endDate : getCurrentDate()}
                onChange={handleEndDateChange}
                fieldClassName={classes.dateSelectionField}
                error={!validDateFilters()}
              />
            </div>
          </Grid>

          <Grid item xs={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Cadena
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.alignLeft}>
            <SelectField
              fieldClassName={classes.generalSelectionField}
              id="chains"
              label="Seleccione"
              name="chains"
              value={selectedDistributor ? filters[selectedDistributor] : ""}
              onChange={handleDistributorChange}
              allValues={allDistributors ? allDistributors : []}
            />
          </Grid>
          <Grid item xs={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Programa
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.alignLeft}>
            <SelectField
              fieldClassName={classes.generalSelectionField}
              id="programs"
              label="Seleccione"
              name="programs"
              value={selectedProgram ? filters[selectedProgram] : ""}
              onChange={handleProgramChange}
              allValues={allPrograms ? allPrograms : []}
            />
          </Grid>

          <Grid item xs={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Patrón
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.alignLeft}>
            <SelectField
              fieldClassName={classes.generalSelectionField}
              id="pattern"
              label="Seleccione"
              name="pattern"
              value={selectedPattern}
              onChange={handlePatternChange}
              allValues={allPatterns ? allPatterns : []}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttonSpacing}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyFilters}
              >
                Aplicar
              </Button>
            </div>
          </Grid>
        </Grid>
        {isLoading ? (
          <Grid container spacing={3} style={{ justify: "center" }}>
            <div className={classes.loader}>
              <Typography variant="h4" className={classes.loaderText}>
                Cargando datos
              </Typography>
              <LinearProgress size={40} className={classes.loaderBar} />
            </div>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomMUIDataTable
                title="Patrones"
                data={tableData ? tableData : undefined}
                columns={columns[filters.pattern]}
                filterStart={1}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
