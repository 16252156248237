import React, { useState, useEffect } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";
// styles
import useStyles from "./styles";

import { dbGet } from "utils/DBFetchers.js";

import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import LoaderBar from "components/LoaderBar/LoaderBar";

export default function ABMVisitorPage() {
  const history = useHistory();
  var classes = useStyles();
  var [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  // Table columns definition
  const columns = [
    {
      name: " ",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return { style: { fontWeight: "bold", minWidth: 100, width: 100 } };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    RedirectToEditVisitor(tableMeta.rowData[1]);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "Id",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        display: "excluded",
        filter: false,
      },
    },

    {
      name: "Nombre/s",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "RUT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
  ];

  const RedirectToEditVisitor = (id) => {
    history.push(routes.abmVisitorsEdit, { visitorId: id });
  };

  const RedirectToNewVisitor = () => {
    history.push(routes.abmVisitorsNew);
  };

  /* Fills table data */
  const fillTable = (data) => {
    if (!data) return;
    var tableData = data.map((row) => ["", row.id, row.name, row.rut]);
    setTableData(tableData);
  };

  /* Get all data */
  const getResults = (category) => {
    setIsLoading(true);
    dbGet(category)
      .then((data) => {
        fillTable(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  /* On page load, get all data */
  useEffect(() => {
    getResults(`consultants`);
  }, []);

  return (
    <>
      <div>
        <PageTitle
          title="Visitadores"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={RedirectToNewVisitor}
          startIcon={<AddIcon />}
        >
          Añadir
        </Button>
      </div>

      {isLoading ? (
        <LoaderBar />
      ) : (
        <Grid
          style={{ display: "flex", margin: 0 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
          xs={12}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <CustomMUIDataTable
                title="Visitadores"
                data={tableData}
                columns={columns}
                filterStart={1}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
