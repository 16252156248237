import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

import { dbGet } from "utils/DBFetchers.js";
import logo from "images/logoPagesRS.jpg";
// styles
import useStyles from "./styles";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import { getRoleName } from "utils/roleNameGetter";
import routes from "routes";
import { useHistory } from "react-router-dom";

// roles
import { roleTranslation } from "pages/users/roles/utils";

export default function RolesPage() {
  var classes = useStyles();
  const history = useHistory();
  const ROLE_ID_POS = 0;
  const columns = [
    {
      name: " ",
      options: {
        download: false,
        filter: false,
        sort: false,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    redirectToEditRole(tableMeta.rowData[ROLE_ID_POS]);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "Rol",
      options: {
        setCellHeaderProps: () => {
          return { style: { fontWeight: "bold" } };
        },
      },
    },
    {
      name: "Permisos",
      options: {
        filter: false,
        setCellHeaderProps: () => {
          return { style: { fontWeight: "bold" } };
        },
      },
    },
  ];

  const [tableData, setTableData] = useState([]);

  const fillTable = (data) => {
    if (!data) return;
    if (getRoleName() !== "super_admin") {
      data = data.filter((role) => role.name !== "super_admin");
    }
    var tableData = data.map((row) => [
      row.id,
      row.name in roleTranslation ? roleTranslation[row.name] : row.name,
      row.permissions.join(" | "),
    ]);
    setTableData(tableData);
  };

  const getResults = (category) => {
    dbGet(category).then((data) => {
      fillTable(data);
    });
  };

  React.useEffect(() => {
    getResults("roles");
  }, []);

  const redirectToEditRole = (id) => {
    history.push(routes.usersEditRole, { roleId: id });
  };

  const redirectToNewRole = () => {
    history.push(routes.usersNewRole);
  };

  return (
    <>
      <div>
        <PageTitle
          title="Roles"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={redirectToNewRole}
          startIcon={<AddIcon />}
        >
          Añadir
        </Button>
      </div>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <CustomMUIDataTable
              title="Roles"
              data={tableData}
              columns={columns}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
