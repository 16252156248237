import React from "react";
import useStyles from "../../pages/dashboard/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Typography as CustomTypography } from "components/Wrappers/Wrappers";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell key="rowHeader"> {props.title}</StyledTableCell>
        {props.columns.map((column) => (
          <StyledTableCell key={column} align="center" padding={"normal"}>
            {column}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function formatNumber(value) {
  return value
    ? Intl.NumberFormat("es-AR").format(Math.round(value * 100) / 100)
    : 0;
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const DividerTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#D4D1E8CC",
  },
}))(TableRow);

const TableCellOverridedPadding = withStyles((theme) => ({
  root: {
    padding: 12,
  },
}))(TableCell);

// It's inverted because rows should be columns and vice versa, so we can't use MUITable's default behavior.
function InvertedTable(props) {
  const { rows, columns } = props;
  const classes = useStyles();

  const createIndividualCell = (value) => {
    if (typeof value === "number") {
      // enters here if value is a number
      return (
        <TableCellOverridedPadding align="center">
          {formatNumber(value)}
        </TableCellOverridedPadding>
      );
    }
    if (!value.includes("/")) {
      // enters here if format is 0.0 (a float as a string)
      return <TableCell align="center">{value}</TableCell>;
    }
    // should only enter here for kpis, with expected format: 'value / goal'
    let valuesArray = value.split("/");

    let monthValue = Math.abs(parseInt(valuesArray[0].trim()));
    let goal = parseInt(valuesArray[1].trim());
    return monthValue && goal && monthValue > goal ? (
      <TableCell style={{ color: "red" }} align="center">
        {value}
      </TableCell>
    ) : (
      <TableCell align="center">{value}</TableCell>
    );
  };

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={"medium"}
        aria-label="enhanced table"
      >
        <EnhancedTableHead
          title={props.title}
          columns={columns}
          classes={classes}
          rowCount={rows.length}
        />
        <TableBody>
          {rows.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;

            return row.category === "data" ? (
              <StyledTableRow hover tabIndex={-1} key={row}>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  style={{ width: 400 }}
                >
                  <CustomTypography weight="bold">{row.label}</CustomTypography>
                </TableCell>
                {row.data.map((value) => createIndividualCell(value))}
              </StyledTableRow>
            ) : (
              <DividerTableRow tabIndex={-1} key={row}>
                <TableCell component="th" id={labelId}>
                  <CustomTypography weight="bold">{row.label}</CustomTypography>
                </TableCell>
              </DividerTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default InvertedTable;
