import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// logo
import logo from "images/logoAbbott.png";

// context
import { useUserDispatch, loginUser } from "context/UserContext";
import { useHistory } from "react-router-dom";
import routes from "routes";
import StagingTag from "components/StagingTag";

const SUBHEADER_URL = "cupones.receta-solidaria.com";

function Login(props) {
  var classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1280));

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState({ message: "", state: null });
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const [displaySubheader, setDisplaySubheader] = useState(false);

  const history = useHistory();
  const RedirectToPasswordReset = () => {
    history.push(routes.passwordResetRequest);
  };

  useEffect(() => {
    const url = window.location.hostname;
    if (url === SUBHEADER_URL) {
      setDisplaySubheader(true);
    }
  }, []);

  const isFormValid = () => {
    return loginValue.trim().length > 0 && passwordValue.trim().length > 0;
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img
          src={logo}
          alt="img-responsive"
          className={classes.logotypeImage}
        />
        <StagingTag className={classes.stagingTag} />
        {displaySubheader && <h1>Cupones de descuento</h1>}
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          {
            <React.Fragment>
              <form>
                {!isMobile ? null : (
                  <>
                    <img
                      src={logo}
                      alt="img-responsive"
                      style={{ marginLeft: "28%", width: "40%" }}
                    />
                    <StagingTag className={classes.stagingTagMobile} />
                    {displaySubheader && (
                      <h2 style={{ textAlign: "center" }}>
                        Cupones de descuento
                      </h2>
                    )}
                  </>
                )}
                <Typography variant="h1" className={classes.greeting}>
                  Ingresar
                </Typography>
                <div className={classes.formDividerContainer}>
                  <div className={classes.formDivider} />
                </div>
                <Fade in={error.state}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error.message}
                  </Typography>
                </Fade>
                <TextField
                  id="username"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={loginValue}
                  onChange={(e) => setLoginValue(e.target.value)}
                  margin="normal"
                  placeholder="Usuario"
                  fullWidth
                />
                <TextField
                  id="password"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={passwordValue}
                  onChange={(e) => setPasswordValue(e.target.value)}
                  margin="normal"
                  placeholder="Contraseña"
                  type="password"
                  fullWidth
                />
                <div className={classes.formButtons}>
                  {isLoading ? (
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  ) : (
                    <Button
                      disabled={!isFormValid()}
                      onClick={() =>
                        loginUser(
                          userDispatch,
                          loginValue,
                          passwordValue,
                          props.history,
                          setIsLoading,
                          setError,
                        )
                      }
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Ingresar
                    </Button>
                  )}
                  <Button
                    color="primary"
                    size="large"
                    className={classes.forgetButton}
                    onClick={RedirectToPasswordReset}
                  >
                    Olvidé mi contraseña
                  </Button>
                </div>
              </form>
            </React.Fragment>
          }
        </div>
        <Typography color="primary" className={classes.copyright}>
          By Dynaxo Corp.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
