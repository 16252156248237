import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Autocomplete } from "@material-ui/lab";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import DateField from "components/CustomFields/DateField";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { Stack, CircularProgress } from "@mui/material";
import { dbGet } from "utils/DBFetchers";
import { getCurrentDate } from "utils/dateGetters.js";
import SelectField from "components/CustomFields/SelectField";
// styles
import useStyles from "./styles";

const BLANK_CONSULTANTS = [{ id: "Todos", name: "Todos" }];

const FILTERS = {
  beginYear: 0,
  endYear: 0,
  beginCycle: 0,
  endCycle: 0,
  program: null,
  zone: null,
  manager: null,
  consultant: null,
};

export default function TraceabilitySelectionForm(props) {
  var classes = useStyles();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [filters, setFilters] = useState(FILTERS);
  const [consultants, setConsultants] = useState(BLANK_CONSULTANTS);
  const [areConsultantsLoading, setAreConsultantsLoading] = useState(false);
  const [managers, setManagers] = useState([]);
  const [allYears, setAllYears] = useState([]);
  const [allCycles, setAllCycles] = useState([]);

  const handleConsultantChange = (event) => {
    setFilters({
      ...filters,
      consultant: event.target.value,
    });
  };

  const handleManagerChange = (e) => {
    setFilters({
      ...filters,
      manager: e.target.value,
      consultant: "Todos",
    });
  };

  const cleanManagers = (managers) => {
    let clean_managers = managers.map((manager) => {
      return {
        id: manager.rut,
        name: manager.name,
      };
    });
    clean_managers.push({ id: "Todos", name: "Todos" });
    return clean_managers;
  };

  const getManagers = () => {
    dbGet(`traceability/district-managers`)
      .then((response) => {
        let managers = cleanManagers(response);
        setManagers(managers);
        setFilters({ ...filters, manager: "Todos" });
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los managers");
      });
  };

  const validDateFilters = () => {
    let validDate =
      filters.beginCycle &&
      filters.endCycle &&
      filters.beginYear &&
      filters.endYear &&
      filters.beginYear <= filters.endYear;
    if (
      filters.beginYear === filters.endYear &&
      filters.beginCycle > filters.endCycle
    ) {
      validDate = false;
    }
    return validDate;
  };

  const validFilters = () => {
    var valid = true;
    if (!validDateFilters()) {
      valid = false;
      snackbarOpen("error", "Ciclo incorrecto");
    }
    return valid;
  };

  const getYearsCycles = () => {
    let years_mapped = [];
    let cycles_mapped = [];
    dbGet("traceability/cycles")
      .then((data) => {
        years_mapped = data.years.map((year) => {
          return { id: parseInt(year), name: year };
        });
        cycles_mapped = data.cycles.map((cycle) => {
          return { id: parseInt(cycle), name: cycle.toString().slice(-2) };
        });
        setFilters({
          ...filters,
          beginYear: parseInt(data.cycles[0].toString().slice(0, 4)),
          endYear: parseInt(data.cycles[0].toString().slice(0, 4)),
          beginCycle: data.cycles[0],
          endCycle: data.cycles[0],
        });
        setAllYears(years_mapped);
        setAllCycles(cycles_mapped);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getManagers();
    getYearsCycles();
  }, []);

  useEffect(() => {
    if (filters.manager && filters.beginCycle && filters.endCycle)
      getManagerConsultants();
  }, [filters.manager, filters.beginCycle, filters.endCycle]);

  const handleApplyFilters = () => {
    if (!validFilters()) return;
    let startingCycle = filters.beginCycle
      ? `?from_cycle=${filters.beginCycle}`
      : "";
    let endingCycle = filters.endCycle ? `&to_cycle=${filters.endCycle}` : "";
    let consultant =
      filters.consultant !== "Todos"
        ? `&consultant_rut=${filters.consultant}`
        : "";
    let manager =
      filters.manager !== "Todos" ? `&manager_rut=${filters.manager}` : "";
    let url = `kpi-control-panel/traceability${startingCycle}${endingCycle}${consultant}${manager}`;
    props.setIsLoading(true);
    dbGet(url)
      .then((response) => {
        setTimeout(updateProgress.bind(null, response.id), 3000);
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los datos");
        props.setIsLoading(false);
      });
  };

  const getManagerConsultants = () => {
    if (!filters.manager) return;

    if (filters.manager === "Todos") {
      setConsultants([{ id: "Todos", name: "Todos" }]);
      setFilters({ ...filters, consultant: "Todos" });
      return;
    }

    let managerRut = filters.manager;
    managerRut = `&manager_rut=${managerRut}`;
    let fromCycle = `&from_cycle=${filters.beginCycle}`;
    let toCycle = `&to_cycle=${filters.endCycle}`; // -1 because it was previously added 1
    setAreConsultantsLoading(true);
    dbGet(`traceability/consultants?${managerRut}${fromCycle}${toCycle}`)
      .then((response) => {
        let consultants = response.map((consultant) => {
          return {
            id: consultant.rut,
            name: consultant.name,
          };
        });
        consultants.push({ id: "Todos", name: "Todos" });
        setConsultants(consultants);
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los consultores");
      })
      .finally(() => {
        setAreConsultantsLoading(false);
      });
  };

  const updateProgress = (id) => {
    dbGet(`request_checker/status?id=${id}`)
      .then((response) => {
        if (response.status === "Completed") {
          let data = response.data;
          props.generateRowsData(data);
          props.setIsLoading(false);
          props.setIsInitialState(false);
        } else if (!response.error_status && response.status !== "Failure") {
          setTimeout(updateProgress.bind(null, id, response), 2000);
        } else {
          snackbarOpen("error", "Error al obtener los datos de la consulta");
          props.setIsLoading(false);
        }
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los datos de la consulta");
        props.setIsLoading(false);
      });
  };

  const handleSelectorChange = (event, beginFilter) => {
    setFilters({
      ...filters,
      [beginFilter]: event.target.value,
      consultant: "Todos",
    });
  };

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        item
        xs={12}
        lg={12}
        style={{ marginBottom: "10px", marginLeft: "2.5%" }}
      >
        <Typography variant="h4" className={classes.alignedText}>
          Selección
        </Typography>
        <Divider />
      </Grid>

      <Grid container justifyContent="center">
        <Grid item container xs={12} spacing={2} justifyContent="center">
          <Grid item container xs={12}>
            <Grid item xs={5} className={classes.alignRight}>
              <Typography className={classes.alignedText} weight="bold">
                Ciclo inicial
              </Typography>
            </Grid>
            <Grid item xs={7} className={classes.alignLeft}>
              <SelectField
                divClassName={classes.generalPurposeItems}
                fieldClassName={classes.yearSelectionField}
                id="beginYear"
                label="Seleccione"
                name="beginYear"
                value={filters.beginYear}
                onChange={(event) => handleSelectorChange(event, "beginYear")}
                allValues={allYears}
              />
              <SelectField
                divClassName={classes.generalPurposeItems}
                fieldClassName={classes.cyclesSelectionField}
                id="beginCycle"
                label="Seleccione"
                name="beginCycle"
                value={filters.beginCycle}
                onChange={(event) => handleSelectorChange(event, "beginCycle")}
                allValues={allCycles.filter((cycleInfo) =>
                  cycleInfo.id.toString().includes(filters.beginYear),
                )}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={5} className={classes.alignRight}>
              <Typography className={classes.alignedText} weight="bold">
                Ciclo final
              </Typography>
            </Grid>
            <Grid item xs={7} className={classes.alignLeft}>
              <Grid item xs={7} className={classes.alignLeft}>
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.yearSelectionField}
                  id="endYear"
                  label="Seleccione"
                  name="endYear"
                  value={filters.endYear}
                  onChange={(event) => handleSelectorChange(event, "endYear")}
                  allValues={allYears}
                />
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.cyclesSelectionField}
                  id="endCycle"
                  label="Seleccione"
                  name="endCycle"
                  value={filters.endCycle}
                  onChange={(event) => handleSelectorChange(event, "endCycle")}
                  allValues={allCycles.filter((cycleInfo) =>
                    cycleInfo.id.toString().includes(filters.endYear),
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={5} className={classes.alignRight}>
              <Typography
                className={classes.alignedTextWithPadding}
                weight="bold"
              >
                Gerente distrito
              </Typography>
            </Grid>
            <Grid item xs={7} className={classes.alignLeft}>
              <SelectField
                divClassName={classes.generalPurposeItems}
                fieldClassName={classes.generalSelectionField}
                id="managers"
                label="Seleccione"
                name="manager"
                value={filters.manager ? filters.manager : ""}
                onChange={handleManagerChange}
                allValues={managers}
              />
            </Grid>
            {areConsultantsLoading ? (
              <Grid
                item
                lg={12}
                xs={12}
                md={12}
                container
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item>
                  <Typography>Calculando consultores...</Typography>
                </Grid>
                <Grid item>
                  <CircularProgress size={26} />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={5} className={classes.alignRight}>
              <Typography
                className={classes.alignedTextWithPadding}
                weight="bold"
              >
                Consultor
              </Typography>
            </Grid>
            <Grid item xs={7} className={classes.alignLeft}>
              <SelectField
                divClassName={classes.generalPurposeItems}
                fieldClassName={classes.generalSelectionField}
                id="consultants"
                label="Seleccione"
                name="consultant"
                value={filters.consultant ? filters.consultant : ""}
                onChange={(e) => {
                  handleConsultantChange(e);
                }}
                disabled={!filters.manager}
                allValues={consultants}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ marginTop: "20px", marginBottom: "10px" }}
            spacing={2}
            className={classes.uploadButton}
          >
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyFilters}
              >
                Aplicar
              </Button>
              <Button
                disabled={props.isLoading || props.isInitialState}
                variant="contained"
                color="secondary"
                onClick={props.exportFunction}
              >
                Exportar
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
