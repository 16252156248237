import React from "react";
import { Route } from "react-router-dom";

// context
import { useUserState } from "../context/UserContext";

export default function AuthorizedRoute({
  component: Component,
  props,
  enabledPermissions,
  ...rest
}) {
  // global
  var { isAuthenticated, permissions } = useUserState(); // Assuming already authenticated

  return (
    <Route
      {...rest}
      render={() => {
        if (
          enabledPermissions.length === 0 ||
          (permissions &&
            enabledPermissions.every((permission) =>
              permissions.includes(permission),
            ) &&
            isAuthenticated)
        ) {
          return <Component props={props} /> // React.createElement(component, props);
        } else {
          return props.history.goBack();
        }
      }}
    />
  );
}
