import React, { useState } from "react";

/* COMPONENTS */
import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

import LinearProgressWithLabel from "components/LinearProgressWithLabel/LinearProgressWithLabel";
import PageTitle from "components/PageTitle/PageTitle";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

/* STYLES */
import useStyles from "./styles";
import logo from "images/logoPagesRS.jpg";

/*  FUNCTIONS AND DATA*/
import { dbDelete, dbPost, dbGet } from "utils/DBFetchers.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import SelectField from "components/CustomFields/SelectField";

const systems = [
  { id: 1, name: "Farmacias" },
  { id: 2, name: "Auditoria" },
];
const types = [
  { id: 1, name: "Empadronamientos" },
  { id: 2, name: "Ventas" },
  { id: 3, name: "Quemables" },
];
const systemsConvert = { 1: "pharmacies", 2: "audit" };
const typesConvert = { 1: "inscriptions", 2: "sales", 3: "burnable" };

const blankDeleteForm = {
  system: "",
  id: "",
  type: "",
};
export default function DeletePage() {
  var classes = useStyles();

  // Useful variables
  var [isLoading, setIsLoading] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [deleteForm, updateForm] = useState(blankDeleteForm);
  const [selectedSystem, setSelectedSystem] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [importId, setImportId] = useState();
  const [progress, setProgress] = useState({ progress: 0, status: "" });
  const [deletedAmount, setDeletedAmount] = useState(-1);
  const [threadId, setThreadId] = useState(0);

  const handleChange = (event, setField) => {
    updateForm({
      ...deleteForm,
      [event.target.name]: event.target.value,
    });
    setField(event.target.value);
  };

  const validForm = () => {
    let validFilters = true;

    if (!deleteForm.system) {
      validFilters = false;
      snackbarOpen("error", "Seleccione un sistema del cual borrar");
    } else if (!deleteForm.type) {
      validFilters = false;
      snackbarOpen("error", "Seleccione el tipo de datos a borrar");
    } else if (!deleteForm.id) {
      validFilters = false;
      snackbarOpen("error", "Ingrese un id a borrar");
    }

    return validFilters;
  };

  const deleteImport = () => {
    if (!validForm()) return;
    setIsLoading(true);
    let type = typesConvert[deleteForm.type];
    let system =
      systemsConvert[deleteForm.system] === "audit"
        ? systemsConvert[deleteForm.system] + "/"
        : ""; // When deleting pharmacies system doesnt need to be in the endpoint
    dbDelete(`${system}${type}/del/import/${importId}`)
      .then((response) => {
        setDeletedAmount(-1);
        setThreadId(response.id);
        setTimeout(updateProgress.bind(null, response.id), 5000);
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error.response && error.response.data
            ? error.response.data.description_es
            : "Ocurrió un error al borrar la importación",
        );
        setIsLoading(false);
      });
  };

  const updateProgress = (id, data) => {
    dbGet(`request_checker/status?id=${id}`)
      .then((data) => {
        setProgress({
          ...progress,
          progress: data.progress ? data.progress : 0,
          status: data.status,
        });
        if (data.status === "Completed") {
          snackbarOpen("success", "Importación borrada correctamente!");
          setDeletedAmount(data.data.deleted);
          setProgress({ progress: 0, status: "" });
          setIsLoading(false);
          // setUploadState(true);
        } else if (!data.error_status && data.status !== "Failure") {
          setTimeout(updateProgress.bind(null, id, data), 2000);
        } else {
          snackbarOpen(
            "error",
            data.error_message
              ? data.error_message.description_es
              : "El archivo puede contener errores y no se pudo cargar.",
          );
          setIsLoading(false);
          // setUploadState(true);
        }
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error.error_message
            ? error.error_message.description_es
            : "El archivo puede contener errores y no se pudo cargar.",
        );
        setIsLoading(false);
        // setUploadState(true);
      });
  };

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <div>
        <PageTitle
          title="Borrar"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justifyContent="space-evenly"
        spacing={4}
        lg={12}
        xs={12}
      >
        <Grid item xs={12} lg={12} spacing={3}>
          <div className={classes.smallItems}>
            <Typography variant="h3" className={classes.text}>
              Borrar importaciones
            </Typography>
          </div>
          <Divider style={{ width: "20%" }} />
        </Grid>
        <Grid
          container
          justifyContent="flex-start"
          item
          xs={12}
          lg={12}
          md={12}
        >
          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Sistema
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} className={classes.alignLeft}>
            <SelectField
              fieldClassName={classes.generalSelectionField}
              id="systems"
              label="Seleccione"
              name="system"
              value={deleteForm.system}
              onChange={(e) => {
                handleChange(e, setSelectedSystem);
              }}
              allValues={systems}
            />
          </Grid>
          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Tipo
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            md={8}
            className={classes.alignLeft}
            style={{ marginTop: "20px" }}
          >
            <SelectField
              fieldClassName={classes.generalSelectionField}
              id="types"
              label="Seleccione"
              name="type"
              value={deleteForm.type}
              onChange={(e) => {
                handleChange(e, setSelectedType);
              }}
              allValues={types}
            />
          </Grid>
          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Import ID
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            md={8}
            style={{ marginTop: "20px" }}
            generalSelectionFieldclassName={classes.alignLeft}
          >
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              value={importId}
              label="ID"
              name="id"
              className={classes.generalSelectionField}
              onChange={(e) => {
                handleChange(e, setImportId);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={4} md={6} spacing={3}>
          <Grid item xs={8} lg={8} className={classes.realCenter}>
            {isLoading ? (
              <Grid style={{ width: "100%", marginLeft: 30 }}>
                <Grid>
                  <LinearProgressWithLabel
                    value={progress.progress}
                    label={"Borrando"}
                    className={classes.linearProgressField}
                    variant="determinate"
                  />
                </Grid>
                <Grid style={{ marginTop: 15 }}>Thread ID: {threadId}</Grid>
              </Grid>
            ) : (
              <Button
                className={classes.sendField}
                variant="contained"
                color="primary"
                onClick={deleteImport}
              >
                Borrar
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ textAlign: "center", marginLeft: "-10%" }}>
        {deletedAmount > -1 && <h3>Cantidad de eliminados: {deletedAmount}</h3>}
      </Grid>
    </>
  );
}
