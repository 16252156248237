import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  stagingTag: {
    backgroundColor: "#4A4A4A1A",
    padding: "5px 10px",
    borderRadius: "5px",
    color: "#000",
    fontWeight: "bold",
    textAlign: "center",
    margin: "0 auto",
  },
}));
