import React from "react";

// styles
import useStyles from "./styles";
import { Typography } from "../Wrappers/Wrappers";
import { TextField } from "@material-ui/core";

export default function Field(props) {
  var classes = useStyles();

  return (
    <>
      <div className={props.divClassType}>
        <Typography className={classes.text} weight="bold">
          {props.labelName}
        </Typography>
        <div className={props.divClassType2}>
          <TextField
            required={props.required ? props.required : false}
            id={props.id}
            name={props.id}
            className={props.fieldClassType}
            variant="outlined"
            margin="dense"
            type={props.type}
            helperText={props.helperText}
            onChange={props.onChange}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.disabled}
            defaultValue={props.defaultValue}
            value={props.value}
            error={props.error}
            style={props.style}
            placeholder="RUT"
            InputProps={props.InputProps1}
          />
          <TextField
            disabled={props.disabled}
            className={props.fieldClassType2}
            variant="outlined"
            margin="dense"
            required={props.required ? props.required : false}
            id={props.id2}
            name={props.id}
            type={props.type}
            InputLabelProps={{
              shrink: true,
            }}
            error={props.error}
            style={props.style}
            defaultValue={props.defaultValue}
            value={props.value2}
            onChange={props.onChange2}
            placeholder="DV"
            InputProps={props.InputProps2}
          ></TextField>
        </div>
      </div>
    </>
  );
}
