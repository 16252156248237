import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "../styles";
import DoneAllIcon from "@material-ui/icons/DoneAll";

// logo
import logo from "images/logoLoginRS.png";

import routes from "routes";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { Reset } from "utils/DBFetchers.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

function PasswordResetRequest(props) {
  let classes = useStyles();
  // local
  let [isLoading, setIsLoading] = useState(false);
  let [error, setError] = useState(false);
  let [emailValue, setEmailValue] = useState("");
  let [userValue, setUserValue] = useState("");
  let [isInUserForm, setIsInUserForm] = useState(false);
  let [successfulSend, setSuccessfulSend] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const handleSubmit = (e) => {
    if (!isInUserForm) return;
    e.preventDefault();
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let userData = { email: emailValue, user: userValue };
    if (!re.test(emailValue)) {
      setError(true);
      return;
    }
    setIsLoading(true);

    Reset(userData)
      .then((data) => {
        setSuccessfulSend(true);
        setError(false);
      })
      .catch((error) => {
        setSuccessfulSend(false);
        setIsLoading(false);
        setError(true);
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al enviar el mail",
        );
      });
  };

  const handleNext = () => {
    setIsInUserForm(true);
  };
  const handleEmailChange = (e) => {
    e.preventDefault();
    setError(false);
    setEmailValue(e.target.value);
  };
  const handleUserChange = (e) => {
    e.preventDefault();
    setError(false);
    setUserValue(e.target.value);
  };
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
        <CustomizedSnackbars
          severity={snackbarStatus.severity}
          mssg={snackbarStatus.message}
          open={snackbarStatus.open}
          onClose={onCloseSnackbar}
        />
        <div className={classes.form}>
          {
            <React.Fragment>
              <form>
                {successfulSend ? (
                  <Grid align="center">
                    <DoneAllIcon
                      fontSize="large"
                      style={{ marginBottom: 20 }}
                    ></DoneAllIcon>
                    <Typography variant="h4" align="center">
                      Se ha enviado un correo a
                      <Typography
                        variant="h4"
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        {emailValue}
                      </Typography>
                      para reestablecer la constraseña
                    </Typography>
                    <Button
                      onClick={() => props.history.push(routes.login)}
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.backButtonsPassReset}
                    >
                      Volver
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <div>
                      <Typography
                        variant="h3"
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        Reestablece tu contraseña
                      </Typography>{" "}
                    </div>
                    <div className={classes.formDividerContainer}>
                      <div className={classes.formDivider} />
                    </div>
                    <Typography variant="h5" align="center">
                      {isInUserForm
                        ? "Ingrese su usuario"
                        : "Ingresa tu correo electrónico para recuperar la contraseña"}
                    </Typography>
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {isInUserForm
                          ? "El usuario es incorrecto"
                          : "El email es inválido"}
                      </Typography>
                    </Fade>
                    {isInUserForm ? (
                      <>
                        <TextField
                          id="user"
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={userValue}
                          onChange={handleUserChange}
                          margin="normal"
                          placeholder="Usuario"
                          type="user"
                          fullWidth
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          id="email"
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          value={emailValue}
                          onChange={handleEmailChange}
                          margin="normal"
                          placeholder="Email"
                          type="email"
                          fullWidth
                        />
                      </>
                    )}
                    <div className={classes.formButtons}>
                      <Button
                        onClick={() => props.history.push(routes.login)}
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        Volver
                      </Button>
                      {isLoading ? (
                        <CircularProgress
                          size={26}
                          className={classes.loginLoader}
                          style={{ marginRight: "27%" }}
                        />
                      ) : (
                        <Button
                          disabled={
                            isInUserForm
                              ? userValue.length === 0
                              : emailValue.length === 0
                          }
                          onClick={isInUserForm ? handleSubmit : handleNext}
                          type={isInUserForm ? "submit" : ""}
                          variant="contained"
                          color="primary"
                          size="large"
                          style={{ width: "60%" }}
                        >
                          {isInUserForm ? "Enviar" : "Siguiente"}
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </form>
            </React.Fragment>
          }
        </div>
        <Typography color="primary" className={classes.copyright}>
          By Dynaxo Corp.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(PasswordResetRequest);
