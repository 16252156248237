import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import routes from "routes";
import moment from "moment";
import { format } from "date-fns";

// components
import NumberFormatter from "components/CustomFields/NumberFormatter";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import logo from "images/logoPagesRS.jpg";
import SelectField from "components/CustomFields/SelectField";
import PageTitle from "components/PageTitle/PageTitle";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Typography } from "components/Wrappers/Wrappers";
import Field from "components/CustomFields/Field.js";
import DateField from "components/CustomFields/DateField.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

// functions
import { dbGet, dbPut } from "utils/DBFetchers.js";
import { getCurrentDate } from "utils/dateGetters.js";
import { cleanEmptyKeys } from "utils/cleanEmptyKeys";

// styles
import useStyles from "./styles";

const blankEditSalcobrandForm = {
  id: "",
  nextDiscount: "",
  nextOnDate: null,
  onDate: null,
  name: "",
  sku: "",
  programId: "",
  nextDiscountsSalco: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  nextDiscountsAbbott: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  distinctsDiscounts: 1,
};

const allDiscounts = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 6, name: 6 },
  { id: 8, name: 8 },
  { id: 9, name: 9 },
  { id: 10, name: 10 },
  { id: 11, name: 11 },
  { id: 12, name: 12 },
];

export default function ABMdSalcobrandsEditPage(props) {
  var classes = useStyles();
  const history = useHistory();
  var [isLoading, setIsLoading] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [tableData, setTableData] = useState([]);
  const [editSalcobrandForm, updateForm] = useState(blankEditSalcobrandForm);
  const [nextDiscountCheck, setNextDiscountCheck] = useState({
    discount: null,
    programId: null,
    onDate: null,
  });
  const [isDiscountValid, setIsDiscountValid] = useState({
    discount: true,
    message: "",
  });

  const handleChange = (event) => {
    updateForm({
      ...editSalcobrandForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleDateChange = (date) => {
    updateForm({
      ...editSalcobrandForm,
      nextOnDate: date,
    });
  };

  const handleDiscountChange = (event) => {
    let split = event.target.name.split("-");
    let index = +split[1] - 1;
    let discountType = split[0];
    let array = editSalcobrandForm[discountType];
    array[index] = event.target.value;
    updateForm({
      ...editSalcobrandForm,
      [discountType]: array,
    });
    if (
      +event.target.value > 100 ||
      +event.target.value < 0 ||
      event.target.value === "-"
    ) {
      setIsDiscountValid({
        discount: false,
        message: "Descuento no puede ser mayor a 100% o menor a 0%.",
      });
    } else {
      setIsDiscountValid({ discount: true, message: "" });
    }
  };

  /* Get all data */
  const getdSalcobrandInfo = (presentationId, programID) => {
    dbGet(`presentations/${presentationId}`).then((data) => {
      updateForm({
        ...editSalcobrandForm,
        id: presentationId,
        programId: programID,
        name: data.presentation.name,
        sku: data.presentation.sku,
        onDate: data.presentation.mi_salcobrand
          ? data.presentation.mi_salcobrand.to_date
            ? moment(data.presentation.mi_salcobrand.to_date, "DD/MM/YY")
                .subtract(1, "days")
                .toDate()
            : null
          : null,
        nextDiscount: data.presentation.next_mi_salcobrand
          ? data.presentation.next_mi_salcobrand.discount
          : "",
        nextOnDate: data.presentation.next_mi_salcobrand
          ? moment(
              data.presentation.next_mi_salcobrand.from_date,
              "DD/MM/YY",
            ).toDate()
          : null,
      });
      var discounts = data.presentation.mi_salcobrand
        ? data.presentation.mi_salcobrand.discount.split("/")
        : [];
      var tableData = discounts.map((row, index) => {
        var split = row.split(",");
        return {
          id: index + 1,
          discountNumber: index + 1,
          salcobrand: split[0],
          abbott: split[1],
        };
      });
      setTableData(tableData);
      if (
        data.presentation.next_mi_salcobrand &&
        data.presentation.mi_salcobrand
      ) {
        setNextDiscountCheck({
          discount: data.presentation.next_mi_salcobrand.discount,
          programId: data.presentation.next_mi_salcobrand.program_id,
          onDate: moment(
            data.presentation.next_mi_salcobrand.from_date,
            "DD/MM/YY",
          ).toDate(),
        });
      }
    });
  };

  /* Basic form check */
  const checkFormComplete = () => {
    var isCompleted = true;
    if (!editSalcobrandForm.id) {
      isCompleted = false;
      snackbarOpen("error", "Error al obtener el id");
    } else if (!editSalcobrandForm.name) {
      isCompleted = false;
      snackbarOpen("error", "Error al obtener el nombre");
    } else if (!editSalcobrandForm.sku) {
      isCompleted = false;
      snackbarOpen("error", "Error al obtener el SKU");
    } else if (!isDiscountValid.discount) {
      isCompleted = false;
      snackbarOpen("error", "Ingrese descuentos válidos");
    } else if (!editSalcobrandForm.nextOnDate) {
      isCompleted = false;
      snackbarOpen("error", "Selecciones la fecha de próximo descuento");
    } else if (editSalcobrandForm.nextDiscountsSalco[0] === 0) {
      isCompleted = false;
      snackbarOpen("error", "Descuento Salcobrand 0%");
    }
    return isCompleted;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      return;
    }
    setIsLoading(true);

    let discounts = [];

    for (let i = 0; i < 12; i++) {
      let distinctsDiscounts = editSalcobrandForm.distinctsDiscounts;
      if (i < distinctsDiscounts) {
        discounts.push(
          editSalcobrandForm.nextDiscountsSalco[i] +
            "," +
            editSalcobrandForm.nextDiscountsAbbott[i],
        );
      } else {
        discounts.push(
          editSalcobrandForm.nextDiscountsSalco[distinctsDiscounts - 1] +
            "," +
            editSalcobrandForm.nextDiscountsAbbott[distinctsDiscounts - 1],
        );
      }
    }

    // Give adecuate format to send form to db
    let completedForm = {
      name: editSalcobrandForm.name,
      sku: +editSalcobrandForm.sku,
      next_mi_salcobrand: {
        discount: discounts.join("/"), // "12,18/12,20/"
        on_date: format(new Date(editSalcobrandForm.nextOnDate), "dd/M/yy"),
      },
    };

    // Sends to db
    dbPut(`presentations/${editSalcobrandForm.id}`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Descuento editado exitosamente");
        redirectTodSalcobrandsPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al editar descuento",
        ); // translate pending
        setIsLoading(false);
        return;
      });
  };

  /* Cancel editing discount and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankEditSalcobrandForm);
    history.goBack();
  };

  /* Redirect to discounts table page after editing correctly */
  const redirectTodSalcobrandsPage = (user) => {
    setTimeout(() => {
      updateForm(blankEditSalcobrandForm);
      setIsLoading(false);
      history.push(routes.abmSalcobrand);
    }, 3000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    if (props.props.location.state) {
      getdSalcobrandInfo(
        props.props.location.state.presentationId,
        props.props.location.state.programId,
      );
    }
  }, []);

  const title = `Editar Descuentos${editSalcobrandForm.name ? ": " : ""}${
    editSalcobrandForm.name ? editSalcobrandForm.name : ""
  }. `;

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Paper elevation={3} style={{ borderRadius: "10px" }}>
        <Grid
          style={{ display: "flex", margin: 50 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
        >
          <form autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  className={classes.text}
                  style={{ marginTop: "10px" }}
                >
                  Datos del producto
                </Typography>
                <div
                  className={classes.text}
                  style={{
                    marginTop: "1%",
                    display: "flex",
                    justifyContent: "space-between",
                    width: 700,
                  }}
                >
                  <Typography weight="medium">
                    A continuación se proveen los datos del producto y sus
                    descuentos para el programa Mi Salcobrand.
                  </Typography>
                </div>
                <div style={{ marginTop: "4%" }}>
                  <Field
                    labelName="Nombre"
                    required={true}
                    id="name"
                    disabled={true}
                    value={editSalcobrandForm.name}
                    divClassType={classes.generalPurposeItems}
                    fieldClassType={classes.generalPurposeField}
                    onChange={handleChange}
                  />
                  <Field
                    labelName="SKU"
                    required={true}
                    id="sku"
                    disabled={true}
                    value={editSalcobrandForm.sku}
                    divClassType={classes.generalPurposeItems}
                    fieldClassType={classes.generalPurposeField}
                    onChange={handleChange}
                  />
                  <div className={classes.generalPurposeItems}>
                    <Typography
                      component={"span"}
                      className={classes.text}
                      weight="bold"
                    >
                      Descuento actual
                      <Typography className={classes.text} weight="bold">
                        válido hasta
                      </Typography>
                    </Typography>
                    <DateField
                      name="onDate"
                      disabled={true}
                      format="dd/MM/yyyy"
                      helperText={
                        editSalcobrandForm.onDate ? "" : "Sin expiración"
                      }
                      value={editSalcobrandForm.onDate}
                      onChange={handleChange}
                      fieldClassName={classes.generalPurposeField}
                    />
                  </div>
                  <div className={classes.generalPurposeItems}>
                    <Typography className={classes.text} weight="bold">
                      Descuentos actuales
                    </Typography>

                    <DataGrid
                      className={classes.generalPurposeField}
                      style={{ height: 350 }}
                      rows={tableData}
                      columns={[
                        {
                          field: "discountNumber",
                          headerName: "Nro Desc",

                          width: 170,
                        },
                        {
                          field: "salcobrand",
                          headerName: "Salcobrand",

                          width: 170,
                        },
                        {
                          field: "abbott",
                          headerName: "Abbott",

                          width: 170,
                        },
                      ]}
                      disableColumnMenu={true}
                      pageSize={25}
                      checkboxSelection={false}
                      disableSelectionOnClick={true}
                      showToolbar={true}
                      disableColumnSelector={true}
                      hideFooter={true}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>

            <Divider style={{ margin: 30 }} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.text}>
                  Establecer próximos descuentos
                </Typography>
                <div
                  className={classes.text}
                  style={{
                    marginTop: "1%",
                    display: "flex",
                    justifyContent: "space-between",
                    width: 700,
                  }}
                >
                  <Typography weight="medium">
                    Establezca una nueva fecha de validez y descuentos para los
                    cuales el producto tendrá efecto.
                  </Typography>
                </div>
                <div style={{ marginTop: "4%" }}>
                  <div className={classes.generalPurposeItems}>
                    <Typography className={classes.text} weight="bold">
                      Nueva fecha inicial
                    </Typography>
                    <DateField
                      name="nextOnDate"
                      value={editSalcobrandForm.nextOnDate}
                      onChange={handleDateChange}
                      format="dd/MM/yyyy"
                      fieldClassName={classes.generalPurposeField}
                      minDate={
                        editSalcobrandForm.onDate
                          ? editSalcobrandForm.onDate
                          : getCurrentDate()
                      }
                    />
                  </div>
                  <SelectField
                    divClassName={classes.generalPurposeItems}
                    fieldClassName={classes.generalPurposeField}
                    displayText="Descuentos diferentes"
                    id="distinctsDiscounts"
                    label="Seleccione"
                    name="distinctsDiscounts"
                    value={editSalcobrandForm.distinctsDiscounts}
                    onChange={handleChange}
                    allValues={allDiscounts ? allDiscounts : []}
                  />
                  <div className={classes.generalPurposeItems}>
                    <Typography className={classes.text} weight="bold">
                      Nuevos descuentos
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container className={classes.generalPurposeItems}>
              {allDiscounts.map((value) => {
                if (
                  value.id &&
                  +value.id > +editSalcobrandForm.distinctsDiscounts
                )
                  return <></>;
                return (
                  <>
                    <Grid item xs={6}>
                      <Field
                        labelName={`${value.id}° SB (%)`}
                        id={`nextDiscountsSalco-${value.id}`}
                        divClassType={classes.tinyItems}
                        fieldClassType={classes.tinyField}
                        onChange={handleDiscountChange}
                        InputProps={{
                          inputComponent: NumberFormatter,
                        }}
                        error={!isDiscountValid.discount}
                        helperText={isDiscountValid.message}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        labelName={`${value.id}° ABT (%)`}
                        id={`nextDiscountsAbbott-${value.id}`}
                        divClassType={classes.tinyItems}
                        fieldClassType={classes.tinyField}
                        onChange={handleDiscountChange}
                        InputProps={{
                          inputComponent: NumberFormatter,
                        }}
                        error={!isDiscountValid.discount}
                        helperText={isDiscountValid.message}
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={12}>
                <div className={classes.buttonSpacing}>
                  {isLoading ? (
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Guardar
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={cancelAndGoBack}
                  >
                    Cancelar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </>
  );
}
