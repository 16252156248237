import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";

import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import TraceabilitySelectionForm from "components/SelectionForm/TraceabilitySelectionForm";
import InvertedTable from "components/Table/InvertedTable";
import PageTitle from "components/PageTitle/PageTitle";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { excelDownload } from "utils/excelDownload";
import loadingGif from "images/documentLoading.gif";
import useStyles from "./styles";
import LoaderBar from "components/LoaderBar/LoaderBar";

const createColumnsHeaderName = (columns) => {
  return columns.map((column) => {
    const columnHeaderName =
      column.substring(4, 6) + "-" + column.substring(0, 4);
    return columnHeaderName.charAt(0).toUpperCase() + columnHeaderName.slice(1);
  });
};

const COLUMN_LIST = [
  "physical_coupons_divider", // divider
  "medics_assigned",
  "medics_assigned_with_coupon",
  "fulfillment_deliver_target", // medics_assigned_with_coupon / medics_assigned
  "total_medics_received_coupon",
  "potentials",
  "users",
  "doesnt_use_coupon",
  "new_medics",
  "total_medics",
  "medics_grow", // new_medics / total_medics
  "new_patients",
  "total_patients",
  "patients_grow", // new_patients / total_patients
  "digital_coupon_divider", // divider
  "digital_coupon_downloads_total",
  "digital_coupon_downloads_unique",
  "digital_coupon_conversions",
  "digital_coupon_converted_percentage", // digital_coupon_conversions / digital_coupon_downloads_unique
  "virtual_coupon_divider", // divider
  "virtual_coupon_medics_received",
  "virtual_coupon_medics_delivered",
  "medics_with_virtual_coupon_retention", // virtual_coupon_medics_delivered / virtual_coupon_medics_received
  "virtual_coupon_patients_received",
  "virtual_coupon_patients_converted",
  "patients_with_virtual_coupon_retention", // virtual_coupon_patient_converted / virtual_coupon_patient_received
];

const COLUMN_HEADERS = {
  physical_coupons_divider: { label: "Cupones Fisicos ", category: "divider" },
  medics_assigned: {
    label: "Médicos Asignados",
    category: "data",
  },
  medics_assigned_with_coupon: {
    label: "Médicos Asignados que recibieron cupón",
    category: "data",
  },
  fulfillment_deliver_target: {
    label: "Cumplimiento de entrega al target",
    category: "data",
  },
  total_medics_received_coupon: {
    label: "Médicos que recibieron cupón (total)",
    category: "data",
  },
  potentials: {
    label: "Potenciales",
    category: "data",
  },
  users: {
    label: "Usuarios",
    category: "data",
  },
  doesnt_use_coupon: {
    label: "No usa / No quiere",
    category: "data",
  },
  new_medics: {
    label: "Médicos nuevos",
    category: "data",
  },
  total_medics: {
    label: "Médicos totales",
    category: "data",
  },
  medics_grow: {
    label: "Crecimiento de médicos",
    category: "data",
  },
  new_patients: {
    label: "Pacientes nuevos",
    category: "data",
  },
  total_patients: {
    label: "Pacientes totales",
    category: "data",
  },
  patients_grow: {
    label: "Crecimiento de pacientes",
    category: "data",
  },
  digital_coupon_divider: { label: "Cupones Digitales ", category: "divider" },
  digital_coupon_downloads_total: {
    label: "Descargas de cupones digitales (total)",
    category: "data",
  },
  digital_coupon_downloads_unique: {
    label: "Descargas de cupones digitales (RUT único)",
    category: "data",
  },
  digital_coupon_conversions: {
    label: "Conversiones de cupones digitales",
    category: "data",
  },
  digital_coupon_converted_percentage: {
    label: "% de cupones digitales convertidos",
    category: "data",
  },
  virtual_coupon_divider: { label: "Cupones Virtuales ", category: "divider" },
  virtual_coupon_medics_received: {
    label: "Médicos que recibieron cupón virtual",
    category: "data",
  },
  virtual_coupon_medics_delivered: {
    label: "Médicos que entregaron cupón virtual",
    category: "data",
  },
  medics_with_virtual_coupon_retention: {
    label: "Fidelización médicos con cupones virtuales",
    category: "data",
  },
  virtual_coupon_patients_received: {
    label: "Pacientes que recibieron cupón virtual",
    category: "data",
  },
  virtual_coupon_patients_converted: {
    label: "Pacientes que convirtieron cupón virtual",
    category: "data",
  },
  patients_with_virtual_coupon_retention: {
    label: "Fidelización pacientes con cupones virtuales",
    category: "data",
  },
};

const FIELDS_AS_MILLIONS = [
  // COLUMN_LIST names
];

const FIELDS_AS_PERCENTAGE = [
  "fulfillment_deliver_target",
  "medics_grow",
  "patients_grow",
  "digital_coupon_converted_percentage",
  "medics_with_virtual_coupon_retention",
  "patients_with_virtual_coupon_retention",
];

export default function TraceabilityDashboard() {
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const classes = useStyles();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialState, setIsInitialState] = useState(true);
  const [excelTotalsData, setExcelTotalsData] = useState([]);

  const generateRowsData = (data) => {
    if (!data) return;
    if (Object.keys(data).length === 0) {
      setColumns([]);
      setRows([]);
      return;
    }
    let columns = Object.keys(data);
    let total_rows = Object.keys(data[columns[0]]);

    setColumns(createColumnsHeaderName(columns));
    generateTableData(total_rows, columns, data);
  };

  const addValueToTable = (data, column, row) => {
    if (FIELDS_AS_MILLIONS.includes(row)) {
      return formatMillions(data[column][row]);
    }
    if (FIELDS_AS_PERCENTAGE.includes(row)) {
      return `${data[column][row].toFixed(2)} %`;
    }
    return data[column][row];
  };

  const generateTableData = (rows, columns, data) => {
    let tableData = {};
    rows.map((row) => {
      let valuesList = columns.map((column) => {
        let category = COLUMN_HEADERS[row].category;
        let rowIncluded = Object.keys(data[column]).includes(row);
        let value =
          rowIncluded && category === "data"
            ? addValueToTable(data, column, row)
            : "";
        return value;
      });
      tableData[row] = valuesList;
    });
    let finalData = COLUMN_LIST.map((row) => {
      let category = COLUMN_HEADERS[row].category;
      return {
        label: COLUMN_HEADERS[row].label,
        category: category,
        data: category === "data" ? tableData[row] : "",
      };
    });

    setRows(finalData);
  };

  const formatMillions = (value) => {
    let lastIndex = value >= 0 ? 4 : 5;
    return String(value / 1000000)
      .slice(0, lastIndex)
      .replace(/\.$/, "")
      .replace(".", ",");
  };

  const renderTables = () => {
    return (
      <>
        <Grid
          item
          xs={12}
          lg={12}
          style={{ marginBottom: "2%", marginTop: "2%", marginLeft: "2.5%" }}
        >
          <Typography variant="h4" className={classes.alignedText}>
            Valores
          </Typography>
          <Divider />
        </Grid>
        <Paper style={{ marginTop: "30px" }}>
          <InvertedTable
            rows={rows}
            columns={columns}
          />
        </Paper>
      </>
    );
  };

  const renderLoadingSection = () => {
    return (
      <Grid
        item
        container
        justifyContent="center"
        xs={12}
        style={{ paddingTop: "2%" }}
      >
        <LoaderBar />
      </Grid>
    );
  };

  const defineSectionToShow = () => {
    if (isLoading) return renderLoadingSection();
    if (isInitialState) return <></>;
    return renderTables();
  };

  return (
    <Grid>
      <PageTitle title="Panel de Trazabilidad" />
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Paper elevation={3}>
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          layout={"row"}
          spacing={"1"}
          style={{ border: "1px" }}
        >
          <TraceabilitySelectionForm
            generateRowsData={generateRowsData}
            setIsInitialState={setIsInitialState}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            isInitialState={isInitialState}
          />
        </Grid>
      </Paper>
      {defineSectionToShow()}
    </Grid>
  );
}
