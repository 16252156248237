import { Divider, Grid, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";

import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import DashboardSelectionForm from "components/SelectionForm/DashboardSelectionForm";
import InvertedTable from "components/Table/InvertedTable";
import PageTitle from "components/PageTitle/PageTitle";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { excelDownload } from "utils/excelDownload";
import useStyles from "./styles";
import { getRoleName } from "utils/roleNameGetter";
import LoaderBar from "components/LoaderBar/LoaderBar";

const GOAL = 5;

const createColumnsHeaderName = (columns) => {
  return columns.map((column) => {
    const columnHeaderName =
      column.substring(4, 6) + "-" + column.substring(0, 4);
    return columnHeaderName.charAt(0).toUpperCase() + columnHeaderName.slice(1);
  });
};

const COLUMN_LIST = [
  "amount_divider",
  "informed_discount",
  "calculated_discount",
  "rejected_discount", // informed_discount - calculated_discount
  "total_rejected_percentage",
  "expired_rejected_amount",
  "expired_rejected_amount_percentage",
  "mismatching_rejected_amount",
  "mismatching_rejected_amount_percentage",
  "units_divider",
  "informed_units",
  "calculated_units",
  "rejected_units", // informed_units - calculated_units
  "rejected_units_percentage", // rejceted_units / informed_units
  "expired_rejected_units",
  "expired_rejected_units_percentage",
  "mismatching_rejected_units",
  "mismatching_rejected_units_percentage",
  "fees_divider",
  "inscription_fee",
  "transaction_fee",
  "admin_fee",
  "total_fee",
  "audited_fee",
  "rejected_fee",
  "rejected_percentage",
  "inscriptions_divider",
  "total_inscriptions",
  "inscriptions_with_warnings",
  "rejected_inscriptions",
  "inscriptions_to_pay",
];

const COLUMN_HEADERS = {
  amount_divider: { label: "MONTOS ", category: "divider" },
  informed_discount: {
    label: "Total Reclamado Cadena MM ($)",
    category: "data",
  },
  calculated_discount: { label: "Total Auditado MM ($)", category: "data" },
  informed_units: { label: "Reclamado cadena", category: "data" },
  calculated_units: { label: "Auditado", category: "data" },
  expired_rejected_amount: {
    label: "Rechazado por vencimiento MM ($)",
    category: "data",
  },
  expired_rejected_units: {
    label: "Rechazado por vencimiento",
    category: "data",
  },
  mismatching_rejected_amount: {
    label: "Rechazado por problemas en cálculos MM ($)",
    category: "data",
  },
  mismatching_rejected_units: {
    label: "Rechazado por problemas en cálculos",
    category: "data",
  },
  units_divider: { label: "UNIDADES ", category: "divider" },
  total_rejected_percentage: {
    label: "% Total rechazado",
    category: "data",
  },
  expired_rejected_units_percentage: {
    label: "% Rechazado por vencimiento",
    category: "data",
  },
  expired_rejected_amount_percentage: {
    label: "% Rechazado por vencimiento",
    category: "data",
  },
  mismatching_rejected_units_percentage: {
    label: "% Rechazado por problemas en cálculos",
    category: "data",
  },
  mismatching_rejected_amount_percentage: {
    label: "% Rechazado por problemas en cálculos",
    category: "data",
  },
  rejected_discount: {
    label: "Total Rechazado MM ($)",
    category: "data",
  },
  rejected_units: {
    label: "Rechazado",
    category: "data",
  },
  rejected_units_percentage: {
    label: "% Rechazado",
    category: "data",
  },
  // Fees
  fees_divider: { label: "FEES", category: "divider" },
  inscription_fee: {
    label: "Fee de inscripción MM",
    category: "data",
  },
  transaction_fee: {
    label: "Fee de transacción MM",
    category: "data",
  },
  admin_fee: {
    label: "Fee de administración MM",
    category: "data",
  },
  total_fee: {
    label: "Total Reclamado Cadena MM",
    category: "data",
  },
  audited_fee: {
    label: "Auditado MM",
    category: "data",
  },
  rejected_fee: {
    label: "Rechazado MM",
    category: "data",
  },
  rejected_percentage: {
    label: "% Rechazado",
    category: "data",
  },
  // Inscriptions
  inscriptions_divider: { label: "EMPADRONAMIENTOS", category: "divider" },
  total_inscriptions: {
    label: "Total de empadronamientos",
    category: "data",
  },
  inscriptions_with_warnings: {
    label: "Empadronamientos con advertencias",
    category: "data",
  },
  rejected_inscriptions: {
    label: "Empadronamientos con errores",
    category: "data",
  },
  inscriptions_to_pay: {
    label: "Cantidad de empadronamientos a pagar",
    category: "data",
  },
};

const FIELDS_AS_MILLIONS = [
  "informed_discount",
  "calculated_discount",
  "expired_rejected_amount",
  "mismatching_rejected_amount",
  "rejected_discount",
  "inscription_fee",
  "transaction_fee",
  "admin_fee",
  "total_fee",
  "audited_fee",
  "rejected_fee",
];

export default function KPIsDashboard() {
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const classes = useStyles();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialState, setIsInitialState] = useState(true);
  const [excelTotalsData, setExcelTotalsData] = useState([]);

  //   const joinTablesData = () => {
  //     excelKPIsData.unshift({ data: ["KPIs"] }, { data: [""] });
  //     excelTotalsData.unshift(
  //       { data: [""] },
  //       { data: ["Totales"] },
  //       { data: [""] },
  //     );
  //     return [...excelKPIsData, ...excelTotalsData];
  //   };

  //   const exportData = () => {
  //     let joinedData = joinTablesData();
  //     let totalColumns = ["Concepto", ...columns];
  //     let formattedColumns = totalColumns.map((column) => {
  //       return { name: column };
  //     });
  //     excelDownload(
  //       formattedColumns,
  //       joinedData,
  //       0,
  //       formattedColumns.length,
  //       "Reporte de trazabilidad",
  //     );
  //   };

  const exportDataInTwoFiles = () => {
    let totalColumns = ["Concepto", ...columns];
    let formattedColumns = totalColumns.map((column) => {
      return { name: column };
    });
    excelDownload(
      formattedColumns,
      excelTotalsData,
      0,
      formattedColumns.length,
      "Panel de Auditorias",
    );
  };

  const assembleExcelData = (tableData) => {
    let formattedData = COLUMN_LIST.map((row, index) => {
      let dataHeader = COLUMN_HEADERS[row].label;
      let dataRow = tableData[row];
      return { data: [dataHeader, ...dataRow] };
    });
    setExcelTotalsData(formattedData);
  };

  const generateRowsData = (data, goalsData) => {
    if (!data) return;
    if (Object.keys(data).length === 0) {
      setColumns([]);
      setRows([]);
      return;
    }
    let columns = Object.keys(data);
    let total_rows = Object.keys(data[columns[0]]);

    setColumns(createColumnsHeaderName(columns));
    generateTableData(total_rows, columns, data, goalsData);
  };

  const rowsWithObjectives = [
    "total_rejected_percentage",
    "expired_rejected_amount_percentage",
    "mismatching_rejected_amount_percentage",
    "rejected_units_percentage",
    "expired_rejected_units_percentage",
    "mismatching_rejected_units_percentage",
    "rejected_percentage",
  ];

  const addValueToTable = (data, column, row, goalsData) => {
    if (rowsWithObjectives.includes(row)) {
      // RETURN WITH OBJECTIVES:
      //return `${data[column][row]} / ${goalsData[column][row] || "-"}`;
      return `${data[column][row]} %`;
    }
    if (FIELDS_AS_MILLIONS.includes(row)) {
      return formatMillions(data[column][row]);
    }
    return data[column][row];
  };

  const generateTableData = (rows, columns, data, goalsData) => {
    let tableData = {};
    rows.map((row) => {
      let valuesList = columns.map((column) => {
        let category = COLUMN_HEADERS[row].category;
        let rowIncluded = Object.keys(data[column]).includes(row);
        let value =
          rowIncluded && category === "data"
            ? addValueToTable(data, column, row, goalsData)
            : "";
        return value;
      });
      tableData[row] = valuesList;
    });
    let finalData = COLUMN_LIST.map((row) => {
      let category = COLUMN_HEADERS[row].category;
      return {
        label: COLUMN_HEADERS[row].label,
        category: category,
        data: category === "data" ? tableData[row] : "",
      };
    });
    setRows(finalData);
    assembleExcelData(tableData);
  };

  const formatMillions = (value) => {
    let roundedValue = (parseInt(value) / 1000000).toFixed(3)
    return String(roundedValue).replace(/\.$/, "").replace(".", ",");
  };

  const renderTables = () => {
    return (
      <>
        <Grid
          item
          xs={12}
          lg={12}
          style={{ marginBottom: "2%", marginTop: "2%", marginLeft: "2.5%" }}
        >
          <Typography variant="h4" className={classes.alignedText}>
            Valores
          </Typography>
          <Divider />
        </Grid>
        <Paper style={{ marginTop: "30px" }}>
          <InvertedTable
            title="Formato de celda: valor o valor (%)"
            rows={rows}
            columns={columns}
            goal={GOAL}
          />
        </Paper>
      </>
    );
  };

  const renderLoadingSection = () => {
    return (
      <Grid
        item
        container
        justifyContent="center"
        xs={12}
        style={{ paddingTop: "2%" }}
      >
        <LoaderBar />
      </Grid>
    );
  };

  const defineSectionToShow = () => {
    if (isLoading) return renderLoadingSection();
    if (isInitialState) return <></>;
    return renderTables();
  };

  return (
    <Grid>
      <PageTitle title="Panel de control - Auditoría" />
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Paper elevation={3}>
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          layout={"row"}
          spacing={"1"}
          style={{ border: "1px" }}
        >
          <DashboardSelectionForm
            generateRowsData={generateRowsData}
            setIsInitialState={setIsInitialState}
            setIsLoading={setIsLoading}
            exportFunction={exportDataInTwoFiles}
            isLoading={isLoading}
            isInitialState={isInitialState}
          />
        </Grid>
      </Paper>
      {defineSectionToShow()}
    </Grid>
  );
}
