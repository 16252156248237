import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(2),
  },
  buttonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    marginLeft: "15em",
    marginTop: "2%",
    marginBottom: "5%",
  },
  onlyButtonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
    height: 15,
    alignItem: "center",
    marginBottom: "5%",
  },
  loginLoader: {
    marginTop: "3%",
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(6.8),
  },
  loginLoaderPopup: {
    display: "flex",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(10.8),
  },
  logotypeImage: {
    width: 155,
    height: 55,
    marginTop: "-1%",
  },
  popupButtonSpacing: {
    margin: theme.spacing(1),
  },
  popupButtonSpacingDelete: {
    margin: theme.spacing(1),
    backgroundColor: "red",
    color: "white",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  popupButtonSpacingEnable: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    color: "white",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  popupDivCenter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  generalPurposeItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 700,
    justifyContent: "space-between",
  },
  smallItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 430,
    justifyContent: "space-between",
  },
  tinyItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 200,
  },
  generalPurposeField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-1%",
    width: 500,
  },
  tinyField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 100,
  },
  smallField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  selectorField: {
    marginRight: theme.spacing(1),
    marginTop: "-1%",
    width: 170,
  },
  searchField: {
    width: 500,
    marginTop: "-1%",
    marginBottom: "2%",
  },
  smallFieldValidation: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  generalFieldValidation: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-1%",
    width: 500,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    height: 25,
    marginTop: "-7%",
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    height: 25,
    marginTop: "-7%",
  },
  dateFilterField: {
    display: "flex",
    marginRight: theme.spacing(1),
    width: 230,
  },
  divDateFilter: {
    display: "flex",
    marginTop: "2%",
    width: 700,
  },
  buttonDateFilter: {
    display: "flex",
    margin: theme.spacing(1),
    height: 40,
  },
  // RUT FIELD REQUIRED STYLES
  generalPurposeItems2: {
    display: "flex",
    width: 310,
    justifyContent: "flex-start",
  },
  smallItems2: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 502,
    justifyContent: "space-between",
  },
  smallFieldValidation2: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginTop: "-2%",
    width: 230,
  },
  smallFieldValidation3: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginTop: "-2%",
    width: 80,
  },
  relationCheckbox: {
    marginLeft: "25.9%",
  },
  rowField: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
  },
  selectionTextEnabled: {
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0, 0, 0, 1)",
      borderColor: "white",
      borderWidth: 4,
      border: "solid 1px",
      opacity: 0.55,
    },
    marginTop: "0%",
  },
  selectionTextDisabled: {
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0, 0, 0, 1)",
      borderColor: "white",
      borderWidth: 4,
      border: "solid 1px",
      opacity: 0.4,
    },
    marginTop: "0%",
  },
  cellFormat: {
    height: 15,
    alignItem: "center",
  },
  root: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        display: "none",
      },
  },
  searchField2: {
    width: 500,
    marginTop: '0rem',
    marginBottom: '1rem',
  },
  generalPurposeItems2: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "1rem",
    width: 700,
    justifyContent: "space-between",
  },
}));
