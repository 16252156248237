import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(1),
  },
  generalPurposeField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-1%",
    width: 500,
  },
  searchField: {
    width: 500,
    marginTop: "-3%",
    marginBottom: "2%",
  },
  productPickerField: {
    width: "100%",
    marginTop: "-3%",
    marginBottom: "2%",
  },
}));
