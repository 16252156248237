const cleanEmptyKeys = (obj) =>
  Object.entries(obj)
    .map(([k, v]) => [
      k,
      v &&
      typeof v === "object" &&
      Object.prototype.toString.call(v) !== "[object Date]" &&
      Object.prototype.toString.call(v) !== "[object Boolean]"
        ? cleanEmptyKeys(v)
        : v,
    ])
    .reduce((a, [k, v]) => (!v ? a : ((a[k] = v), a)), {});

export { cleanEmptyKeys };
