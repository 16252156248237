import React, { useEffect, useState } from "react";
import { Fade, Grid, Button } from "@material-ui/core";
import DateField from "components/CustomFields/DateField.js";

// styles
import useStyles from "./styles";

// components
import PageTitle from "components/PageTitle/PageTitle";
import { Typography } from "components/Wrappers/Wrappers";
import { dbGet } from "utils/DBFetchers";
// material
import { alpha, styled } from "@mui/material/styles";
import { Card } from "@mui/material";
import {
  RootStyleGreen,
  RootStyleBlue,
  RootStyleOrange,
  RootStyleRed,
  IconWrapperStyleBlue,
  IconWrapperStyleGreen,
  IconWrapperStyleOrange,
  IconWrapperStyleRed,
  CardWrapperInscriptions,
  CardWrapperSales,
} from "./StylesWrappers";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import WorkIcon from "@mui/icons-material/Work";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ChartCard from "./ChartCard";
import MainCard from "./MainCard";
import { format } from "date-fns";
import {
  getCurrentDate,
  getNextMonthDate,
  getCurrentDateToday,
} from "utils/dateGetters";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import { getRoleName } from "utils/roleNameGetter";
import { blankFilters } from "pages/audit/tablesUtils/Inscriptions";

const blankUserData = {
  username: "",
  name: "",
  lastName: "",
};

const getDateFormatted = (date) => {
  return format(date, "dd/MM/yy");
};

const blankData = {
  begin: getDateFormatted(new Date()),
  beginDate: new Date(),
  end: getDateFormatted(new Date(getCurrentDate())),
  endDate: new Date(getCurrentDate()),
  week_sales: 0,
  day_sales: 0,
  week_inscriptions: 0,
  day_inscriptions: 0,
  month_sales: [],
  month_inscriptions: [],
  year_sales: [],
  year_inscriptions: [],
  total_year_sales: 0,
  total_year_inscriptions: 0,
  total_month_sales: 0,
  total_month_inscriptions: 0,
};

export default function Dashboard(props) {
  var classes = useStyles();
  const [profileData, setProfileData] = useState(blankUserData);
  const [dataInfo, setData] = useState(blankData);
  const [selectedDate, setSelectedDate] = useState(getCurrentDateToday());
  const [isDataFetched, setDataFetched] = useState(false);
  const [mustChooseProgram, setMustChooseProgram] = useState(false);
  const [hasDashboard, setHasDashboard] = useState(false);
  const monthNames = {
    1: "Enero",
    2: "Febrero",
    3: "Marzo",
    4: "Abril",
    5: "Mayo",
    6: "Junio",
    7: "Julio",
    8: "Agosto",
    9: "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre",
  };
  const week_start_number = {
    1: 1,
    2: 8,
    3: 15,
    4: 22,
  };

  const handleBeginDateChange = (date) => {
    var dateOnFirstDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );
    setSelectedDate(dateOnFirstDay);
    setData({
      ...blankData,
      beginDate: dateOnFirstDay,
      begin: getDateFormatted(dateOnFirstDay),
    });
  };

  const clearData = () => {
    setData(blankData);
    setDataFetched(false);
  };

  useEffect(() => {
    const role = getRoleName();
    if (role === "pharmacies") {
      localStorage.setItem("programId", "-1");
      window.dispatchEvent(new Event("storage"));
    }
    
    function updateProgramSelection() {
      const programId = localStorage.getItem("programId");
      if (role === "pharmacies") {
        if (!programId || programId === "-1") setMustChooseProgram(true);
        else setMustChooseProgram(false);
      }
    }

    updateProgramSelection();

    window.addEventListener("storage", updateProgramSelection);

    return () => {
      window.removeEventListener("storage", updateProgramSelection);
    };
  }, []);

  const getData = () => {
    // clearData();
    var role = getRoleName();
    if (
      role === "super_admin" ||
      role === "pharmacies" ||
      role === "distributors"
    ) {
      dbGet(`dashboard?&from_date=${dataInfo.begin}&to_date=${dataInfo.end}`)
        .then((data) => {
          setHasDashboard(true);
          let formatedData = blankData;
          formatedData.year_inscriptions = [];
          formatedData.year_sales = [];
          formatedData.month_inscriptions = [];
          formatedData.month_sales = [];
          // non 0 based month
          let n = data.initial_month + 1;
          for (let i = 0; i < 12; i++) {
            if (n + i > 12) n -= 12;
            // year_xxx is a vector with data starting on initial_month
            formatedData.year_sales.push({
              x: monthNames[n + i],
              y: data.year_sales[n + i],
            });
            formatedData.year_inscriptions.push({
              x: monthNames[n + i],
              y: data.year_inscriptions[n + i],
            });
          }
          for (let i = 1; i <= 4; i++) {
            formatedData.month_sales.push({
              x: week_start_number[i],
              y: data.month_sales[i],
            });
            formatedData.month_inscriptions.push({
              x: week_start_number[i],
              y: data.month_inscriptions[i],
            });
          }
          formatedData.week_sales = data.week_sales;
          formatedData.week_inscriptions = data.week_inscriptions;
          formatedData.day_sales = data.day_sales;
          formatedData.day_inscriptions = data.day_inscriptions;
          formatedData.total_year_sales = data.total_year_sales;
          formatedData.total_year_inscriptions = data.total_year_inscriptions;
          formatedData.total_month_sales = data.total_month_sales;
          formatedData.total_month_inscriptions = data.total_month_inscriptions;
          formatedData.initial_month = data.initial_month;
          formatedData.year_inscriptions_up = data.year_inscriptions_up;
          formatedData.year_sales_up = data.year_sales_up;
          formatedData.month_inscriptions_up = data.month_inscriptions_up;
          formatedData.month_sales_up = data.month_sales_up;
          setData(formatedData);
          setDataFetched(true);
        })
        .catch((error) => {});
    }
  };

  React.useEffect(() => {
    dbGet("profile").then((data) => {
      setProfileData({
        name: data.name,
        lastName: data.lastname,
        username: data.username,
      });
    });
    getData();
  }, []);

  var d = new Date();
  var weekday = new Array(7);
  weekday[0] = "domingo";
  weekday[1] = "lunes";
  weekday[2] = "martes";
  weekday[3] = "miércoles";
  weekday[4] = "jueves";
  weekday[5] = "viernes";
  weekday[6] = "sábado";

  var day = weekday[d.getDay()];

  return (
    <>
      <PageTitle title="Inicio" />
      <Card style={{ borderRadius: "20px" }}>
        <Grid
          container
          spacing={4}
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Fade
              in={true}
              timeout={{
                enter: 2000,
                exit: 2000,
              }}
            >
              <Typography variant="h1" className={classes.greeting}>
                Bienvenido/a{" "}
                {profileData.name
                  ? profileData.name +
                    " " +
                    (profileData.lastName ? profileData.lastName : "")
                  : profileData.username}
              </Typography>
            </Fade>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div style={{ marginTop: 30, paddingLeft: 30 }}>
              <Fade
                in={true}
                timeout={{
                  enter: 4000,
                  exit: 2000,
                }}
              >
                <Typography variant="h3" className={classes.greeting}>
                  Buen {day}
                </Typography>
              </Fade>
            </div>
          </Grid>

          {hasDashboard && !mustChooseProgram ? (
            <>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                spacing={4}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid
                  item
                  lg={6}
                  xs={12}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <ChartCard
                    isLoading={false}
                    label={"Empadronamientos"}
                    CardWrapper={CardWrapperInscriptions}
                    isSales={false}
                    dataMonth={
                      isDataFetched
                        ? dataInfo.month_inscriptions
                        : blankData.month_inscriptions
                    }
                    dataYear={
                      isDataFetched
                        ? dataInfo.year_inscriptions
                        : blankData.year_inscriptions
                    }
                    totalMonth={
                      isDataFetched
                        ? dataInfo.total_month_inscriptions
                        : blankData.total_month_inscriptions
                    }
                    totalYear={
                      isDataFetched
                        ? dataInfo.total_year_inscriptions
                        : blankData.total_year_inscriptions
                    }
                    yearArrowUp={dataInfo.year_inscriptions_up}
                    monthArrowUp={dataInfo.month_inscriptions_up}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <ChartCard
                    isLoading={false}
                    label={"Ventas"}
                    CardWrapper={CardWrapperSales}
                    isSales={true}
                    dataMonth={
                      isDataFetched
                        ? dataInfo.month_sales
                        : blankData.month_sales
                    }
                    dataYear={
                      isDataFetched ? dataInfo.year_sales : blankData.year_sales
                    }
                    totalMonth={
                      isDataFetched
                        ? dataInfo.total_month_sales
                        : blankData.total_month_sales
                    }
                    totalYear={
                      isDataFetched
                        ? dataInfo.total_year_sales
                        : blankData.total_year_sales
                    }
                    yearArrowUp={dataInfo.year_sales_up}
                    monthArrowUp={dataInfo.month_sales_up}
                  />
                </Grid>
                <Grid
                  item
                  container
                  lg={6}
                  xs={12}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <RootStyleGreen>
                    <IconWrapperStyleGreen>
                      <ReceiptIcon />
                    </IconWrapperStyleGreen>
                    <Typography variant="h3">
                      {isDataFetched
                        ? dataInfo.day_inscriptions
                        : blankData.day_inscriptions}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      Empadronamientos del dia
                    </Typography>
                  </RootStyleGreen>
                </Grid>
                <Grid
                  item
                  container
                  lg={6}
                  xs={12}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <RootStyleOrange>
                    <IconWrapperStyleOrange>
                      <PointOfSaleOutlinedIcon />
                    </IconWrapperStyleOrange>
                    <Typography variant="h3">
                      {isDataFetched ? dataInfo.day_sales : blankData.day_sales}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      Ventas del dia
                    </Typography>
                  </RootStyleOrange>
                </Grid>
                <Grid
                  item
                  container
                  lg={6}
                  xs={12}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <RootStyleRed>
                    <IconWrapperStyleRed>
                      <ReceiptIcon />
                    </IconWrapperStyleRed>
                    <Typography variant="h3">
                      {isDataFetched
                        ? dataInfo.week_inscriptions
                        : blankData.week_inscriptions}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      Empadronamientos de la semana
                    </Typography>
                  </RootStyleRed>
                </Grid>
                <Grid
                  item
                  container
                  lg={6}
                  xs={12}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <RootStyleBlue>
                    <IconWrapperStyleBlue>
                      <PointOfSaleOutlinedIcon />
                    </IconWrapperStyleBlue>
                    <Typography variant="h3">
                      {isDataFetched
                        ? dataInfo.week_sales
                        : blankData.week_sales}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      Ventas de la semana
                    </Typography>
                  </RootStyleBlue>
                </Grid>{" "}
                <Grid
                  container
                  item
                  lg={11}
                  md={11}
                  sm={11}
                  xs={11}
                  spacing={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 50,
                  }}
                >
                  <Grid item>
                    <DateField
                      helperText="Fecha de visualización (día, mes y año)"
                      name="begin"
                      views={["year", "month", "date"]}
                      minDate={new Date("2021-09-02")} // Actually its 2021-09-01 but it's bugged
                      maxDate={getCurrentDateToday()}
                      format={"dd/MM/yyyy"}
                      value={selectedDate}
                      onChange={handleBeginDateChange}
                      fieldClassName={classes.smallField}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={getData}
                      className={classes.uploadButton}
                      style={{ marginTop: -20 }}
                    >
                      Aplicar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Card>
    </>
  );
}
