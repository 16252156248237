import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";
import { Autocomplete } from "@material-ui/lab";
import Field from "components/CustomFields/Field.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { dbGet, dbPut } from "utils/DBFetchers.js";
import { useHistory } from "react-router-dom";
import routes from "routes";
import { getCurrentDateToday } from "utils/dateGetters.js";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";
import DateField from "components/CustomFields/DateField";
import { format } from "date-fns";
import NumberFormatter from "components/CustomFields/NumberFormatter";
import SKUFormatter from "components/CustomFields/SKUFormatter";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import SelectField from "components/CustomFields/SelectField";

const blankNewSalcobrandForm = {
  name: "",
  sku: "",
  discountsSalco: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  discountsAbbott: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  distinctsDiscounts: 1,
  onDate: null,
  id: "",
};

const allDiscounts = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 6, name: 6 },
  { id: 8, name: 8 },
  { id: 9, name: 9 },
  { id: 10, name: 10 },
  { id: 11, name: 11 },
  { id: 12, name: 12 },
];

export default function ABMSalcobrandNewPage(props) {
  var classes = useStyles();
  const history = useHistory();
  var [isLoading, setIsLoading] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [newSalcobrandForm, updateForm] = useState(blankNewSalcobrandForm);
  const [allProducts, setAllProducts] = useState([]);
  const [isSkuValid, setIsSkuValid] = useState({ sku: false, message: "" });

  const [isDiscountValid, setIsDiscountValid] = useState({
    discount: true,
    message: "",
  });

  const handleChange = (event) => {
    updateForm({
      ...newSalcobrandForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleDateChange = (date) => {
    updateForm({ ...newSalcobrandForm, onDate: date });
  };

  const handleSkuChangeEvent = (event) => {
    handleSkuChange(event.target.value);
  };

  const handleSkuChange = (skuValue) => {
    updateForm({
      ...newSalcobrandForm,
      sku: skuValue,
      name: "",
      id: "",
      discountsSalco: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      discountsAbbott: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      onDate: null,
    });
    var filtered = allProducts.filter((x) => +x.sku === +skuValue);
    if (filtered.length === 0) {
      setIsSkuValid({ sku: false, message: "Producto inexistente." });
    } else if (filtered.length === 1) {
      if (
        filtered[0][`salcobrand`] // WATCH OUT
      ) {
        setIsSkuValid({
          sku: false,
          message: "Producto ya posee descuento para el programa.",
        });
      } else {
        setIsSkuValid({ sku: true, message: "" });
        updateForm({
          ...newSalcobrandForm,
          name: filtered[0].name,
          id: filtered[0].id,
          sku: skuValue,
        });
      }
    } else {
      // Shouldn't be in here, sku is unique
      setIsSkuValid({ sku: false, message: "Producto con errores." });
    }
  };

  const handleDiscountChange = (event) => {
    let split = event.target.name.split("-");
    let index = +split[1] - 1;
    let discountType = split[0];
    let array = newSalcobrandForm[discountType];
    array[index] = event.target.value;
    updateForm({
      ...newSalcobrandForm,
      [discountType]: array,
    });
    if (
      +event.target.value > 100 ||
      +event.target.value < 0 ||
      event.target.value === "-"
    ) {
      setIsDiscountValid({
        discount: false,
        message: "Descuento no puede ser mayor a 100% o menor a 0%.",
      });
    } else {
      setIsDiscountValid({ discount: true, message: "" });
    }
  };

  /* Basic form check */
  const checkFormComplete = () => {
    var isCompleted = true;
    if (!newSalcobrandForm.id) {
      isCompleted = false;
      snackbarOpen("error", "Error al obtener el id");
    } else if (!newSalcobrandForm.name) {
      isCompleted = false;
      snackbarOpen("error", "Error al obtener el nombre");
    } else if (!newSalcobrandForm.sku) {
      isCompleted = false;
      snackbarOpen("error", "Error al obtener el SKU");
    } else if (!isDiscountValid.discount) {
      isCompleted = false;
      snackbarOpen("error", "Ingrese descuentos válidos");
    } else if (!newSalcobrandForm.onDate) {
      isCompleted = false;
      snackbarOpen("error", "Selecciones la fecha de próximo descuento");
    } else if (newSalcobrandForm.discountsSalco[0] === 0) {
      isCompleted = false;
      snackbarOpen("error", "Descuento Salcobrand 0%");
    }
    return isCompleted;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      return;
    }
    setIsLoading(true);

    let discounts = [];

    for (let i = 0; i < 12; i++) {
      let distinctsDiscounts = newSalcobrandForm.distinctsDiscounts;
      if (i < distinctsDiscounts) {
        discounts.push(
          newSalcobrandForm.discountsSalco[i] +
            "," +
            newSalcobrandForm.discountsAbbott[i],
        );
      } else {
        discounts.push(
          newSalcobrandForm.discountsSalco[distinctsDiscounts - 1] +
            "," +
            newSalcobrandForm.discountsAbbott[distinctsDiscounts - 1],
        );
      }
    }

    // Give adecuate format to send form to db
    let completedForm = {
      name: newSalcobrandForm.name,
      sku: +newSalcobrandForm.sku,
      next_mi_salcobrand: {
        discount: discounts.join("/"), // "12,18/12,20/"
        on_date: format(new Date(newSalcobrandForm.onDate), "dd/M/yy"),
      },
    };

    // Sends to db
    dbPut(`presentations/${newSalcobrandForm.id}`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Descuento añadido exitosamente");
        redirectToSalcobrandPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al añadir descuento",
        ); // translate pending
        setIsLoading(false);
        return;
      });
  };

  /* Cancel editing distributor and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankNewSalcobrandForm);
    history.goBack();
  };

  /* Redirect to pharmacies table page after editing correctly */
  const redirectToSalcobrandPage = (user) => {
    setTimeout(() => {
      updateForm(blankNewSalcobrandForm);
      setIsLoading(false);
      history.push(routes.abmSalcobrand);
    }, 3000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    dbGet("presentations/mi_salco").then((data) => {
      setAllProducts(data);
    });
  }, []);

  const title = `Añadir Descuento a producto${
    newSalcobrandForm.name ? ": " : ""
  }${newSalcobrandForm.name ? newSalcobrandForm.name : ""}`;

  const handleAutoCompleteNameChange = (event, newValue) => {
    if (newValue) handleSkuChange(newValue.sku);
    else {
      updateForm({
        ...newSalcobrandForm,
        name: "",
        sku: "",
      });
    }
  };

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <Paper elevation={3} style={{ borderRadius: "10px" }}>
        <CustomizedSnackbars
          severity={snackbarStatus.severity}
          mssg={snackbarStatus.message}
          open={snackbarStatus.open}
          onClose={onCloseSnackbar}
        />
        <Grid
          style={{ display: "flex", margin: 50 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
        >
          <form autoComplete="off">
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  className={classes.text}
                  style={{ marginTop: "10px" }}
                >
                  Datos del producto
                </Typography>
                <div
                  className={classes.text}
                  style={{
                    marginTop: "1%",
                    display: "flex",
                    justifyContent: "space-between",
                    width: 700,
                  }}
                >
                  <Typography weight="medium">
                    A continuación ingrese los datos requeridos para añadir un
                    nuevo descuento al producto.
                  </Typography>
                </div>

                <div className={classes.generalPurposeItems}>
                  <Typography className={classes.text} weight="bold">
                    Nombre
                  </Typography>

                  <Autocomplete
                    freeSolo
                    className={classes.generalFieldValidation}
                    options={allProducts ? allProducts : []}
                    onChange={handleAutoCompleteNameChange}
                    style={{
                      noBorder: {
                        border: "none",
                      },
                    }}
                    getOptionLabel={(option) => {
                      let name = option.name;
                      name += option.notes ? " - " + option.notes : "";
                      name +=
                        option.distributors_code &&
                        option.distributors_code.salcobrand_code
                          ? " | " + option.distributors_code.salcobrand_code
                          : "";
                      return name;
                    }}
                    renderInput={(params) => (
                      <TextField
                        style={{
                          noBorder: {
                            border: "none",
                          },
                        }}
                        className={
                          params.disabled
                            ? classes.selectionTextDisabled
                            : classes.selectionTextEnabled
                        }
                        {...params}
                        placeholder={
                          params.label
                            ? params.label
                            : newSalcobrandForm.name
                            ? newSalcobrandForm.name
                            : "Seleccione"
                        }
                        margin="dense"
                        variant="outlined"
                      />
                    )}
                  />
                </div>

                <Field
                  labelName="SKU"
                  required={true}
                  id="sku"
                  value={newSalcobrandForm.sku}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalFieldValidation}
                  error={!isSkuValid.sku}
                  helperText={isSkuValid.message}
                  onChange={handleSkuChangeEvent}
                  InputProps={{
                    inputComponent: SKUFormatter,
                  }}
                />

                <div className={classes.generalPurposeItems}>
                  <Typography className={classes.text} weight="bold">
                    Nueva fecha inicial
                  </Typography>
                  <DateField
                    name="onDate"
                    value={newSalcobrandForm.onDate}
                    onChange={handleDateChange}
                    fieldClassName={classes.generalPurposeField}
                    minDate={getCurrentDateToday()}
                    format={"dd/MM/yyyy"}
                  />
                </div>
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  displayText="Descuentos diferentes"
                  id="distinctsDiscounts"
                  label="Seleccione"
                  name="distinctsDiscounts"
                  value={newSalcobrandForm.distinctsDiscounts}
                  onChange={handleChange}
                  allValues={allDiscounts ? allDiscounts : []}
                />
                <div className={classes.generalPurposeItems}>
                  <Typography className={classes.text} weight="bold">
                    Descuentos
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container className={classes.generalPurposeItems}>
              {allDiscounts.map((value) => {
                if (
                  value.id &&
                  +value.id > +newSalcobrandForm.distinctsDiscounts
                )
                  return <></>;
                return (
                  <>
                    <Grid item xs={6}>
                      <Field
                        labelName={`${value.id}° SB (%)`}
                        id={`discountsSalco-${value.id}`}
                        divClassType={classes.tinyItems}
                        fieldClassType={classes.tinyField}
                        onChange={handleDiscountChange}
                        InputProps={{
                          inputComponent: NumberFormatter,
                        }}
                        error={!isDiscountValid.discount}
                        helperText={isDiscountValid.message}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        labelName={`${value.id}° ABT (%)`}
                        id={`discountsAbbott-${value.id}`}
                        divClassType={classes.tinyItems}
                        fieldClassType={classes.tinyField}
                        onChange={handleDiscountChange}
                        InputProps={{
                          inputComponent: NumberFormatter,
                        }}
                        error={!isDiscountValid.discount}
                        helperText={isDiscountValid.message}
                      />
                    </Grid>
                  </>
                );
              })}
              <Grid item xs={12} style={{ marginTop: "-1%" }}></Grid>
            </Grid>
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <div className={classes.buttonSpacing}>
                  {isLoading ? (
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Guardar
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={cancelAndGoBack}
                  >
                    Cancelar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </>
  );
}
