import React, { useState, useEffect } from "react";

/* COMPONENTS */
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import SelectionForm from "components/SelectionForm/AuditSelectionForm";
import { SummaryTable, exportToPDF } from "components/Table/SummaryTable";
import PageTitle from "components/PageTitle/PageTitle";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import LoaderBar from "components/LoaderBar/LoaderBar";

/* STYLES */
import useStyles from "./styles";
import logo from "images/logoPagesRS.jpg";

/*  FUNCTIONS AND DATA*/
import { dbGet } from "utils/DBFetchers.js";
import { format } from "date-fns";
import {
  parseGetAllErrorsAndWarnings,
  blankFilters,
  summaryHeader,
  summaryRowHeader,
  createColumns,
} from "./tablesUtils/Inscriptions";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

const allPrograms = [
  { id: 1, name: "Receta solidaria" },
  { id: 2, name: "Solo mujer" },
];

const errors = [
  { id: 3, name: "Vademecum inexistente" }, // INEXISTENT_VADEMECUM_ERROR
  { id: 4, name: "Código de barras inexistente" }, // INEXISTENT_BARCODE_ERROR
  { id: 5, name: "Presentacion inexistente" }, // INEXISTENT_PRESENTATION_ERROR

  { id: 7, name: "Paciente ya tiene el producto" }, // PATIENT_HAS_PRESENTATION_ERROR
  { id: 8, name: "Fecha de empadronamiento invalida" }, // INVALID_TRANSACTION_DATE_ERROR
  { id: 9, name: "Farmacia inexistente" }, // INEXISTENT_PHARMACY_ERROR
  { id: 10, name: "Descuento erróneo" }, // INCORRECT_DISCOUNT_ERROR
  { id: 11, name: "Paciente inexistente error" }, // INEXISTENT_PATIENT_ERROR
];

export default function InscriptionsPage() {
  // Color variables
  let errorCellColor = "245, 122, 118"; // red
  let warningCellColor = "245, 255, 118"; // yellow
  let okCellColor = "134, 227, 127"; // green
  // Important to set the right color for the cells.
  // Keep updated if there are changes to the table.
  const OBSERVATIONS_POS = 8;
  const WARNINGS_POS = 9;
  const PERIOD_POS = 2;

  const ALL_GROUPS = "";

  const PAGE_TITLE = "Empadronamientos";

  var classes = useStyles();

  // Useful variables
  var [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(blankFilters);
  const [columns, setColumns] = useState(createColumns(filters, classes));
  const [inscriptionsFee, setInscriptionsFee] = useState(0);
  const [allData, setAllData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [canDownloadPDF, setCanDownloadPDF] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  // Table filters
  const [groupFilter, setGroupFilter] = useState("");
  const GROUP_INDEX = 9; // Passed as a dictionary key to the MUIDataTable

  // Formats price with dot for thousands and comma for decimals
  const formatNumber = (value) => {
    return value
      ? Intl.NumberFormat("es-AR").format(Math.round(value * 100) / 100)
      : 0;
  };

  // Export to PDF setup
  const handleExportToPDF = (props) => {
    // API requiers header to be a list of lists or dics.
    let headers = [];
    props.header.map((value) => {
      headers.push(value.title);
    });
    props.header = [];
    props.header.push(headers);
    props.name = `Reporte de Empadronamientos ${props.date}`;
    exportToPDF(props);
  };

  // Checks if the filters are valid
  const validFilters = () => {
    let validDistributor = filters.distributor ? true : false;
    let validProgram = filters.program ? true : false;

    if (!validDistributor) {
      snackbarOpen("error", "El campo de cadena está vacio");
    }
    if (!validProgram) {
      snackbarOpen("error", "El campo del programa está vacio");
    }

    return validDistributor && validProgram;
  };

  // Get data
  const handleApplyFilters = () => {
    if (!validFilters()) {
      return;
    }

    let periodFormated = filters.period.split("/").join("");
    let period = `period=${periodFormated}`;
    let distributorId = `${
      filters.distributor ? `&distributor_id=${filters.distributor}` : ""
    }`;
    let programId = `&program_id=${filters.program}`;
    let importID = `&import_id=${filters.importID}`;
    let status = `&status=${filters.status}`;
    let errors = `&error_ids=${filters.observations.join(",")}`;
    let zoneId = `${filters.zone ? `&zone_id=${filters.zone}` : ""}`;

    setColumns(createColumns(filters, classes));
    setIsLoading(true);

    dbGet(
      `audit/inscriptions?${period}${programId}${distributorId}${importID}${status}${errors}${zoneId}`,
    )
      .then((data) => {
        if (data.length === 0 || data.data.length === 0) {
          setTableData([]);
          setSummaryData([]);
          setCanDownloadPDF(false);
          setIsLoading(false);
          return;
        }
        // Data Table
        let tableData = [];
        tableData = data.data.map((row) => [
          row.id, // Nro interno
          row.pharmacy_code, // Sucursal
          row.period
            ? row.period.toString().substring(4) +
              "/" +
              row.period.toString().substring(0, 4)
            : "", // Período
          format(new Date(row.transaction_date + " EDT"), "dd/MM/yyyy"), // Fecha
          row.barcode, // Cod. barras cupon
          row.presentation_external_code, // Cod. pres.
          row.presentation_vrf, // Producto
          row.zone_name, // Zona
          row.errors === 0 ? "OK" : parseGetAllErrorsAndWarnings(row.errors),
          row.warnings === 0
            ? "OK"
            : parseGetAllErrorsAndWarnings(row.warnings), // Warnings
          row.group_name, // Grupo
          row.patient_id, // ID Paciente
        ]);
        setTableData(tableData);
        setAllData(data.data);
        setInscriptionsFee(data.fees.inscription_fee);

        // Summary Table
        calculateSummary(data.data, ALL_GROUPS, data.fees.inscription_fee);
        setCanDownloadPDF(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // Summary Table
  const calculateSummary = (data, group, fee) => {
    let totalErrors = 0;
    let totalWarnings = 0;
    let inscriptions_count = 0;
    if (group === ALL_GROUPS) {
      data.map((row) => {
        totalErrors += row.errors.length ? 1 : 0;
        totalWarnings += row.warnings.length ? 1 : 0;
      });
      inscriptions_count = data.length;
    } else {
      data.map((row) => {
        if (row.group_name === group) {
          totalErrors += row.errors.length ? 1 : 0;
          totalWarnings += row.warnings.length ? 1 : 0;
          inscriptions_count++;
        }
      });
    }
    let newSummaryData = [
      inscriptions_count,
      totalErrors,
      totalWarnings,
      inscriptions_count - totalErrors,
      formatNumber(fee),
      formatNumber(fee * (inscriptions_count - totalErrors)),
    ];
    let summaryData = [];
    newSummaryData.map((value, index) => {
      summaryData.push([summaryRowHeader[index], value]);
    });
    setSummaryData(summaryData);
  };

  // Update the summary with the selected group
  useEffect(() => {
    if (allData && Object.keys(allData).length) {
      if (!groupFilter) {
        calculateSummary(allData, ALL_GROUPS, inscriptionsFee);
      } else {
        calculateSummary(allData, groupFilter, inscriptionsFee);
      }
    }
  }, [groupFilter]);

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <div>
        <PageTitle
          title={PAGE_TITLE}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        alignItems="flex-start"
        spacing={4}
        xs={12}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginLeft: "-30px",
          }}
        >
          <Grid
            container
            item
            xs={12}
            layout={"row"}
            spacing={"1"}
            style={{ border: "1px" }}
          >
            <SelectionForm
              setFilters={setFilters}
              filters={filters}
              applyFunction={handleApplyFilters}
              programs={allPrograms}
              errors={errors}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              hideTypeSelector
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} md={12} lg={6} layout={"row"} spacing={1}>
          <Grid item xs={12} style={{ border: "1px" }}>
            <SummaryTable
              title={PAGE_TITLE}
              columns={summaryHeader}
              rows={summaryData}
            />
          </Grid>
          <Grid item xs={12} className={classes.alignCenter}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleExportToPDF({
                  title: { PAGE_TITLE },
                  header: summaryHeader,
                  data: summaryData,
                  date: tableData[0][PERIOD_POS],
                });
              }}
              style={{ marginTop: "5%", marginBottom: "5px" }}
              disabled={!canDownloadPDF}
            >
              Descargar PDF
            </Button>
          </Grid>
        </Grid>

        {isLoading ? (
          <LoaderBar />
        ) : (
          <Grid container xs={12} spacing={3}>
            <Grid item xs={12}>
              <CustomMUIDataTable
                title={PAGE_TITLE}
                data={tableData}
                columns={columns}
                setFilter={{ [GROUP_INDEX]: setGroupFilter }}
                filterStart={1}
                setRowProps={(row) => {
                  if (row[OBSERVATIONS_POS]?.length > 0)
                    return {
                      style: { backgroundColor: `rgb(${errorCellColor})` },
                    };
                  else if (row[WARNINGS_POS]?.length > 0) {
                    return {
                      style: { backgroundColor: `rgb(${warningCellColor})` },
                    };
                  } else
                    return {
                      style: { backgroundColor: `rgb(${okCellColor})` },
                    };
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
