import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";
// styles
import useStyles from "./styles";

import { dbGet } from "utils/DBFetchers.js";

import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import LoaderBar from "components/LoaderBar/LoaderBar";

export default function ABMKamPage() {
  const history = useHistory();
  // EDIT DISTRIBUTOR
  const RedirectToEditKam = (id) => {
    history.push(routes.abmKamEdit, { kamId: id });
  };
  // NEW DISTRIBUTOR
  const RedirectToNewKam = () => {
    history.push(routes.abmKamNew);
  };

  // Table columns definition
  const columns = [
    {
      name: " ",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return { style: { fontWeight: "bold", minWidth: 100, width: 100 } };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    RedirectToEditKam(tableMeta.rowData[1]);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "Id",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        display: "excluded",
        filter: false,
      },
    },

    {
      name: "Nombre",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Email",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Distribuidores",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
  ];

  var classes = useStyles();
  const [tableData, setTableData] = useState([]);

  /* Fills table data */
  const fillTable = (data) => {
    if (!data) return;
    var tableData = data.map((row) => [
      "",
      row.id,
      row.name,
      row.email,
      row.distributors.map((distributor) => distributor.name).join(" | "),
    ]);
    setTableData(tableData);
  };

  var [isLoading, setIsLoading] = useState(false);
  /* Get all data */
  const getResults = (category) => {
    setIsLoading(true);
    dbGet(category)
      .then((data) => {
        fillTable(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getResults(`kams`);
  }, []);

  return (
    <>
      <div>
        <PageTitle
          title="KAMs"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={RedirectToNewKam}
          startIcon={<AddIcon />}
        >
          Añadir
        </Button>
      </div>

      {isLoading ? (
        <LoaderBar />
      ) : (
        <Grid
          style={{ display: "flex", margin: 0 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
          xs={12}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <CustomMUIDataTable
                title="KAMs"
                data={tableData}
                columns={columns}
                filterStart={1}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
