import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";
import { format } from "date-fns";

import Field from "components/CustomFields/Field.js";
import DateField from "components/CustomFields/DateField.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { dbGet, dbPut } from "utils/DBFetchers.js";
import { getCurrentDate } from "utils/dateGetters.js";
import { cleanEmptyKeys } from "utils/cleanEmptyKeys";
import { useHistory } from "react-router-dom";
import routes from "routes";
import moment from "moment";

// styles
import useStyles from "./styles";
import NumberFormatter from "components/CustomFields/NumberFormatter";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import logo from "images/logoPagesRS.jpg";

const blankEditVademecumForm = {
  id: "",
  nextDiscount: "",
  nextOnDate: null,
  discount: "",
  onDate: null,
  name: "",
  sku: "",
  programId: "",
};

export default function ABMVademecumsEditPage(props) {
  var classes = useStyles();
  const history = useHistory();
  var [isLoading, setIsLoading] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const [editVademecumForm, updateForm] = useState(blankEditVademecumForm);
  const [nextDiscountCheck, setNextDiscountCheck] = useState({
    discount: null,
    programId: null,
    onDate: null,
  });
  const [isDiscountValid, setIsDiscountValid] = useState({
    discount: true,
    message: "",
  });

  const handleChange = (event) => {
    updateForm({
      ...editVademecumForm,
      [event.target.name]: event.target.value,
    });
  };
  const handleDateChange = (date) => {
    updateForm({ ...editVademecumForm, nextOnDate: date });
  };
  const handleDiscountChange = (event) => {
    updateForm({
      ...editVademecumForm,
      [event.target.name]: event.target.value,
    });
    if (
      +event.target.value > 100 ||
      +event.target.value < 0 ||
      event.target.value === "-"
    ) {
      setIsDiscountValid({
        discount: false,
        message: "Descuento no puede ser mayor a 100% o menor a 0%.",
      });
    } else {
      setIsDiscountValid({ discount: true, message: "" });
    }
  };

  /* Get all data */
  const getVademecumInfo = (presentationId, programID) => {
    dbGet(`presentations/${presentationId}`).then((data) => {
      updateForm({
        ...editVademecumForm,
        id: presentationId,
        programId: programID,
        name: data.presentation.name,
        sku: data.presentation.sku,
        discount: data.presentation[`program_${programID}_vademecum`]
          ? data.presentation[`program_${programID}_vademecum`].discount
          : "",
        onDate: data.presentation[`program_${programID}_vademecum`]
          ? data.presentation[`program_${programID}_vademecum`].to_date
            ? moment(
                data.presentation[`program_${programID}_vademecum`].to_date,
                "DD/MM/YY",
              )
                .subtract(1, "days")
                .toDate()
            : null
          : null,
        nextDiscount: data.presentation[`program_${programID}_next_vademecum`]
          ? data.presentation[`program_${programID}_next_vademecum`].discount
          : "",
        nextOnDate: data.presentation[`program_${programID}_next_vademecum`]
          ? moment(
              data.presentation[`program_${programID}_next_vademecum`]
                .from_date,
              "DD/MM/YY",
            ).toDate()
          : null,
      });
      if (
        data.presentation[`program_${programID}_next_vademecum`] &&
        data.presentation[`program_${programID}_vademecum`]
      ) {
        setNextDiscountCheck({
          discount:
            data.presentation[`program_${programID}_next_vademecum`].discount,
          programId:
            data.presentation[`program_${programID}_next_vademecum`].program_id,
          onDate: moment(
            data.presentation[`program_${programID}_next_vademecum`].from_date,
            "DD/MM/YY",
          ).toDate(),
        });
      }
    });
  };

  /* Basic form check */
  const checkFormComplete = () => {
    if (
      !editVademecumForm.id ||
      !editVademecumForm.name ||
      !editVademecumForm.sku ||
      !isDiscountValid.discount ||
      (!editVademecumForm.nextOnDate && editVademecumForm.nextDiscount) ||
      (!editVademecumForm.nextDiscount && editVademecumForm.nextOnDate)
    )
      return false;
    return true;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder editar el descuento.",
      );
      return;
    }
    setIsLoading(true);

    // Give adecuate format to send form to db
    let completedForm = {
      name: editVademecumForm.name,
      sku: +editVademecumForm.sku,
    };
    if (
      (editVademecumForm.nextOnDate &&
        editVademecumForm.nextDiscount &&
        !nextDiscountCheck.onDate) ||
      (nextDiscountCheck.onDate &&
        (nextDiscountCheck.onDate.getTime() !==
          editVademecumForm.nextOnDate.getTime() ||
          nextDiscountCheck.discount !== +editVademecumForm.nextDiscount))
    ) {
      completedForm["next_discount"] = {
        discount: +editVademecumForm.nextDiscount,
        program_id: +editVademecumForm.programId,
        on_date: editVademecumForm.nextOnDate
          ? format(new Date(editVademecumForm.nextOnDate), "dd/M/yy")
          : null,
      };
    }
    completedForm = cleanEmptyKeys(completedForm);

    // Sends to db
    dbPut(`presentations/${editVademecumForm.id}`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Descuento editado exitosamente");
        redirectToVademecumsPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al editar descuento",
        ); // translate pending
        setIsLoading(false);
        return;
      });
  };

  /* Cancel editing discount and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankEditVademecumForm);
    history.goBack();
  };

  /* Redirect to discounts table page after editing correctly */
  const redirectToVademecumsPage = (user) => {
    setTimeout(() => {
      updateForm(blankEditVademecumForm);
      setIsLoading(false);
      history.push(routes.abmVademecums);
    }, 3000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    if (props.props.location.state) {
      getVademecumInfo(
        props.props.location.state.presentationId,
        props.props.location.state.programId,
      );
    }
  }, []);

  const title = `Editar Descuento${editVademecumForm.name ? ": " : ""}${
    editVademecumForm.name ? editVademecumForm.name : ""
  }. Programa: ${
    localStorage.getItem("programId") === "1"
      ? "Receta Solidaria"
      : "Solo Mujer"
  }`;

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del producto
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  A continuación se proveen los datos del producto. Puede
                  ingresar un nuevo descuento y su fecha de validez.
                </Typography>
              </div>
              <div style={{ marginTop: "4%" }}>
                <Field
                  labelName="Nombre"
                  required={true}
                  id="name"
                  disabled={true}
                  value={editVademecumForm.name}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <Field
                  labelName="SKU"
                  required={true}
                  id="sku"
                  disabled={true}
                  value={editVademecumForm.sku}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <Field
                  labelName="Descuento actual (%)"
                  id="discount"
                  disabled={true}
                  value={editVademecumForm.discount}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <div className={classes.generalPurposeItems}>
                  <Typography
                    component={"span"}
                    className={classes.text}
                    weight="bold"
                  >
                    Descuento actual
                    <Typography className={classes.text} weight="bold">
                      válido hasta
                    </Typography>
                  </Typography>
                  <DateField
                    name="onDate"
                    disabled={true}
                    format="dd/MM/yyyy"
                    helperText={
                      editVademecumForm.onDate ? "" : "Sin expiración"
                    }
                    value={editVademecumForm.onDate}
                    onChange={handleDateChange}
                    fieldClassName={classes.generalPurposeField}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Establecer próximo descuento
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  Establezca una nueva fecha de validez y descuento para los
                  cuales el producto tendrá efecto.
                </Typography>
              </div>
              <div style={{ marginTop: "4%" }}>
                <div className={classes.generalPurposeItems}>
                  <Typography className={classes.text} weight="bold">
                    Nueva fecha inicial
                  </Typography>
                  <DateField
                    name="nextOnDate"
                    value={editVademecumForm.nextOnDate}
                    onChange={handleDateChange}
                    format="dd/MM/yyyy"
                    fieldClassName={classes.generalPurposeField}
                    minDate={
                      editVademecumForm.onDate
                        ? editVademecumForm.onDate
                        : getCurrentDate()
                    }
                  />
                </div>
                <Field
                  labelName="Nuevo Descuento (%)"
                  required={true}
                  id="nextDiscount"
                  value={editVademecumForm.nextDiscount}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleDiscountChange}
                  InputProps={{
                    inputComponent: NumberFormatter,
                  }}
                  error={!isDiscountValid.discount}
                  helperText={isDiscountValid.message}
                ></Field>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelAndGoBack}
                >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
