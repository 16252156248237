import React from "react";

import datatableTextLabels from "components/MUIDatatable/textLabels";

import { excelDownload } from "utils/excelDownload";

import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TablePagination from "@material-ui/core/TablePagination";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, changePage } = props;

  function handleFirstPageButtonClick() {
    changePage(0);
  }

  function handleBackButtonClick() {
    changePage(page - 1);
  }

  function handleNextButtonClick() {
    changePage(page + 1);
  }
  function handleBackFastButtonClick() {
    changePage(Math.max(page - 5, 0));
  }

  function handleNextFastButtonClick() {
    changePage(Math.min(page + 5, Math.ceil(count / rowsPerPage) - 1));
  }

  function handleLastPageButtonClick() {
    changePage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackFastButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardDoubleArrowRightIcon />
        ) : (
          <KeyboardDoubleArrowLeftIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      {`${page + 1} de ${Math.ceil(count / rowsPerPage)} páginas`}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextFastButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardDoubleArrowLeftIcon />
        ) : (
          <KeyboardDoubleArrowRightIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

function CustomFooter(props) {
  const {
    count,
    page,
    rowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels,
    rowsPerPageOptions,
  } = props;

  return (
    <>
      <TablePagination
        {...props}
        labelRowsPerPage={textLabels.rowsPerPage}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${textLabels.displayRows} ${count} registros`
        }
        onChangeRowsPerPage={(e) => changeRowsPerPage(e.target.value)}
        rowsPerPageOptions={rowsPerPageOptions}
        ActionsComponent={() => {
          return (
            <>
              <TablePaginationActions
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                changePage={changePage}
              />
            </>
          );
        }}
      />
    </>
  );
}

export default function CustomMUIDataTable(props) {
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            backgroundColor: "#dadaf6 !important",
            position: "sticky",
            textAlign: "center",
            zIndex: 2,
          },
          body: {
            display: "table-cell",
            verticalAlign: "middle",
            zIndex: 1,
          },
        },
        MUIDataTableBodyCell: {
          root: {
            height: 15,
            whiteSpace: "nowrap",
          },
        },
        MUIDataTableToolbar: {
          filterPaper: {
            width: "300px",
          },
        },
      },
    });
  // Insert each row of the table in a diccionary; based on onDownload (values)
  const formatExportData = (table) => {
    const exportData = [];
    table.forEach((row) => {
      const rowData = {
        data: row.filter((x) => !x.excluded).map((x) => x.data),
      };
      exportData.push(rowData);
    });
    return exportData;
  };

  return (
    <>
      <MuiThemeProvider theme={getMuiTheme}>
        <MUIDataTable
          title={props.title}
          data={props.data}
          columns={props.columns}
          options={{
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
            onFilterChange: (column, value, type, columnIndex, data) => {
              if (props.setFilter) {
                props.setFilter[columnIndex](value[columnIndex][0]);
              }
            },

            onDownload: (buildHead, buildBody, columns, values) => {
              if (props.exportData) {
                values = formatExportData(props.exportData);
              }
              return excelDownload(
                columns,
                values,
                props.filterStart,
                props.filterEnd,
                props.titleDownload
                  ? props.titleDownload
                  : props.title.toLowerCase(),
              );
            },
            filterType: props.filterType ? props.filterType : "checkbox",
            textLabels: datatableTextLabels(),
            print: false,
            selectableRows: "none",
            viewColumns: false,
            rowsPerPage: 25,
            tableBodyMaxHeight: "700px",
            fixedHeader: true,
            setRowProps: props.setRowProps,
            customFooter: (
              count,
              page,
              rowsPerPage,
              changeRowsPerPage,
              changePage,
              textLabels,
            ) => {
              return (
                props.options?.pagination !== false && (
                  <CustomFooter
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    changeRowsPerPage={changeRowsPerPage}
                    changePage={changePage}
                    textLabels={textLabels}
                    rowsPerPageOptions={[25, 50, 100]}
                  />
                )
              );
            },
            ...props.options,
          }}
        />
      </MuiThemeProvider>
    </>
  );
}
