import { makeStyles } from "@material-ui/styles";

const selectorMargin = "70px";

export default makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(1),
  },
  generalSelectionField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    marginTop: "-1%",
    marginLeft: selectorMargin,
    width: 350,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  hidden: {
    display: "none !important",
  },
}));
