/* COMPONENTS */
import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { SummaryTable, exportToPDF } from "components/Table/SummaryTable";
import {
  blankFilters,
  createColumns,
  detailedFeesColumns,
  parseGetAllErrorsAndWarnings,
  summaryHeader,
  summaryRowHeader,
} from "./tablesUtils/Sales";

import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import LoaderBar from "components/LoaderBar/LoaderBar";
import PageTitle from "components/PageTitle/PageTitle";
import SelectionForm from "components/SelectionForm/AuditSelectionForm";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
/*  FUNCTIONS AND DATA */
import { dbGet } from "utils/DBFetchers";
import { format } from "date-fns";
import { getRoleName } from "utils/roleNameGetter";
import logo from "images/logoPagesRS.jpg";
/* STYLES */
import useStyles from "./styles";
import {
  mapFormattedBurnableTableData,
  mapBurnableTableData,
} from "./tablesUtils/AuditBurnableSales";

const allPrograms = [
  { id: 1, name: "Receta solidaria" },
  { id: 2, name: "Solo mujer" },
  { id: 3, name: "GES" },
  { id: 4, name: "Mi Salcobrand" },
];

const gesProgram = [{ id: 3, name: "GES" }];

const errors = [
  { id: 1, name: "Descuento porcentual informado cero" }, // INFORMED_DISCOUNT_PERCENTAGE_ZERO_ERROR
  { id: 2, name: "Presentación inexistente" }, // INEXISTENT_PRESENTATION_ERROR
  { id: 3, name: "Unidades totales informadas excedidas" }, // INFORMED_TOTAL_UNITS_EXCEEDED_ERROR
  { id: 4, name: "Unidades totales informadas inválidas" }, // INVALID_INFORMED_TOTAL_UNITS_ERROR
  { id: 5, name: "Descuento informado cero" }, // INFORMED_DISCOUNT_ZERO_ERROR
  { id: 14, name: "Vademécum inexistente para presentación" }, // INEXISTENT_VADEMECUM_FOR_PRESENTATION_ERROR
  { id: 15, name: "Precio GES inexistente para presentación" }, // INEXISTENT_GES_PRICE_FOR_PRESENTATION_ERROR
  { id: 16, name: "Descuento Mi Salco inexistente para presentación" }, // INEXISTENT_MISALCOBRAND_DISCOUNT_FOR_PRESENTATION_ERROR
  { id: 18, name: "Paciente inexistente" }, // INEXISTENT_PATIENT_ERROR
  { id: 19, name: "Empadronamiento inexistente para paciente" }, // INEXISTENT_INSCRIPTION_FROM_PATIENT_ERROR
  { id: 20, name: "Empadronamiento expirado" }, // EXPIRED_INSCRIPTION_ERROR

  { id: 22, name: "Distribuidor incorrecto para la fecha" }, // INCORRECT_DISTRIBUTOR_ERROR
  { id: 23, name: "Farmacia inexistente." }, // INEXISTENT_PHARMACY_ERROR
  { id: 24, name: "Fecha de venta invalida" }, // INVALID_SALE_DATE_ERROR
];

export default function SalesPage() {
  var [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  var classes = useStyles();
  const [groupFilter, setGroupFilter] = useState("");
  const [tableData, setTableData] = useState([]);
  const [formattedTableData, setFormattedTableData] = useState([]);
  const [filters, setFilters] = useState(blankFilters);
  const [isGES, setIsGES] = useState(+filters.program === 3);
  const [isSalco, setIsSalco] = useState(+filters.program === 4);
  const [columns, setColumns] = useState(createColumns(isGES, isSalco));
  const [summaryData, setSummaryData] = useState([]);
  const [summary, setSummary] = useState({});
  const [canDownloadPDF, setCanDownloadPDF] = useState(false);
  const [distributorFees, setDistributorFees] = useState([]);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [allDistributors, setAllDistributors] = useState([]);

  // Color variables
  let errorCellColor = "251, 201, 199"; // red
  let warningCellColor = "255, 255, 187"; // yellow
  let okCellColor = "194, 241, 190"; // green
  // Important to set the right color for the cells

  // Keep updated if there are changes to the table.
  const WARNING_POS = 36;
  const COLOR_POS = 42;
  const PERIOD_POS = 2;
  // Groups //
  const ALL_GROUPS = "Total";
  const GROUP_INDEX = 39; // Passed as a dictionary key to the MUIDataTable

  // Formats price with dot for thousands and comma for decimals
  const formatNumber = (value) => {
    return value
      ? Intl.NumberFormat("es-AR").format(Math.round(value * 100) / 100)
      : 0;
  };

  // Checks if the filters are valid
  const validFilters = () => {
    let validDistributor = filters.distributor ? true : false;
    let validProgram = filters.program ? true : false;

    if (!validDistributor) {
      snackbarOpen("error", "El campo de cadena está vacio");
    }
    if (!validProgram) {
      snackbarOpen("error", "El campo del programa está vacio");
    }

    return validDistributor && validProgram;
  };

  // Export to PDF setup
  const handleExportToPDF = (props) => {
    // API requiers header to be a list of lists or dics.
    let headers = [];
    props.header.map((value) => {
      headers.push(value.title);
    });
    props.header = [];
    props.header.push(headers);
    props.name = `Reporte de Ventas `;
    if (groupFilter) {
      props.name += `${groupFilter} `;
    }
    props.name += `${props.date.data}`;
    exportToPDF(props);
  };

  useEffect(() => {
    setIsGES(+filters.program === 3);
    setIsSalco(+filters.program === 4);
  }, [filters.program]);

  // Create and save the summary data table
  const createSummaryData = (data, distributorFees) => {
    // INFORMADO === LOS QUE ESTÁN BIEN + LOS ERRORS + LOS WARNINGS
    // CALCULADO === LOS QUE ESTÁN BIEN + LOS WARNINGS
    // CORREGIDOS === LOS WARNINGS
    let newSummaryData = [
      [
        // informed
        formatNumber(data.total_transactions),
        formatNumber(data.total_informed_units),
        formatNumber(data.total_informed_discount),
        "-",
        "-",
      ],
      [
        // errors
        formatNumber(data.total_error_transactions),
        formatNumber(data.total_error_units),
        formatNumber(-data.total_error_discount),
        "-",
        "-",
      ],
      [
        // warnings
        formatNumber(data.total_warning_transactions),
        formatNumber(data.total_warning_units),
        formatNumber(data.total_warning_discount),
        "-",
        "-",
      ],
      [
        // final - informed = diferencia
        formatNumber(data.total_transactions_valid - data.total_transactions),
        formatNumber(data.total_calculated_units - data.total_informed_units),
        formatNumber(
          data.total_calculated_discount - data.total_informed_discount,
        ),
        "-",
        "-",
      ],
      [
        // final
        formatNumber(data.total_transactions_valid),
        formatNumber(data.total_calculated_units),
        formatNumber(data.total_calculated_discount),
        formatNumber(data.total_transaction_fee),
        data.total_administration_fee
          ? formatNumber(data.total_administration_fee)
          : 0,
      ],
    ];

    summaryRowHeader.map((value, index) => {
      newSummaryData[index].unshift(value);
    });

    setSummaryData(newSummaryData);
  };

  const mapTableData = (data) => {
    return data.map((row) => {
      const errorsAndWarnings = parseGetAllErrorsAndWarnings(
        row.errors,
        row.warnings,
        filters.distributor,
        isGES,
        isSalco,
        row.program_id,
        row.calculated_discount,
        row.informed_discount,
      );
      return [
        { excluded: false, data: row.id }, // 0 Nro de venta
        {
          excluded: false,
          data: format(new Date(row.transaction_date + " EDT"), "dd/MM/yyyy"),
        }, // 1 Fecha
        {
          excluded: false,
          data: row.period
            ? row.period.toString().substring(4) +
              "/" +
              row.period.toString().substring(0, 4)
            : "",
        }, // 2 Periodo
        {
          excluded: false,
          data: row.pharmacy_code ? row.pharmacy_code : "-",
        }, // 3 Sucursal
        {
          excluded: false,
          data: row.presentation_external_code
            ? row.presentation_external_code
            : "",
        }, // 4 Cod. presentacion
        {
          excluded: false,
          data: row.presentation?.sku ? row.presentation.sku : "-",
        }, // 5 SKU
        {
          excluded: false,
          data: row.presentation_vrf ? row.presentation_vrf : "-",
        }, // 6 Presentacion informada
        {
          excluded: false,
          data: row.inscribed_presentation?.name
            ? row.inscribed_presentation?.name
            : "-",
        }, // 6 Presentacion suscrita
        {
          excluded: false,
          data: row.coupon_type || "",
        }, // 7 Tipo de cupón
        {
          excluded: false,
          data:
            row.is_reachable !== null ? (row.is_reachable ? "Sí" : "No") : "-",
        }, // 8 Contactable
        {
          excluded: false,
          data: row.barcode || "",
        }, // 9 Codigo de barras
        { excluded: false, data: row.informed_total_units }, // 10 Informado {Cant.}
        { excluded: false, data: row.informed_exlab_price }, // 11 Informado {ExLab.}
        {
          excluded: false,
          data: isSalco
            ? row.informed_exlab_price
            : row.informed_exlab_price * row.informed_total_units,
        }, // 12 Informado {Total}
        {
          excluded: false,
          data: isGES
            ? row.informed_ges_price
            : row.informed_discount_percentage,
        }, // 13 Informado {GES | Des %.}
        { excluded: false, data: row.informed_discount }, // 14 Informado {Desc $}
        { excluded: false, data: row.calculated_total_units }, // 15 Calculado {Cant}
        { excluded: false, data: row.calculated_exlab_price }, // 16 Calculado {ExLab}
        {
          excluded: false,
          data: isSalco
            ? row.calculated_exlab_price
            : row.calculated_exlab_price * row.calculated_total_units,
        }, // 17 Calculado {Total}
        {
          excluded: false,
          data: isGES
            ? row.calculated_ges_price
            : row.calculated_discount_percentage,
        }, // 18 Calculado {GES | Desc %.}
        { excluded: false, data: row.calculated_discount }, // 19 Calculado {Desc $}
        {
          excluded: false,
          data:
            Math.abs(row.calculated_discount - row.informed_discount) <= 10
              ? 0
              : row.calculated_discount - row.informed_discount,
        }, // 20 Diferencia: si es menor o igual a 10 => es 0
        { excluded: false, data: row.ticket }, // 21 Ticket
        { excluded: false, data: row.zone_name }, // 22 Zona
        { excluded: false, data: errorsAndWarnings.paymentStatus }, // 23 Estado pago
        {
          excluded: isSalco || isGES,
          data: row.inscription_date
            ? format(new Date(row.inscription_date + " EDT"), "dd/MM/yyyy")
            : "-",
        }, // 24 Fecha de empadronamiento
        {
          excluded: isSalco || isGES,
          data: errorsAndWarnings.inscription.length
            ? errorsAndWarnings.inscription
            : "-",
        }, // 25 Comentario empadronamiento
        {
          excluded: false,
          data: errorsAndWarnings.units.length ? errorsAndWarnings.units : "-",
        }, // 26 "Comentario unidades"
        {
          excluded: !isSalco,
          data: errorsAndWarnings.purchaseNumber.length
            ? errorsAndWarnings.purchaseNumber
            : "-",
        }, // 27 "Comentario unidad de avance"
        {
          excluded: false,
          data: errorsAndWarnings.discountPercent.length
            ? errorsAndWarnings.discountPercent
            : "-",
        }, // 28 "Comentario descuento ( % )"
        {
          excluded: false,
          data: errorsAndWarnings.discount.length
            ? errorsAndWarnings.discount
            : "-",
        }, // 29 "Comentario descuento ( $ )"
        {
          excluded: false,
          data: errorsAndWarnings.exlab.length ? errorsAndWarnings.exlab : "-",
        }, // 30 "Comentario EXLAB"
        {
          excluded: isSalco || isGES,
          data: errorsAndWarnings.vademecum.length
            ? errorsAndWarnings.vademecum
            : "-",
        }, // 31 "Comentario Vademécum presentación"
        {
          excluded: !isGES,
          data: errorsAndWarnings.ges.length ? errorsAndWarnings.ges : "-",
        }, // 32 "Comentario GES"
        {
          excluded: !isSalco,
          data: errorsAndWarnings.patientInexistent.length
            ? errorsAndWarnings.patientInexistent
            : "-",
        }, // 33 "Comentario paciente inexistente"
        {
          excluded: isGES || isSalco,
          data: errorsAndWarnings.independents.length
            ? errorsAndWarnings.independents
            : "-",
        }, // 34 Errores de independientes
        {
          excluded: false,
          data: errorsAndWarnings.warnings.length
            ? errorsAndWarnings.warnings
            : "-",
        }, // 35 Advertencias
        { excluded: !isGES, data: row.isapre }, // 36 ISAPRE
        {
          excluded: !isSalco,
          data:
            isSalco && row.mi_salcobrand_discount
              ? row.mi_salcobrand_discount[0]
              : "",
        }, // 37 Descuento total
        {
          excluded: !isSalco,
          data:
            isSalco && row.mi_salcobrand_discount
              ? row.mi_salcobrand_discount[1]
              : "",
        }, // 38 Descuento Abbott
        { excluded: true, data: row.group_name }, // 38 Grupo
        {
          excluded: false,
          data: row.patient_id ? row.patient_id : "-", // GES doesnt send patient_id (wont generate an error)
        }, // 40 ID Paciente
        {
          excluded: false,
          data: row.distributor?.name,
        }, // Distribuidor
        {
          excluded: false,
          data: row.pharmacy?.name ? row.pharmacy.name : "-",
        }, // Nombre de farmacia
        {
          excluded: false,
          data: row.pharmacy?.rut ? row.pharmacy.rut : "-",
        }, // RUT de farmacia
        {
          excluded: false,
          data: row.presentation?.cfr_code ? row.presentation.cfr_code : "-",
        },
        {
          excluded: true,
          data: errorsAndWarnings.cellColor,
        }, // 41 Defines cell color for special cases
      ];
    });
  };

  const mapFormattedTableData = (table) => {
    return table.map((row) => [
      row[0].data, // 0 Nro de venta
      row[1].data, // 1 Fecha
      row[2].data, // 2 Periodo
      row[3].data, // 3 Sucursal
      row[4].data, // 4 Cod. presentacion
      row[5].data, // 5 SKU
      row[6].data, // 6 Presentacion informada
      row[7].data, // 7 Tipo de cupón
      row[8].data, // 8 Contactable
      row[9].data, // 9 Codigo de barras del cupón
      row[10].data, // 10 Informado {Cant. | Nro. compra}
      formatNumber(row[11].data), // 11 Informado {ExLab.}
      formatNumber(row[12].data), // 12 Informado {Total}
      formatNumber(row[13].data), // 13 Informado {GES | Des %.}
      formatNumber(row[14].data), // 14 Informado {Desc $}
      row[15].data, // 15 Calculado {Cant. | Nro. compra}
      formatNumber(row[16].data), // 16 Calculado {ExLab}
      formatNumber(row[17].data), // 17 Calculado {Total}
      formatNumber(row[18].data), // 18 Calculado {GES | Desc %.}
      formatNumber(row[19].data), // 19 Calculado {Desc $}
      formatNumber(row[20].data), // 20 Diferencia
      row[21].data, // 21 Ticket
      row[22].data, // 22 Zona
      row[23].data, // 23 Estado pago
      row[24].data, // 24 Fecha de empadronamiento
      row[25].data, // 25 Comentario empadronamiento
      row[26].data, // 26 Comentario unidades
      row[27].data, // 27 Comentario unidad de avance
      row[28].data, // 28 Comentario descuento ( % )
      row[29].data, // 29 Comentario descuento ( $ )
      row[30].data, // 30 Comentario EXLAB
      row[31].data, // 31 Comentario Vademécum
      row[32].data, // 32 Comentario GES
      row[33].data, // 33 Comentario paciente inexistente
      row[34].data, // 34 Errores de independientes
      row[35].data, // 35 Advertencias
      row[36].data, // 36 ISAPRE
      row[37].data, // 37 Descuento total
      row[38].data, // 38 Descuento Abbott
      row[39].data, // 39 Grupo
      row[40].data, // 40 ID Paciente
      row[41].data, // 41 Color de celda (propio del front)
      row[42].data, // 6-bis Presentación suscrita
      row[43].data, // Distrbuidor
      row[44].data, // Nombre de farmacia
      row[45].data, // RUT de farmacia
      row[46].data, // Código CFR
    ]);
  };

  const updateProgress = (id) => {
    dbGet(`request_checker/status?id=${id}`)
      .then((data) => {
        if (data.status === "Completed") {
          setIsLoading(false);
          setIsLoadingButton(false);
          if (data.length === 0 || data.data.length === 0) {
            setTableData([]);
            setFormattedTableData([]);
            setSummaryData([]);
            setCanDownloadPDF(false);
            return;
          }
          let table = mapTableData(data.data.data);
          setTableData(table);
          setFormattedTableData(mapFormattedTableData(table));
          let distributorFees = [
            data.data.fees.unitary_administration_fee,
            data.data.fees.unitary_transaction_fee,
          ];
          createSummaryData(
            data.data.fees.summary[ALL_GROUPS],
            distributorFees,
          );
          // Save all the groups data for later use
          setSummary(data.data.fees.summary);
          setCanDownloadPDF(true);
          setIsLoading(false);
        } else if (!data.error_status && data.status !== "Failure") {
          setTimeout(updateProgress.bind(null, id), 2000);
        } else {
          snackbarOpen(
            "error",
            data.error_message
              ? data.error_message.description_es
              : "Error cargando la tabla",
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error.error_message
            ? error.error_message.description_es
            : "Error cargando la tabla",
        );
        setIsLoading(false);
      });
  };

  // Get data
  const handleApplyFilters = () => {
    if (!validFilters()) {
      return;
    }

    const periodFormated = filters.period.split("/").join("");
    const period = `&period=${periodFormated}`;
    const distributorId = `${
      filters.distributor ? `&distributor_id=${filters.distributor}` : ""
    }`;
    const programId = `&program_id=${filters.program}`;
    const status = `&status=${filters.status}`;
    const importID = `&import_id=${filters.importID}`;
    const errors = `&error_ids=${filters.observations.join(",")}`;
    const zoneId = `${filters.zone ? `&zone_id=${filters.zone}` : ""}`;
    const auditType =
      filters.program === 1
        ? `&audit_type=${filters.type}`
        : "&audit_type=traditionals";

    setIsLoading(true);
    dbGet(
      `audit/sales?${period}${programId}${distributorId}${importID}${status}${errors}${zoneId}${auditType}`,
    )
      .then((response) => {
        setTimeout(updateProgress.bind(null, response.id), 3000);
      })
      .catch((error) => {
        if (error.response) {
          snackbarOpen(
            "error",
            error.response.data
              ? error.response.data.description_es
              : "Error cargando la tabla",
          );
          setIsLoading(false);
        }
      });
  };

  const getDistributors = () => {
    dbGet("distributors").then((data) => {
      setAllDistributors(data);
    });
  };

  useEffect(() => {
    getDistributors();
  }, []);

  const getDistributorNameById = (id) => {
    let distributor = allDistributors.find(
      (distributor) => distributor.id === id,
    );
    return distributor ? distributor.name : "";
  };

  const getProgramNameById = (id) => {
    let program = allPrograms.find((program) => program.id === id);
    return program ? program.name : "";
  };

  // Update the summary with the selected group
  useEffect(() => {
    if (summary && Object.keys(summary).length) {
      if (!groupFilter) {
        createSummaryData(summary[ALL_GROUPS]);
      } else {
        createSummaryData(summary[groupFilter]);
      }
    }
  }, [groupFilter]);

  useEffect(() => {
    if (filters.period && filters.distributor) {
      const period = `period=${filters.period.split("/").join("")}`;
      const distributor = `distributor_id=${filters.distributor}`;
      dbGet(`distributors/historical?${period}&${distributor}`)
        .then((data) => {
          setDistributorFees(data);
        })
        .catch((err) => {
          setDistributorFees([]);
          snackbarOpen(
            "error",
            "Error cargando los descuentos de distribuidor",
          );
        });
    }
  }, [filters.distributor, filters.period]);

  const defaultRowProps = (row) => {
    if (row[row.length - 1] === "red")
      return {
        style: { backgroundColor: `rgb(${errorCellColor})` },
      };
    else if (row[row.length - 1] === "yellow") {
      return {
        style: { backgroundColor: `rgb(${warningCellColor})` },
      };
    } else
      return {
        style: { backgroundColor: `rgb(${okCellColor})` },
      };
  };

  useEffect(() => {
    setColumns(createColumns(isGES, isSalco, filters.type === "quemable"));
    setTableData([]);
    setFormattedTableData([]);
    setSummaryData([]);
    setCanDownloadPDF(false);
  }, [filters]);

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <div>
        <PageTitle
          title="Ventas"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={4}
        xs={12}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={6}
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginLeft: "-30px",
          }}
        >
          <Grid
            container
            xs={12}
            md={12}
            lg={12}
            layout={"row"}
            spacing={"1"}
            style={{ border: "1px" }}
          >
            <SelectionForm
              isLoading={isLoading}
              isLoadingButton={isLoadingButton}
              setIsLoading={setIsLoadingButton}
              setFilters={setFilters}
              filters={filters}
              applyFunction={handleApplyFilters}
              programs={getRoleName() === "ges" ? gesProgram : allPrograms}
              errors={errors}
              independents={getRoleName() === "ges" ? false : undefined}
              sales
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={6}>
          <Grid item xs={12} style={{ border: "1px" }}>
            <SummaryTable
              title="Ventas"
              columns={summaryHeader}
              rows={summaryData}
              detailedFeesColumns={detailedFeesColumns}
              detailedFeesRows={distributorFees}
            />
          </Grid>
          <Grid item xs={12} className={classes.alignCenter}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleExportToPDF({
                  title: "Ventas",
                  header: summaryHeader,
                  data: summaryData,
                  date: tableData[0][PERIOD_POS],
                });
              }}
              style={{ marginTop: "5%", marginBottom: "5px" }}
              disabled={!canDownloadPDF}
            >
              Descargar PDF
            </Button>
          </Grid>
        </Grid>
        {isLoading ? (
          <LoaderBar />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomMUIDataTable
                title="Ventas"
                data={formattedTableData} // to show
                exportData={tableData} // to export
                columns={columns}
                setFilter={{ [GROUP_INDEX]: setGroupFilter }}
                titleDownload={`Ventas ${filters.period} ${getProgramNameById(
                  filters.program,
                )} ${getDistributorNameById(filters.distributor)}`}
                filterEnd={
                  isGES
                    ? columns.length - 3
                    : isSalco
                    ? columns.length - 2
                    : columns.length - 4
                }
                setRowProps={defaultRowProps}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
