import { makeStyles } from "@material-ui/styles";

// @ts-ignore
export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    fontWeight: "fontWeightBold",
    fontSize: "24px",
  },
  button: {
    margin: theme.spacing(1),
    padding: "43px",
    fontSize: "72px",
    "&:hover": {
      background: "#3f51b5",
      color: "white",
    },
  },
  head: {
    fontSize: "64px",
    variant: "h2",
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  buttonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    justifyContent: "center",
    marginLeft: "",
    marginTop: "2%",
    marginBottom: "2%",
  },
  onlyButtonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  loginLoader: {
    marginTop: "3%",
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(6.8),
  },
  loginLoaderPopup: {
    display: "flex",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(10.8),
  },
  logotypeImage: {
    width: 157,
    height: 60,
    marginTop: "-1%",
  },
  popupButtonSpacing: {
    margin: theme.spacing(1),
  },
  popupButtonSpacingDelete: {
    margin: theme.spacing(1),
    backgroundColor: "red",
    color: "white",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  popupButtonSpacingEnable: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    color: "white",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  popupDivCenter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  generalPurposeItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 700,
    justifyContent: "space-between",
  },
  smallItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 430,
    justifyContent: "space-between",
  },
  generalPurposeField: {
    marginRight: theme.spacing(1),
    //marginTop: "-1%",
    //width: 500,
  },
  smallField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  selectorField: {
    marginRight: theme.spacing(1),
    marginTop: "-1%",
    width: 170,
  },
  searchField: {
    width: 500,
    marginTop: "-1%",
    marginBottom: "2%",
  },
  smallFieldValidation: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  generalFieldValidation: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 500,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "#fff",
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
  },
  dateFilterField: {
    display: "flex",
    marginRight: theme.spacing(1),
    width: 230,
  },
  divDateFilter: {
    display: "flex",
    marginTop: "2%",
    width: 700,
  },
  buttonDateFilter: {
    display: "flex",
    margin: theme.spacing(1),
    height: 40,
  },
  // RUT FIELD REQUIRED STYLES
  generalPurposeItems2: {
    display: "flex",
    width: 310,
    justifyContent: "flex-start",
  },
  smallItems2: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 502,
    justifyContent: "space-between",
  },
  smallFieldValidation2: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginTop: "-2%",
    width: 230,
  },
  smallFieldValidation3: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginTop: "-2%",
    width: 80,
  },
}));
