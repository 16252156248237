import { makeStyles } from "@material-ui/styles";

const selectorMargin = "30px";
const selectorWidth = 250;
const selectorMinWidth = 200;
const selectorMaxWidth = 350;
const errorSelectorWidth = 350;

export default makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(2),
  },
  alignedText: {
    display: "flex",
    justifyContent: "flex-start",
    alignSelf: "center",
  },
  buttonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    // marginLeft: "15em",
    marginTop: "2%",
    marginBottom: "3%",
  },
  generalPurposeItems: {
    width: 310,
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
  },
  generalPurposeField: {
    width: 250,
  },
  chip: {
    margin: 2,
    display: "flex",
    flexWrap: "wrap",
  },
  rowValueItems: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginLeft: "2.4em",
  },
  fieldValueContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  fieldListValueContainer: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 500,
  },
  dateSelectionField: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-1%",
    marginLeft: selectorMargin,
    width: selectorWidth,
    maxWidth: selectorMaxWidth,
    minWidth: selectorMinWidth,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  generalSelectionField: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-1%",
    marginLeft: selectorMargin,
    width: selectorWidth,
    maxWidth: selectorMaxWidth,
    minWidth: selectorMinWidth,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  errorSelectionField: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-1%",
    marginLeft: selectorMargin,
    width: errorSelectorWidth,
    maxWidth: errorSelectorWidth,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  productPickerContainer: {
    display: "flex",
    justifyContent: "center",
    minHeight: "460px",
  },
  emailPhoneChangerText: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "-1em",
    marginBottom: "1em",
    width: "130px",
  },
  loadingField: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  alignLeft: {
    display: "flex",
    justifyContent: "flex-start",
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
  },
  textAlignRight: {
    textAlign: "right",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  realCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  uploadButton: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: "1%",
  },
  uploadFormButton: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  nameSelector: {
    display: "flex",
    justifyContent: "center",
    width: "400px",
    minWidth: "235px",
  },
  submitLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sendField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 130,
    height: 40,
  },
  // RUT FIELD REQUIRED STYLES
  generalPurposeItems2: {
    display: "flex",
    width: 310,
    justifyContent: "flex-start",
  },
  smallItems2: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "5%",
    width: 502,
    justifyContent: "space-between",
  },
  rutVerifierField: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginTop: "-2%",
    width: 230,
  },
  digitVerifierField: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginTop: "-2%",
    width: 80,
  },
}));
