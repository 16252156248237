import { Card, Paper } from "@mui/material";
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import { Typography } from "components/Wrappers/Wrappers";
import WorkIcon from "@mui/icons-material/Work";
import ReceiptIcon from "@mui/icons-material/Receipt";
import useStyles from "../styles";
import { useTheme } from "@material-ui/styles";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

const DEFAULT_CARDS_DATA = {
  converted: 0,
  coupons_to_patients: 0,
  digital_convertions: {
    value: 0,
    percentage: 0,
  },
};

const formatNumber = (value) => {
  return value
    ? Intl.NumberFormat("es-AR").format(Math.round(value * 100) / 100)
    : 0;
};

const formatPercentage = (value) => {
  return (value * 100).toFixed(0) + "%";
};

export default function SummaryCards(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [cardsData, setCardsData] = useState(DEFAULT_CARDS_DATA);

  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    textAlign: "center",
    borderRadius: "20px",
    padding: theme.spacing(5, 10),
    color: "#7640B3",
    backgroundColor: "#E4D1FC",
  }));
  const RootStyle2 = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    textAlign: "center",
    borderRadius: "20px",
    padding: theme.spacing(5, 10),
    color: "#3E62AD",
    backgroundColor: "#D0F2FF",
  }));
  const RootStyle3 = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    textAlign: "center",
    borderRadius: "20px",
    padding: theme.spacing(5, 12),
    color: "#99742F",
    backgroundColor: "#FFF7CD",
  }));
  const IconWrapperStyle = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "30%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.dark,
    backgroundImage: `linear-gradient(135deg, ${alpha(
      theme.palette.primary.dark,
      0,
    )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
  }));
  const IconWrapperStyle3 = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "30%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    color: theme.palette.warning.dark,
    backgroundImage: `linear-gradient(135deg, ${alpha(
      theme.palette.warning.dark,
      0,
    )} 0%, ${alpha(theme.palette.warning.dark, 0.24)} 100%)`,
  }));

  useEffect(() => {
    setCardsData(assembleCardsData(props.information));
  }, []);

  const assignCardInfo = (value1, value2) => {
    let formatedValue1 = formatNumber(value1);
    let percentage = value2 === 0 ? "-" : formatPercentage(value1 / value2);
    return {
      value: formatedValue1,
      percentage: percentage,
    };
  };

  const assembleCardsData = (information) => {
    return {
      digital_convertions: assignCardInfo(
        information.converted.total,
        information.coupons_to_patients,
      ),
      converted: information.converted.total,
      coupons_to_patients: formatNumber(information.coupons_to_patients),
    };
  };

  return (
    <Grid
      item
      container
      spacing={2}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Grid
        item
        xs={3}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <RootStyle2>
          <IconWrapperStyle>
            <PeopleAltIcon />
          </IconWrapperStyle>
          <Typography variant="h3">
            {cardsData.coupons_to_patients || 0}
          </Typography>
          <Typography variant="subtitle1">{"-"}</Typography>{" "}
          {/* To have the same height as "Conversiones" */}
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Cupones a pacientes
          </Typography>
        </RootStyle2>
      </Grid>
      <Grid
        item
        xs={3}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <RootStyle3>
          <IconWrapperStyle3>
            <ReceiptIcon />
          </IconWrapperStyle3>
          <Typography variant="h3">{cardsData.converted || 0}</Typography>
          <Typography variant="subtitle1">
            {cardsData.digital_convertions["percentage"] || 0}
          </Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Conversiones
          </Typography>
        </RootStyle3>
      </Grid>
    </Grid>
  );
}
