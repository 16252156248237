import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Typography } from "@material-ui/core";

export default function LinearProgressWithLabel(props) {
  return (
    <div className={props.className}>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant={props.variant} {...props} />
          <Typography variant="body2" color="textSecondary">
            {props.label}
          </Typography>
        </Box>
        {props.variant === "determinate" ? (
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              props.value,
            )}%`}</Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </div>
  );
}
