import React, { useEffect, useState } from "react";
import { dbGet } from "utils/DBFetchers";
import SelectField from "./SelectField";

import useStyles from "./styles";

export default function ProgramsField(props) {
  var classes = useStyles();
  const [allPrograms, setAllPrograms] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([
    { id: 1, name: "Receta solidaria" },
    { id: 2, name: "Solo mujer" },
  ]);

  const filterPrograms = (data) => {
    var filteredData = data;
    if (props.GES === false) {
      filteredData = filteredData.filter((program) => program.name !== "GES");
    }
    if (props.Salco === false) {
      filteredData = filteredData.filter(
        (program) => program.name !== "Mi Salcobrand",
      );
    }
    if (props.burnable) {
      filteredData = [...filteredData, { id: 1000, name: "Quemables" }];
    }
    setFilteredPrograms(filteredData);
  };

  useEffect(() => {
    if (allPrograms.length) filterPrograms(allPrograms);
  }, [props.GES, props.Salco]);

  useEffect(() => {
    dbGet("/programs")
      .then((data) => {
        setAllPrograms(data);
        filterPrograms(data);
      })
      .catch((error) => {});
  }, []);

  return (
    <SelectField
      divClassName={props.divClassName}
      fieldClassName={props.fieldClassName}
      displayText={props.displayText}
      id="programs"
      label="Seleccione"
      name="program"
      value={props.value}
      onChange={props.onChange}
      allValues={filteredPrograms ? filteredPrograms : []}
    />
  );
}
