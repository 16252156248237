import { makeStyles } from "@material-ui/styles";
import { fileExistsSync } from "../../../node_modules/tsconfig-paths/lib/filesystem";

export default makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(2),
  },
  alignedText: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  buttonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    marginLeft: "30em",
    marginTop: "2%",
    marginBottom: "3%",
  },
  generalPurposeItems: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  selectionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "auto",
  },
  rowValueItems: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginLeft: "2.4em",
  },
  dateValueContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifySelf: "stretch",
    maxWidth: 506,
  },
  fieldValueContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  fieldListValueContainer: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 506,
  },
  smallItems: {
    display: "flex",
    marginTop: "1%",
    width: 350,
    justifyContent: "space-between",
  },
  dateSelectionField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-1%",
    marginLeft: "500px",
    width: 250,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  generalSelectionField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    marginBottom: "3%",
    marginLeft: "2em",
    width: 350,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
    
  },
  cyclesSelectionField: {
    width: 150,
    //maxWidth: 110,
    //minWidth: 90,
    marginBottom: "5.5%",
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  yearSelectionField: {
    marginBottom: "3%",
    width: 160,
    marginLeft: "2em",
    //maxWidth: 135,
    //minWidth: 115,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  smallField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  selectorField: {
    marginRight: theme.spacing(1),
    marginTop: "-1%",
    width: 170,
  },
  logotypeImage: {
    width: 157,
    height: 60,
    marginTop: "-1%",
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  alignLeft: {
    display: "flex",
    justifyContent: "flex-start",
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
  },
  sendItem: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "1%",
    width: 700,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  sendField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "6%",
    width: 130,
    height: 40,
  },
  submitLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  realCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridItemSelector: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1%",
  },
}));
