import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, CircularProgress, Grid, Paper } from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";
import RutField from "components/CustomFields/RutField";

import Field from "components/CustomFields/Field.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { dbGet, dbPost } from "utils/DBFetchers.js";
import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";
import SelectField from "components/CustomFields/SelectField";

// rut verification
import { verifyRut } from "utils/rutValidations.js";

// styles
import useStyles from "./styles";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import ProgramsField from "components/CustomFields/ProgramField";

const blankNewMedicForm = {
  id: "",
  rut: "",
  rutVerifier: "",
  name: "",
  lastName: "",
};

export default function ABMMedicsNewPage(props) {
  var classes = useStyles();
  var [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [program, setProgram] = useState(0);
  const [newMedicForm, updateForm] = useState(blankNewMedicForm);
  const [allMedics, setAllMedics] = useState([]);
  const [isRutValid, setIsRutValid] = useState({ rut: false, message: "" });

  const handleChange = (event) => {
    updateForm({
      ...newMedicForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleRutVerification = () => {
    var currentRut = newMedicForm.rut + newMedicForm.rutVerifier;
    var rutValid = verifyRut(currentRut);
    var rutExists = allMedics.some((elem) => elem.rut === currentRut);

    if (rutExists && newMedicForm.rutVerifier)
      setIsRutValid({ rut: false, message: "Médico ya existente." });
    else if (
      (rutExists && !newMedicForm.rutVerifier) ||
      (rutValid && !newMedicForm.rutVerifier)
    )
      setIsRutValid({
        rut: false,
        message: "Ingrese el dígito verificador en el campo respectivo.",
      });
    else if (!rutValid) setIsRutValid({ rut: false, message: "RUT inválido." });
    else setIsRutValid({ rut: true, message: "" });
  };

  // Rut updaters
  const handleRutChange = (e) => {
    updateForm({
      ...newMedicForm,
      [e.target.id]: e.target.value.toUpperCase(),
    });
  };
  React.useEffect(() => {
    if (!newMedicForm.rut && !newMedicForm.rutVerifier) return; // no fetch on page load
    handleRutVerification();
  }, [newMedicForm.rut, newMedicForm.rutVerifier]);

  /* Fills medic data to check if exists */
  const getMedics = () => {
    dbGet("medics").then((data) => {
      if (!data.length) return;
      let mapped = data.map((item) => {
        return { id: item["id"], rut: item["rut"] };
      });
      setAllMedics(mapped);
    });
  };

  /* Basic form check */
  const checkFormComplete = () => {
    if (
      !newMedicForm.name ||
      !newMedicForm.rut ||
      !newMedicForm.lastName ||
      !isRutValid.rut
    )
      return false;
    return true;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder añadir el médico.",
      );
      return;
    }
    setIsLoading(true);

    // Give adecuate format to send form to db
    let completedForm = {
      rut: newMedicForm.rut + newMedicForm.rutVerifier,
      name: newMedicForm.name,
      last_name: newMedicForm.lastName,
      programs: program,
    };

    // Sends to db
    dbPost("medics", completedForm)
      .then((data) => {
        snackbarOpen("success", "Médico añadido exitosamente");
        redirectToMedicsPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al añadir médico", // translate pending
        );
        setIsLoading(false);
        return;
      });
  };

  /* Cancel editing medic and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankNewMedicForm);
    history.goBack();
  };

  /* Redirect to medics table page after adding correctly */
  const redirectToMedicsPage = (user) => {
    setTimeout(() => {
      updateForm(blankNewMedicForm);
      setIsLoading(false);
      history.push(routes.abmMedics);
    }, 2000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getMedics();
  }, []);

  const title = `Nuevo médico${newMedicForm.name ? ": " : ""}${
    newMedicForm.name ? newMedicForm.name : ""
  } ${newMedicForm.lastName ? newMedicForm.lastName : ""}`;

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Paper elevation={3} style={{ borderRadius: "10px" }}>
        <Grid
          style={{ display: "flex", margin: 50 }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={4}
        >
          <form autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  className={classes.text}
                  style={{ marginTop: "20px" }}
                >
                  Datos del médico
                </Typography>
                <div
                  className={classes.text}
                  style={{
                    marginTop: "1%",
                    display: "flex",
                    justifyContent: "space-between",
                    width: 700,
                  }}
                >
                  <Typography weight="medium">
                    A continuación ingrese los datos del médico a añadir.
                  </Typography>
                </div>
                <div style={{ marginTop: "4%" }}>
                  <Field
                    labelName="Nombres *"
                    required={true}
                    id="name"
                    value={newMedicForm.name}
                    divClassType={classes.generalPurposeItems}
                    fieldClassType={classes.generalPurposeField}
                    onChange={handleChange}
                  ></Field>
                  <Field
                    labelName="Apellidos *"
                    required={true}
                    id="lastName"
                    value={newMedicForm.lastName}
                    divClassType={classes.generalPurposeItems}
                    fieldClassType={classes.generalPurposeField}
                    onChange={handleChange}
                  ></Field>
                  <RutField
                    labelName="RUT * (sin guión)"
                    required={true}
                    id="rut"
                    id2="rutVerifier"
                    value={newMedicForm.rut}
                    divClassType={classes.smallItems2}
                    divClassType2={classes.generalPurposeItems2}
                    fieldClassType={classes.smallFieldValidation2}
                    fieldClassType2={classes.smallFieldValidation3}
                    onChange={handleRutChange}
                    helperText={isRutValid.message}
                    error={!isRutValid.rut}
                    value2={newMedicForm.rutVerifier}
                    onChange2={handleRutChange}
                  ></RutField>
                  <ProgramsField
                    divClassName={classes.generalPurposeItems}
                    fieldClassName={classes.generalPurposeField}
                    displayText="Programa"
                    value={program}
                    onChange={(e) => setProgram(e.target.value)}
                    GES={false}
                    Salco={false}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={12}>
                <div className={classes.buttonSpacing}>
                  {isLoading ? (
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Guardar
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={cancelAndGoBack}
                  >
                    Cancelar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </>
  );
}
