import React, { useState, useEffect } from "react";

// material

import { CircularProgress, Divider, Grid, Paper, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// custom components
import { Typography as CustomTypography } from "components/Wrappers/Wrappers";
import PopupMessage from "components/PopupMessage/PopupMessage";
import { Button, Typography } from "components/Wrappers/Wrappers";
import useStyles from "./styles";
import SelectField from "components/CustomFields/SelectField";
import { dbGet } from "utils/DBFetchers";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import { getRoleName } from "utils/roleNameGetter";

const BLANK_CONSULTANTS = [{ id: "Todos", name: "Todos" }];

const blankFilters = {
  beginYear: 0,
  beginCycle: 0,
  endYear: 0,
  endCycle: 0,
  manager: null,
  consultant: "",
  zone: "",
  program: "",
  dashboard: 0,
  beginYearPopup: 0,
  beginCyclePopup: 0,
};

export default function TraceabilitySelectionGrid(props) {
  const {
    dashboardUpdateFunction,
    tableUpdateFunction,
    virtualTableUpdateFunction,
    totalTableUpdateFunction,
    setIsLoading,
    setIsInitialState,
    exportTraceabilityData,
    isExportEnabled,
    isLoading,
  } = props;
  const classes = useStyles();
  const [consultants, setConsultants] = useState(BLANK_CONSULTANTS);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [areConsultantsLoading, setAreConsultantsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [managers, setManagers] = useState([]);
  const [allCycles, setAllCycles] = useState([]);
  const [allYears, setAllYears] = useState([]);
  const [filters, setFilters] = useState(blankFilters);
  const [showPopup, setShowPopup] = useState(false);

  const validDateFilters = () => {
    let validDate =
      filters.beginCycle &&
      filters.endCycle &&
      filters.beginYear &&
      filters.endYear &&
      filters.beginYear <= filters.endYear;
    if (
      filters.beginYear === filters.endYear &&
      filters.beginCycle > filters.endCycle
    ) {
      validDate = false;
    }
    return validDate;
  };

  const dashboards = [
    { id: 1, name: "General" },
    { id: 2, name: "Cupones digitales" },
    { id: 3, name: "Cupones virtuales" },
  ];

  const cleanManagers = (managers) => {
    let clean_managers = managers.map((manager) => {
      return {
        id: manager.rut,
        name: manager.name,
      };
    });
    clean_managers.push({ id: "Todos", name: "Todos" });
    return clean_managers;
  };

  const handleDownloadReports = () => {
    const url = "traceability/get_report_url?cycle=" + filters.beginCyclePopup;
    dbGet(url)
      .then((response) => {
        const download_link = response;
        if (download_link) {
          window.location.href = download_link;
          snackbarOpen("success", "Reportes descargados correctamente");
        } else {
          snackbarOpen(
            "warning",
            "Los reportes de este período aún no han sido generados",
          );
        }
      })
      .catch(() => {
        snackbarOpen("error", "Ocurrió un error en la generación de reportes");
      })
      .finally(() => {
        handlePopupClose();
      });
  };

  const executeButton = (
    <Button
      onClick={handleDownloadReports}
      variant="contained"
      color="secondary"
      className={classes.popupButtonSpacing}
      disabled={filters.beginCyclePopup === 0}
    >
      Descargar
    </Button>
  );

  const getManagers = () => {
    dbGet(`traceability/district-managers`)
      .then((response) => {
        let managers = cleanManagers(response);
        setManagers(managers);
        setFilters({ ...filters, manager: "Todos" });
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los managers");
      });
  };

  const getYearsCycles = () => {
    let years_mapped = [];
    let cycles_mapped = [];
    dbGet("traceability/cycles")
      .then((data) => {
        years_mapped = data.years.map((year) => {
          return { id: parseInt(year), name: year };
        });
        cycles_mapped = data.cycles.map((cycle) => {
          return { id: parseInt(cycle), name: cycle.toString().slice(-2) };
        });
        setFilters({
          ...filters,
          beginYear: parseInt(data.cycles[0].toString().slice(0, 4)),
          endYear: parseInt(data.cycles[0].toString().slice(0, 4)),
          beginCycle: data.cycles[0],
          endCycle: data.cycles[0],
        });
        setAllYears(years_mapped);
        setAllCycles(cycles_mapped);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getManagers();
    getYearsCycles();
  }, []);

  useEffect(() => {
    if (filters.manager && filters.beginCycle && filters.endCycle)
      getManagerConsultants();
  }, [filters.manager, filters.beginCycle, filters.endCycle]);

  const validFilters = () => {
    let valid = true;
    if (!validDateFilters()) {
      valid = false;
      snackbarOpen("error", "Ciclo incorrecto");
    }
    return valid;
  };

  const updateProgress = (id) => {
    dbGet(`request_checker/status?id=${id}`)
      .then((response) => {
        if (response.status === "Completed") {
          dashboardUpdateFunction(response.data);
          tableUpdateFunction(response.data["physical"].medic_coupon_summary);
          virtualTableUpdateFunction(response.data["virtual"].medic_summary);
          totalTableUpdateFunction(response.data["total"].medic_coupon_summary);
          setIsLoading(false);
        } else if (!response.error_status && response.status !== "Failure") {
          setTimeout(updateProgress.bind(null, id, response), 2000);
        } else {
          snackbarOpen("error", "Error al obtener los datos de la consulta");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los datos de la consulta");
        setIsLoading(false);
      });
  };

  const getInformation = (url) => {
    setIsLoading(true);
    dbGet(url)
      .then((response) => {
        setIsInitialState(false); // lets main page know that there is data to show
        setTimeout(updateProgress.bind(null, response.id), 3000);
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los datos");
        setIsLoading(false);
      });
  };

  const checkFiltersApplication = () => {
    if (filters.manager === "Todos" && filters.consultant === "Todos") {
      setOpenDialog(true);
    } else {
      applyFilters();
    }
  };

  const applyFilters = () => {
    if (!validFilters()) return;
    let managerRut =
      filters.manager && filters.manager !== "Todos"
        ? `&manager_rut=${filters.manager}`
        : "";
    let consultantRut =
      filters.consultant && filters.consultant !== "Todos"
        ? `&consultant_rut=${filters.consultant}`
        : "";
    let programId = `&program_id=1`; // hardcoded (might change later)
    // let zoneId = `&zone_id=${filters.zone}`; might change later
    let fromCycle = `&from_cycle=${filters.beginCycle}`;
    let toCycle = `&to_cycle=${filters.endCycle}`; // -1 because it was previously added 1
    //let dashboardId = `&dashboard_id=${filters.dashboard}`;
    getInformation(
      `traceability/dashboard?${managerRut}${consultantRut}${programId}${fromCycle}${toCycle}`,
    );
  };

  const handleConsultantChange = (event) => {
    setFilters({
      ...filters,
      consultant: event.target.value,
    });
  };

  const getManagerConsultants = () => {
    if (!filters.manager) return;

    if (filters.manager === "Todos") {
      setConsultants([{ id: "Todos", name: "Todos" }]);
      setFilters({ ...filters, consultant: "Todos" });
      return;
    }

    let managerRut = filters.manager;
    managerRut = `&manager_rut=${managerRut}`;
    let fromCycle = `&from_cycle=${filters.beginCycle}`;
    let toCycle = `&to_cycle=${filters.endCycle}`; // -1 because it was previously added 1
    setAreConsultantsLoading(true);
    dbGet(`traceability/consultants?${managerRut}${fromCycle}${toCycle}`)
      .then((response) => {
        let consultants = response.map((consultant) => {
          return {
            id: consultant.rut,
            name: consultant.name,
          };
        });
        consultants.push({ id: "Todos", name: "Todos" });
        setConsultants(consultants);
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los consultores");
      })
      .finally(() => {
        setAreConsultantsLoading(false);
      });
  };

  const handleSelectorChange = (event, beginFilter) => {
    setFilters({
      ...filters,
      [beginFilter]: event.target.value,
      consultant: "Todos",
    });
  };

  const handleDashboardChange = (e) => {
    setFilters({
      ...filters,
      dashboard: e.target.value,
    });
  };

  const handleManagerChange = (e) => {
    setFilters({
      ...filters,
      manager: e.target.value,
      consultant: "Todos",
    });
  };

  const isRequestEnabled = () => {
    return (
      filters.manager &&
      filters.consultant &&
      filters.beginCycle &&
      filters.endCycle &&
      !isLoading
    );
  };

  const handleOk = () => {
    setOpenDialog(false);
    applyFilters();
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <PopupMessage
        title={"Descargar reportes"}
        message={
          "Por favor, elija los datos que quiere descargar a continuación."
        }
        open={showPopup}
        handleClose={handlePopupClose}
        buttonClassName={classes.popupButtonSpacing}
        buttonDivClassName={classes.popupButtonDiv}
        executeButton={executeButton}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <CustomTypography
            component={"span"}
            className={classes.text}
            weight="bold"
          >
            Ciclo
          </CustomTypography>
          <SelectField
            divClassName={classes.generalPurposeItems}
            fieldClassName={classes.yearSelectionField}
            id="beginYearPopup"
            label="Seleccione"
            name="beginYearPopup"
            value={filters.beginYearPopup}
            onChange={(event) => handleSelectorChange(event, "beginYearPopup")}
            allValues={allYears}
          />
          <SelectField
            divClassName={classes.generalPurposeItems}
            fieldClassName={classes.yearSelectionField}
            id="beginCyclePopup"
            label="Seleccione"
            name="beginCyclePopup"
            value={filters.beginCyclePopup}
            onChange={(event) => handleSelectorChange(event, "beginCyclePopup")}
            allValues={allCycles.filter((cycleInfo) =>
              cycleInfo.id.toString().includes(filters.beginYearPopup),
            )}
          />
        </div>
      </PopupMessage>
      <Dialog
        open={openDialog}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Consulta de alta demora"}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Typography className={classes.alignedText}>
              La consulta con estos filtros puede demorar varios minutos. ¿Desea
              realizarla?
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleOk}>
            Sí
          </Button>
          <Button color="secondary" onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={5}>
        {/* Selection: whole screen */}
        <Grid item xs={12} style={{ marginLeft: "10px" }}>
          <Grid item lg={12} xs={12} md={12}>
            <Typography variant="h4" className={classes.alignedText}>
              Selección
            </Typography>
            <Divider />
          </Grid>
          {/* Selections */}
          <Paper
            elevation={3}
            style={{
              borderRadius: "10px",
              justifyContent: "center",
              display: "flex",
              padding: 25,
            }}
          >
            <Stack spacing={1} alignItems="flex-end">
              <Stack direction="row" spacing={5}>
                <SelectField
                  displayText="Ciclo inicial"
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.yearSelectionField}
                  id="beginYear"
                  label="Seleccione"
                  name="beginYear"
                  value={filters.beginYear}
                  onChange={(event) => handleSelectorChange(event, "beginYear")}
                  allValues={allYears}
                />
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.cyclesSelectionField}
                  id="beginCycle"
                  label="Seleccione"
                  name="beginCycle"
                  value={filters.beginCycle}
                  onChange={(event) =>
                    handleSelectorChange(event, "beginCycle")
                  }
                  allValues={allCycles.filter((cycleInfo) =>
                    cycleInfo.id.toString().includes(filters.beginYear),
                  )}
                />
              </Stack>
              <Stack direction="row" spacing={5}>
                {" "}
                <SelectField
                  displayText="Ciclo final"
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.yearSelectionField}
                  id="endYear"
                  label="Seleccione"
                  name="endYear"
                  value={filters.endYear}
                  onChange={(event) => handleSelectorChange(event, "endYear")}
                  allValues={allYears}
                />
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.cyclesSelectionField}
                  id="endCycle"
                  label="Seleccione"
                  name="endCycle"
                  value={filters.endCycle}
                  onChange={(event) => handleSelectorChange(event, "endCycle")}
                  allValues={allCycles.filter((cycleInfo) =>
                    cycleInfo.id.toString().includes(filters.endYear),
                  )}
                />
              </Stack>
              <Stack direction="row" spacing={5}>
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  displayText="Gerente distrito"
                  fieldClassName={classes.generalSelectionField}
                  id="managers"
                  label="Seleccione"
                  name="manager"
                  value={filters.manager ? filters.manager : ""}
                  onChange={handleManagerChange}
                  allValues={managers}
                />
              </Stack>
              {areConsultantsLoading ? (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item>
                    <Typography>Calculando consultores...</Typography>
                  </Grid>
                  <Grid item>
                    <CircularProgress size={26} />
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              <Stack direction="row" spacing={5}>
                {" "}
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  displayText="Consultor"
                  fieldClassName={classes.generalSelectionField}
                  id="consultants"
                  label="Seleccione"
                  name="consultant"
                  value={filters.consultant ? filters.consultant : ""}
                  onChange={(e) => {
                    handleConsultantChange(e);
                  }}
                  disabled={!filters.manager}
                  allValues={consultants}
                />
              </Stack>
              <Stack direction="row" spacing={1.5}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowPopup(true)}
                >
                  Descargar resumen
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={checkFiltersApplication}
                  disabled={!isRequestEnabled()}
                >
                  Aplicar
                </Button>
                <Button
                  disabled={!isExportEnabled()}
                  color="secondary"
                  style={{ marginLeft: "10px" }}
                  variant="contained"
                  onClick={exportTraceabilityData}
                >
                  Exportar
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

{
  /* <Grid
      item
      lg={12}
      xs={12}
      md={12}
      className={classes.gridItemSelector}
      style={{ padding: "1%" }}
  >
      <SelectField
      divClassName={classes.generalPurposeItems}
      displayText="Programa"
      fieldClassName={classes.generalSelectionField}
      id="programs"
      label="Seleccione"
      name="program"
      value={selectedProgram ? filters[selectedProgram] : ""}
      onChange={(e) => {
          handleChange(e, setSelectedProgram, setFilters, filters);
      }}
      allValues={programs}
      />
  </Grid> */
}

{
  /* <Grid
                item
                lg={12}
                xs={12}
                md={12}
                className={classes.gridItemSelector}
                style={{ padding: "1%" }}
              >
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  displayText="Zona"
                  fieldClassName={classes.generalSelectionField}
                  id="zones"
                  label="Seleccione"
                  name="zone"
                  value={selectedZone ? filters[selectedZone] : ""}
                  onChange={(e) => {
                    handleChange(e, setSelectedZone, setFilters, filters);
                  }}
                  allValues={zones}
                />
          </Grid> */
}
