import React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

import PageTitle from "components/PageTitle/PageTitle";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

import useStyles from "./styles";
import { format } from "date-fns";
import logo from "images/logoPagesRS.jpg";

import { cleanEmptyKeys } from "utils/cleanEmptyKeys";
import { dbGet, dbPut } from "utils/DBFetchers";
import { getRoleName } from "utils/roleNameGetter";
import { roleTranslation } from "pages/users/roles/utils";

const blankProfileData = {
  id: null,
  username: "",
  email: "",
  name: "",
  lastName: "",
  role: null,
  distributorId: null,
  distributor: null,
  lastActivityAt: null,
  pharmaGroupId: null,
  pharmaGroup: null,
  pharmacyId: null,
  pharmacy: null,
  registeredOn: null,
  updatedAt: null,
  password: "",
  isEnabled: null,
};

// TODO: Show pharmacy/distrib/group NAME with id provided

export default function AccountProfileDetails(props) {
  var classes = useStyles();
  const [profileData, setProfileData] = useState(blankProfileData);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const handleChange = (event) => {
    setProfileData({
      ...profileData,
      [event.target.name]: event.target.value,
    });
  };

  /* Fills form data */
  const fillData = (data) => {
    if (!data) return;
    setProfileData({
      ...profileData,
      id: data.id,
      username: data.username ? data.username : "",
      email: data.email ? data.email : "",
      name: data.name ? data.name : "",
      lastName: data.lastname ? data.lastname : "",
      role: data.role,
      distributorId: data.distributor_id,
      distributor: data.distributor,
      lastActivityAt: data.last_activity_at,
      pharmaGroupId: data.pharma_group_id,
      pharmaGroup: data.pharma_group,
      pharmacyId: data.pharmacy_id,
      pharmacy: data.pharmacy,
      registeredOn: data.registered_on,
      updatedAt: data.updated_at,
      isEnabled: data.is_enabled,
    });
  };

  /* Basic form check */
  const checkFormComplete = () => {
    if (
      !profileData.username ||
      !profileData.email ||
      !profileData.email.includes("@") ||
      (profileData.password &&
        (!(profileData.password.length >= 6) ||
          !(profileData.password.length <= 20)))
    )
      return false;
    return true;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder editar el usuario.",
      );
      return;
    }
    if (
      (profileData.name && !profileData.lastName) ||
      (!profileData.name && profileData.lastName)
    ) {
      snackbarOpen(
        "error",
        "Debe ingresar ambos Nombre y Apellido. Por favor completelos para poder editar el usuario.",
      );
      return;
    }

    // Give adecuate format to send form to db
    let completedForm = {
      username: profileData.username,
      email: profileData.email,
      name: profileData.name,
      lastname: profileData.lastName,
      password: profileData.password,
    };

    // Cleans empty form fields
    completedForm = cleanEmptyKeys(completedForm);

    // Sends to db
    dbPut(`users/${profileData.id}`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Usuario editado exitosamente");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch(() => {
        snackbarOpen(
          "error",
          "No se pudo editar el usuario. Intente nuevamente.",
        );
        return;
      });
  };

  /* On page load, get all user data */
  const getProfile = () => {
    dbGet("profile").then((data) => {
      fillData(data);
    });
  };
  React.useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <div>
        <PageTitle
          title={`Usuario: ${profileData.username}`}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <form autoComplete="off" noValidate {...props}>
        <Card {...props}>
          <CardContent>
            <Box>
              <Typography color="textPrimary" gutterBottom variant="h2">
                {`${
                  profileData.name || profileData.lastName
                    ? ""
                    : profileData.username
                }${profileData.name ? profileData.name : ""} ${
                  profileData.lastName ? profileData.lastName : ""
                }`}
              </Typography>
              <Typography color="textSecondary" variant="h6">
                {`Email: ${profileData.email}`}
              </Typography>
              <Typography color="textSecondary" variant="h6">
                {`Rol: ${
                  profileData.role
                    ? profileData.role.name in roleTranslation
                      ? roleTranslation[profileData.role.name]
                      : profileData.role.name
                    : ""
                }`}
              </Typography>
              {profileData.pharmacy &&
              Object.keys(profileData.pharmacy).length !== 0 ? (
                <Typography color="textSecondary" variant="h6">
                  {`Farmacia: ${profileData.pharmacy.name} - ${profileData.pharmacy.code}`}
                </Typography>
              ) : null}
              {profileData.distributor &&
              Object.keys(profileData.distributor).length !== 0 ? (
                <Typography color="textSecondary" variant="h6">
                  {`Distribuidor: ${profileData.distributor.name}`}
                </Typography>
              ) : null}
              {profileData.pharmaGroup &&
              Object.keys(profileData.pharmaGroup).length !== 0 ? (
                <Typography color="textSecondary" variant="h6">
                  {`Grupo: ${profileData.pharmaGroup.name}`}
                </Typography>
              ) : null}
              <Typography
                style={{ color: profileData.isEnabled ? "green" : "red" }}
                variant="h6"
              >
                {`Estado: ${profileData.isEnabled ? "Activo" : "Inactivo"}`}
              </Typography>
              <Typography color="textSecondary" variant="h6">
                {`Registrado el ${format(
                  new Date(profileData.registeredOn),
                  "dd-MM-yyyy",
                )}`}
              </Typography>
            </Box>
          </CardContent>
        </Card>

        <Card style={{ marginTop: "5%" }}>
          <CardHeader
            subheader="Esta información puede ser editada únicamente por un administrador."
            title="Datos del usuario"
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Usuario"
                  name="username"
                  onChange={handleChange}
                  required
                  value={profileData.username}
                  disabled={
                    getRoleName() !== "admin" && getRoleName() !== "super_admin"
                  }
                  variant="outlined"
                  className={classes.generalPurposeField}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Email"
                  name="email"
                  onChange={handleChange}
                  required
                  disabled={
                    getRoleName() !== "admin" && getRoleName() !== "super_admin"
                  }
                  value={profileData.email}
                  variant="outlined"
                  className={classes.generalPurposeField}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Nombre"
                  name="name"
                  onChange={handleChange}
                  required
                  disabled={
                    getRoleName() !== "admin" && getRoleName() !== "super_admin"
                  }
                  value={profileData.name}
                  variant="outlined"
                  className={classes.generalPurposeField}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Apellido"
                  name="lastName"
                  onChange={handleChange}
                  required
                  disabled={
                    getRoleName() !== "admin" && getRoleName() !== "super_admin"
                  }
                  value={profileData.lastName}
                  variant="outlined"
                  className={classes.generalPurposeField}
                />
              </Grid>
            </Grid>
          </CardContent>

          <Divider />
          <CardHeader title="Contraseña" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                {passwordOpen ? (
                  <TextField
                    fullWidth
                    helperText="De 6 a 20 caracteres - Si no ingresa contraseña, esta no se modificará."
                    name="password"
                    onChange={handleChange}
                    required
                    value={profileData.password}
                    variant="outlined"
                    type="password"
                  />
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setPasswordOpen(!passwordOpen)}
                  >
                    Modificar contraseña
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Guardar
                </Button>
              </div>
            </Grid>
          </Grid>
        </Card>
      </form>
    </>
  );
}
