import { makeStyles } from "@material-ui/styles";

const selectorMargin = "30px";
const selectorWidth = 250;
const largeSelectorWidth = 350;
const selectorMinWidth = 200;
const selectorMaxWidth = 350;
const largeSelectorMaxWidth = 500;
const errorSelectorWidth = 350;

export default makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(2),
  },
  alignedText: {
    display: "flex",
    justifyContent: "flex-start",
    alignSelf: "center",
  },
  buttonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    // marginLeft: "15em",
    marginTop: "2%",
    marginBottom: "3%",
  },
  buttonSmallSpacing: {

    display: "flex",
    marginTop: "1%",
    marginBottom: "1%",
  },
  chip: {
    margin: 2,
    display: "flex",
    flexWrap: "wrap",
  },
  rowValueItems: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginLeft: "2.4em",
  },
  fieldValueContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  fieldListValueContainer: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 500,
  },
  dateSelectionField: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-1%",
    marginLeft: selectorMargin,
    width: selectorWidth,
    maxWidth: selectorMaxWidth,
    minWidth: selectorMinWidth,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  cyclesSelectionField: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-1%",
    width: 103,
    marginLeft: "1.5em",
    maxWidth: 110,
    minWidth: 90,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  yearSelectionField: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-1%",
    marginLeft: selectorMargin,
    width: 125,
    maxWidth: 135,
    minWidth: 115,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  alignedTextWithPadding: {
    paddingTop: "12.5px",
    display: "flex",
    justifyContent: "flex-start",
    alignSelf: "center",
  },
  generalSelectionField: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-1%",
    marginLeft: selectorMargin,
    width: selectorWidth,
    maxWidth: selectorMaxWidth,
    minWidth: selectorMinWidth,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  simpleSecondColumnSelectionField: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: selectorMargin,
    width: 700,
    maxWidth: selectorMaxWidth,
    minWidth: selectorMinWidth,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  simpleSelectionField: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: selectorMargin,
    width: selectorWidth,
    maxWidth: selectorMaxWidth,
    minWidth: selectorMinWidth,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  largeSelectionField: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "12.5px",
    marginLeft: selectorMargin,
    width: largeSelectorWidth,
    maxWidth: largeSelectorMaxWidth,
    minWidth: selectorMinWidth,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  errorSelectionField: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-1%",
    marginLeft: selectorMargin,
    width: errorSelectorWidth,
    maxWidth: errorSelectorWidth,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  alignLeft: {
    display: "flex",
    justifyContent: "flex-start",
  },
  textAlignRight: {
    textAlign: "right",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  realCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  uploadButton: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: "1%",
  },
  uploadFormButton: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: -50,
  },
  nameSelector: {
    display: "flex",
    justifyContent: "center",
    minWidth: "26%",
  },
  submitLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sendField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "6%",
    width: 130,
    height: 40,
  },
}));
