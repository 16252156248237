import {
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { getRoleName } from "utils/roleNameGetter";

// Create the columns for the main table
export default function CreateBurnableColumns(isAudit, onDelete) {
  const role = getRoleName();
  const hasPermissions =
    role === "admin" || role === "super_admin" || role === "full";

  const hidePrices = role === "pharmacies" || role === "pharmagroups";

  // Color variables
  let primaryOpacity = "0.7";
  let secondaryOpacity = "0.5";
  let tempColor = "255, 255, 255"; // white

  // Column positions
  const PRODUCT_POS = 11;
  const SALE_POS = 1;
  const PHARMACY_POS = 4;
  const PATIENT_RUT_POS = 8;

  return [
    {
      name: " ",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return { style: { fontWeight: "bold" } };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {!isAudit && hasPermissions && (
                <Tooltip title="Eliminar">
                  <IconButton
                    aria-label="upload picture"
                    component="span"
                    size="small"
                    onClick={() => {
                      onDelete(
                        tableMeta.rowData[PRODUCT_POS],
                        tableMeta.rowData[SALE_POS],
                        tableMeta.rowData[PHARMACY_POS],
                        tableMeta.rowData[PATIENT_RUT_POS],
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        },
      },
    },
    {
      name: "Nro. Venta",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Fecha",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        sortCompare: (order) => {
          return (a, b) => {
            var dateSplitA = a.data.split("/");
            var dateSplitB = b.data.split("/");
            const dateA = new Date(
              dateSplitA[2],
              dateSplitA[1],
              dateSplitA[0],
            ).getTime();
            const dateB = new Date(
              dateSplitB[2],
              dateSplitB[1],
              dateSplitB[0],
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === "desc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "Distribuidor",
      options: {
        filter: false,
        display: hidePrices ? "excluded" : true,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: isAudit ? "Sucursal" : "Cód. farma",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Nombre farm",
      options: {
        display: isAudit ? true : "exclude",
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "RUT farm",
      options: {
        display: isAudit ? true : "exclude",
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Vía",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "RUT Paciente",
      options: {
        filter: false,
        display: role === "admin" || role === "full" ? "excluded" : true,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "ID Paciente",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Tipo de cupón",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Producto informado",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Producto suscrito",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Unidades informadas",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap" },
          align: "center",
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell
                    colspan={4}
                    style={{ padding: hidePrices ? "4px" : "" }}
                  >
                    {hidePrices ? <strong>Informado</strong> : <></>}
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>Unidades</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "Costo informado",
      options: {
        display: hidePrices ? "excluded" : true,
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap" },
          align: "center",
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>Informado</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>Costo</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "Descuento (%) informado",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap" },
          align: "center",
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>Descuento (%)</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "Descuento ($) informado",
      options: {
        filter: false,
        display: hidePrices ? "excluded" : true,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap" },
          align: "center",
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>Descuento ($)</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "Unidades calculadas",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap" },
          align: "center",
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell
                    colspan={4}
                    style={{ padding: hidePrices ? "4px" : "" }}
                  >
                    {hidePrices ? <strong>Calculado</strong> : <></>}
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>Unidades</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "Costo calculado",
      options: {
        display: hidePrices ? "excluded" : true,
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap" },
          align: "center",
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>Calculado</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>Costo</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "Descuento (%) calculado",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap" },
          align: "center",
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>Descuento (%)</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "Descuento ($) calculado",
      options: {
        filter: false,
        display: hidePrices ? "excluded" : true,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap" },
          align: "center",
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>Descuento ($)</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "Diferencia",
      options: {
        filter: false,
        display: isAudit ? true : "exclude",
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Errores",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Advertencias",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Color de celda",
      options: {
        display: "excluded",
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
  ];
}
