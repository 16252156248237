import React, { useEffect, useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Paper } from "@material-ui/core";
import SendPatientCouponForm from "pages/coupon_managment/Forms/SendPatientCouponForm";

import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { dbGet, dbPost } from "utils/DBFetchers.js";
import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";

// rut verification
import { verifyRut } from "utils/rutValidations.js";

// styles
import useStyles from "./styles";
import MedicData from "./Forms/MedicData";
import PopupMessage from "components/PopupMessage/PopupMessage";

const maxAvailableSelectedProducts = 10; // Same as db (hardcoded)

export default function AccreditCouponPage() {
  var classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const blankMedicForm = {
    name: "",
    firstName: "",
    lastName: "",
    rut: "",
    couponsRS: 0,
    couponsSM: 0,
    couponsLeft: false,
  };

  const blankPatientForm = {
    email: "",
    phone: "",
    rut: "",
    rutDV: "",
    productsSelected: [],
  };

  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  const [presentations, setPresentations] = useState([]);
  const [presentationNames, setPresentationNames] = useState([]);
  const [medicForm, updateMedicForm] = useState(blankMedicForm);
  const [patientForm, updatePatientForm] = useState(blankPatientForm);
  const [isRutValid, setIsRutValid] = useState({ rut: false, message: "" });
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [invalidEmail, setInvalidEmail] = useState(true);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [enabledEmail, setEnableEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleEnableEmail = () => {
    setEnableEmail(!enabledEmail);
    updatePatientForm({ ...patientForm, email: "", phone: "" });
  };

  const updateFormOnSelection = (newSelect) => {
    updatePatientForm({ ...patientForm, productsSelected: newSelect });
  };

  const handleEmailChange = (event) => {
    verifyEmail(event.target.value);
    updatePatientForm({
      ...patientForm,
      [event.target.name]: event.target.value,
    });
  };

  const verifyEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setInvalidEmail(!re.test(email));
  };

  const handlePhoneChange = (value) => {
    setInvalidPhone(value.length === 0);
    updatePatientForm({
      ...patientForm,
      phone: value,
    });
  };

  const handlePresentationChange = (event) => {
    setPresentationNames(event.target.value);
  };

  // RUT
  const onRutChange = (e) => {
    updatePatientForm({
      ...patientForm,
      [e.target.id]: e.target.value.toUpperCase(),
    });
  };
  const rutValidation = () => {
    let currentRut = patientForm.rut + patientForm.rutDV;
    let currentRutValidation = verifyRut(currentRut);
    if (currentRutValidation && patientForm.rutDV) {
      getPatientByRut(currentRut);
      setIsRutValid({
        ...isRutValid,
        rut: currentRutValidation,
        message: "",
      });
    } else if (currentRutValidation && !patientForm.rutDV) {
      setIsRutValid({
        ...isRutValid,
        rut: false,
        message: "Ingrese el dígito verificador en el campo respectivo.",
      });
    } else {
      setIsRutValid({
        ...isRutValid,
        rut: currentRutValidation,
        message: "RUT inválido",
      });
    }
  };
  React.useEffect(() => {
    if (!patientForm.rut && !patientForm.rutDV) return; // no fetch on page load
    rutValidation();
  }, [patientForm.rut, patientForm.rutDV]);

  // Check for errors on submit
  const validateSubmit = () => {
    if (!isRutValid.rut) {
      snackbarOpen("error", "Rut inválido");
      return false;
    }
    if (enabledEmail) {
      if (invalidEmail) {
        snackbarOpen("error", "Debe ingresar un email");
        return false;
      }
    } else {
      if (invalidPhone) {
        snackbarOpen("error", "Debe ingresar un teléfono");
        return false;
      }
    }
    if (patientForm.productsSelected.length > maxAvailableSelectedProducts) {
      snackbarOpen(
        "error",
        `Solo puede seleccionar hasta ${maxAvailableSelectedProducts} productos`,
      );
      return false;
    }
    return true;
  };

  // submit
  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);
    if (!validateSubmit()) {
      setIsLoading(false);
      return;
    }

    let products = [];
    let presentation_ids = [];
    products.push(
      patientForm.productsSelected.map((id) => {
        return presentations.find((presentation) => presentation.id === id)
          .name;
      }),
    );

    presentation_ids.push(
      patientForm.productsSelected.map((id) => {
        return presentations.find((presentation) => presentation.id === id)
          .id;
      }),
    );

    let completedForm = {
      first_name: patientForm.firstName ? patientForm.firstName : "",
      last_name: patientForm.lastName ? patientForm.lastName : "",
      rut: patientForm.rut + patientForm.rutDV,
      id: patientForm.id,
      email: patientForm.email,
      program_id: programId,
      products: products[0],
      presentation_ids: presentation_ids[0],
      send_email: enabledEmail ? 1 : 0,
      send_sms: enabledEmail ? 0 : 1,
    };

    // // remove any special character except the '+' at the beginning
    // completedForm.phone = completedForm.phone.replace(/(?!^\+)[\D]/g, "");

    dbPost(`api/barcodes/patient`, completedForm)
      .then((data) => {
        snackbarOpen(
          "success",
          "Cupón generado correctamente, su paciente lo recibira en breve",
        );
        updatePatientForm(blankPatientForm);
        getMedic();
        getPresentations();
        setIsLoading(false);
        return;
      })
      .catch((error) => {
        if (error[0] && error[0].code === "406") {
          setShowPopup(true);
        }
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al enviar cupones",
        );
        setIsLoading(false);
        return;
      });
  };

  // get patients
  const getPatientByRut = (rut) => {
    dbGet(`patients/${rut}`).then((data) => {
      updatePatientForm({
        ...patientForm,
        email: data.email ? data.email : patientForm.email,
      });
      if (data.email) verifyEmail(data.email);
    });
  };

  // update program ID
  useEffect(() => {
    setProgramId(localStorage.getItem("programId"));
    updateMedicForm({
      ...medicForm,
      couponsLeft:
        (programId === "1" && medicForm.couponsRS !== 0) ||
        (programId === "2" && medicForm.couponsSM !== 0),
    });
  }, [programId]);

  // get logged in medic
  const getMedic = () => {
    dbGet(`medics/send_coupon`)
      .then((data) => {
        updateMedicForm({
          ...medicForm,
          name: data.name,
          lastName: data.last_name,
          couponsRS: data.available_coupons_rs ? data.available_coupons_rs : 0,
          couponsSM: data.available_coupons_sm ? data.available_coupons_sm : 0,
          couponsLeft:
            (programId === "1" && data.available_coupons_rs !== 0) ||
            (programId === "2" && data.available_coupons_sm !== 0),
        });
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al obtener cupones",
        );
      });
  };

  function filterDuplicatesById(list) {
    const map = {};
    const result = [];
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.product_id === null || !map[item.product_id] || map[item.product_id].id < item.id) {
        map[item.product_id] = item;
      }
    }
    for (let key in map) {
      result.push(map[key]);
    }
    return result;
  }

  // get presentations
  const getPresentations = () => {
    setPresentations([]); // reset presentations
    dbGet(`presentations/program/${localStorage.getItem("programId")}`)
      .then((data) => {
        // sort by name
        data = data.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
        const withProductIdNull = data.filter(item => item.product_id === null)
        setPresentations(withProductIdNull.concat(filterDuplicatesById(data)));
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0]
            ? error[0].description_es
            : "Error al obtener las presentaciones",
        );
      });
  };

  // get medics & presentations on page load
  useEffect(() => {
    getMedic();
    getPresentations();
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);

  return (
    <>
      <PopupMessage
        title={"No se puede generar el cupón"}
        message={
          "Por favor, comuníquese con el encargado del sistema e infórmele que no hay cupones disponibles para generarse."
        }
        open={showPopup}
        handleClose={() => setShowPopup(false)}
        buttonClassName={classes.popupButtonSpacing}
        buttonDivClassName={classes.popupButtonDiv}
      />
      <div>
        <PageTitle
          title="Enviar cupón al paciente"
        />
        <Paper elevation={3} style={{ borderRadius: "10px" }}>
          <MedicData
            title={"Datos del profesional"}
            name={medicForm.name || "-"}
            couponsLeft={
              programId === "1" ? medicForm.couponsRS : medicForm.couponsSM
            }
          />
          <SendPatientCouponForm
            isMobile={isMobile}
            programId={programId}
            title={"Datos del paciente"}
            patient={patientForm}
            onRutChange={onRutChange}
            onDVChange={onRutChange}
            isRutValid={isRutValid}
            onEmailChange={handleEmailChange}
            onPhoneChange={handlePhoneChange}
            enabledEmail={enabledEmail}
            handleEnableEmail={handleEnableEmail}
            handleSelectionChange={handlePresentationChange}
            updateFormOnSelection={updateFormOnSelection}
            allOptions={presentations}
            selectionValue={presentationNames}
            maxAvailableSelectedOptions={maxAvailableSelectedProducts}
            upload={handleSubmit}
            disabled={!medicForm.couponsLeft || isLoading}
            sending={isLoading}
          />
        </Paper>
      </div>
      <div>
        <CustomizedSnackbars
          severity={snackbarStatus.severity}
          mssg={snackbarStatus.message}
          open={snackbarStatus.open}
          onClose={onCloseSnackbar}
          duration={0}
        />
      </div>
    </>
  );
}
