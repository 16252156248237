// material
import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { getCurrentDate, getNextMonthDate } from "utils/dateGetters";

import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import { Grid } from "@material-ui/core";
import PageTitle from "components/PageTitle/PageTitle";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import TraceabilityDashboard from "components/Traceability/Dashboards/TraceabilityDashboard";
import TraceabilityDashboardVirtual from "components/Traceability/Dashboards/TraceabilityDashboardVirtual";
import TraceabilityDashboardDigital from "components/Traceability/Dashboards/TraceabilityDashboardDigital";
import TraceabilityDashboardTotal from "components/Traceability/Dashboards/TraceabilityDashboardTotal";
import TraceabilitySelectionGrid from "components/Traceability/TraceabiltySelectionGrid";
import TraceabilityTable from "components/Traceability/TraceabilityTable";
import TraceabilityTableVirtual from "components/Traceability/TraceabilityTableVirtual";
import TraceabilityTableTotal from "components/Traceability/TraceabilityTableTotal";
import { excelDownload } from "utils/excelDownload";
import { format } from "date-fns";
import logo from "images/logoPagesRS.jpg";
import useStyles from "./styles";
import LoaderBar from "components/LoaderBar/LoaderBar";
import { getRoleName } from "utils/roleNameGetter";

// custom

const getDateFormatted = (date) => {
  return format(date, "dd/MM/yy");
};
const blankFilters = {
  begin: getDateFormatted(
    new Date(getCurrentDate().getFullYear(), getCurrentDate().getMonth(), 1),
  ),
  beginDate: new Date(
    getCurrentDate().getFullYear(),
    getCurrentDate().getMonth(),
    1,
  ),
  end: getDateFormatted(
    new Date(
      getNextMonthDate().getFullYear(),
      getNextMonthDate().getMonth(),
      1,
    ),
  ),
  endDate: new Date(
    getCurrentDate().getFullYear(),
    getCurrentDate().getMonth(),
    1,
  ),
  manager: "",
  consultant: "",
  zone: "",
  program: "",
};

const EXCEL_COLUMNS = [
  { name: "Concepto" },
  { name: "Valor" },
  { name: "" },
  { name: " " },
];

const PHYSICAL_DASHBOARD = 1;
const MEDIC_TABLE = 2;
const VIRTUAL_DASHBOARD = 3;
const VIRTUAL_MEDIC_TABLE = 4;
const DIGITAL_DASHBOARD = 5;
const TOTAL_DASHBOARD = 10;
const TOTAL_MEDIC_TABLE = 11;

const PHYSICAL = 6;
const VIRTUAL = 7;
const DIGITAL = 8;
const TOTAL = 9;

const DEFAULT_SECOND_DASHBOARD_INFO = {
  total: {
    assigned: 0,
    delivered: 0,
    received: 0,
  },
  Potenciales: {
    assigned: 0,
    delivered: 0,
    received: 0,
  },
  Usuarios: {
    assigned: 0,
    delivered: 0,
    received: 0,
  },
  "No Usan/No quieren": {
    assigned: 0,
    delivered: 0,
    received: 0,
  },
  "Sin Status": {
    assigned: 0,
    delivered: 0,
    received: 0,
  },
};

export default function TraceabilityPage() {
  const classes = useStyles();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [chosenSection, setChosenSection] = useState(PHYSICAL_DASHBOARD);
  const [chosenType, setChosenType] = useState(PHYSICAL);
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [secondDashboardInfo, setSecondDashboardInfo] = useState(
    DEFAULT_SECOND_DASHBOARD_INFO,
  );
  const [tableInfo, setTableInfo] = useState({});
  const [virtualTableInfo, setVirtualTableInfo] = useState({});
  const [totalTableInfo, setTotalTableInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialState, setIsInitialState] = useState(true);
  const [excelData, setExcelData] = useState([]);

  const dashboardsComponents = {
    1: (
      <TraceabilityDashboard
        information={dashboardInfo["physical"]}
        secondTableInformation={secondDashboardInfo}
        setInformation={setDashboardInfo}
        setExcelData={setExcelData}
      />
    ),
    2: <TraceabilityTable information={tableInfo} />,
    3: (
      <TraceabilityDashboardVirtual
        information={dashboardInfo["virtual"]}
        setExcelData={setExcelData}
      />
    ),
    4: <TraceabilityTableVirtual information={virtualTableInfo} />,
    5: <TraceabilityDashboardDigital information={dashboardInfo["digital"]} />,
    10: (
      <TraceabilityDashboardTotal
        information={dashboardInfo["total"]}
        setInformation={setDashboardInfo}
        setExcelData={setExcelData}
      />
    ),
    11: <TraceabilityTableTotal information={totalTableInfo} />,
  };

  let screenToRender = dashboardsComponents[chosenSection];

  const handleTypeChange = (event, value) => {
    if (value === "physical") {
      setChosenType(PHYSICAL);
      setChosenSection(PHYSICAL_DASHBOARD);
    } else if (value === "virtual") {
      setChosenType(VIRTUAL);
      setChosenSection(VIRTUAL_DASHBOARD);
    } else if (value === "digital") {
      setChosenType(DIGITAL);
      setChosenSection(DIGITAL_DASHBOARD);
    } else if (value === "total") {
      setChosenType(TOTAL);
      setChosenSection(TOTAL_DASHBOARD);
    }
  };

  const handleSectionChange = (event, value) => {
    if (value === "global_info") {
      setChosenSection(PHYSICAL_DASHBOARD);
    } else if (value === "medics_table") {
      setChosenSection(MEDIC_TABLE);
    } else if (value === "digital_info") {
      setChosenSection(DIGITAL_DASHBOARD);
    } else if (value === "virtual_info") {
      setChosenSection(VIRTUAL_DASHBOARD);
    } else if (value === "virtual_medics_table") {
      setChosenSection(VIRTUAL_MEDIC_TABLE);
    } else if (value === "total_info") {
      setChosenSection(TOTAL_DASHBOARD);
    } else if (value === "total_medics_table") {
      setChosenSection(TOTAL_MEDIC_TABLE);
    }
  };

  const SectionButtonGroup = () => {
    return (
      <Grid
        container
        justifyContent="space-between"
        style={{ marginTop: "10px" }}
      >
        <Grid item xs={6}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={handleTypeChange}
              row
            >
              <FormControlLabel
                checked={chosenType === PHYSICAL}
                value="physical"
                control={<Radio />}
                label="Físicos"
              />
              <FormControlLabel
                checked={chosenType === VIRTUAL}
                value="virtual"
                control={<Radio />}
                label="Virtuales"
              />
              <FormControlLabel
                checked={chosenType === DIGITAL}
                value="digital"
                control={<Radio />}
                label="Digitales"
              />
              <FormControlLabel
                checked={chosenType === TOTAL}
                value="total"
                control={<Radio />}
                label="Total"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={handleSectionChange}
              row
            >
              {chosenType === PHYSICAL ? (
                <>
                  <FormControlLabel
                    checked={chosenSection === PHYSICAL_DASHBOARD}
                    value="global_info"
                    control={<Radio />}
                    label="Dashboard"
                  />
                  <FormControlLabel
                    checked={chosenSection === MEDIC_TABLE}
                    value="medics_table"
                    control={<Radio />}
                    label="Reporte de médicos"
                  />
                </>
              ) : (
                <></>
              )}
              {chosenType === VIRTUAL ? (
                <>
                  <FormControlLabel
                    checked={chosenSection === VIRTUAL_DASHBOARD}
                    value="virtual_info"
                    control={<Radio />}
                    label="Dashboard"
                  />
                  <FormControlLabel
                    checked={chosenSection === VIRTUAL_MEDIC_TABLE}
                    value="virtual_medics_table"
                    control={<Radio />}
                    label="Reporte de médicos"
                  />
                </>
              ) : (
                <> </>
              )}
              {chosenType === DIGITAL ? (
                <FormControlLabel
                  checked={chosenSection === DIGITAL_DASHBOARD}
                  value="digital_info"
                  control={<Radio />}
                  label="Dashboard"
                />
              ) : (
                <></>
              )}
              {chosenType === TOTAL ? (
                <>
                  <FormControlLabel
                    checked={chosenSection === TOTAL_DASHBOARD}
                    value="total_info"
                    control={<Radio />}
                    label="Dashboard"
                  />
                  <FormControlLabel
                    checked={chosenSection === TOTAL_MEDIC_TABLE}
                    value="total_medics_table"
                    control={<Radio />}
                    label="Reporte de médicos"
                  />
                </>
              ) : (
                <></>
              )}
            </RadioGroup>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderLoadingSection = () => {
    return (
      <Grid
        item
        container
        justifyContent="center"
        xs={12}
        style={{ paddingTop: "2%" }}
      >
        <LoaderBar />
      </Grid>
    );
  };

  const defineSectionToShow = () => {
    if (isLoading) return renderLoadingSection();
    if (isInitialState) return <></>;
    return renderCompleteSections();
  };

  const renderCompleteSections = () => {
    return (
      <>
        <Grid item xs={12}>
          <SectionButtonGroup />
        </Grid>
        <Grid item xs={12}>
          {screenToRender}
        </Grid>
      </>
    );
  };

  const exportTraceabilityData = () => {
    excelDownload(
      EXCEL_COLUMNS,
      excelData,
      0,
      EXCEL_COLUMNS.length,
      "Trazabilidad - conversiones",
    );
  };

  // useEffect(() => {
  //   console.log(dashboardInfo);
  // }, [dashboardInfo]);

  const isExportEnabled = () => {
    return (
      (!isLoading && !isInitialState && chosenSection === PHYSICAL_DASHBOARD) ||
      chosenSection === VIRTUAL_DASHBOARD || chosenSection === TOTAL_DASHBOARD
    );
  };

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />

      <div>
        <PageTitle
          title="Tablero de control"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <Grid container>
        <Grid item xs={12}>
          <TraceabilitySelectionGrid
            dashboardUpdateFunction={setDashboardInfo}
            secondDashboardUpdateFunction={setSecondDashboardInfo}
            tableUpdateFunction={setTableInfo}
            virtualTableUpdateFunction={setVirtualTableInfo}
            totalTableUpdateFunction={setTotalTableInfo}
            setIsLoading={setIsLoading}
            setIsInitialState={setIsInitialState}
            exportTraceabilityData={exportTraceabilityData}
            isExportEnabled={isExportEnabled}
            isLoading={isLoading}
          />
          {defineSectionToShow()}
        </Grid>
      </Grid>
    </>
  );
}
