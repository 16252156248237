import React from "react";
import ReactApexChart from "react-apexcharts";
import ReactDOM from "react-dom";
import { dark } from "../../../node_modules/@material-ui/core/styles/createPalette";

const defaultData = {
  series: [],
  options: {
    title: {
      text: "A title",
      margin: 10,
      align: "center",
    },
    dataLabels: {
      enabled: true,
      style: {
        fontWeight: "normal",
        colors: ["black"],
      },
    },
    chart: {
      type: "pie",
      width: "50%",
      height: "auto",
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    legend: {
      position: "top",
    },
    theme: dark, // font color black
    opacity: 1,
  },
};

export default function DonutChart(props) {
  const [data, setData] = React.useState(defaultData);

  React.useEffect(() => {
    if (!props.new_data) return;

    setData({
      ...data,
      series: props.new_data.series,
      options: {
        ...data.options,
        colors: props.new_data.colors ? props.new_data.colors : [],
        labels: props.new_data.labels,
        title: {
          ...data.options.title,
          text: props.new_data.title,
        },
      },
    });
  }, [props.new_data]);

  React.useEffect(() => {}, [data]);

  return (
    <div id="chart">
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="donut"
        height={250}
      />
    </div>
  );
}
