import React from "react";

import { Grid, Typography, LinearProgress } from "@material-ui/core";
import useStyles from "./styles";

export default function LoaderBar() {
  var classes = useStyles();

  return (
    <Grid container xs={12}>
      <Grid item xs={12} className={classes.loader}>
        <Typography variant="h4" className={classes.loaderText}>
          Cargando datos
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.loader}>
        <LinearProgress size={40} className={classes.loaderBar} />
      </Grid>
    </Grid>
  );
}
