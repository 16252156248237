import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, CircularProgress, Grid, TextField } from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";
import { Autocomplete } from "@material-ui/lab";
import Field from "components/CustomFields/Field.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { dbGet, dbPut } from "utils/DBFetchers.js";
import { useHistory } from "react-router-dom";
import routes from "routes";
import { getCurrentDateToday } from "utils/dateGetters.js";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";
import DateField from "components/CustomFields/DateField";
import { format } from "date-fns";
import NumberFormatter from "components/CustomFields/NumberFormatter";
import SKUFormatter from "components/CustomFields/SKUFormatter";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

const blankNewVademecumForm = {
  name: "",
  sku: "",
  discount: "",
  onDate: null,
  id: "",
};

export default function ABMVademecumsNewPage(props) {
  var classes = useStyles();
  const history = useHistory();
  var [isLoading, setIsLoading] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const [newVademecumForm, updateForm] = useState(blankNewVademecumForm);
  const [allProducts, setAllProducts] = useState([]);
  const [isSkuValid, setIsSkuValid] = useState({ sku: false, message: "" });
  const [isDiscountValid, setIsDiscountValid] = useState({
    discount: true,
    message: "",
  });

  const handleChange = (event) => {
    updateForm({
      ...newVademecumForm,
      [event.target.name]: event.target.value,
    });
  };
  const handleDateChange = (date) => {
    updateForm({ ...newVademecumForm, onDate: date });
  };
  const handleSkuChange = (skuValue) => {
    updateForm({
      ...newVademecumForm,
      sku: skuValue,
      name: "",
      id: "",
      discount: "",
      onDate: null,
    });
    var filtered = allProducts.filter((x) => +x.sku === +skuValue);
    if (filtered.length === 0) {
      setIsSkuValid({ sku: false, message: "Producto inexistente." });
    } else if (filtered.length === 1) {
      if (
        filtered[0][`program_${localStorage.getItem("programId")}_vademecum`]
      ) {
        setIsSkuValid({
          sku: false,
          message: "Producto ya posee descuento para el programa.",
        });
      } else {
        setIsSkuValid({ sku: true, message: "" });
        updateForm({
          ...newVademecumForm,
          name: filtered[0].name,
          id: filtered[0].id,
          sku: skuValue,
        });
      }
    } else {
      // Shouldn't be in here, sku is unique
      setIsSkuValid({ sku: false, message: "Producto con errores." });
    }
  };
  const handleSkuChangeEvent = (event) => {
    handleSkuChange(event.target.value);
  };

  const handleDiscountChange = (event) => {
    updateForm({
      ...newVademecumForm,
      [event.target.name]: event.target.value,
    });
    if (
      +event.target.value > 100 ||
      +event.target.value < 0 ||
      event.target.value === "-"
    ) {
      setIsDiscountValid({
        discount: false,
        message: "Descuento no puede ser mayor a 100% o menor a 0%.",
      });
    } else {
      setIsDiscountValid({ discount: true, message: "" });
    }
  };

  const getProducts = () => {
    dbGet("presentations").then((data) => {
      setAllProducts(data);
    });
  };

  /* Basic form check */
  const checkFormComplete = () => {
    if (
      !newVademecumForm.name ||
      !newVademecumForm.sku ||
      !newVademecumForm.discount ||
      !newVademecumForm.onDate ||
      !isSkuValid.sku ||
      !isDiscountValid.discount
    )
      return false;
    return true;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder añadir un nuevo descuento.",
      );
      return;
    }
    setIsLoading(true);

    // Give adecuate format to send form to db
    let completedForm = {
      name: newVademecumForm.name,
      sku: +newVademecumForm.sku,
      next_discount: {
        discount: +newVademecumForm.discount,
        program_id: +localStorage.getItem("programId"),
        on_date: format(new Date(newVademecumForm.onDate), "dd/M/yy"),
      },
    };

    // Sends to db
    dbPut(`presentations/${newVademecumForm.id}`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Descuento añadido exitosamente");
        redirectToVademecumsPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al añadir descuento",
        ); // translate pending
        setIsLoading(false);
        return;
      });
  };

  /* Cancel editing distributor and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankNewVademecumForm);
    history.goBack();
  };

  /* Redirect to pharmacies table page after editing correctly */
  const redirectToVademecumsPage = (user) => {
    setTimeout(() => {
      updateForm(blankNewVademecumForm);
      setIsLoading(false);
      history.push(routes.abmVademecums);
    }, 3000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getProducts();
  }, []);

  // Program switch logic
  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  const updateSkuDataOnProgramIdChange = () => {
    handleSkuChange(newVademecumForm.sku);
  };
  React.useEffect(() => {
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);
  React.useEffect(() => {
    if (!newVademecumForm.sku) return; // no fetch on page load
    updateSkuDataOnProgramIdChange();
  }, [programId]);

  const title = `Añadir Descuento a producto${
    newVademecumForm.name ? ": " : ""
  }${newVademecumForm.name ? newVademecumForm.name : ""}. Programa: ${
    localStorage.getItem("programId") === "1"
      ? "Receta Solidaria"
      : "Solo Mujer"
  }`;

  const handleAutoCompleteNameChange = (event, newValue) => {
    if (newValue) handleSkuChange(newValue.sku);
    else {
      updateForm({
        ...newVademecumForm,
        name: "",
        sku: "",
      });
    }
  };

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del producto
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  A continuación ingrese los datos requeridos para añadir un
                  nuevo descuento al producto.
                </Typography>
              </div>

              <div
                className={classes.generalPurposeItems}
                style={{ marginTop: "4%" }}
              >
                <Typography className={classes.text} weight="bold">
                  Nombre
                </Typography>

                <Autocomplete
                  freeSolo
                  className={classes.generalFieldValidation}
                  options={allProducts ? allProducts : []}
                  onChange={handleAutoCompleteNameChange}
                  style={{
                    noBorder: {
                      border: "none",
                    },
                  }}
                  getOptionLabel={(option) =>
                    option.name
                      ? option.notes
                        ? option.name + " - " + option.notes
                        : option.name
                      : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      style={{
                        noBorder: {
                          border: "none",
                        },
                      }}
                      className={
                        params.disabled
                          ? classes.selectionTextDisabled
                          : classes.selectionTextEnabled
                      }
                      {...params}
                      placeholder={
                        params.label
                          ? params.label
                          : newVademecumForm.name
                          ? newVademecumForm.name
                          : "Seleccione"
                      }
                      margin="dense"
                      variant="outlined"
                    />
                  )}
                />
              </div>

              <Field
                labelName="SKU"
                required={true}
                id="sku"
                value={newVademecumForm.sku}
                divClassType={classes.generalPurposeItems}
                fieldClassType={classes.generalFieldValidation}
                error={!isSkuValid.sku}
                helperText={isSkuValid.message}
                onChange={handleSkuChangeEvent}
                InputProps={{
                  inputComponent: SKUFormatter,
                }}
              ></Field>

              <Field
                labelName="Descuento (%)"
                id="discount"
                value={newVademecumForm.discount}
                divClassType={classes.generalPurposeItems}
                fieldClassType={classes.generalPurposeField}
                onChange={handleDiscountChange}
                InputProps={{
                  inputComponent: NumberFormatter,
                }}
                error={!isDiscountValid.discount}
                helperText={isDiscountValid.message}
              ></Field>
              <div className={classes.generalPurposeItems}>
                <Typography className={classes.text} weight="bold">
                  Nueva fecha inicial
                </Typography>
                <DateField
                  name="onDate"
                  value={newVademecumForm.onDate}
                  onChange={handleDateChange}
                  fieldClassName={classes.generalPurposeField}
                  minDate={getCurrentDateToday()}
                  format={"dd/MM/yyyy"}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelAndGoBack}
                >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
