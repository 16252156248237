import React, { useEffect, useState } from "react";
import { dbGet, dbPost } from "utils/DBFetchers";
import {
  TextField,
  Button,
  MenuItem,
  Paper,
  FormControlLabel,
  Stack,
  Checkbox,
  Grid,
  Typography,
  Link,
  CircularProgress,
} from "@mui/material";
import CheckboxForm from "../burnableCouponForm/checkboxForm";
import { verifyRut } from "utils/rutValidations";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import logoSM from "images/solo-mujer-logo.png";
import ErrorDialog from "components/errorDialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@material-ui/styles";

const defaultFormValues = {
  name: "",
  last_name: "",
  patient_rut: "",
  patient_rut_dv: "",
  mail: "",
  phone_number: null,
  matrona_name: "",
  matrona_last_name: "",
  matrona_rut: "",
  matrona_rut_dv: "",
  terms: false,
  consent: false,
};

export default function SoloMujerForm() {
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [phoneError, setPhoneError] = useState("");
  const [patientRutError, setPatientRutError] = useState("");
  const [matronaRutError, setMatronaRutError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [finishedSendingForm, setFinishedSendingForm] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  // Error dialog logic
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  var theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down("sm")); // sm = 600px
  const isLg = useMediaQuery(theme.breakpoints.down("lg")); // lg = 1200px
  const isXl = useMediaQuery(theme.breakpoints.down("xl")); // xl = 1536px

  const handleOpen = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //

  const isValidEmail = (email) => {
    const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    validEmail ? setEmailError("") : setEmailError("Email inválido");
    return validEmail;
  };

  const isValidPhoneNumber = (phone) => {
    const validPhone = /^[0-9]{9}$/.test(phone);
    validPhone
      ? setPhoneError("")
      : setPhoneError("Teléfono inválido. Evite espacios y guiones");
    return validPhone;
  };

  const areValidRuts = () => {
    const validPatientRut = verifyRut(
      formValues.patient_rut + formValues.patient_rut_dv,
    );
    const validMatronaRut = verifyRut(
      formValues.matrona_rut + formValues.matrona_rut_dv,
    );

    validPatientRut
      ? setPatientRutError("")
      : setPatientRutError("RUT inválido");
    validMatronaRut
      ? setMatronaRutError("")
      : setMatronaRutError("RUT inválido");
    return validPatientRut && validMatronaRut;
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleRutChange = (event) => {
    const { name, value } = event.target;
    const number_pattern = /^[0-9]+$/;
    const number_and_k_pattern = /^[0-9kK]+$/;

    if (
      value === "" ||
      (name.includes("dv") &&
        value.length <= 1 &&
        number_and_k_pattern.test(value)) ||
      (!name.includes("dv") && number_pattern.test(value) && value.length <= 8)
    ) {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    if (!areValidRuts()) return false;
    if (!isValidEmail(formValues.mail)) return false;
    if (!isValidPhoneNumber(formValues.phone_number)) return false;
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    const body = `data=${JSON.stringify({
      last_name: formValues.last_name,
      first_name: formValues.name,
      rut: `${formValues.patient_rut}-${formValues.patient_rut_dv}`,
      phone: formValues.phone_number,
      email: formValues.mail,
      send_email: "1",
      program: "sm",
      mat_rut: `${formValues.matrona_rut}-${formValues.matrona_rut_dv}`,
      mat_last_name: formValues.matrona_last_name,
      mat_first_name: formValues.matrona_name,
      send_sms: "0",
    })}`;
    const url = `${process.env.REACT_APP_API_URI}/api/barcodes`;

    try {
      setIsLoading(true);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic dXNlcnRlc3Q6cHd0ZXN0",
        },
        body: body,
      });
      if (response.ok) {
        setFinishedSendingForm(true);
        snackbarOpen("success", "Cupón enviado correctamente");
      } else {
        const errorData = await response.json();
        handleOpen(errorData.message || "Error al enviar el formulario");
      }
    } catch (error) {
      handleOpen("Error al enviar el formulario");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ width: isXl ? "100%" : "auto" }}>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <div
        className="color-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ErrorDialog open={open} handleClose={handleClose} message={message} />
        <Paper
          className="paper-container"
          elevation={3}
          style={{
            marginLeft: "0%",
            width: isLg ? "80%" : isSm ? "100%" : "34vw",
            height: isSm ? "0" : "auto",
          }}
        >
          <Grid
            container
            style={{
              marginTop: "2em",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={logoSM}
              alt="Logo Solo Mujer"
              style={{
                width: "auto",
                height: "4em",
              }}
            />
          </Grid>
          {finishedSendingForm ? (
            <Grid style={{ textAlign: "center", padding: "2em" }}>
              <Typography fontSize="1em">
                <b>
                  TU REGISTRO EN EL PROGRAMA SOLO MUJER SE REALIZÓ CORRECTAMENTE
                </b>
                <br />
                Hemos enviado tu cupón a tu correo electrónico.
                <br />
                <br />
                Si no recibes el cupón, por favor, comunícate a:
                <br />
                <br />
                <Link
                  href="mailto:ayuda@soporte-abbott.info"
                  underline="always"
                >
                  ayuda@soporte-abbott.info
                </Link>
                <br />
                +562 29790580
              </Typography>
            </Grid>
          ) : (
            <form onSubmit={handleSubmit}>
              <div style={{ padding: "1% 7% 7% 7%" }}>
                <Stack
                  className="form"
                  direction="column"
                  style={{
                    width: isSm ? "98%" : "90%",
                    height: isSm ? "100%" : "auto",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <TextField
                    required
                    size="small"
                    fullWidth
                    label="Nombre"
                    name="name"
                    color="secondary"
                    value={formValues.name}
                    onChange={handleInputChange}
                    margin="normal"
                  />
                  <TextField
                    required
                    size="small"
                    fullWidth
                    label="Apellido"
                    name="last_name"
                    color="secondary"
                    value={formValues.last_name}
                    onChange={handleInputChange}
                    margin="normal"
                  />
                  <Grid container>
                    <Grid item xs={9}>
                      <TextField
                        required
                        size="small"
                        error={patientRutError !== ""}
                        helperText={patientRutError}
                        fullWidth
                        label="RUT"
                        name="patient_rut"
                        color="secondary"
                        value={formValues.patient_rut}
                        onChange={handleRutChange}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        required
                        error={patientRutError !== ""}
                        size="small"
                        fullWidth
                        label="DV"
                        color="secondary"
                        name="patient_rut_dv"
                        value={formValues.patient_rut_dv}
                        onChange={handleRutChange}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={3}>
                      <TextField
                        size="small"
                        fullWidth
                        color="secondary"
                        name="area_phone_code"
                        value="+ 56"
                        margin="normal"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        required
                        color="secondary"
                        size="small"
                        error={phoneError !== ""}
                        helperText={phoneError}
                        fullWidth
                        label="Teléfono"
                        name="phone_number"
                        value={formValues.phone_number}
                        onChange={handleInputChange}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    required
                    error={emailError !== ""}
                    helperText={emailError}
                    color="secondary"
                    fullWidth
                    size="small"
                    label="Email"
                    name="mail"
                    value={formValues.mail}
                    onChange={handleInputChange}
                    margin="normal"
                  />

                  <TextField
                    fullWidth
                    size="small"
                    required
                    label="Nombre de la Matrona"
                    color="secondary"
                    name="matrona_name"
                    value={formValues.matrona_name}
                    onChange={handleInputChange}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    size="small"
                    required
                    label="Apellido de la Matrona"
                    color="secondary"
                    name="matrona_last_name"
                    value={formValues.matrona_last_name}
                    onChange={handleInputChange}
                    margin="normal"
                  />
                  <Grid container>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth
                        required
                        error={matronaRutError !== ""}
                        helperText={matronaRutError}
                        color="secondary"
                        size="small"
                        label="RUT de la Matrona"
                        name="matrona_rut"
                        value={formValues.matrona_rut}
                        onChange={handleRutChange}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={3} pb={1}>
                      <TextField
                        required
                        error={matronaRutError !== ""}
                        size="small"
                        fullWidth
                        label="DV"
                        name="matrona_rut_dv"
                        color="secondary"
                        value={formValues.matrona_rut_dv}
                        onChange={handleRutChange}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <Grid>
                    <CheckboxForm
                      name="terms"
                      required
                      color="secondary"
                      checked={formValues.terms}
                      handleInputChange={handleInputChange}
                      firstTypography="Acepto haber leído los"
                      linkTypography="Términos y Condiciones"
                      secondTypography="del Programa Solo Mujer."
                      href="https://recetasolidaria.cl/content/uploads/2024/01/Terminos-y-condiciones.pdf"
                    />
                    <CheckboxForm
                      name="consent"
                      required
                      color="secondary"
                      checked={formValues.consent}
                      handleInputChange={handleInputChange}
                      firstTypography="Acepto haber leído el"
                      linkTypography="Consentimiento sobre uso de mi información"
                      secondTypography="del Programa Solo Mujer."
                      href="https://recetasolidaria.cl/content/uploads/2024/01/consentimiento-de-inscripcion.png"
                    />
                  </Grid>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      style={{ marginTop: "1em" }}
                      disabled={
                        isLoading ||
                        !formValues.consent ||
                        !formValues.terms ||
                        !formValues.name ||
                        !formValues.last_name ||
                        !formValues.patient_rut ||
                        !formValues.patient_rut_dv ||
                        !formValues.mail ||
                        !formValues.matrona_name ||
                        !formValues.matrona_last_name ||
                        !formValues.matrona_rut ||
                        !formValues.matrona_rut_dv
                      }
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      Enviar
                    </Button>
                  )}
                </Stack>
              </div>
            </form>
          )}
        </Paper>
      </div>
    </div>
  );
}
