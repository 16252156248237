import routes from "routes";

const searchbarRoles = {
  unknown: {
    Inicio: routes.root,
  },
  admin: {
    "Nuevo cupón": routes.benefitsNewCupon,
    "Nueva venta": routes.benefitsNewSale,
    "Importar beneficios tradicionales": routes.benefitsImport,
    // "Importar beneficios quemables": routes.benefitsImportBurnable,
    "Consultar productos": routes.benefitsConsultProduct,
    "Control empadronamientos": routes.controlCuponesIngresados,
    "Ventas realizadas": routes.controlVentasRealizadas,
    Liquidaciones: routes.reportsSettlements,
    Vademecums: routes.abmVademecums,
    "Nuevo vademecum": routes.abmVademecumsNew,
    "Lista de precios": routes.abmProducts,
    "Nuevo producto": routes.abmProductsNew,
    Farmacias: routes.abmPharmacies,
    "Nueva farmacia": routes.abmPharmaciesNew,
    Distribuidores: routes.abmDistributors,
    "Nuevo distribuidor": routes.abmDistributorsNew,
    Grupos: routes.abmPharmaGroups,
    "Codigos de barras": routes.abmBarcodes,
    "Farmacia-Distribuidor": routes.abmPharmacyDistributors,
    "Nueva farmacia-distribuidor": routes.abmPharmacyDistributorsNew,
    Usuarios: routes.usersUsers,
    "Nuevo usuario": routes.usersNewUser,
    Perfil: routes.profile,
  },
  commertial: {
    Empadronamientos: routes.auditInscriptions,
    Ventas: routes.auditSales,
    Perfil: routes.profile,
  },
  distributors: {
    Importar: routes.benefitsImport,
    "Consultar productos": routes.benefitsConsultProduct,
    Empadronamientos: routes.controlCuponesIngresados,
    "Ventas realizadas": routes.controlVentasRealizadas,
    Liquidaciones: routes.reportsSettlements,
    Farmacias: routes.abmPharmacies,
  },
  kam: {
    Empadronamientos: routes.auditInscriptions,
    Ventas: routes.auditSales,
    Perfil: routes.profile,
  },
  pharmacies: {
    "Nuevo cupón": routes.benefitsNewCupon,
    "Nueva venta": routes.benefitsNewSale,
    Importar: routes.benefitsImport,
    "Consultar productos": routes.benefitsConsultProduct,
    Empadronamientos: routes.controlCuponesIngresados,
    "Ventas realizadas": routes.controlVentasRealizadas,
    Liquidaciones: routes.reportsSettlements,
  },
  pharmacies_read_only: {
    Empadronamientos: routes.controlCuponesIngresados,
    "Ventas realizadas": routes.controlVentasRealizadas,
    "Consultar productos": routes.benefitsConsultProduct,
    Liquidaciones: routes.reportsSettlements,
  },
  pharmagroups: {
    Empadronamientos: routes.controlCuponesIngresados,
    "Ventas realizadas": routes.controlVentasRealizadas,
    "Importar beneficios tradicionales": routes.benefitsImport,
    "Consultar productos": routes.benefitsConsultProduct,
    Liquidaciones: routes.reportsSettlements,
  },
  super_admin: {
    "Auditoria de empadronamientos": routes.auditInscriptions,
    "Auditoria de ventas": routes.auditSales,
    "Importar auditoria": routes.auditImport,
    "Importar beneficios quemables": routes.benefitsImportBurnable,
    "Cargar UF": routes.auditUF,
    "Nuevo cupón": routes.benefitsNewCupon,
    "Nueva venta": routes.benefitsNewSale,
    "Importar beneficios tradicionales": routes.benefitsImport,
    "Consultar productos": routes.benefitsConsultProduct,
    "Control empadronamientos": routes.controlCuponesIngresados,
    "Ventas realizadas": routes.controlVentasRealizadas,
    Liquidaciones: routes.reportsSettlements,
    Vademecums: routes.abmVademecums,
    "Nuevo vademecum": routes.abmVademecumsNew,
    "Lista de precios": routes.abmProducts,
    "Nuevo producto": routes.abmProductsNew,
    Farmacias: routes.abmPharmacies,
    "Nueva farmacia": routes.abmPharmaciesNew,
    Distribuidores: routes.abmDistributors,
    "Nuevo distribuidor": routes.abmDistributorsNew,
    Grupos: routes.abmPharmaGroups,
    "Codigos de barras": routes.abmBarcodes,
    "Farmacia-Distribuidor": routes.abmPharmacyDistributors,
    "Nueva farmacia-distribuidor": routes.abmPharmacyDistributorsNew,
    "Nuevo usuario": routes.usersNewUser,
    Usuarios: routes.usersUsers,
    "Roles de usuarios": routes.usersRoles,
    "Nuevo rol": routes.usersNewRole,
    "Borrar importacion": routes.delete,
    Perfil: routes.profile,
  },
};

export default searchbarRoles;
