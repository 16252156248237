import React from "react";
import routes from "routes";

import { Login } from "utils/DBFetchers";
import { encodeRoleName } from "utils/roleNameGetter";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS": {
      return {
        ...state,
        isAuthenticated: true,
        permissions: action.permissions,
      };
    }
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, permissions: [] };
    case "RESET_SUCCESS":
      return { ...state, isAuthenticated: false, permissions: [] };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("token"),
    permissions: localStorage.getItem("permissions")
      ? JSON.parse(localStorage.getItem("permissions"))
      : [],
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

const INCORRECT_DATA = "Los datos son incorrectos.";
const NO_PROGRAMS_TO_SHOW =
  "Su distribuidor no le permite acceder a las transacciones, por favor comuníquese con él.";
const USER_DISABLED =
  "Su usuario ha sido desactivado. Por favor contacte al administrador.";
const USER_DELETED =
  "Su usuario ha sido eliminado. Por favor contacte al administrador.";
const PHARMACY_DISABLED =
  "Su farmacia ha sido deshabilitada. Por favor contacte al administrador.";

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError({ message: "", state: false });
  setIsLoading(true);
  localStorage.clear();

  var userData = { username: login, password: password };

  if (!!login && !!password) {
    Login(userData)
      .then((response) => {
        setTimeout(() => {
          // Checks for programs
          if (response.programs.length === 0) {
            setError({ message: NO_PROGRAMS_TO_SHOW, state: true });
            setIsLoading(false);
            return;
          }
          // Check if user is not deactivated
          if (!response.is_enabled) {
            setError({ message: USER_DISABLED, state: true });
            setIsLoading(false);
            return;
          }
          // Check if user is deleted
          if (response.is_deleted) {
            setError({ message: USER_DELETED, state: true });
            setIsLoading(false);
            return;
          }
          if (
            response.pharmacy &&
            Object.keys(response.pharmacy).length !== 0 &&
            !response.pharmacy.is_enabled
          ) {
            setError({ message: PHARMACY_DISABLED, state: true });
            setIsLoading(false);
            return;
          }

          localStorage.setItem(
            "switchDisabledActivePrograms",
            response.programs.length === 1
              ? JSON.stringify(response.programs[0])
              : "0",
          );
          localStorage.setItem("token", response.token);

          if (response.programs.length === 1) {
            localStorage.setItem("programId", response.programs[0].toString());
          } else if (response.programs.length > 1) {
            if (response.role.name !== "pharmacies")
              localStorage.setItem(
                "programId",
                response.programs[0].toString(),
              );
            else localStorage.setItem("programId", "-1");
          }

          localStorage.setItem(
            "permissions",
            JSON.stringify(response.permissions),
          );
          localStorage.setItem("username", response.username);
          localStorage.setItem("email", response.email);
          localStorage.setItem("roleId", response.role.id);
          localStorage.setItem("roleName", encodeRoleName(response.role.name));
          localStorage.setItem(
            "authoritarianPrograms",
            JSON.stringify(response.programs_authoritarian),
          );
          setError({ message: INCORRECT_DATA, state: null });
          setIsLoading(false);
          dispatch({
            type: "LOGIN_SUCCESS",
            permissions: response.permissions,
          });
          if (response.role.name === "medic") {
            history.push(routes.sendCoupon);
          } else if (response.role.name === "consultant") {
            history.push(routes.accreditCoupon);
          } else {
            history.push(routes.home);
          }
        }, 1500);
      })
      .catch((error) => {
        // Enters here if recvs error from backend
        setError({ message: INCORRECT_DATA, state: true });
        setIsLoading(false);
        return;
      });
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError({ message: INCORRECT_DATA, state: true });
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.clear();
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  if (history) {
    history.push(routes.login);
  }
}
