import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";

import Field from "components/CustomFields/Field.js";
import SelectField from "components/CustomFields/SelectField.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { dbGet, dbPut } from "utils/DBFetchers.js";
import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";
import NumberFormatter from "components/CustomFields/NumberFormatter";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

const blankEditDistributorForm = {
  id: "",
  name: "",
  discount: "",
  authoritarian: null,
  programs: null,
};

const allAuthoritarian = [
  { id: 1, name: "Si" },
  { id: 2, name: "No" },
];
const allPrograms = [
  { id: 1, name: "Receta solidaria" },
  { id: 2, name: "Solo mujer" },
  { id: 3, name: "Ambos" },
];

const formattedPrograms = {
  1: [1],
  2: [2],
  3: [1, 2],
};

export default function ABMDistributorsEditPage(props) {
  var classes = useStyles();
  var [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [editDistributorForm, updateForm] = useState(blankEditDistributorForm);
  const [allDistributors, setAllDistributors] = useState([]);
  const [isNameValid, setIsNameValid] = useState({ name: false, message: "" });
  const [isDiscountValid, setIsDiscountValid] = useState({
    discount: true,
    message: "",
  });

  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const handleChange = (event) => {
    updateForm({
      ...editDistributorForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleNameVerification = () => {
    var nameExists = allDistributors.some(
      (elem) => elem.name === editDistributorForm.name,
    );
    if (nameExists)
      setIsNameValid({
        name: nameExists,
        message: "Distribuidor ya existente.",
      });
    else setIsNameValid({ name: nameExists, message: "" });
  };

  React.useEffect(() => {
    if (!editDistributorForm.name) return; // no fetch on page load
    handleNameVerification();
  }, [editDistributorForm.name]);

  const handleDiscountChange = (event) => {
    updateForm({
      ...editDistributorForm,
      [event.target.name]: event.target.value,
    });
    if (
      +event.target.value > 100 ||
      +event.target.value < 0 ||
      event.target.value === "-"
    ) {
      setIsDiscountValid({
        discount: false,
        message: "Descuento no puede ser mayor a 100% o menor a 0%.",
      });
    } else {
      setIsDiscountValid({ discount: true, message: "" });
    }
  };

  /* Fills distributor name options */
  const getDistributors = (distributorId) => {
    dbGet("distributors").then((data) => {
      let mapped = data.map((item) => {
        return {
          id: item["id"],
          name: item["name"],
        };
      });
      setAllDistributors(mapped.filter((x) => x.id !== distributorId));
    });
  };

  /* Fills form data */
  const getDistributorInfo = (distributorId) => {
    dbGet(`distributors/${distributorId}`).then((data) => {
      updateForm({
        ...editDistributorForm,
        id: distributorId,
        name: data.name ? data.name : "",
        discount: data.discount ? data.discount : "",
        authoritarian: data.is_authoritarian ? 1 : 2,
        programs: data.programs.length === 1 ? data.programs[0].id : 3, //check format
      });
    });
  };

  /* Basic form check */
  const checkFormComplete = () => {
    if (
      !editDistributorForm.name ||
      !editDistributorForm.discount ||
      !editDistributorForm.authoritarian ||
      !isDiscountValid.discount
    )
      return false;
    return true;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder editar el distribuidor.",
      );
      return;
    }
    setIsLoading(true);

    // Give adecuate format to send form to db
    let completedForm = {
      name: editDistributorForm.name,
      works_with_independents: true,
      discount: editDistributorForm.discount,
      programs: formattedPrograms[editDistributorForm.programs],
      is_authoritarian: editDistributorForm.authoritarian === 1 ? true : false,
    };

    // Sends to db
    dbPut(`distributors/${editDistributorForm.id}`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Distribuidor editado exitosamente");
        redirectToDistributorsPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al editar distribuidor",
        ); // translate pending
        setIsLoading(false);
        return;
      });
  };

  /* Cancel editing distributor and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankEditDistributorForm);
    history.goBack();
  };

  /* Redirect to distributor table page after editing correctly */
  const redirectToDistributorsPage = (user) => {
    setTimeout(() => {
      updateForm(blankEditDistributorForm);
      setIsLoading(false);
      history.push(routes.abmDistributors);
    }, 2000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    if (props.props.location.state) {
      getDistributors(props.props.location.state.distributorId);
      getDistributorInfo(props.props.location.state.distributorId);
    }
  }, []);

  const title = `Editar Distribuidor${editDistributorForm.name ? ": " : ""}${
    editDistributorForm.name ? editDistributorForm.name : ""
  }`;

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del distribuidor
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  A continuación edite los datos del distribuidor.
                </Typography>
              </div>
              <div style={{ marginTop: "4%" }}>
                <Field
                  labelName="Nombre *"
                  required={true}
                  id="name"
                  value={editDistributorForm.name}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  error={isNameValid.name}
                  helperText={isNameValid.message}
                ></Field>
                <Field
                  labelName="Descuento *"
                  required={true}
                  id="discount"
                  value={editDistributorForm.discount}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleDiscountChange}
                  InputProps={{
                    inputComponent: NumberFormatter,
                  }}
                  error={!isDiscountValid.discount}
                  helperText={isDiscountValid.message}
                ></Field>
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  displayText="Programa/s *"
                  required={true}
                  id="programs"
                  label="Seleccione"
                  name="programs"
                  value={editDistributorForm.programs}
                  onChange={handleChange}
                  allValues={allPrograms ? allPrograms : []}
                />
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  displayText="¿Es autoritario? *"
                  id="authoritarian"
                  label="Seleccione"
                  name="authoritarian"
                  value={editDistributorForm.authoritarian}
                  onChange={handleChange}
                  allValues={allAuthoritarian ? allAuthoritarian : []}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelAndGoBack}
                >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
