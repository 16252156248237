import React, { useState, useEffect } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import {
  Button,
  Grid,
  TextField,
  CircularProgress,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";
import InputAdornment from "@material-ui/core/InputAdornment";
import DescriptionIcon from "@material-ui/icons/Description";
import { Link } from "@material-ui/core";

// styles
import useStyles from "./styles";

import logo from "images/logoPagesRS.jpg";
import { dbGet, dbPost, dbUploadFile } from "utils/DBFetchers";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import SelectField from "components/CustomFields/SelectField";
import { getCurrentDate, getNextMonthDate } from "utils/dateGetters";
import { format } from "date-fns";
import DistributorsField from "components/CustomFields/DistributorsField";
import DateField from "components/CustomFields/DateField";
import LinearProgressWithLabel from "components/LinearProgressWithLabel/LinearProgressWithLabel";
import ProgramField from "components/CustomFields/ProgramField";

const IMPORT_TYPE_INDEPENDENT = 1;
const IMPORT_TYPE_SHEET = 2;

const types = [
  { id: 1, name: "Empadronamientos" },
  { id: 2, name: "Ventas" },
];

const statusConvertion = {
  Pending: "Comenzando",
  Inscriptions: "Cargando empadronamientos",
  Sales: "Cargando ventas",
  SavingInscriptions: "Guardando empadronamientos",
  SavingSales: "Guardando ventas",
  Completed: "Terminado",
};

const getDateFormatted = (date) => {
  return format(date, "yyyy/MM");
};

const blankFilters = {
  period: getDateFormatted(new Date().setMonth(new Date().getMonth() - 1)),
  distributor: "",
  program: "",
  type: "",
};

export default function AuditImportPage(props) {
  var classes = useStyles();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(blankFilters);
  const [sheetFilters, setSheetFilters] = useState(blankFilters);
  const [selectedDistributor, setSelectedDistributor] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedSheetDistributor, setSelectedSheetDistributor] = useState("");
  const [selectedSheetProgram, setSelectedSheetProgram] = useState("");
  const [selectedSheetType, setSelectedSheetType] = useState("");
  const [progress, setProgress] = useState({ progress: 0, status: "" });
  const [importIdFile, setImportIdFile] = useState("");
  const [importIdPharm, setImportIdPharm] = useState("");
  const hiddenFileInput = React.useRef(null);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [importType, setImportType] = useState(null);
  const [areIDs, setAreIDs] = useState(false);

  const [reprocessProgress, setReprocessProgress] = useState({
    progress: 0,
    status: "",
  });
  const [reprocessIsLoading, setReprocessIsLoading] = useState(false);
  const [reprocessImportIdFile, setReprocessImportIdFile] = useState("");

  const [showDistributors, setShowDistributors] = useState(true);
  const [allPharmaGroups, setAllPharmaGroups] = useState([]);
  const [selectedReprocessDistributor, setSelectedReprocessDistributor] =
    useState("");
  const [selectedReprocessProgram, setSelectedReprocessProgram] = useState("");
  const [selectedReprocessPharmaGroup, setSelectedReprocessPharmaGroup] =
    useState("");
  const [selectedReprocessPeriod, setSelectedReprocessPeriod] = useState(
    getDateFormatted(new Date().setMonth(new Date().getMonth() - 1)),
  );

  const selectFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const validFilters = (filters) => {
    let validDistributor = filters.distributor ? true : false;
    let validProgram = filters.program ? true : false;
    let validType = filters.type ? true : false;

    if (!validDistributor) {
      snackbarOpen("error", "El campo de cliente está vacio");
    }
    if (!validProgram) {
      snackbarOpen("error", "El campo del programa está vacio");
    }
    if (!validType) {
      snackbarOpen("error", "El campo del tipo está vacio");
    }

    return validDistributor && validProgram && validType;
  };

  const updateProgress = (id, data) => {
    setImportIdFile(id);

    dbGet(`request_checker/status?id=${id}`)
      .then((data) => {
        setProgress({
          ...progress,
          progress: data.progress ? data.progress : 0,
          status: data.status,
        });
        if (data.status === "Completed") {
          snackbarOpen("success", "Archivo cargado correctamente!");
          setIsLoading(false);
          setImportIdFile(data.id);
          setProgress({ progress: 0, status: "" });
        } else if (!data.error_status && data.status !== "Failure") {
          setTimeout(updateProgress.bind(null, id, data), 5000);
        } else {
          snackbarOpen(
            "error",
            data.error_message
              ? data.error_message.description_es
              : importType === IMPORT_TYPE_INDEPENDENT
              ? "Error en la importación."
              : "El archivo puede contener errores y no se pudo cargar.",
          );
          setIsLoading(false);
          setProgress({ progress: 0, status: "" });
        }
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error.error_message
            ? error.error_message.description_es
            : importType === IMPORT_TYPE_INDEPENDENT
            ? "Error en la importación."
            : "El archivo puede contener errores y no se pudo cargar.",
        );
        setIsLoading(false);
        setProgress({ progress: 0, status: "" });
      });
  };

  const upload = () => {
    setIsLoading(true);
    let periodFormated = sheetFilters.period.split("/").join("");
    let period = `period=${periodFormated}`;
    let distributorId = `${
      sheetFilters.distributor
        ? `&distributor_id=${sheetFilters.distributor}`
        : ""
    }`;
    let programId = `&program_id=${sheetFilters.program}`;
    let type = +sheetFilters.type === 1 ? "inscriptions" : "sales";
    let mock = { progress: 10 };
    dbUploadFile(
      selectedFile,
      `/upload/audit/${type}?${period}${programId}${distributorId}&id_as_rut=${
        areIDs ? "true" : ""
      }`,
    )
      .then((response) => {
        setImportType(IMPORT_TYPE_SHEET);
        setTimeout(updateProgress.bind(null, response.id, mock), 5000);
      })
      .catch((error) => {
        if (error.response) {
          snackbarOpen(
            "error",
            error.response && error.response.data
              ? error.response.data.description_es
              : importType === IMPORT_TYPE_INDEPENDENT
              ? "Error en la importación."
              : "El archivo puede contener errores y no se pudo cargar.",
          );
          setIsLoading(false);
          setProgress({ progress: 0, status: "" });
        }
      });
  };

  const validReprocess = () => {
    let validDistributorOrGroup = false;
    if (showDistributors === 1) {
      validDistributorOrGroup = selectedReprocessDistributor ? true : false;
    } else if (showDistributors === 2) {
      validDistributorOrGroup = selectedReprocessPharmaGroup ? true : false;
    } else {
      validDistributorOrGroup = false;
    }
    let validProgram = selectedReprocessProgram ? true : false;

    if (!validDistributorOrGroup) {
      snackbarOpen("error", "El campo de cliente está vacio");
    }
    if (!validProgram) {
      snackbarOpen("error", "El campo del programa está vacio");
    }

    return validDistributorOrGroup && validProgram;
  };

  const reprocess = () => {
    if (!validReprocess()) return;
    let periodFormated = selectedReprocessPeriod.split("/").join("");
    let period = `period=${periodFormated}`;
    let programId = `&program_id=${selectedReprocessProgram}`;
    let distributorId = `${
      showDistributors === 1 && selectedReprocessDistributor
        ? `&distributor_id=${selectedReprocessDistributor}`
        : ""
    }`;
    let pharmaGroupId = `${
      showDistributors === 2 && selectedReprocessPharmaGroup
        ? `&pharma_group_id=${selectedReprocessPharmaGroup}`
        : ""
    }`;
    dbGet(
      `/audit/sales/reprocess?${period}${programId}${distributorId}${pharmaGroupId}`,
    ).then((response) => {
      setReprocessIsLoading(true);
      downloadReport(response.id, response);
    });
  };

  const downloadReport = (id, data) => {
    dbGet(`request_checker/status?id=${id}`)
      .then((data) => {
        setProgress({
          ...progress,
          progress: data.progress ? data.progress : 0,
          status: data.status,
        });
        if (data.status === "Completed") {
          snackbarOpen("success", "Completado!");
          setReprocessIsLoading(false);
          setReprocessImportIdFile(data.id);
          setProgress({ progress: 0, status: "" });
          if (data.data.url) {
            const download_link = data.data.url;
            if (download_link) {
              window.location.href = download_link;
            }
          }
        } else if (!data.error_status && data.status !== "Failure") {
          setTimeout(downloadReport.bind(null, id, data), 5000);
        } else {
          snackbarOpen("error", "Ocurrió un error");
          setReprocessIsLoading(false);
          setProgress({ progress: 0, status: "" });
        }
      })
      .catch((error) => {
        snackbarOpen("error", "Ocurrió un error");
        setReprocessIsLoading(false);
        setReprocessProgress({ progress: 0, status: "" });
      });
  };

  const generate = () => {
    if (!validReprocess()) return;
    let periodFormated = selectedReprocessPeriod.split("/").join("");
    let period = `period=${periodFormated}`;
    let programId = `&program_id=${selectedReprocessProgram}`;
    let distributorId = `${
      showDistributors === 1 && selectedReprocessDistributor
        ? `&distributor_id=${selectedReprocessDistributor}`
        : ""
    }`;
    let pharmaGroupId = `${
      showDistributors === 2 && selectedReprocessPharmaGroup
        ? `&pharma_group_id=${selectedReprocessPharmaGroup}`
        : ""
    }`;
    dbGet(
      `/audit/sales/generate_report?${period}${programId}${distributorId}${pharmaGroupId}`,
    ).then((response) => {
      setReprocessIsLoading(true);
      downloadReport(response.id, response);
    });
  };

  const makeImport = () => {
    if (!validFilters(filters)) return;
    let periodFormated = filters.period.split("/").join("");
    let period = `period=${periodFormated}`;
    let distributorId = `${
      +filters.distributor !== -1
        ? `&distributor_id=${filters.distributor}`
        : ""
    }`;
    let programId = `&program_id=${filters.program}`;
    let type = +filters.type === 1 ? "inscriptions" : "sales";

    dbPost(
      `/audit/${type}/pharmacy-export?${period}${programId}${distributorId}`,
      filters,
    )
      .then((response) => {
        setIsLoading(true);
        setImportIdPharm(response.import_id);
        let mock = { progress: 10 };
        setImportType(IMPORT_TYPE_INDEPENDENT);
        setTimeout(updateProgress.bind(null, response.import_id, mock), 5000);
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error.response && error.response.data
            ? error.response.data.description_es
            : "Ocurrió un error al realizar la importación",
        );
      });
  };

  const handleSelectFileClick = () => {
    if (!validFilters(sheetFilters)) return;
    hiddenFileInput.current.click();
  };

  const handleDateChange = (date, setFilters, filters) => {
    var dateOnLastDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setFilters({
      ...filters,
      period: getDateFormatted(dateOnLastDay),
    });
  };

  const handleDateChangeReprocess = (date, setFilters) => {
    var dateOnLastDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setFilters(getDateFormatted(dateOnLastDay));
  };

  const handleChange = (event, setField, setFilters, filters) => {
    setField(event.target.name);
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  /* Fills some field name options */
  const getPharmaGroups = () => {
    dbGet("pharmagroups").then((data) => {
      data = data.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        // a must be equal to b
        return 0;
      });
      let mapped = data.map((item) => {
        return { id: item["id"], name: item["name"] };
      });
      setAllPharmaGroups(mapped);
    });
  };

  useEffect(() => {
    getPharmaGroups();
  }, []);

  return (
    <>
      <div>
        <PageTitle
          title="Importar empadronamientos o ventas"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        <CustomizedSnackbars
          severity={snackbarStatus.severity}
          mssg={snackbarStatus.message}
          open={snackbarStatus.open}
          onClose={onCloseSnackbar}
          duration={0}
        />
      </div>

      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justifyContent="space-evenly"
        spacing={4}
        lg={12}
        xs={12}
      >
        <Grid item xs={12} lg={12} spacing={3}>
          <div className={classes.smallItems}>
            <Typography variant="h3" className={classes.text}>
              Importar independientes
            </Typography>
          </div>
          <Divider style={{ width: "20%" }} />
        </Grid>
        <Grid container justifyContent="flex-start" item xs={12} lg={6} md={8}>
          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Período
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} className={classes.alignLeft}>
            <DateField
              views={["year", "month"]}
              minDate={new Date("2013-10-01")} // TODO: es esta la fecha?
              maxDate={getCurrentDate()}
              format={"MM/yyyy"}
              value={filters.period ? filters.period : getCurrentDate()}
              onChange={(date) => {
                handleDateChange(date, setFilters, filters);
              }}
              fieldClassName={classes.dateSelectionField}
            />
          </Grid>
          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Tipo
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} className={classes.alignLeft}>
            <SelectField
              fieldClassName={classes.generalSelectionField}
              id="types"
              label="Seleccione"
              name="type"
              value={selectedType ? filters[selectedType] : ""}
              onChange={(e) => {
                handleChange(e, setSelectedType, setFilters, filters);
              }}
              allValues={types}
            />
          </Grid>
          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Programa
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} className={classes.alignLeft}>
            <ProgramField
              fieldClassName={classes.generalSelectionField}
              GES={false}
              Salco={false}
              value={selectedProgram ? filters[selectedProgram] : ""}
              onChange={(e) => {
                handleChange(e, setSelectedProgram, setFilters, filters);
              }}
              burnable={true}
            />
          </Grid>
          <Grid item xs={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Cliente
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.alignLeft}>
            <DistributorsField
              fieldClassName={classes.generalSelectionField}
              value={selectedDistributor ? filters[selectedDistributor] : ""}
              onChange={(e) => {
                handleChange(e, setSelectedDistributor, setFilters, filters);
              }}
              all={true}
              independents={true}
              filterByDistributor={true}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ display: "flex", margin: 0 }}
          justifyContent="space-evenly"
          item
          xs={12}
          lg={8}
          md={6}
          spacing={3}
        >
          <Grid item xs={6} lg={5} className={classes.realCenter}>
            {isLoading && importType === IMPORT_TYPE_INDEPENDENT ? (
              <CircularProgress size={26} className={classes.submitLoader} />
            ) : (
              <Button
                className={classes.sendField}
                variant="contained"
                color="primary"
                onClick={makeImport}
              >
                Importar
              </Button>
            )}
          </Grid>
          {isLoading && importType === IMPORT_TYPE_INDEPENDENT ? (
            <LinearProgressWithLabel
              value={progress.progress}
              label={statusConvertion[progress.status]}
              variant={
                progress.status?.includes("Saving")
                  ? "indeterminate"
                  : "determinate"
              }
              className={classes.linearProgressField}
            />
          ) : (
            <div style={{ marginBottom: "0.5%" }} />
          )}
          {importIdPharm && importType === IMPORT_TYPE_INDEPENDENT ? (
            <Grid
              item
              xs={12}
              lg={12}
              style={{ marginLeft: "-6%" }}
              className={classes.generalPurposeItems}
            >
              <div className={classes.sendItem} style={{ marginBottom: "-3%" }}>
                <Typography variant="h5" className={classes.text}>
                  ID de importación del archivo: {importIdPharm}
                </Typography>
              </div>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>

        <Grid item xs={12} lg={12} spacing={3}>
          <div className={classes.smallItems}>
            <Typography variant="h3" className={classes.text}>
              Reprocesamiento de ventas
            </Typography>
          </div>
          <Divider style={{ width: "20%" }} />
        </Grid>
        <Grid container justifyContent="flex-start" item xs={12} lg={6} md={8}>
          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Período
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} className={classes.alignLeft}>
            <DateField
              views={["year", "month"]}
              minDate={new Date("2013-10-01")} // TODO: es esta la fecha?
              maxDate={getCurrentDate()}
              format={"MM/yyyy"}
              value={selectedReprocessPeriod}
              onChange={(date) => {
                handleDateChangeReprocess(date, setSelectedReprocessPeriod);
              }}
              fieldClassName={classes.dateSelectionField}
            />
          </Grid>
          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Programa
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} className={classes.alignLeft}>
            <ProgramField
              fieldClassName={classes.generalSelectionField}
              GES={false}
              Salco={false}
              value={selectedReprocessProgram}
              onChange={(e) => setSelectedReprocessProgram(e.target.value)}
            />
          </Grid>

          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Tipo
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} className={classes.alignLeft}>
            <SelectField
              fieldClassName={classes.generalSelectionField}
              id="reprocessType"
              label="Seleccione"
              name="reprocessType"
              value={showDistributors}
              onChange={(e) => {
                setShowDistributors(e.target.value);
              }}
              allValues={[
                { name: "Distribuidor", id: 1 },
                { name: "Grupo", id: 2 },
              ]}
            />
          </Grid>

          {showDistributors === 1 ? (
            <>
              <Grid item xs={4} md={4} className={classes.alignRight}>
                <Typography className={classes.alignedText} weight="bold">
                  Distribuidores
                </Typography>
              </Grid>
              <Grid item xs={8} md={8} className={classes.alignLeft}>
                <DistributorsField
                  fieldClassName={classes.generalSelectionField}
                  all
                  value={selectedReprocessDistributor}
                  onChange={(e) =>
                    setSelectedReprocessDistributor(e.target.value)
                  }
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={4} md={4} className={classes.alignRight}>
                <Typography className={classes.alignedText} weight="bold">
                  Grupo
                </Typography>
              </Grid>
              <Grid item xs={8} md={8} className={classes.alignLeft}>
                <SelectField
                  fieldClassName={classes.generalSelectionField}
                  id="groups"
                  label="Seleccione"
                  name="groups"
                  value={selectedReprocessPharmaGroup}
                  onChange={(e) => {
                    setSelectedReprocessPharmaGroup(e.target.value);
                  }}
                  allValues={allPharmaGroups}
                />
              </Grid>
            </>
          )}
        </Grid>

        {reprocessIsLoading ? (
          <></>
        ) : (
          <Grid
            container
            style={{ display: "flex", margin: 0 }}
            justifyContent="space-evenly"
            item
            xs={12}
            lg={8}
            md={6}
            spacing={3}
          >
            <Grid item xs={6} lg={5} className={classes.realCenter}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.submitLoader} />
              ) : (
                <>
                  <Button
                    className={classes.sendField}
                    variant="contained"
                    color="primary"
                    onClick={generate}
                  >
                    Generar
                  </Button>
                  <Button
                    className={classes.sendField}
                    variant="contained"
                    color="primary"
                    onClick={reprocess}
                  >
                    Reprocesar
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        )}

        {reprocessIsLoading ? (
          <LinearProgressWithLabel
            value={progress.progress}
            label={statusConvertion[progress.status]}
            variant={
              progress.status?.includes("Saving")
                ? "indeterminate"
                : "determinate"
            }
            className={classes.linearProgressField}
          />
        ) : (
          <div style={{ marginBottom: "0.5%" }} />
        )}

        <Grid item xs={12} lg={12} spacing={3}>
          <Divider />
        </Grid>
        <Grid item xs={12} lg={12} spacing={3}>
          <div className={classes.smallItems}>
            <Typography variant="h3" className={classes.text}>
              Importar por planilla
            </Typography>
          </div>
          <Divider style={{ width: "20%" }} />
        </Grid>
        <Grid container justifyContent="flex-start" item xs={12} lg={6} md={8}>
          <Grid
            item
            xs={12}
            md={12}
            className={classes.alignRight}
            style={{ marginTop: "-20px" }}
          >
            <div className={classes.generalPurposeItems}>
              <Typography variant="h4" className={classes.text}>
                Complete todas las columnas de la planilla
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Período
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} className={classes.alignLeft}>
            <DateField
              views={["year", "month"]}
              minDate={new Date("2013-10-01")} // TODO: es esta la fecha?
              maxDate={getCurrentDate()}
              format={"MM/yyyy"}
              value={
                sheetFilters.period ? sheetFilters.period : getCurrentDate()
              }
              onChange={(date) => {
                handleDateChange(date, setSheetFilters, sheetFilters);
              }}
              fieldClassName={classes.dateSelectionField}
            />
          </Grid>
          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Tipo
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} className={classes.alignLeft}>
            <SelectField
              fieldClassName={classes.generalSelectionField}
              id="types"
              label="Seleccione"
              name="type"
              value={selectedSheetType ? sheetFilters[selectedSheetType] : ""}
              onChange={(e) => {
                handleChange(
                  e,
                  setSelectedSheetType,
                  setSheetFilters,
                  sheetFilters,
                );
              }}
              allValues={types}
            />
          </Grid>
          <Grid item xs={4} md={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Programa
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} className={classes.alignLeft}>
            <ProgramField
              fieldClassName={classes.generalSelectionField}
              burnable
              GES={sheetFilters[selectedSheetType] === 1 ? false : true}
              Salco={sheetFilters[selectedSheetType] === 1 ? false : true}
              value={
                selectedSheetProgram ? sheetFilters[selectedSheetProgram] : ""
              }
              onChange={(e) => {
                handleChange(
                  e,
                  setSelectedSheetProgram,
                  setSheetFilters,
                  sheetFilters,
                );
              }}
            />
          </Grid>
          <Grid item xs={4} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Cliente
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.alignLeft}>
            <DistributorsField
              fieldClassName={classes.generalSelectionField}
              independents={false}
              filterByDistributor={true}
              program={sheetFilters.program}
              value={
                selectedSheetDistributor
                  ? sheetFilters[selectedSheetDistributor]
                  : ""
              }
              onChange={(e) => {
                handleChange(
                  e,
                  setSelectedSheetDistributor,
                  setSheetFilters,
                  sheetFilters,
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={12}
          md={8}
          spacing={3}
          className={classes.alignCenter}
          style={{ marginTop: "3%" }}
        >
          <Grid item xs={3} lg={2} className={classes.fileExtensionText}>
            <Typography className={classes.alignedText} weight="bold">
              Archivo (*.xls, *.xlsx)
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            lg={3}
            className={classes.generalPurposeItems}
            style={{ marginTop: "1%" }}
          >
            <div className={classes.sendItem} style={{ marginBottom: "1%" }}>
              <TextField // TODO Pending: Cambiar y Remover buttons. Necessary?
                id="fileSelected"
                className={classes.fileUploadField}
                disabled
                value={selectedFile ? selectedFile.name : ""}
                variant="outlined"
                margin="dense"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DescriptionIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {isLoading && importType === IMPORT_TYPE_SHEET ? (
                <LinearProgressWithLabel
                  value={progress.progress}
                  label={statusConvertion[progress.status]}
                  variant={
                    progress.status?.includes("Saving")
                      ? "indeterminate"
                      : "determinate"
                  }
                  className={classes.linearProgressField}
                />
              ) : (
                <div style={{ marginBottom: "0.5%" }} />
              )}
            </div>
          </Grid>
          <Grid item xs={4} lg={2} className={classes.generalPurposeItems}>
            <Button
              className={classes.uploadButton}
              disableElevation
              variant="contained"
              color="primary"
              onClick={handleSelectFileClick}
              disabled={isLoading}
            >
              Seleccionar archivo
            </Button>
            <input
              type="file"
              onChange={selectFile}
              style={{ display: "none" }}
              ref={hiddenFileInput}
            />
          </Grid>
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                color="primary"
                onChange={(event) => setAreIDs(event.target.checked)}
              />
            }
            label={
              <>
                <Typography>
                  Son <strong>IDs</strong>, no RUTs
                </Typography>
              </>
            }
            weight="bold"
          />
          {importIdFile && importType === IMPORT_TYPE_SHEET ? (
            <Grid item xs={12} lg={12} className={classes.sendItem}>
              <div className={classes.sendItem}>
                <Typography variant="h5" className={classes.text}>
                  ID de importación del archivo: {importIdFile}
                </Typography>
              </div>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={4}
          md={6}
          spacing={3}
          className={classes.alignCenter}
        >
          <Grid item xs={6} lg={5} className={classes.realCenter}>
            {isLoading && importType === IMPORT_TYPE_SHEET ? (
              <CircularProgress size={26} className={classes.submitLoader} />
            ) : (
              <Button
                className={classes.sendField}
                disabled={!selectedFile || isLoading}
                variant="contained"
                color="primary"
                onClick={upload}
              >
                Enviar
              </Button>
            )}
          </Grid>
          <Grid item xs={6} lg={5}>
            <Button
              className={classes.sendField}
              disabled={isLoading}
              variant="contained"
              color="secondary"
              onClick={() => {
                setSelectedFile(undefined);
                window.location.reload();
              }}
            >
              Limpiar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
