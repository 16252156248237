import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";
// styles
import useStyles from "./styles";

import { dbGet } from "utils/DBFetchers.js";
import DateField from "components/CustomFields/DateField";

import { format } from "date-fns";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import LoaderBar from "components/LoaderBar/LoaderBar";

export default function ABMPresentationsPage() {
  const history = useHistory();
  var [isLoading, setIsLoading] = useState(false);
  // EDIT PRESENTATION
  const RedirectToEditPresentation = (id) => {
    history.push(routes.abmProductsEdit, { productId: id });
  };
  // NEW PRESENTATION
  const RedirectToNewPresentation = () => {
    history.push(routes.abmProductsNew);
  };

  // DATE FILTER
  const [dateFilter, setDateFilter] = React.useState(null);
  const handleApplyDateFilter = () => {
    if (!dateFilter) return;
    setTableData([]);
    getResults(
      `presentations?on_date=${format(new Date(dateFilter), "dd/MM/yy")}`,
    );
  };

  // Table columns definition
  const columns = [
    {
      name: " ",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return { style: { fontWeight: "bold", minWidth: 100, width: 100 } };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    RedirectToEditPresentation(tableMeta.rowData[1]); // presentation_id
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "Id",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "Nombre",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "SKU",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Lista actual ($)",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Válido desde",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
        sortCompare: (order) => {
          return (a, b) => {
            var dateSplitA = a.data.split("/");
            var dateSplitB = b.data.split("/");
            const dateA = new Date(
              dateSplitA[2],
              dateSplitA[1],
              dateSplitA[0],
            ).getTime();
            const dateB = new Date(
              dateSplitB[2],
              dateSplitB[1],
              dateSplitB[0],
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === "desc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "GES Actual ($)",
      options: {
        filter: false,
        display: "excluded",
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Válido desde",
      options: {
        filter: false,
        display: "excluded",
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
        sortCompare: (order) => {
          return (a, b) => {
            var dateSplitA = a.data.split("/");
            var dateSplitB = b.data.split("/");
            const dateA = new Date(
              dateSplitA[2],
              dateSplitA[1],
              dateSplitA[0],
            ).getTime();
            const dateB = new Date(
              dateSplitB[2],
              dateSplitB[1],
              dateSplitB[0],
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === "desc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "Lista próx. ($)",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Válido desde",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
        sortCompare: (order) => {
          return (a, b) => {
            var dateSplitA = a.data.split("/");
            var dateSplitB = b.data.split("/");
            const dateA = new Date(
              dateSplitA[2],
              dateSplitA[1],
              dateSplitA[0],
            ).getTime();
            const dateB = new Date(
              dateSplitB[2],
              dateSplitB[1],
              dateSplitB[0],
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === "desc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "GES próx. ($)",
      options: {
        filter: false,
        display: "excluded",
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Válido desde",
      options: {
        filter: false,
        display: "excluded",
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
        sortCompare: (order) => {
          return (a, b) => {
            var dateSplitA = a.data.split("/");
            var dateSplitB = b.data.split("/");
            const dateA = new Date(
              dateSplitA[2],
              dateSplitA[1],
              dateSplitA[0],
            ).getTime();
            const dateB = new Date(
              dateSplitB[2],
              dateSplitB[1],
              dateSplitB[0],
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === "desc" ? 1 : -1);
          };
        },
      },
    },
  ];

  var classes = useStyles();
  const [tableData, setTableData] = useState([]);

  /* Fills table data */
  const fillTable = (data) => {
    if (!data) return;
    var tableData = data.map((row) => [
      "",
      row.id,
      `${row.name}${row.notes ? ` - ${row.notes}` : ""}`,
      row.sku,
      row.list_price ? row.list_price.value : "",
      row.list_price
        ? row.list_price.from_date
          ? row.list_price.from_date
          : ""
        : "",
      row.ges_price ? row.ges_price.value : "",
      row.ges_price
        ? row.ges_price.value
          ? row.ges_price.from_date
            ? row.ges_price.from_date
            : ""
          : ""
        : "",
      row.next_list_price ? row.next_list_price.value : "",
      row.next_list_price
        ? row.next_list_price.from_date
          ? row.next_list_price.from_date
          : ""
        : "",
      row.next_ges_price ? row.next_ges_price.value : "",
      row.next_ges_price
        ? row.next_ges_price.from_date
          ? row.next_ges_price.from_date
          : ""
        : "",
    ]);
    setTableData(tableData);
  };

  /* Get all data */
  const getResults = (category) => {
    setIsLoading(true);
    dbGet(category)
      .then((data) => {
        setIsLoading(false);
        fillTable(data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getResults("presentations");
  }, []);

  return (
    <>
      <div>
        <PageTitle
          title="Lista de precios"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={RedirectToNewPresentation}
          startIcon={<AddIcon />}
        >
          Añadir
        </Button>
      </div>
      <div className={classes.divDateFilter}>
        <DateField
          name="dateFilter"
          format={"dd/MM/yyyy"}
          value={dateFilter}
          onChange={setDateFilter}
          fieldClassName={classes.dateFilterField}
          placeholder="Fecha"
        />
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonDateFilter}
          onClick={handleApplyDateFilter}
        >
          Ver en fecha
        </Button>
        <Button
          color="secondary"
          variant="contained"
          className={classes.buttonDateFilter}
          onClick={() => {
            setDateFilter(null);
            setTableData([]);
            getResults("presentations");
          }}
        >
          Limpiar
        </Button>
      </div>
      {isLoading ? (
        <LoaderBar />
      ) : (
        <Grid
          style={{ display: "flex", margin: 0 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
        >
          <Grid container spacing={3} xs={12}>
            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <CustomMUIDataTable
                title="Lista de precios"
                data={tableData}
                columns={columns}
                filterStart={2}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
