import React from "react";
import {
  Grid,
  Divider,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import RutField from "components/CustomFields/RutField.js";
import Field from "components/CustomFields/Field.js";
import ReactPhoneInput from "react-phone-input-mui";

// styles
import useStyles from "./styles.js";
import ProductPicker from "components/ProductPicker/ProductPicker.js";
import NumberNoSeparatorFormatter from "components/CustomFields/NumberNoSeparatorFormatter.js";
import HyperText from "components/HyperText/HyperText.js";
import SubmitButton from "components/SubmitButton/SubmitButton.js";

export default function AccreditCouponForm(props) {
  var classes = useStyles();
  let fieldNameSpace = 5;
  let fieldValueSpace = 12 - fieldNameSpace;

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          lg={12}
          style={{ marginBottom: "15px", marginLeft: "2.5%" }}
        >
          <Typography variant="h4" className={classes.alignedText}>
            {props.title}
          </Typography>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <RutField
            labelName={"RUT * (sin guión)"}
            required={true}
            id="rut"
            id2="rutDV"
            value={props.patient.rut}
            value2={props.patient.rutDV}
            fieldClassType={classes.rutVerifierField}
            fieldClassType2={classes.digitVerifierField}
            error={!props.isRutValid.rut}
            helperText={props.isRutValid.message}
            onChange={props.onRutChange}
            onChange2={props.onDVChange}
          />
        </Grid>

        {props.enabledEmail ? (
          <>
            <Grid item xs={12} className={classes.alignCenter}>
              <div>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Email *
                </Typography>
                <TextField
                  required={true}
                  style={{ width: "310px" }}
                  name="email"
                  id="email"
                  value={props.patient.email}
                  onChange={props.onEmailChange}
                />
              </div>
            </Grid>

            <Grid item xs={fieldNameSpace}></Grid>
          </>
        ) : (
          <>
            <Grid item xs={fieldNameSpace} className={classes.alignRight}>
              <Typography className={classes.alignedText} weight="bold">
                Teléfono *
              </Typography>
            </Grid>
            <Grid item xs={fieldValueSpace} className={classes.alignLeft}>
              <ReactPhoneInput
                required={true}
                id="phone"
                name="phone"
                defaultCountry="cl"
                value={props.patient.phone}
                onChange={props.onPhoneChange}
                component={TextField}
                inputExtraProps={{
                  helperText: "En este número recibirá un SMS con el cupón.",
                }}
              />
            </Grid>

            <Grid item xs={fieldNameSpace}></Grid>
            <Grid item xs={fieldValueSpace}>
              <HyperText
                divClassName={classes.emailPhoneChangerText}
                onClick={props.handleEnableEmail}
                text={"¿No tiene teléfono?"}
              />
            </Grid>
          </>
        )}

        {props.programId === '2' && (
          <Grid
            item
            xs={fieldNameSpace + fieldValueSpace}
            className={classes.productPickerContainer}
            style={{ minHeight: 475 }}
          >
            <ProductPicker
              // rut={props.patient.rut}
              isMobile={props.isMobile}
              titleClassName={classes.alignRight}
              titleFieldSpace={fieldNameSpace}
              selectorClassName={classes.alignLeft}
              selectorFieldSpace={fieldValueSpace}
              availableProducts={props.allOptions}
              updateFormOnSelection={props.updateFormOnSelection}
              maxAvailableSelectedOptions={props.maxAvailableSelectedOptions}
              checkboxSelection={true}
            />
          </Grid>
        )}

        <Grid item xs={12} className={classes.uploadFormButton}>
          <SubmitButton
            isLoading={props.sending}
            buttonClassName={classes.sendField}
            loadingClassName={classes.loadingField}
            disabled={props.disabled}
            onClick={props.upload}
            buttonText={"Enviar"}
          />
        </Grid>
      </Grid>
    </>
  );
}
