import React, { useState } from "react";
import Field from "./Field";

// styles
import useStyles from "./styles";

export default function EmailField(props) {
  var classes = useStyles();

  const required = props.required ? props.required : false;
  const labelName = "Email" + (required ? " *" : "");

  return (
    <>
      <Field
        labelName={labelName}
        required={required}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        divClassType={props.divClassType}
        fieldClassType={props.fieldClassType}
        error={props.error}
        InputProps={props.InputProps}
      ></Field>
    </>
  );
}
