import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Autocomplete } from "@material-ui/lab";
import {
  Button,
  CircularProgress,
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@material-ui/core";

import { Typography } from "components/Wrappers/Wrappers";
import logo from "images/logoPagesRS.jpg";

import SelectField from "components/CustomFields/SelectField.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

import { dbGet, dbPost } from "utils/DBFetchers.js";
import { useHistory } from "react-router-dom";
import routes from "routes";

// styles
import useStyles from "./styles";

const blankNewRelationshipForm = {
  pharmacyId: null,
  distributorId: null,
  hasHistoricDistributor: true,
};

export default function ABMPharmacyDistributorNewPage(props) {
  var classes = useStyles();
  const history = useHistory();
  var [isLoading, setIsLoading] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const [newRelationshipForm, updateForm] = useState(blankNewRelationshipForm);
  const [allDistributors, setAllDistributors] = useState([]);
  const [allPharmacies, setAllPharmacies] = useState([]);
  const [pageLoad, setPageLoad] = useState(false);
  let [startRelationNow, setStartRelationNow] = useState(false);

  const handleChange = (event) => {
    updateForm({
      ...newRelationshipForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleAutoCompletePharmacyChange = (event, newValue) => {
    updateForm({
      ...newRelationshipForm,
      pharmacyId: newValue ? newValue.id : null,
      hasHistoricDistributor:
        newValue &&
        newValue.distributor &&
        Object.keys(newValue.distributor).length === 0
          ? false
          : true,
    });
    if (!newValue) setStartRelationNow(false);
  };
  const handleStartRelationNow = () => {
    setStartRelationNow(!startRelationNow);
  };

  /* Fills some field name options */
  const getDistributors = () => {
    dbGet("distributors").then((data) => {
      data = data.filter((x) =>
        x.programs.find((y) => y.id === +localStorage.getItem("programId")),
      );
      data = data.filter((x) => x.works_with_independents && x.id !== 19);
      let mapped = data.map((item) => {
        return {
          id: item["id"],
          name: item["name"],
          isAuthoritarian: item["is_authoritarian"],
        };
      });
      setAllDistributors(mapped);
    });
  };
  const getPharmacies = () => {
    dbGet("pharmacies").then((data) => {
      data = data.filter((x) =>
        x.programs.find((y) => y.id === +localStorage.getItem("programId")),
      );
      let mapped = data.map((item) => {
        return {
          id: item["id"],
          name: `${item["name"]} - ${item["code"]}`,
          distributor:
            item["distributors"][+localStorage.getItem("programId") - 1],
        };
      });
      setAllPharmacies(mapped);
    });
  };

  /* Basic form check */
  const checkFormComplete = () => {
    if (!newRelationshipForm.pharmacyId || !newRelationshipForm.distributorId)
      return false;
    return true;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder añadir la relación.",
      );
      return;
    }
    setIsLoading(true);

    // Give adecuate format to send form to db
    let completedForm = {
      pharmacy_id: newRelationshipForm.pharmacyId,
      distributor_id: newRelationshipForm.distributorId,
      program_id: localStorage.getItem("programId"),
    };

    // Sends to db
    dbPost(
      `pharmacies_distributors?starts_today=${startRelationNow ? 1 : 0}`,
      completedForm,
    )
      .then((data) => {
        snackbarOpen("success", "Relación añadida exitosamente");
        redirectToPharmacyDistributorsPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0]
            ? error[0].description_es
            : "Error al iniciar relación farmacia-distribuidor",
        ); // translate pending
        setIsLoading(false);
        return;
      });
  };

  /* Cancel adding relationship and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankNewRelationshipForm);
    history.goBack();
  };

  /* Redirect to relationships table page after adding correctly */
  const redirectToPharmacyDistributorsPage = (user) => {
    setTimeout(() => {
      updateForm(blankNewRelationshipForm);
      setIsLoading(false);
      history.push(routes.abmPharmacyDistributors);
    }, 3000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getDistributors();
    getPharmacies();
    setPageLoad(true);
  }, []);

  const title = `Nueva relación farmacia-distribuidor. Programa: ${
    localStorage.getItem("programId") === "1"
      ? "Receta Solidaria"
      : "Solo Mujer"
  }`;

  // Program switch logic
  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  const updateTableDataOnProgramIdChange = () => {
    getDistributors();
    getPharmacies();
  };
  React.useEffect(() => {
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);
  React.useEffect(() => {
    if (!pageLoad) return; // no fetch on page load
    updateTableDataOnProgramIdChange();
  }, [programId]);

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />

      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Participantes de la relación
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  A continuación ingrese farmacia y distribuidor que estarán
                  relacionados a partir del proximo mes.
                </Typography>
              </div>
              <div style={{ marginTop: "4%" }}>
                <div className={classes.generalPurposeItems}>
                  <Typography className={classes.text} weight="bold">
                    Farmacia *
                  </Typography>
                  <Autocomplete
                    freeSolo
                    className={classes.generalPurposeField}
                    options={allPharmacies ? allPharmacies : []}
                    onChange={handleAutoCompletePharmacyChange}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        className={classes.selectionTextEnabled}
                        {...params}
                        placeholder="Seleccione"
                        margin="dense"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleStartRelationNow}
                      disabled={newRelationshipForm.hasHistoricDistributor}
                    />
                  }
                  label={
                    <>
                      <Typography>
                        Iniciar relación ahora
                        <strong> (sólo si nunca tuvo distribuidor)</strong>
                      </Typography>
                    </>
                  }
                  weight="bold"
                  labelPlacement="end"
                  className={classes.relationCheckbox}
                />

                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  displayText="Distribuidor *"
                  id="distributorId"
                  label="Seleccione"
                  name="distributorId"
                  value={newRelationshipForm.distributorId}
                  onChange={handleChange}
                  allValues={allDistributors ? allDistributors : []}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelAndGoBack}
                >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
