import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(2),
  },
  buttonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    marginLeft: "15em",
    marginTop: "2%",
    marginBottom: "5%",
  },
  onlyButtonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
    height: 15,
    alignItem: "center",
    marginBottom: "5%",
  },
  popupButtonSpacing: {
    margin: theme.spacing(1),
  },
  popupButtonSpacingDelete: {
    margin: theme.spacing(1),
    backgroundColor: "red",
    color: "white",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  popupButtonSpacingEnable: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    color: "white",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  popupDivCenter: {
    display: "flex",
    justifyContent: "flex-end",
  },
  generalPurposeItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "1%",
    width: 550,
    justifyContent: "space-between",
  },
  smallItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "1%",
    width: 350,
    justifyContent: "space-between",
  },
  generalPurposeField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-1%",
    width: 350,
  },
  smallField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  selectorField: {
    marginRight: theme.spacing(1),
    marginTop: "-1%",
    width: 170,
  },
  searchField: {
    width: 500,
    marginTop: "-1%",
    marginBottom: "2%",
  },
  smallFieldValidation: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "#fff",
    height: 25,
    marginTop: "-7%",
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    height: 25,
    marginTop: "-7%",
  },
  logotypeImage: {
    width: 157,
    height: 60,
    marginTop: "-1%",
  },
  loaderText: {
    marginTop: "5%",
    marginBottom: "-1%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    marginTop: "5%",
    marginBottom: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
