import { Card, Paper } from "@mui/material";
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BlockIcon from "@mui/icons-material/Block";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import { Typography } from "components/Wrappers/Wrappers";
import WorkIcon from "@mui/icons-material/Work";
import useStyles from "../styles";
import { useTheme } from "@material-ui/styles";

const DEFAULT_CARDS_DATA = {
  coupons_to_medics: 0,
  coupons_to_consultants: 0,
  converted_coupons: 0,
  rejected_coupons: 0,
};

const formatNumber = (value) => {
  return value
    ? Intl.NumberFormat("es-AR").format(Math.round(value * 100) / 100)
    : 0;
};

const formatPercentage = (value) => {
  return (value * 100).toFixed(0) + "%";
};

export default function SummaryCards(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [cardsData, setCardsData] = useState(DEFAULT_CARDS_DATA);

  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    textAlign: "center",
    borderRadius: "20px",
    padding: theme.spacing(5, 10),
    color: "#7640B3",
    backgroundColor: "#E4D1FC",
  }));
  const RootStyle2 = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    textAlign: "center",
    borderRadius: "20px",
    padding: theme.spacing(5, 12),
    color: "#3E62AD",
    backgroundColor: "#D0F2FF",
  }));
  const RootStyle3 = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    textAlign: "center",
    borderRadius: "20px",
    padding: theme.spacing(5, 13),
    color: "#99742F",
    backgroundColor: "#FFF7CD",
  }));
  const RootStyle4 = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    textAlign: "center",
    borderRadius: "20px",
    padding: theme.spacing(5, 10),
    color: "#6e4a45",
    backgroundColor: "#FABFB7",
  }));
  const RootStyle5 = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    textAlign: "center",
    borderRadius: "20px",
    padding: theme.spacing(5, 8),
    color: "#6e4a45",
    backgroundColor: "#FFDFC3",
  }));
  const IconWrapperStyle = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "30%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.dark,
    backgroundImage: `linear-gradient(135deg, ${alpha(
      theme.palette.primary.dark,
      0,
    )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
  }));
  const IconWrapperStyle2 = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "30%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    color: "#8046C2",
    backgroundImage: `linear-gradient(135deg, ${alpha(
      "#8046C2",
      0,
    )} 0%, ${alpha("#8046C2", 0.24)} 100%)`,
  }));
  const IconWrapperStyle3 = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "30%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    color: theme.palette.warning.dark,
    backgroundImage: `linear-gradient(135deg, ${alpha(
      theme.palette.warning.dark,
      0,
    )} 0%, ${alpha(theme.palette.warning.dark, 0.24)} 100%)`,
  }));
  const IconWrapperStyle4 = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "30%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    color: "#6e4a45",
    backgroundImage: `linear-gradient(135deg, ${alpha(
      "#6e4a45",
      0,
    )} 0%, ${alpha("#6e4a45", 0.24)} 100%)`,
  }));

  const IconWrapperStyle5 = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "30%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    color: "#6e4a45",
    backgroundImage: `linear-gradient(135deg, ${alpha(
      "#6e4a45",
      0,
    )} 0%, ${alpha("#6e4a45", 0.24)} 100%)`,
  }));
  useEffect(() => {
    setCardsData(assembleCardsData(props.information));
  }, []);

  const assignCardInfo = (value1, value2) => {
    let formatedValue1 = formatNumber(value1);
    let percentage = value2 === 0 ? "-" : formatPercentage(value1 / value2);
    return {
      value: formatedValue1,
      percentage: percentage,
    };
  };

  const assembleCardsData = (information) => {
    return {
      coupons_to_consultants: formatNumber(information.coupons_to_consultants),
      coupons_to_medics: assignCardInfo(
        information.coupons_to_medics.total.delivered,
        information.coupons_to_consultants,
      ),
      converted_coupons: assignCardInfo(
        information.converted.total,
        information.coupons_to_medics.total.delivered,
      ),
      rejected_coupons: formatNumber(information.rejected_coupons),
      inscriptions_informed: formatNumber(information.inscriptions_informed),
    };
  };

  return (
    <Grid item container spacing={2}>
      <Grid
        item
        xs={2.4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <RootStyle>
          <IconWrapperStyle2>
            <WorkIcon />
          </IconWrapperStyle2>
          <Typography variant="h3">
            {cardsData.coupons_to_consultants || 0}
          </Typography>
          <Typography variant="subtitle1">-</Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Cupones a consultores
          </Typography>
        </RootStyle>
      </Grid>
      <Grid
        item
        xs={2.4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <RootStyle2>
          <IconWrapperStyle>
            <MedicalServicesIcon />
          </IconWrapperStyle>
          <Typography variant="h3">
            {cardsData.coupons_to_medics["value"] || 0}
          </Typography>
          <Typography variant="subtitle1">
            {cardsData.coupons_to_medics["percentage"] || 0}
          </Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Cupones a médicos
          </Typography>
        </RootStyle2>
      </Grid>
      <Grid
        item
        xs={2.4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <RootStyle3>
          <IconWrapperStyle3>
            <ReceiptIcon />
          </IconWrapperStyle3>
          <Typography variant="h3">
            {cardsData.converted_coupons["value"] || 0}
          </Typography>
          <Typography variant="subtitle1">
            {cardsData.converted_coupons["percentage"] || 0}
          </Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Conversiones
          </Typography>
        </RootStyle3>
      </Grid>
      <Grid
        item
        xs={2.4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <RootStyle5>
          <IconWrapperStyle5>
            <SafetyDividerIcon />
          </IconWrapperStyle5>
          <Typography variant="h3">
            {cardsData.inscriptions_informed || 0}
          </Typography>
          <Typography variant="subtitle1">-</Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Informados de Salesforce
          </Typography>
        </RootStyle5>
      </Grid>

      <Grid
        item
        xs={2.4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <RootStyle4>
          <IconWrapperStyle4>
            <BlockIcon />
          </IconWrapperStyle4>
          <Typography variant="h3">
            {cardsData.rejected_coupons || 0}
          </Typography>
          <Typography variant="subtitle1">-</Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Cupones rechazados
          </Typography>
        </RootStyle4>
      </Grid>
    </Grid>
  );
}
