import { useState } from "react";

/* 
Snackbar popup handler to inform user of actions.
Provides status, open handler, close handler.
*/
export const SnackbarHandler = () => {
  const [snackbarStatus, setSnackbarStatus] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const onCloseSnackbar = (event, reason) => {
    setSnackbarStatus({ ...snackbarStatus, open: false });
  };
  const snackbarOpen = (severity, message) => {
    setSnackbarStatus({
      open: true,
      severity: severity,
      message: message,
    });
  };

  return [
    snackbarStatus,
    (severity, message) => {
      snackbarOpen(severity, message);
    },
    () => {
      onCloseSnackbar();
    },
  ];
};
