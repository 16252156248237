import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, CircularProgress, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Typography } from "components/Wrappers/Wrappers";

import Field from "components/CustomFields/Field.js";
import SelectField from "components/CustomFields/SelectField.js";
import RutField from "components/CustomFields/RutField.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { dbGet, dbPost } from "utils/DBFetchers.js";
import { verifyRut } from "utils/rutValidations";
import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";
import { cleanEmptyKeys } from "utils/cleanEmptyKeys";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

const blankNewPharmacyForm = {
  rut: "",
  rutVerifier: "",
  code: "",
  name: "",
  company: "",
  group_id: "",
  address: "",
  commune_id: "",
  email: "",
  phone: "",
  contact: "",
  programs: null,
};

const allPrograms = [
  { id: 1, name: "Receta solidaria" },
  { id: 2, name: "Solo mujer" },
  { id: 3, name: "Ambos" },
];

const formattedPrograms = {
  1: [1],
  2: [2],
  3: [1, 2],
};

export default function ABMPharmaciesNewPage(props) {
  var classes = useStyles();
  const history = useHistory();
  var [isLoading, setIsLoading] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const [newPharmacyForm, updateForm] = useState(blankNewPharmacyForm);
  const [allPharmacies, setAllPharmacies] = useState([]);
  const [isRutValid, setIsRutValid] = useState({ rut: false, message: "" });
  const [allPharmaGroups, setAllPharmaGroups] = useState([]);
  const [allCommunes, setAllCommunes] = useState([]);

  const handleChange = (event) => {
    updateForm({
      ...newPharmacyForm,
      [event.target.name]: event.target.value,
    });
  };
  const handleAutoCompleteCommuneChange = (event, newValue) => {
    updateForm({
      ...newPharmacyForm,
      commune_id: newValue ? newValue.id : null,
    });
  };

  const handleRutVerification = () => {
    var currentRut = newPharmacyForm.rut + newPharmacyForm.rutVerifier;
    var rutValid = verifyRut(currentRut);

    if (rutValid && !newPharmacyForm.rutVerifier)
      setIsRutValid({
        rut: false,
        message: "Ingrese el dígito verificador en el campo respectivo.",
      });
    else if (!rutValid) setIsRutValid({ rut: false, message: "RUT inválido." });
    else setIsRutValid({ rut: true, message: "" });
  };

  // Rut updaters
  const handleRutChange = (e) => {
    updateForm({
      ...newPharmacyForm,
      [e.target.id]: e.target.value.toUpperCase(),
    });
  };
  React.useEffect(() => {
    if (!newPharmacyForm.rut && !newPharmacyForm.rutVerifier) return; // no fetch on page load
    handleRutVerification();
  }, [newPharmacyForm.rut, newPharmacyForm.rutVerifier]);

  /* Fills some field name options */
  const getPharmaGroups = () => {
    dbGet("pharmagroups").then((data) => {
      data = data.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        // a must be equal to b
        return 0;
      });
      let mapped = data.map((item) => {
        return { id: item["id"], name: item["name"] };
      });
      setAllPharmaGroups(mapped);
    });
  };
  const getCommunes = () => {
    dbGet("communes").then((data) => {
      data = data.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        // a must be equal to b
        return 0;
      });
      let mapped = data.map((item) => {
        return { id: item["id"], name: item["name"] };
      });
      setAllCommunes(mapped);
    });
  };
  const getPharmacies = () => {
    dbGet("pharmacies").then((data) => {
      let mapped = data.map((item) => {
        return {
          id: item["id"],
          name: item["name"],
          rut: item["rut"],
        };
      });
      setAllPharmacies(mapped);
    });
  };

  /* Basic form check */
  const checkFormComplete = () => {
    if (
      !newPharmacyForm.name ||
      !newPharmacyForm.rut ||
      !newPharmacyForm.code ||
      !newPharmacyForm.programs ||
      !isRutValid.rut
    )
      return false;
    return true;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder añadir la farmacia.",
      );
      return;
    }
    setIsLoading(true);

    // Give adecuate format to send form to db
    let completedForm = {
      rut: newPharmacyForm.rut + newPharmacyForm.rutVerifier,
      code: newPharmacyForm.code,
      name: newPharmacyForm.name,
      company: newPharmacyForm.company,
      group_id: newPharmacyForm.group_id,
      address: newPharmacyForm.address,
      commune_id: newPharmacyForm.commune_id,
      email: newPharmacyForm.email,
      phone: newPharmacyForm.phone,
      contact: newPharmacyForm.contact,
    };

    completedForm = cleanEmptyKeys(completedForm);
    completedForm["programs"] = formattedPrograms[newPharmacyForm.programs];

    // Sends to db
    dbPost(`pharmacies`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Farmacia añadida exitosamente");
        redirectToPharmaciesPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al añadir farmacia",
        );
        setIsLoading(false);
        return;
      });
  };

  /* Cancel editing pharmacy and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankNewPharmacyForm);
    history.goBack();
  };

  /* Redirect to pharmacies table page after adding correctly */
  const redirectToPharmaciesPage = (user) => {
    setTimeout(() => {
      updateForm(blankNewPharmacyForm);
      setIsLoading(false);
      history.push(routes.abmPharmacies);
    }, 2000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getPharmacies();
    getPharmaGroups();
    getCommunes();
  }, []);

  const title = `Nueva Farmacia${newPharmacyForm.name ? ": " : ""}${
    newPharmacyForm.name ? newPharmacyForm.name : ""
  }`;

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos de la farmacia
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  A continuación ingrese los datos requeridos para añadir una
                  nueva farmacia.
                </Typography>
              </div>
              <div style={{ marginTop: "4%" }}>
                <Field
                  labelName="Nombre *"
                  required={true}
                  id="name"
                  value={newPharmacyForm.name}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <Field
                  labelName="Código de farmacia *"
                  required={true}
                  id="code"
                  value={newPharmacyForm.code}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <RutField
                  labelName="RUT * (sin guión)"
                  required={true}
                  id="rut"
                  id2="rutVerifier"
                  value={newPharmacyForm.rut}
                  divClassType={classes.smallItems2}
                  divClassType2={classes.generalPurposeItems2}
                  fieldClassType={classes.smallFieldValidation2}
                  fieldClassType2={classes.smallFieldValidation3}
                  onChange={handleRutChange}
                  helperText={isRutValid.message}
                  error={!isRutValid.rut}
                  value2={newPharmacyForm.rutVerifier}
                  onChange2={handleRutChange}
                  //InputProps1={{
                  //  inputComponent: NumberNoSeparatorFormatter,
                  //}}
                ></RutField>
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  displayText="Programa/s *"
                  required={true}
                  id="programs"
                  label="Seleccione"
                  name="programs"
                  value={newPharmacyForm.programs}
                  onChange={handleChange}
                  allValues={allPrograms ? allPrograms : []}
                />
                <Field
                  labelName="Compañia/Empresa"
                  required={true}
                  id="company"
                  value={newPharmacyForm.company}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <Field
                  labelName="Domicilio"
                  required={true}
                  id="address"
                  value={newPharmacyForm.address}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <div className={classes.generalPurposeItems}>
                  <Typography className={classes.text} weight="bold">
                    Comuna
                  </Typography>
                  <Autocomplete
                    freeSolo
                    onChange={handleAutoCompleteCommuneChange}
                    options={allCommunes ? allCommunes : []}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    className={classes.generalPurposeField}
                    renderInput={(params) => (
                      <TextField
                        className={classes.selectionTextEnabled}
                        {...params}
                        placeholder="Seleccione"
                        margin="dense"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                <Field
                  labelName="Teléfono"
                  required={true}
                  id="phone"
                  value={newPharmacyForm.phone}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <Field
                  labelName="Email"
                  required={true}
                  id="email"
                  value={newPharmacyForm.email}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <Field
                  labelName="Contacto"
                  required={true}
                  id="contact"
                  value={newPharmacyForm.contact}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  displayText="Grupo"
                  id="group_id"
                  label="Seleccione"
                  name="group_id"
                  value={newPharmacyForm.group_id}
                  onChange={handleChange}
                  allValues={allPharmaGroups ? allPharmaGroups : []}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelAndGoBack}
                >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
