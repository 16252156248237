import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";

const columns = [
  {
    name: "Médico",
    label: "Médico",
  },
  {
    name: "Cupones recibidos",
    label: "Cupones recibidos",
  },
  {
    name: "Convertidos",
    label: "Convertidos",
  },
  {
    name: "Pacientes nuevos",
    label: "Pacientes nuevos",
  },
  {
    name: "Pacientes viejos",
    label: "Pacientes viejos",
  },
  {
    name: "Categoría",
    label: "Categoría",
  },
];

const assembleTableData = (rawData) => {
  return Object.entries(rawData)
    .map(([key, value]) => {
      let medicName = `${
        value.name ? value.name.toUpperCase() : "DESCONOCIDO"
      } (${key})`;
      return [
        medicName,
        value.given_coupons,
        value.obtained_inscriptions,
        value.new_patient_inscriptions || 0,
        value.old_patient_inscriptions || 0,
        value.category || "Indefinida",
      ];
    })
    .sort((a, b) => {
      return a[2] > b[2] ? -1 : 1;
    });
};

export default function TraceabilityTable(props) {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let data = assembleTableData(props.information);
    setTableData(data);
  }, []);

  return (
    <Grid item lg={12} xs={12} md={12}>
      <CustomMUIDataTable
        titleDownload="Tabla de médicos"
        title={""}
        columns={columns}
        data={tableData}
      />
    </Grid>
  );
}
