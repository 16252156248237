import { makeStyles } from "@material-ui/styles";
import { fileExistsSync } from "../../../node_modules/tsconfig-paths/lib/filesystem";

export default makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(2),
  },
  alignedText: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  buttonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    marginLeft: "30em",
    marginTop: "2%",
    marginBottom: "3%",
  },
  fileUploadField: {
    display: "flex",
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: "100%",
  },
  linearProgressField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: "0.5%",
    width: "100%",
  },
  generalPurposeItems: {
    display: "flex",
    width: 700,
    justifyContent: "space-between",
  },
  fileExtensionText: {
    display: "flex",
    width: 700,
    justifyContent: "center",
    alignItems: "center",
  },
  selectionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  rowValueItems: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginLeft: "2.4em",
  },
  dateValueContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifySelf: "stretch",
    width: 506,
  },
  fieldValueContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  fieldListValueContainer: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 506,
  },
  smallItems: {
    display: "flex",

    marginTop: "1%",
    width: 350,
    justifyContent: "space-between",
  },
  dateSelectionField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-1%",
    marginLeft: "50px",
    width:250,
    maxWidth: 250,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  generalSelectionField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    marginTop: "-1%",
    marginLeft: "50px",
    width: 250,
    maxWidth: 250,
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0,0,0, 1)",
    },
  },
  smallField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  selectorField: {
    marginRight: theme.spacing(1),
    marginTop: "-1%",
    width: 170,
  },
  logotypeImage: {
    width: 157,
    height: 60,
    marginTop: "-1%",
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  alignLeft: {
    display: "flex",
    justifyContent: "flex-start",
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
  },
  sendItem: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "1em",
    marginTop: "1%",
    width: 700,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  sendField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "6%",
    width: 130,
    height: 40,
  },
  submitLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  realCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  uploadButton: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: "1%",
    height: 40,
  },
  popupButtonSpacing: {
    display: "flex",
    justifyContent: "center",
  },
  popupButtonDiv: {
    display: "flex",
    justifyContent: "center",
  },
}));
