import React, { useState } from "react";
import { Button, Paper, Grid } from "@material-ui/core";

import {
  NEW_ROW_HEADERS,
  NEW_ROW_HEADERS_LIST,
} from "utils/generalDashboardConstants";
import PageTitle from "components/PageTitle/PageTitle";
import { Typography as CustomTypography } from "components/Wrappers/Wrappers";
import { getCurrentDate } from "utils/dateGetters";
import DateField from "components/CustomFields/DateField";
import useStyles from "pages/dashboard/styles";
import { useEffect } from "react";
import PopupMessage from "components/PopupMessage/PopupMessage";
import { dbGet } from "utils/DBFetchers";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import InvertedTable from "components/Table/InvertedTable";
import GeneralDashboardFilters from "../../../components/GeneralDashboardFilters/GeneralDashboardFilters";
import LinearProgressWithLabel from "components/LinearProgressWithLabel/LinearProgressWithLabel";

const FIRST_MONTH = new Date(getCurrentDate().getFullYear(), 0, 1);

const LAST_MONTH = new Date(
  getCurrentDate().getFullYear(),
  getCurrentDate().getMonth(),
  1,
);

const createColumnsHeaderName = (columns) => {
  return columns.map((column) => {
    const columnHeaderName =
      column.substring(4, 6) + "-" + column.substring(0, 4);
    return columnHeaderName.charAt(0).toUpperCase() + columnHeaderName.slice(1);
  });
};

// This component solves the issue of using the same code twice for the
// general dashboard RS and general dashboard SM. If they ask for a row
// appearing only in one of the dashboards, the constants ROW_HEADERS and
// ROW_HEADERS_LIST should be pass through props to the component.
export default function Dashboard({ programId, name }) {
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDownloadPeriod, setSelectedDownloadPeriod] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const currentDateMonth = new Date(
    getCurrentDate().getFullYear(),
    getCurrentDate().getMonth() - 1,
    1,
  );

  let elevenMonthsAgo = new Date();
  elevenMonthsAgo.setMonth(currentDateMonth.getMonth() - 11);
  elevenMonthsAgo.setDate(1);

  const [maxPeriod, setMaxPeriod] = useState(new Date(2023, 1, 11));
  const [filters, setFilters] = useState({
    fromDate: elevenMonthsAgo,
    toDate: currentDateMonth,
    period: null,
  });
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [period, setPeriod] = useState(null);

  const generateRowsData = (data) => {
    if (!data) return;
    if (Object.keys(data).length === 0) {
      setColumns([]);
      setRows([]);
      return;
    }
    let columns = Object.keys(data);
    let firstValidIndex = -1;

    for (let i = 0; i < columns.length; i++) {
      if (data[columns[i]] && Object.keys(data[columns[i]]).length > 0) {
        firstValidIndex = i;
        break;
      }
    }

    if (firstValidIndex === -1) return;

    let rows = Object.keys(data[columns[firstValidIndex]]);
    
    let table_data = rows.map((row) => {
      return columns.map((column) => {
        let value = Object.keys(data[column]).includes(row)
          ? data[column][row]
          : 0;
        return value;
      });
    });
    setColumns(createColumnsHeaderName(columns));
    generateTableData(rows, table_data);
  };

  const handlePeriodChange = (date) => {
    setSelectedDownloadPeriod(date);
  };

  const handleToDateChange = (date) => {
    setPeriod(date);
    setFiltersDate("toDate", date);
  };
  const handleFromDateChange = (date) => {
    setFiltersDate("fromDate", date);
  };

  const setFiltersDate = (filterCategory, date) => {
    var dateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setFilters({
      ...filters,
      [filterCategory]: dateOnFirstDay,
    });
  };

  // Added the filteredTableData because of a previous issue this function had
  // where it would filter out the rows and then use table_data[index] which
  // hasn't be filtered.
  const generateTableData = (rows, table_data) => {
    // Filter out rows and their corresponding data from table_data
    const filteredTableData = table_data
      .filter((_, index) => NEW_ROW_HEADERS[rows[index]])
      .map((data) =>
        data.filter((value, index) => NEW_ROW_HEADERS[rows[index]]),
      );

    // Generate the final data array
    const data = rows
      .filter((row) => NEW_ROW_HEADERS[row])
      .map((row, index) => ({
        label: NEW_ROW_HEADERS[row]?.label,
        category: NEW_ROW_HEADERS[row]?.category,
        data: filteredTableData[index],
      }));

    // Sort the data based on ROW_HEADERS_LIST
    data.sort(
      (a, b) =>
        NEW_ROW_HEADERS_LIST.indexOf(a.label) -
        NEW_ROW_HEADERS_LIST.indexOf(b.label),
    );

    // Set the rows state with the filtered and processed data
    setRows(data);
  };

  // Receives a date and returns an integer representing the period in format YYYYMM
  const getPeriodFromDate = (date) => {
    return parseInt(
      date.toISOString().split("T")[0].substring(0, 7).replace("-", ""),
    );
  };

  useEffect(() => {
    if (filters.fromDate && filters.toDate) {
      let url = `control-panel/get_last_period?program_id=${programId}`;
      dbGet(url).then((response) => {
        if (response == null) {
          setMaxPeriod(getCurrentDate());
          setPeriod(getCurrentDate());
          return;
        }
        let year = parseInt(response.toString().substring(0, 4));
        let month = parseInt(response.toString().substring(4, 6));
        setMaxPeriod(new Date(year, month - 1, 1));
        setPeriod(new Date(year, month - 1, 1));
      });
    }
  }, []);

  const handleDownload = () => {
    let period = getPeriodFromDate(selectedDownloadPeriod);
    let url =
      `control-panel/get_zip_url?program_id=${programId}&period=` + period;
    dbGet(url)
      .then((response) => {
        let download_link = response.zip_file;
        if (download_link) {
          window.location.href = download_link;
          snackbarOpen("success", "Reportes descargados correctamente");
        } else {
          snackbarOpen(
            "warning",
            "Los reportes de este período aún no han sido generados",
          );
        }
      })
      .catch(() => {
        snackbarOpen("error", "Ocurrió un error en la generación de reportes");
      })
      .finally(() => {
        handlePopupClose();
      });
  };

  const executeButton = (
    <Button
      disabled={selectedDownloadPeriod === null}
      onClick={handleDownload}
      variant="contained"
      color="secondary"
      className={classes.popupButtonSpacing}
    >
      Descargar
    </Button>
  );

  const handlePopupClose = () => {
    setSelectedDownloadPeriod(null);
    setShowPopup(false);
  };

  return (
    <div>
      <PageTitle title={`Panel de control - General ${name}`} />
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <PopupMessage
        title={"Descargar reportes"}
        message={
          "Por favor, elija los datos que quiere descargar a continuación."
        }
        open={showPopup}
        handleClose={handlePopupClose}
        buttonClassName={classes.popupButtonSpacing}
        buttonDivClassName={classes.popupButtonDiv}
        executeButton={executeButton}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CustomTypography
            component={"span"}
            className={classes.text}
            weight="bold"
          >
            Período
          </CustomTypography>
          <DateField
            views={["month", "year"]}
            minDate={new Date("2020-01-02")}
            maxDate={maxPeriod}
            format={"MM/yyyy"}
            value={selectedDownloadPeriod}
            onChange={handlePeriodChange}
            placeholder="Fecha"
            fieldClassName={classes.generalPurposeField}
          />
        </div>
      </PopupMessage>
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        layout={"row"}
        spacing={"1"}
        style={{ border: "1px", marginBottom: "10px" }}
      >
        <GeneralDashboardFilters
          generateRowsData={generateRowsData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          initialFiltersEndpoint="control-panel/filters"
          getEndpoint="control-panel/summary"
          programId={programId}
        >
          <Button
            variant="contained"
            size="medium"
            onClick={() => setShowPopup(true)}
          >
            Descargar reportes
          </Button>
        </GeneralDashboardFilters>
      </Grid>
      <Paper className={classes.paper}>
        {isLoading ? (
          <LinearProgressWithLabel />
        ) : (
          <InvertedTable rows={rows} columns={columns} />
        )}
      </Paper>
    </div>
  );
}
