import { Card, Paper } from "@mui/material";
import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// material
import { Typography } from "components/Wrappers/Wrappers";
import { format } from "date-fns";
import useStyles from "../styles";
import { useTheme } from "@material-ui/styles";

import SummaryCards from "../SummaryCards/SummaryCards";
import Graphs from "../Graphs/Graphs";
import Table from "../Table/Table";

const getDateFormatted = (date) => {
  return format(date, "dd/MM/yy");
};

const assembleExcelData = (information) => {
  let notDelivered =
    information.coupons_to_consultants -
    information.coupons_to_medics.total.delivered;
  // notDelivered = Math.max(notDelivered, 0);
  let totalNotConverted =
    information.coupons_to_medics.total.delivered -
      information.converted.total || 0;
  // totalNotConverted = Math.max(totalNotConverted, 0);
  return [
    { data: ["Cupones convertidos", information.converted.total] },
    { data: ["Pacientes nuevos", information.converted.new_patients] },
    { data: ["Pacientes viejos", information.converted.old_patients] },
    { data: [] },
    // Second table
    { data: ["Concepto", "Recibidos", "Asignados", "Entregados", ""] },
    {
      data: [
        "Potenciales",
        "-",
        information.coupons_to_medics["Potenciales"].assigned,
        information.coupons_to_medics["Potenciales"].delivered,
      ],
    },
    {
      data: [
        "Nuevos",
        "-",
        information.coupons_to_medics["Nuevos"].assigned,
        information.coupons_to_medics["Nuevos"].delivered,
      ],
    },
    {
      data: [
        "Usuario",
        "-",
        information.coupons_to_medics["Usuario"].assigned,
        information.coupons_to_medics["Usuario"].delivered,
      ],
    },
    {
      data: [
        "No usuarios",
        "-",
        information.coupons_to_medics["No Usan/No quieren"].assigned,
        information.coupons_to_medics["No Usan/No quieren"].delivered,
      ],
    },
    {
      data: [
        "Sin status",
        "-",
        information.coupons_to_medics["Sin Status"].assigned,
        information.coupons_to_medics["Sin Status"].delivered,
      ],
    },
    {
      data: [
        "Total",
        information.coupons_to_consultants,
        information.coupons_to_medics.total.assigned,
        information.coupons_to_medics.total.delivered,
      ],
    },
  ];
}

export default function TraceabilityDashboard(props) {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    props.setExcelData(assembleExcelData(props.information));
  }, []);

  return (
    <Grid container>
      <Grid item container xs={12} alignItems="stretch" spacing={2}>
        <Grid item xs={12} style={{ margin: "0 0 10px 10px" }}>
          <Typography variant="h4" className={classes.alignedText}>
            Resumen
          </Typography>
          <Divider />
        </Grid>
        <SummaryCards information={props.information} />
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column"
        spacing={2}
        style={{ marginTop: "10px" }}
      >
        {/* Left side */}
        <Grid item container lg={5} xs={12} md={5}>
          <Grid item lg={12} xs={12} md={12} style={{ marginLeft: "10px" }}>
            <Typography variant="h4" className={classes.alignedText}>
              Información global
            </Typography>
            <Divider />
          </Grid>
          <Table information={props.information}/>
        </Grid>
        <Graphs information={props.information} setInformation={props.setInformation} />
      </Grid>
    </Grid>
  );
}
