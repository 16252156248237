import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Grid, Typography, Button } from "@material-ui/core";
import useStyles from "../../ABMs/styles";
import { verifyRut } from "utils/rutValidations";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import Field from "components/CustomFields/Field.js";
import { QRCodeSVG } from 'qrcode.react';
import { encode } from 'js-base64';
import { saveAs } from "file-saver";

const INITIAL_LINK = 'https://recetasolidaria.cl/suscribir?r=';

const initialForm = {
  link: INITIAL_LINK,
  rut: "",
};

export default function VisitorQRPage() {
  const classes = useStyles();
  const [form, setForm] = useState(initialForm);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [qrData, setQrData] = useState("");

  const handleRutChange = (e) => {
    // Eliminate any ',', '.' or '-' from the RUT and capitalize any letter
    const cleanedRut = e.target.value
      .replace(/[.,-]/g, '')
      .toUpperCase();

    // Should be !verifyRut(form.rut) but it works this way
    if (verifyRut(form.rut)) {
      setQrData("");
    }

    setForm({
      ...form,
      [e.target.id]: cleanedRut,
    });
  };


  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = () => {
    if (!form.link || !form.rut) {
      snackbarOpen("error","Hay campos sin completar");
    }else if (!verifyRut(form.rut)) {
      snackbarOpen("error","RUT inválido");
    } else { // Generate QR
      const encodedRut = encode(form.rut);
      const data = `${form.link}${encodedRut}`;
      setQrData(data);
      snackbarOpen("success", "QR generado exitosamente");
    }
  };

  const handleDownload = () => {
    const svgElement = document.getElementById('qr-code');
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    saveAs(blob, `${form.rut}.svg`);
  }

  return (
    <>
      <div>
        <PageTitle title="Generar QR Visitador" />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid container spacing={3} style={{ marginTop: "2%" }} justifyContent="center">
        <div style={{ marginTop: "4%" }}>
          <Field
            labelName="Link"
            required={true}
            id="link"
            value={form.link}
            divClassType={classes.generalPurposeItems}
            fieldClassType={classes.generalPurposeField}
            onChange={handleChange}
          ></Field>
          <Field
            labelName="RUT"
            required={true}
            id="rut"
            value={form.rut}
            divClassType={classes.generalPurposeItems}
            fieldClassType={classes.generalPurposeField}
            onChange={handleRutChange}
          ></Field>
        </div>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {qrData && (
            <div>
              <Typography variant="h6">Código QR:</Typography>
              <QRCodeSVG 
                id="qr-code" 
                value={qrData} 
                bgColor="transparent"  
              />
              <div style={{ marginTop: "1rem" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDownload}
                >
                  Descargar QR
                </Button>
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Generar QR
          </Button>
        </Grid>
      </Grid>
    </>
  );
  
}