/*
 * Default text labels.
 */
const datatableTextLabels = () => ({
  body: {
    noMatch: 'No hay resultados.',
    toolTip: 'Ordenar',
  },
  pagination: {
    next: 'Próxima página',
    previous: 'Anterior página',
    rowsPerPage: 'Filas por página:',
    displayRows: 'de',
    jumpToPage: 'Ir a página:',
  },
  toolbar: {
    search: 'Buscar',
    downloadCsv: 'Descargar',
    print: 'Imprimir',
    viewColumns: 'Ver Columnas',
    filterTable: 'Filtrar',
  },
  filter: {
    all: 'Todo',
    title: 'FILTROS',
    reset: 'REINICIAR',
  },
  viewColumns: {
    title: 'Mostrar Columnas',
    titleAria: 'Mostrar/Ocultar Columnas',
  },
  selectedRows: {
    text: 'fila(s) seleccionada(s)',
    delete: 'Eliminar',
    deleteAria: 'Eliminar filas seleccionadas',
  },
});

export default datatableTextLabels;