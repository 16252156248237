import React, { useState, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import {
  TextField,
  Grid,
  Divider,
  Box,
  Typography,
  Button,
  LinearProgress,
} from "@material-ui/core";
import Field from "components/CustomFields/Field.js";
import ProgramsField from "components/CustomFields/ProgramField";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// styles
import useStyles from "./styles";

export default function AccreditCouponForm(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  var classes = useStyles();
  let fieldNameSpace = 5;
  let fieldValueSpace = 12 - fieldNameSpace;

  return (
    <>
      <Grid container xs={12} spacing={3}>
        <Grid
          item
          xs={12}
          lg={12}
          style={{ marginBottom: "15px", marginLeft: "2.5%" }}
        >
          <Typography variant="h4" className={classes.alignedText}>
            Datos del profesional
          </Typography>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: isMobile ? "80vw" : 280 }}>
            <Typography className={classes.text} style={{ fontWeight: "bold" }}>
              Programa
            </Typography>
            <ProgramsField
              fieldClassName={classes.generalPurposeField}
              value={props.program}
              onChange={(e) => props.setProgram(e.target.value)}
              GES={false}
              Salco={false}
            />
          </div>
        </Grid>
        {props.isLoading ? (
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: isMobile ? "90%" : "50%",
                margin: 20,
                textAlign: "center",
              }}
            >
              <div style={{ marginBottom: 10 }}>Cargando médicos</div>
              <LinearProgress />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: isMobile || "70px",
              }}
            >
              <div>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Nombre
                </Typography>
                <Autocomplete
                  disabled={props.program === 0}
                  freeSolo
                  style={{ width: isMobile ? "80vw" : 350 }}
                  options={props.medics ? props.medics : []}
                  onChange={props.onMedicSelectChange}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  renderInput={(params) => (
                    <TextField
                      className={
                        params.disabled
                          ? classes.selectionTextDisabled
                          : classes.selectionTextEnabled
                      }
                      {...params}
                      placeholder="Nombre"
                      margin="dense"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Cupones actuales
                </Typography>
                <Field
                  style={{ width: isMobile ? "80vw" : 280 }}
                  disabled={true}
                  defaultValue={"0"}
                  value={props.loadingCouponsMessage ? props.loadingCouponsMessage : props.currentCouponCount}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div>
                <Typography
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  Cupones a agregar
                </Typography>
                <Field
                  style={{ width: isMobile ? "80vw" : 280 }}
                  error={props.couponsError}
                  defaultValue={0}
                  value={props.accreditCouponCount}
                  onChange={props.onAccreditCouponChange}
                  helperText={
                    props.couponsMaxAndRemaining.total > 0 &&
                    `Maximo ${props.couponsMaxAndRemaining.total} cupones mensuales,\n quedan ${props.couponsMaxAndRemaining.remaining} disponibles`
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} className={classes.uploadFormButton}>
              <Button
                className={classes.sendField}
                variant="contained"
                color="primary"
                onClick={props.upload}
                disabled={props.couponsError || props.buttonIsDisabled}
              >
                Acreditar
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
