import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";

import Field from "components/CustomFields/Field.js";
import SelectField from "components/CustomFields/SelectField.js";
import RutField from "components/CustomFields/RutField.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { dbGet, dbPut } from "utils/DBFetchers.js";
import { verifyRut } from "utils/rutValidations";
import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";
import { cleanEmptyKeys } from "utils/cleanEmptyKeys";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

const blankEditPharmacyForm = {
  id: "",
  rut: "",
  rutVerifier: "",
  code: "",
  name: "",
  company: "",
  group_id: "",
  address: "",
  commune_id: "",
  email: "",
  phone: "",
  contact: "",
  programs: null,
};

const allPrograms = [
  { id: 1, name: "Receta solidaria" },
  { id: 2, name: "Solo mujer" },
  { id: 3, name: "Ambos" },
];

const formattedPrograms = {
  1: [1],
  2: [2],
  3: [1, 2],
};

export default function ABMPharmaciesEditPage(props) {
  var classes = useStyles();
  const history = useHistory();
  var [isLoading, setIsLoading] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const [editPharmacyForm, updateForm] = useState(blankEditPharmacyForm);
  const [isRutValid, setIsRutValid] = useState({ rut: false, message: "" });
  const [allPharmaGroups, setAllPharmaGroups] = useState([]);
  const [allCommunes, setAllCommunes] = useState([]);

  const handleChange = (event) => {
    updateForm({
      ...editPharmacyForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleRutVerification = () => {
    var currentRut = editPharmacyForm.rut + editPharmacyForm.rutVerifier;
    var rutValid = verifyRut(currentRut);

    if (rutValid && !editPharmacyForm.rutVerifier)
      setIsRutValid({
        rut: false,
        message: "Ingrese el dígito verificador en el campo respectivo.",
      });
    else if (!rutValid) setIsRutValid({ rut: false, message: "RUT inválido." });
    else setIsRutValid({ rut: true, message: "" });
  };

  /* Fills some field name options */
  const getPharmaGroups = () => {
    dbGet("pharmagroups").then((data) => {
      data = data.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        // a must be equal to b
        return 0;
      });
      let mapped = data.map((item) => {
        return { id: item["id"], name: item["name"] };
      });
      setAllPharmaGroups(mapped);
    });
  };
  const getCommunes = () => {
    dbGet("communes").then((data) => {
      data = data.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        // a must be equal to b
        return 0;
      });
      let mapped = data.map((item) => {
        return { id: item["id"], name: item["name"] };
      });
      setAllCommunes(mapped);
    });
  };

  // Rut updaters
  const handleRutChange = (e) => {
    updateForm({
      ...editPharmacyForm,
      [e.target.id]: e.target.value.toUpperCase(),
    });
  };
  React.useEffect(() => {
    if (!editPharmacyForm.rut && !editPharmacyForm.rutVerifier) return; // no fetch on page load
    handleRutVerification();
  }, [editPharmacyForm.rut, editPharmacyForm.rutVerifier]);

  /* Get all data */
  const getPharmacyInfo = (pharmacyId) => {
    dbGet(`pharmacies/${pharmacyId}`).then((data) => {
      updateForm({
        ...editPharmacyForm,
        id: pharmacyId,
        rut: data.rut ? data.rut.slice(0, -1) : "",
        rutVerifier: data.rut ? data.rut.slice(-1)[0] : "",
        code: data.code ? data.code : "",
        name: data.name ? data.name : "",
        company: data.company ? data.company : "",
        group_id: data.group ? data.group.id : "",
        address: data.address ? data.address : "",
        commune_id: data.commune ? data.commune.id : "",
        email: data.email ? data.email : "",
        phone: data.phone ? data.phone : "",
        contact: data.contact ? data.contact : "",
        programs: data.programs.length === 1 ? data.programs[0].id : 3,
      });
    });
  };

  /* Basic form check */
  const checkFormComplete = () => {
    if (
      !editPharmacyForm.name ||
      !editPharmacyForm.rut ||
      !editPharmacyForm.code ||
      !editPharmacyForm.programs ||
      !isRutValid.rut
    )
      return false;
    return true;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder editar la farmacia.",
      );
      return;
    }
    setIsLoading(true);

    // Give adecuate format to send form to db
    let completedForm = {
      rut: editPharmacyForm.rut + editPharmacyForm.rutVerifier,
      code: editPharmacyForm.code,
      name: editPharmacyForm.name,
      company: editPharmacyForm.company,
      group_id: editPharmacyForm.group_id,
      address: editPharmacyForm.address,
      commune_id: editPharmacyForm.commune_id,
      email: editPharmacyForm.email,
      phone: editPharmacyForm.phone,
      contact: editPharmacyForm.contact,
    };

    completedForm = cleanEmptyKeys(completedForm);
    completedForm["programs"] = formattedPrograms[editPharmacyForm.programs];

    // Sends to db
    dbPut(`pharmacies/${editPharmacyForm.id}`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Farmacia editada exitosamente");
        redirectToPharmaciesPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al editar farmacia",
        ); // translate pending
        setIsLoading(false);
        return;
      });
  };

  /* Cancel editing distributor and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankEditPharmacyForm);
    history.goBack();
  };

  /* Redirect to pharmacies table page after editing correctly */
  const redirectToPharmaciesPage = (user) => {
    setTimeout(() => {
      updateForm(blankEditPharmacyForm);
      setIsLoading(false);
      history.push(routes.abmPharmacies);
    }, 3000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getPharmaGroups();
    getCommunes();
    if (props.props.location.state) {
      getPharmacyInfo(props.props.location.state.pharmacyId);
    }
  }, []);

  const title = `Editar Farmacia${editPharmacyForm.name ? ": " : ""}${
    editPharmacyForm.name ? editPharmacyForm.name : ""
  }`;

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos de la farmacia
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  A continuación edite los datos de la farmacia.
                </Typography>
              </div>
              <div style={{ marginTop: "4%" }}>
                <Field
                  labelName="Nombre *"
                  required={true}
                  id="name"
                  value={editPharmacyForm.name}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <Field
                  labelName="Código de farmacia *"
                  required={true}
                  id="code"
                  value={editPharmacyForm.code}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <RutField
                  labelName="RUT * (sin guión)"
                  required={true}
                  id="rut"
                  id2="rutVerifier"
                  value={editPharmacyForm.rut}
                  divClassType={classes.smallItems2}
                  divClassType2={classes.generalPurposeItems2}
                  fieldClassType={classes.smallFieldValidation2}
                  fieldClassType2={classes.smallFieldValidation3}
                  onChange={handleRutChange}
                  helperText={isRutValid.message}
                  error={!isRutValid.rut}
                  value2={editPharmacyForm.rutVerifier}
                  onChange2={handleRutChange}
                ></RutField>
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  displayText="Programa/s"
                  id="programs"
                  label="Seleccione"
                  name="programs"
                  value={editPharmacyForm.programs}
                  onChange={handleChange}
                  allValues={allPrograms ? allPrograms : []}
                />
                <Field
                  labelName="Compañia/Empresa"
                  required={true}
                  id="company"
                  value={editPharmacyForm.company}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <Field
                  labelName="Domicilio"
                  required={true}
                  id="address"
                  value={editPharmacyForm.address}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  displayText="Comuna"
                  id="commune_id"
                  label="Seleccione"
                  name="commune_id"
                  value={editPharmacyForm.commune_id}
                  onChange={handleChange}
                  allValues={allCommunes ? allCommunes : []}
                />
                <Field
                  labelName="Teléfono"
                  required={true}
                  id="phone"
                  value={editPharmacyForm.phone}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <Field
                  labelName="Email"
                  required={true}
                  id="email"
                  value={editPharmacyForm.email}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <Field
                  labelName="Contacto"
                  required={true}
                  id="contact"
                  value={editPharmacyForm.contact}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  displayText="Grupo"
                  id="group_id"
                  label="Seleccione"
                  name="group_id"
                  value={editPharmacyForm.group_id}
                  onChange={handleChange}
                  allValues={allPharmaGroups ? allPharmaGroups : []}
                /> 
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelAndGoBack}
                >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
