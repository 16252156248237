import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  buttonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    justifyContent: "center",
    // marginLeft: "15em",
    marginTop: "1%",
  },
  generalPurposeItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 700,
    justifyContent: "space-between",
  },
  smallItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "7%",
    width: 430,
    justifyContent: "space-between",
  },
  generalPurposeField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-1%",
    width: 500,
  },
  smallField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  smallFieldValidation: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  logotypeImage: {
    width: 157,
    height: 60,
    marginTop: "-1%",
  },
  // NEW RUT VERIFICATION STYLES
  generalPurposeItems2: {
    display: "flex",
    width: 310,
    justifyContent: "flex-start",
  },
  smallItems2: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "5%",
    width: 502,
    justifyContent: "space-between",
  },
  smallFieldValidation2: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginTop: "-2%",
    width: 230,
  },
  smallFieldValidation3: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginTop: "-2%",
    width: 80,
  },
}));
