import { Icon } from "@iconify/react";
import { Button, IconButton } from "@material-ui/core";

const ResendEmailButton = ({ onClick, label }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    }}
  >
    <Button
      onClick={onClick}
      size="small"
      style={{
        padding: 8,
      }}
      color="primary"
    >
      {label}
    </Button>
  </div>
);

const createColumn = (
  field,
  headerName,
  minWidth = 160,
  type = "string",
  align = "left",
  additionalProps = {}
) => ({
  field,
  headerName,
  minWidth,
  type, // to sort correctly
  align,
  headerAlign: align,
  sortable: false,
  headerClassName: "super-app-theme--Header",
  ...additionalProps,
});

const createIconActionColumn = (
  headerName,
  field,
  icon,
  onClick,
  width = 50,
) => ({
  field: field,
  sortable: false,
  headerName,
  headerAlign: "center",
  width,
  renderCell: (params) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <IconButton
        key={field}
        onClick={() => onClick(params)}
        size="small"
        style={{
          padding: 8,
        }}
      >
        <Icon icon={icon} />
      </IconButton>
    </div>
  ),
});

const createTextActionColumn = (headerName, field, onClick, width = 50) => ({
  field: field,
  sortable: false,
  headerName,
  headerAlign: "center",
  width,
  renderCell: (params) =>
    params.value ? (
      <ResendEmailButton
        onClick={() => onClick(params)}
        key={field}
        label="Reenviar"
      />
    ) : (
      <div />
    ),
});

const createSubscriptionsColumns = (
  setIsDetailsOpen,
  setSelectedDetailsRow,
) => [
  createColumn("id", "ID", 100, "number", "center", {
    valueFormatter: (params) => {
      return `${Number(params.value)}`;
    }
  }),
  createColumn("presentation", "Presentación", 450),
  createColumn("type", "Origen", 250, "string", "center"),
  createColumn(
    "subscription_date",
    "Fecha Suscripción",
    180,
    "string",
    "center",
  ),
  {
    field: 'details',
    sortable: false,
    headerName: "Ver Emails",
    headerAlign: "center",
    width: 110,
    renderCell: (params) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <IconButton
          key={'details'}
          onClick={() => {
            const { row } = params;
            setIsDetailsOpen(true);
            setSelectedDetailsRow(row);
          }}
          size="small"
          style={{
            padding: 8,
          }}
        >
          <span style={{ fontSize: '1.25rem' }}>↗︎</span>
        </IconButton>
      </div>
    ),
  }
];

const salesWithoutCouponColumns = [
  createColumn("presentation", "Presentación", 450),
  createColumn("subscription_date", "Fecha Suscripción", 200, "string", "center"),
];

const getFormattedDate = (date) => {
  // Creo la fecha sin ajustar la zona horaria
  const dateParts = date.split('-');
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];

  return `${day}/${month}/${year}`;
}

const mapSubscriptions = (subscriptions) => {
  if (!subscriptions) return [];

  return subscriptions.map((sub) => ({
    id: sub.id,
    presentation: sub.presentation.name,
    type: sub.origin,
    subscription_date: getFormattedDate(sub.transaction_date),
    automatic_emails: sub.automatic_emails.map((automatic_email) => ({
      id: automatic_email.id,
      email: automatic_email.email,
      date_to_send: new Date(automatic_email.date_to_send).toLocaleDateString("es-CL"),
      was_sent: automatic_email.was_sent,
    })),
  }));
};

const mapSalesWithoutCoupon = (sales) => {
  if (!sales) return [];

  return sales.map((sale) => ({
    id: sale.id,
    presentation: sale.presentation.name,
    subscription_date: getFormattedDate(sale.transaction_date),
  }));
};

const createSubscriptionDetailsColumns = (openResendConfirmation) => [
  createColumn("email", "Email", 400),
  createColumn("date_to_send", "Fecha Envío", 140, "string", "center"),
  createTextActionColumn(
    "Reenviar",
    "was_sent",
    ({ id }) => openResendConfirmation(id),
    100,
  ),
];

export {
  createSubscriptionsColumns,
  salesWithoutCouponColumns,
  createSubscriptionDetailsColumns,
  mapSubscriptions,
  mapSalesWithoutCoupon,
};
