import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  Input,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";

import Field from "components/CustomFields/Field.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { dbGet, dbPut } from "utils/DBFetchers.js";
import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

const blankEditKamForm = {
  id: "",
  name: "",
  email: "",
  distributors: null,
};

const DISTRIBUTORS_MAP = {
  "Ramirez y Sanchez": 1,
  "Farma 7": 2,
  Farmagestion: 3,
  Mediven: 4,
  Globalpharma: 6,
  Recalcine: 8,
  "Schubert Spa": 10,
  Salcobrand: 13,
  "Cruz Verde": 15,
  Ahumada: 18,
};

export default function ABMKamEditPage(props) {
  var classes = useStyles();
  var [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [editKamForm, updateForm] = useState(blankEditKamForm);
  const [allKam, setAllKam] = useState([]);
  const [isNameValid, setIsNameValid] = useState({ name: false, message: "" });
  const [distributorsName, setKamsName] = useState([]);
  const [allDistributors, setAllDistributors] = useState([]);
  const [isDiscountValid, setIsDiscountValid] = useState({
    discount: true,
    message: "",
  });

  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const handleChange = (event) => {
    updateForm({
      ...editKamForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleNameVerification = () => {
    var nameExists = allKam.some((elem) => elem.name === editKamForm.name);
    if (nameExists)
      setIsNameValid({
        name: nameExists,
        message: "Distribuidor ya existente.",
      });
    else setIsNameValid({ name: nameExists, message: "" });
  };

  React.useEffect(() => {
    if (!editKamForm.name) return; // no fetch on page load
    handleNameVerification();
  }, [editKamForm.name]);

  const handleDiscountChange = (event) => {
    updateForm({
      ...editKamForm,
      [event.target.name]: event.target.value,
    });
    if (
      +event.target.value > 100 ||
      +event.target.value < 0 ||
      event.target.value === "-"
    ) {
      setIsDiscountValid({
        discount: false,
        message: "Descuento no puede ser mayor a 100% o menor a 0%.",
      });
    } else {
      setIsDiscountValid({ discount: true, message: "" });
    }
  };

  /* Fills form data */
  const getKamInfo = (kamId) => {
    dbGet(`kams/${kamId}`).then((data) => {
      updateForm({
        ...editKamForm,
        id: kamId,
        name: data.name ? data.name : "",
        email: data.email ? data.email : "",
        distributors: data.distributors
          ? [data.distributors.map((distributor) => distributor.id)]
          : [],
      });
      if (data.distributors)
        setKamsName(data.distributors.map((distributor) => distributor.name));
    });
  };

  const handleKamChange = (event) => {
    setKamsName(event.target.value);
    let distributorsId = event.target.value.map((value) => {
      return DISTRIBUTORS_MAP[value];
    });
    setKamsName(event.target.value);
    updateForm({
      ...editKamForm,
      [event.target.name]: distributorsId,
    });
  };

  const checkFormComplete = () => {
    var isCompleted = true;
    if (!editKamForm.name) {
      isCompleted = false;
      snackbarOpen("error", "Complete el campo de nombre");
    } else if (!editKamForm.email) {
      isCompleted = false;
      snackbarOpen("error", "Complete el campo de email");
    } else if (!editKamForm.email.includes("@")) {
      isCompleted = false;
      snackbarOpen("error", "El campo de mail es incorrecto");
    } else if (editKamForm.distributors.length === 0) {
      isCompleted = false;
      snackbarOpen("error", "Selecciones los distribuidores");
    }
    return isCompleted;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder editar el KAM.",
      );
      return;
    }
    setIsLoading(true);

    // Give adecuate format to send form to db
    let completedForm = {
      name: editKamForm.name,
      email: editKamForm.email,
      distributors: editKamForm.distributors.join(","),
    };

    // Sends to db
    dbPut(`kams/${editKamForm.id}`, completedForm)
      .then((data) => {
        snackbarOpen("success", "KAM editado exitosamente");
        redirectToKamPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al editar KAM",
        );
        setIsLoading(false);
        return;
      });
  };

  /* Cancel editing kam and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankEditKamForm);
    history.goBack();
  };

  /* Redirect to kam table page after editing correctly */
  const redirectToKamPage = (user) => {
    setTimeout(() => {
      updateForm(blankEditKamForm);
      setIsLoading(false);
      history.push(routes.abmKam);
    }, 2000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    if (props.props.location.state) {
      getKamInfo(props.props.location.state.kamId);
    }
    dbGet("distributors").then((data) => {
      data = data.filter((x) => x.id !== 19);
      let mapped = data.map((item) => {
        return {
          id: item["id"],
          name: item["name"],
        };
      });
      setAllDistributors(mapped);
    });
  }, []);

  const title = `Editar KAM${editKamForm.name ? ": " : ""}${
    editKamForm.name ? editKamForm.name : ""
  }`;

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del KAM
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  A continuación edite los datos del KAM.
                </Typography>
              </div>
              <div style={{ marginTop: "4%" }}>
                <Field
                  labelName="Nombre *"
                  required={true}
                  id="name"
                  value={editKamForm.name}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  error={isNameValid.name}
                  helperText={isNameValid.message}
                />
                <Field
                  labelName="Email *"
                  required={true}
                  id="email"
                  value={editKamForm.email}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  error={!editKamForm.email}
                />
                <div className={classes.generalPurposeItems}>
                  <Typography className={classes.text} weight="bold">
                    Distribuidor/es
                  </Typography>
                  <Select
                    id="distributors"
                    name="distributors"
                    className={classes.generalPurposeField}
                    multiple
                    value={distributorsName}
                    onChange={handleKamChange}
                    input={<Input id="select-multiple-chip" />}
                    margin="dense"
                    variant="outlined"
                    renderValue={(selected) => (
                      <div>
                        {selected.map((key, value) => {
                          return (
                            <Chip
                              key={value}
                              label={key}
                              className={classes.chip}
                              variant="outlined"
                            />
                          );
                        })}
                      </div>
                    )}
                  >
                    {allDistributors.map((distributor) => {
                      return (
                        <MenuItem key={distributor.id} value={distributor.name}>
                          {distributor.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelAndGoBack}
                >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
