import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Grid } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Typography } from "components/Wrappers/Wrappers";

import Field from "components/CustomFields/Field.js";
import RutField from "components/CustomFields/RutField";
import { verifyRut } from "utils/rutValidations.js";
import { getInscriptionsByPatientRut, dbGet } from "utils/DBFetchers.js";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";
import dataGridTexts from "components/DataGrid/dataGridTexts.js";

const blankForm = {
  patientRut: "",
  patientRutVerifier: "",
  patientEmail: "",
};

const acquiredProductsColumns = [
  {
    field: "presentation",
    headerName: "Producto",
    width: 360,
    valueFormatter: (params) => params.row?.presentation?.name,
  },
  {
    field: "vademecum",
    headerName: "Descuento (%)",
    width: 155,
    align: "center",
    valueFormatter: (params) => params.row?.vademecum?.discount,
  },
  {
    field: "remaining_units",
    headerName: "Compras restantes",
    width: 180,
    align: "center",
  },
];
// Row example
// {id: 1, presentation: {id: 1, name: "Ibuprofeno1"}, vademecum: {discount: 10}, remaining_units: 5, etc...}

export default function ConsultProductPage(props) {
  var classes = useStyles();
  const [form, updateForm] = useState(blankForm);

  const [acquiredProductsRows, setAcquiredProducts] = useState([]);
  const [isRutValid, setIsRutValid] = useState({ patient: false, message: "" });

  const totalWidth = acquiredProductsColumns
    .map((x) => (x.width ? x.width : 0))
    .reduce((accumulator, currentValue) => accumulator + currentValue);

  const handleChange = (event) => {
    updateForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };

  /* Set acquired and available products via get inscriptions */
  const updateAllProducts = (rut) => {
    // custom dbGet --> getInscriptionsByPatientRut
    getInscriptionsByPatientRut(rut).then((data) => {
      data = data.sort((a, b) => {
        if (a.presentation.name > b.presentation.name) return 1;
        if (a.presentation.name < b.presentation.name) return -1;
        // a must be equal to b
        return 0;
      });
      setAcquiredProducts(data);
    });
  };

  const updateFormPatient = (rut) => {
    if (!rut) return;
    dbGet(`patients/${rut}`).then((data) => {
      if (Object.keys(data).length === 0) {
        setIsRutValid({ patient: false, message: "Paciente no registrado" });
        return;
      }
      updateForm({
        ...form,
        patientEmail: data.email,
      });
      updateAllProducts(rut);
    });
  };
  const fetchPatient = () => {
    var currentRut = form.patientRut + form.patientRutVerifier;
    var currentRutValidation = verifyRut(currentRut);
    if (currentRutValidation && form.patientRutVerifier) {
      updateFormPatient(currentRut);
      setIsRutValid({ patient: currentRutValidation, message: "" });
    } else if (currentRutValidation && !form.patientRutVerifier) {
      setIsRutValid({
        patient: false,
        message: "Ingrese el dígito verificador en el campo respectivo.",
      });
    } else {
      updateForm({
        ...form,
        patientEmail: "",
      });
      setAcquiredProducts([]);
      setIsRutValid({ patient: currentRutValidation, message: "RUT invalido" });
    }
  };

  const onRutChangePatient = (e) => {
    updateForm({ ...form, patientRut: e.target.value.toUpperCase() });
  };
  const onRutChangeVerifier = (e) => {
    updateForm({ ...form, patientRutVerifier: e.target.value.toUpperCase() });
  };

  React.useEffect(() => {
    if (!form.patientRut && !form.patientRutVerifier) return; // no fetch on page load
    fetchPatient();
  }, [form.patientRut, form.patientRutVerifier]);

  // Program switch logic
  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  const updateFormPatientOnProgramIdChange = () => {
    updateFormPatient(form.patientRut + form.patientRutVerifier);
  };
  React.useEffect(() => {
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);
  React.useEffect(() => {
    updateFormPatientOnProgramIdChange();
  }, [programId]);

  return (
    <>
      <div>
        <PageTitle
          title="Consulta de productos habilitados"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del paciente
              </Typography>
              <RutField
                labelName="RUT * (sin guión)"
                required={true}
                id="patientRut"
                id2="patientRutVerifier"
                value={form.patientRut}
                divClassType={classes.smallItems2}
                divClassType2={classes.generalPurposeItems2}
                fieldClassType={classes.smallFieldValidation2}
                fieldClassType2={classes.smallFieldValidation3}
                onChange={onRutChangePatient}
                helperText={isRutValid.message}
                error={!isRutValid.patient}
                value2={form.patientRutVerifier}
                onChange2={onRutChangeVerifier}
              ></RutField>
              <Field
                labelName="Email"
                required={false}
                id="patientEmail"
                disabled={true}
                value={form.patientEmail}
                onChange={handleChange}
                divClassType={classes.generalPurposeItems}
                fieldClassType={classes.generalPurposeField}
              ></Field>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Productos habilitados
              </Typography>
              <div
                className={classes.generalPurposeItems}
                style={{ marginTop: "2%", marginBottom: "1%" }}
              >
                <Typography className={classes.text} weight="bold">
                  Ya habilitados ({acquiredProductsRows.length})
                </Typography>
              </div>
              <div
                className={classes.generalPurposeField}
                style={{ height: 350, width: totalWidth + 20 }}
              >
                <DataGrid
                  rows={acquiredProductsRows}
                  columns={acquiredProductsColumns}
                  disableColumnMenu={true}
                  pageSize={25}
                  checkboxSelection={false}
                  disableSelectionOnClick={true}
                  showToolbar={true}
                  localeText={dataGridTexts}
                  disableColumnSelector={true}
                  hideFooter={true}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
