import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  Chip,
  Input,
  Divider,
  CircularProgress,
} from "@material-ui/core";

// styles
import useStyles from "./styles";

import { getCurrentDate } from "utils/dateGetters.js";
import { format } from "date-fns";
import SelectField from "components/CustomFields/SelectField";
import DateField from "components/CustomFields/DateField";
import DistributorsField from "components/CustomFields/DistributorsField";
import ZonesField from "components/CustomFields/ZonesField";
import { dbGet } from "utils/DBFetchers";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const getDateFormatted = (date) => {
  return format(date, "yyyy/MM");
};

const status = {
  todos: 0,
  validos: 1,
  invalidos: 2,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MIN_IMPORT_ID_LEN = 13;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const errorsMap = {
  // SALES
  "Descuento porcentual informado cero": 1,
  "Presentación inexistente": 2,
  "Unidades totales informadas excedidas": 3,
  "Unidades totales informadas inválidas": 4,
  "Descuento informado cero": 5,
  "Vademécum inexistente para presentación": 14,
  "Precio GES inexistente para presentación": 15,
  "Descuento Mi Salco inexistente para presentación": 16,
  "Paciente inexistente": 18,
  "Empadronamiento inexistente para paciente": 19,
  "Distribuidor incorrecto para la fecha": 22,
  "Farmacia inexistente.": 23,
  "Fecha de venta invalida": 24,
  //"Empadronamiento expirado": 20,
  "Empadronamiento expirado": 21,
  // INSCRIPTIONS
  "Vademecum inexistente": 3,
  "Código de barras inexistente": 4,
  "Presentacion inexistente": 5,
  "Paciente ya tiene el producto": 7,
  "Fecha de empadronamiento invalida": 8,
  "Farmacia inexistente": 9,
  "Descuento erróneo": 10,
  "Paciente inexistente error": 11,
};

export default function SelectionForm({
  filters,
  setFilters,
  setIsLoading = null,
  hideTypeSelector = false,
  ...props
}) {
  var classes = useStyles();
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedDistributor, setSelectedDistributor] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedType, setSelectedType] = useState("total");
  const [allZones, setAllZones] = useState(["zona A", "zona B"]);
  const [observationsName, setObservationsName] = React.useState([]);
  const [invalids, setInvalids] = React.useState(false);
  const [openImportIDDialog, setOpenImportIDDialog] = useState(false);
  const [possiblesImportsIDs, setPossiblesImportsIDs] = useState([]);
  const allTypes = [
    { name: "Total", id: "total" },
    { name: "Tradicionales", id: "traditionals" },
    { name: "Quemables", id: "burnable" },
  ];

  const SelectImportID = () => {
    return (
      <Select
        value={filters.importID}
        onChange={handleImportIDChange}
        variant="outlined"
        margin="dense"
        className={classes.generalSelectionField}
      >
        {possiblesImportsIDs.map((importID) => {
          return (
            <MenuItem value={importID.id ? importID.id : -1}>
              {importID.label}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const handleImportIDChange = (event) => {
    setFilters({ ...filters, importID: event.target.value });
  };

  const handleClose = () => {
    setOpenImportIDDialog(false);
  };

  const handleDateChange = (date) => {
    var dateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setFilters({
      ...filters,
      period: getDateFormatted(dateOnFirstDay),
    });
  };

  const handleDistributorChange = (event) => {
    setSelectedDistributor(event.target.name);
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  const handleZoneChange = (event) => {
    setSelectedZone(event.target.name);
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  const handleProgramChange = (event) => {
    setSelectedProgram(event.target.value);
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  const handleObservationChange = (event) => {
    let errorsId = event.target.value.map((value) => {
      return errorsMap[value];
    });
    setObservationsName(event.target.value);
    setFilters({
      ...filters,
      [event.target.name]: errorsId,
    });
  };

  const handleStatusChange = (event) => {
    setInvalids(event.target.value === "invalidos");
    setFilters({ ...filters, [event.target.name]: status[event.target.value] });
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  // Get zones (and in the future distributors too) from back
  useEffect(() => {
    getZones();
    // getDistributors();
  }, []);

  const parseImportIDAsDate = (importID) => {
    return new Date(
      +importID.slice(4, 8), // year
      +importID.slice(2, 4) - 1, // month
      +importID.slice(0, 2), // day
      +importID.slice(8, 10), // hour
      +importID.slice(10, 12), // minute
    );
  };

  useEffect(() => {
    setPossiblesImportsIDs([]);
    setOpenImportIDDialog(false);
    if (filters.distributor && filters.period && filters.program) {
      const periodFormated = filters.period.split("/").join("");
      const period = `period=${periodFormated}`;
      const distributorId = `${
        filters.distributor ? `&distributor_id=${filters.distributor}` : ""
      }`;
      const programId = `&program_id=${filters.program}`;
      const auditType = props.sales ? "sales" : "inscriptions";
      setIsLoading && setIsLoading(true);
      dbGet(
        `audit/${auditType}/diff/import_id?${period}${distributorId}${programId}`,
      )
        .then((data) => {
          setIsLoading && setIsLoading(false);
          if (data.length > 1) {
            let sortedImports = data.sort((a, b) => {
              if (!a || a.toString().length < MIN_IMPORT_ID_LEN) return -1; // non existent ids and old import ids (shorter format) should go first
              if (!b || b.toString().length < MIN_IMPORT_ID_LEN) return 1; // non existent ids and old import ids (shorter format) should go first
              const aDate = parseImportIDAsDate(a.toString());
              const bDate = parseImportIDAsDate(b.toString());
              if (aDate > bDate) return 1;
              if (aDate < bDate) return -1;
              return 0;
            });

            sortedImports = sortedImports.map((importID, index) => {
              return { id: importID, label: `Importación ${index + 1}` };
            });

            setPossiblesImportsIDs(sortedImports);
            setOpenImportIDDialog(true);
          } else {
            setFilters({ ...filters, importID: data.lenght ? data[0] : "" });
          }
        })
        .catch((err) => {
          setIsLoading && setIsLoading(false);
        });
    }
  }, [filters.distributor, filters.period, filters.program]);

  const getZones = () => {
    dbGet("zones")
      .then((data) => {
        if (data.length === 0) {
          return;
        }
        let zones = data.map((zone) => {
          return {
            name: zone.name,
            id: zone.id,
          };
        });
        zones.unshift({ name: "Todas", id: "0" });
        setAllZones(zones);
      })
      .catch((err) => {});
  };

  return (
    <>
      <Grid
        item
        xs={12}
        lg={12}
        style={{ marginBottom: "65px", marginLeft: "2.5%" }}
      >
        <Typography variant="h4" className={classes.alignedText}>
          Selección
        </Typography>
        <Divider />
      </Grid>

      <Grid item xs={5} className={classes.alignRight}>
        <Typography className={classes.alignedText} weight="bold">
          Período *
        </Typography>
      </Grid>
      <Grid item xs={7} className={classes.alignLeft}>
        <DateField
          views={["month", "year"]}
          minDate={new Date("2013-10-01")}
          maxDate={getCurrentDate()}
          format={"MM/yyyy"}
          value={filters.period ? filters.period : getCurrentDate()}
          onChange={handleDateChange}
          fieldClassName={classes.dateSelectionField}
        />
      </Grid>

      <Grid item xs={5} className={classes.alignRight}>
        <Typography className={classes.alignedText} weight="bold">
          Programa *
        </Typography>
      </Grid>
      <Grid item xs={7} className={classes.alignLeft}>
        <SelectField
          fieldClassName={classes.generalSelectionField}
          id="programs"
          label="Seleccione"
          name="program"
          value={selectedProgram ? selectedProgram : ""}
          onChange={handleProgramChange}
          allValues={props.programs ? props.programs : []}
        />
      </Grid>

      <Grid item xs={5} className={classes.alignRight}>
        <Typography className={classes.alignedText} weight="bold">
          Cadena *
        </Typography>
      </Grid>
      <Grid item xs={7} className={classes.alignLeft}>
        <DistributorsField
          fieldClassName={classes.generalSelectionField}
          value={selectedDistributor ? filters[selectedDistributor] : ""}
          program={selectedProgram}
          onChange={handleDistributorChange}
          independents={props.independents}
        />
      </Grid>

      <Grid item xs={5} className={classes.alignRight}>
        <Typography className={classes.alignedText} weight="bold">
          Zona
        </Typography>
      </Grid>
      <Grid item xs={7} className={classes.alignLeft}>
        <ZonesField
          fieldClassName={classes.generalSelectionField}
          value={selectedZone ? filters[selectedZone] : ""}
          onChange={handleZoneChange}
          allValues={allZones}
        />
      </Grid>

      {!hideTypeSelector && selectedProgram === 1 && (
        <>
          <Grid item xs={5} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Tipo
            </Typography>
          </Grid>
          <Grid item xs={7} className={classes.alignLeft}>
            <SelectField
              id="type"
              label="Seleccione"
              name="type"
              fieldClassName={classes.generalSelectionField}
              value={selectedType ? selectedType : filters.type.name}
              onChange={handleTypeChange}
              allValues={allTypes}
            />
          </Grid>
        </>
      )}

      {possiblesImportsIDs.length > 0 && (
        <>
          <Grid item xs={5} className={classes.alignRight}>
            <Typography className={classes.alignedText} weight="bold">
              Fecha de importación
            </Typography>
          </Grid>
          <Grid item xs={7} className={classes.alignLeft}>
            <SelectImportID />
          </Grid>
        </>
      )}

      <Grid item xs={5} className={classes.alignRight}>
        <Typography className={classes.alignedText} weight="bold">
          Estado *
        </Typography>
      </Grid>
      <Grid item xs={7} className={classes.alignLeft}>
        <RadioGroup
          row
          aria-label="position"
          name="status"
          defaultValue="todos"
          className={classes.rowValueItems}
          onChange={handleStatusChange}
        >
          <FormControlLabel
            value="todos"
            control={<Radio color="primary" />}
            label="Todos"
          />
          <FormControlLabel
            value="validos"
            control={<Radio color="primary" />}
            label="Válidos"
          />
          <FormControlLabel
            value="invalidos"
            control={<Radio color="primary" />}
            label="Inválidos"
          />
        </RadioGroup>
      </Grid>

      {invalids ? (
        <>
          <Grid
            item
            xs={5}
            className={classes.alignRight}
            style={{ marginTop: "10px" }}
          >
            <Typography className={classes.alignedText} weight="bold">
              Errores
            </Typography>
          </Grid>
          <Grid items xs={7} className={classes.alignLeft}>
            <Select
              id="observations"
              name="observations"
              className={classes.errorSelectionField}
              multiple
              value={observationsName}
              onChange={handleObservationChange}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div>
                  {selected.map((value) => {
                    return (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    );
                  })}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {props.errors.map((observation) => {
                return (
                  <MenuItem key={observation.id} value={observation.name}>
                    {observation.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </>
      ) : (
        <></>
      )}

      <Grid
        items
        xs={12}
        style={{ marginTop: "20px" }}
        className={classes.uploadButton}
      >
        <div className={classes.buttonSpacing}>
          <Button
            variant="contained"
            color="primary"
            onClick={props.applyFunction}
            disabled={props.isLoading || props.isLoadingButton}
          >
            Aplicar
          </Button>
          {props.isLoadingButton && <CircularProgress />}
        </div>
      </Grid>
    </>
  );
}
