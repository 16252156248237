import React, { useEffect, useState } from "react";
import { dbGet } from "utils/DBFetchers";
import SelectField from "./SelectField";

import useStyles from "./styles";

export default function DistributorsField(props) {
  var classes = useStyles();
  const [allDistributors, setAllDistributors] = useState([]);
  const [selectedDistributor, setSelectedDistributor] = useState("");

  const getDistributors = () => {
    dbGet("distributors").then((data) => {
      if (props.filterByProgram) {
        data = data.filter((distributor) =>
          distributor.programs.find(
            (y) => y.id === +localStorage.getItem("programId"),
          ),
        );
      }
      if (props.program) {
        data = data.filter((distributor) =>
          distributor.programs.find((program) => program.id === +props.program),
        );
      }
      if (props.independents) {
        data = data.filter((x) => x.works_with_independents);
      } else if (props.independents === false) {
        data = data.filter((x) => !x.works_with_independents && x.id !== 19);
      }
      if (props.filterByDistributor) {
        data = data.filter((x) => x.id !== 19);
      }
      let mapped = data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          isAuthoritarian: item.is_authoritarian,
        };
      });
      if (props.all)
        mapped.unshift({
          id: -1,
          name: "Todos",
          isAuthoritarian: true,
        });
      setAllDistributors(mapped);
    });
  };

  useEffect(() => {
    if (props.program) getDistributors();
  }, [props.program]);

  useEffect(() => {
    getDistributors();
  }, []);

  return (
    <SelectField
      fieldClassName={props.fieldClassName}
      id="distributors"
      label="Seleccione"
      name="distributor"
      value={props.value}
      onChange={props.onChange}
      allValues={allDistributors ? allDistributors : []}
    />
  );
} 
