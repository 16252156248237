import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// styles
import useStyles from "./styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomizedSnackbars(props) {
  const classes = useStyles();
  const vertical = props.vertical ? props.vertical : 'top';
  const horizontal = props.horizontal ? props.horizontal : 'center';
  return (
    <div className={classes.root}>
      <Snackbar
        open={props.open}
        autoHideDuration={props.duration === 0? null : 6000}
        onClose={props.onClose}
        anchorOrigin={{ vertical , horizontal }}
      >
        <Alert onClose={props.onClose} severity={props.severity}>
          {props.mssg}
        </Alert>
      </Snackbar>
    </div>
  );
}
