import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";
import { format } from "date-fns";

import Field from "components/CustomFields/Field.js";
import DateField from "components/CustomFields/DateField.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { dbGet, dbPut } from "utils/DBFetchers.js";
import { getCurrentDate } from "utils/dateGetters.js";
import { cleanEmptyKeys } from "utils/cleanEmptyKeys";
import { useHistory } from "react-router-dom";
import routes from "routes";
import moment from "moment";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";
import NumberFormatter from "components/CustomFields/NumberFormatter";
import SKUFormatter from "components/CustomFields/SKUFormatter";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

const blankEditProductForm = {
  id: "",
  nextListPrice: "",
  nextListPriceOnDate: null,
  nextGesPrice: "",
  nextGesPriceOnDate: null,
  listPrice: "",
  listPriceToDate: null,
  gesPrice: "",
  gesPriceToDate: null,
  name: "",
  sku: "",
  salcoCode: "",
  ahumadaCode: "",
  cruzVerdeCode: "",
};

export default function ABMProductsEditPage(props) {
  var classes = useStyles();
  const history = useHistory();
  var [isLoading, setIsLoading] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const [allProducts, setAllProducts] = useState([]);
  const [isSkuInvalid, setIsSkuInvalid] = useState({ sku: false, message: "" });
  const [editProductForm, updateForm] = useState(blankEditProductForm);
  const [nextPriceCheck, setNextPriceCheck] = useState({
    listPrice: null,
    listPriceDate: null,
    gesPrice: null,
    gesPriceDate: null,
  });

  const handleChange = (event) => {
    updateForm({
      ...editProductForm,
      [event.target.name]: event.target.value,
    });
  };
  const handleListPriceDateChange = (date) => {
    updateForm({ ...editProductForm, nextListPriceOnDate: date });
  };
  const handleGesPriceDateChange = (date) => {
    updateForm({ ...editProductForm, nextGesPriceOnDate: date });
  };
  const handleSkuChange = (event) => {
    updateForm({
      ...editProductForm,
      sku: event.target.value,
    });
    var skuExists = allProducts.some(
      (elem) => +elem.sku === +event.target.value,
    );
    if (skuExists)
      setIsSkuInvalid({
        sku: skuExists,
        message: "Producto ya existente.",
      });
    else if (event.target.value.length < 12 || event.target.value.length > 13)
      setIsSkuInvalid({
        sku: true,
        message: "SKU debe tener entre 12 y 13 dígitos.",
      });
    else setIsSkuInvalid({ sku: false, message: "" });
  };

  /* Get all data */
  const getProductInfo = (productId) => {
    dbGet(`presentations/${productId}`).then((data) => {
      let presentation = data.presentation;
      let distributor_codes = data.distributor_codes;
      updateForm({
        ...editProductForm,
        id: productId,
        listPrice: presentation.list_price?.value,
        listPriceToDate: presentation.list_price?.to_date
          ? moment(presentation.list_price.to_date, "DD/MM/YY")
              .subtract(1, "days")
              .toDate()
          : null,
        gesPrice: presentation.ges_price ? presentation.ges_price.value : "",
        gesPriceToDate: presentation.ges_price
          ? presentation.ges_price.to_date
            ? moment(presentation.ges_price.to_date, "DD/MM/YY")
                .subtract(1, "days")
                .toDate()
            : null
          : null,
        name: presentation.name,
        sku: presentation.sku,
        salcoCode: distributor_codes.salcobrand_code
          ? distributor_codes.salcobrand_code
          : "",
        ahumadaCode: distributor_codes.ahumada_code
          ? distributor_codes.ahumada_code
          : "",
        cruzVerdeCode: distributor_codes.cruz_verde_code
          ? distributor_codes.cruz_verde_code
          : "",
        nextListPrice: presentation.next_list_price
          ? presentation.next_list_price.value
          : "",
        nextListPriceOnDate: presentation.next_list_price
          ? moment(presentation.next_list_price.from_date, "DD/MM/YY").toDate()
          : null,
        nextGesPrice: presentation.next_ges_price
          ? presentation.next_ges_price.value
          : "",
        nextGesPriceOnDate: presentation.next_ges_price
          ? moment(presentation.next_ges_price.from_date, "DD/MM/YY").toDate()
          : null,
      });
      if (presentation.next_list_price) {
        setNextPriceCheck({
          ...nextPriceCheck,
          listPrice: presentation.next_list_price.value,
          listPriceDate: moment(
            presentation.next_list_price.from_date,
            "DD/MM/YY",
          ).toDate(),
        });
      }
      if (presentation.next_ges_price) {
        setNextPriceCheck({
          ...nextPriceCheck,
          gesPrice: presentation.next_ges_price.value,
          gesPriceDate: moment(
            presentation.next_ges_price.from_date,
            "DD/MM/YY",
          ).toDate(),
        });
      }
    });
  };

  /* Get all data */
  const getProducts = (productId) => {
    dbGet("presentations").then((data) => {
      let mapped = data.map((item) => {
        return {
          id: item["id"],
          name: item["name"],
          sku: item["sku"],
        };
      });
      setAllProducts(mapped.filter((item) => +item.id !== +productId));
    });
  };

  /* Basic form check */
  const checkFormComplete = () => {
    if (
      !editProductForm.id ||
      !editProductForm.name ||
      !editProductForm.sku ||
      isSkuInvalid.sku ||
      (!editProductForm.nextListPriceOnDate && editProductForm.nextListPrice) ||
      (!editProductForm.nextGesPriceOnDate && editProductForm.nextGesPrice) ||
      (editProductForm.nextListPriceOnDate && !editProductForm.nextListPrice) ||
      (editProductForm.nextGesPriceOnDate && !editProductForm.nextGesPrice)
    )
      return false;
    return true;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder editar el producto.",
      );
      return;
    }
    setIsLoading(true);

    // Give adecuate format to send form to db
    let completedForm = {
      name: editProductForm.name,
      sku: +editProductForm.sku,
      salcobrand_code: editProductForm.salcoCode,
      cruz_verde_code: editProductForm.cruzVerdeCode,
      ahumada_code: editProductForm.ahumadaCode,
    };
    // New list price check
    if (
      (editProductForm.nextListPriceOnDate &&
        editProductForm.nextListPrice &&
        !nextPriceCheck.listPriceDate) ||
      (nextPriceCheck.listPriceDate &&
        (editProductForm.nextListPriceOnDate.getTime() !==
          nextPriceCheck.listPriceDate.getTime() ||
          +editProductForm.nextListPrice !== +nextPriceCheck.listPrice))
    ) {
      completedForm["next_list_price"] = {
        value: +editProductForm.nextListPrice,
        on_date: editProductForm.nextListPriceOnDate
          ? format(new Date(editProductForm.nextListPriceOnDate), "dd/M/yy")
          : null,
      };
    }
    // New Ges price check
    if (
      (editProductForm.nextGesPriceOnDate &&
        editProductForm.nextGesPrice &&
        !nextPriceCheck.gesPriceDate) ||
      (nextPriceCheck.gesPriceDate &&
        (editProductForm.nextGesPriceOnDate.getTime() !==
          nextPriceCheck.gesPriceDate.getTime() ||
          +editProductForm.nextGesPrice !== +nextPriceCheck.gesPrice))
    ) {
      completedForm["next_ges_price"] = {
        value: +editProductForm.nextGesPrice,
        on_date: editProductForm.nextGesPriceOnDate
          ? format(new Date(editProductForm.nextGesPriceOnDate), "dd/M/yy")
          : null,
      };
    }

    completedForm = cleanEmptyKeys(completedForm);

    // Sends to db
    dbPut(`presentations/${editProductForm.id}`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Producto editado exitosamente");
        redirectToProductsPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al editar el producto",
        ); // translate pending
        setIsLoading(false);
        return;
      });
  };

  /* Cancel editing product and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankEditProductForm);
    history.goBack();
  };

  /* Redirect to products table page after editing correctly */
  const redirectToProductsPage = (user) => {
    setTimeout(() => {
      updateForm(blankEditProductForm);
      setIsLoading(false);
      history.push(routes.abmProducts);
    }, 2000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    if (props.props.location.state) {
      getProductInfo(props.props.location.state.productId);
      getProducts(props.props.location.state.productId);
    }
  }, []);

  const title = `Editar Producto${editProductForm.name ? ": " : ""}${
    editProductForm.name ? editProductForm.name : ""
  }`;

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del producto
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  Puede modificar el nombre del producto, el sku asociado o la
                  fecha de validez (y de ser así, su precio respectivo para esa
                  nueva fecha).
                </Typography>
              </div>
              <div style={{ marginTop: "4%" }}>
                <Field
                  labelName="Nombre"
                  required={true}
                  id="name"
                  value={editProductForm.name}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <Field
                  labelName="SKU"
                  required={true}
                  id="sku"
                  value={editProductForm.sku}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleSkuChange}
                  error={isSkuInvalid.sku}
                  helperText={isSkuInvalid.message}
                  InputProps={{
                    inputComponent: SKUFormatter,
                  }}
                ></Field>
                <Field
                  labelName="Código interno Salcobrand"
                  required={false}
                  id="salcoCode"
                  value={editProductForm.salcoCode}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  // error={isSkuInvalid.sku}
                  // helperText={isSkuInvalid.message}
                  InputProps={{
                    inputComponent: NumberFormatter,
                  }}
                ></Field>
                <Field
                  labelName="Código interno Ahumada"
                  required={false}
                  id="ahumadaCode"
                  value={editProductForm.ahumadaCode}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  // error={isSkuInvalid.sku}
                  // helperText={isSkuInvalid.message}
                  InputProps={{
                    inputComponent: NumberFormatter,
                  }}
                ></Field>
                <Field
                  labelName="Código interno Cruz Verde"
                  required={false}
                  id="cruzVerdeCode"
                  value={editProductForm.cruzVerdeCode}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  // error={isSkuInvalid.sku}
                  // helperText={isSkuInvalid.message}
                  InputProps={{
                    inputComponent: NumberFormatter,
                  }}
                ></Field>
                <Field
                  labelName="Precio lista actual ($)"
                  id="listPrice"
                  disabled={true}
                  value={editProductForm.listPrice}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <div className={classes.generalPurposeItems}>
                  <Typography component={"span"} weight="bold">
                    Precio Lista actual
                    <Typography weight="bold">válido hasta</Typography>
                  </Typography>
                  <DateField
                    name="listPriceToDate"
                    disabled={true}
                    format="dd/MM/yyyy"
                    helperText={
                      editProductForm.listPriceToDate ? "" : "Sin expiración"
                    }
                    value={editProductForm.listPriceToDate}
                    fieldClassName={classes.generalPurposeField}
                  />
                </div>
                <Field
                  labelName="Precio GES actual ($)"
                  id="gesPrice"
                  disabled={true}
                  value={editProductForm.gesPrice}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <div className={classes.generalPurposeItems}>
                  <Typography component={"span"} weight="bold">
                    Precio GES actual
                    <Typography weight="bold">válido hasta</Typography>
                  </Typography>
                  <DateField
                    name="gesPriceToDate"
                    disabled={true}
                    format="dd/MM/yyyy"
                    helperText={
                      editProductForm.gesPriceToDate ? "" : "Sin expiración"
                    }
                    value={editProductForm.gesPriceToDate}
                    fieldClassName={classes.generalPurposeField}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                className={classes.text}
                style={{ marginTop: "2%" }}
              >
                Establecer próximo precio
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  Establezca una nueva fecha de validez y precio para cada tipo
                  de precio en particular para los cuales el producto tendrá
                  efecto.
                </Typography>
              </div>
              <div style={{ marginTop: "4%" }}>
                <div className={classes.generalPurposeItems}>
                  <Typography component={"span"} weight="bold">
                    Nueva fecha inicial
                    <Typography weight="bold">Precio Lista</Typography>
                  </Typography>
                  <DateField
                    name="nextListPriceOnDate"
                    value={editProductForm.nextListPriceOnDate}
                    format="dd/MM/yyyy"
                    onChange={handleListPriceDateChange}
                    fieldClassName={classes.generalPurposeField}
                    minDate={
                      editProductForm.onDate
                        ? editProductForm.onDate
                        : getCurrentDate()
                    }
                  />
                </div>
                <Field
                  labelName="Nuevo Precio de lista ($)"
                  id="nextListPrice"
                  value={editProductForm.nextListPrice}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: NumberFormatter,
                  }}
                ></Field>
                <div className={classes.generalPurposeItems}>
                  <Typography component={"span"} weight="bold">
                    Nueva fecha inicial
                    <Typography weight="bold">Precio GES</Typography>
                  </Typography>
                  <DateField
                    name="nextGesPriceOnDate"
                    value={editProductForm.nextGesPriceOnDate}
                    format="dd/MM/yyyy"
                    onChange={handleGesPriceDateChange}
                    fieldClassName={classes.generalPurposeField}
                    minDate={
                      editProductForm.onDate
                        ? editProductForm.onDate
                        : getCurrentDate()
                    }
                  />
                </div>
                <Field
                  labelName="Nuevo Precio GES ($)"
                  required={true}
                  id="nextGesPrice"
                  value={editProductForm.nextGesPrice}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: NumberFormatter,
                  }}
                ></Field>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelAndGoBack}
                >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
