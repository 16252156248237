const dataGridTexts = {
  // Root
  rootGridLabel: "grid",
  noRowsLabel: "Sin datos",
  errorOverlayDefaultLabel: "Ocurrió un error.",

  // Density selector toolbar button text
  toolbarDensity: "Density",
  toolbarDensityLabel: "Density",
  toolbarDensityCompact: "Compact",
  toolbarDensityStandard: "Standard",
  toolbarDensityComfortable: "Comfortable",

  // Columns selector toolbar button text
  toolbarColumns: "Columns",
  toolbarColumnsLabel: "Show Column Selector",

  // Filters toolbar button text
  toolbarFilters: "Filters",
  toolbarFiltersLabel: "Show Filters",
  toolbarFiltersTooltipHide: "Hide Filters",
  toolbarFiltersTooltipShow: "Show Filters",
  toolbarFiltersTooltipActive: (count) => `${count} active filter(s)`,

  // Columns panel text
  columnsPanelTextFieldLabel: "Find column",
  columnsPanelTextFieldPlaceholder: "Column title",
  columnsPanelDragIconLabel: "Reorder Column",
  columnsPanelShowAllButton: "Show All",
  columnsPanelHideAllButton: "Hide All",

  // Filter panel text
  filterPanelAddFilter: "Add Filter",
  filterPanelDeleteIconLabel: "Delete",
  filterPanelOperators: "Operators",
  filterPanelOperatorAnd: "And",
  filterPanelOperatorOr: "Or",
  filterPanelColumns: "Columns",

  // Filter operators text
  filterOperatorContains: "contains",
  filterOperatorEquals: "equals",
  filterOperatorStartsWith: "starts with",
  filterOperatorEndsWith: "ends with",
  filterOperatorIs: "is",
  filterOperatorNot: "is not",
  filterOperatorOnOrAfter: "is on or after",
  filterOperatorBefore: "is before",
  filterOperatorOnOrBefore: "is on or before",

  // Column menu text
  columnMenuLabel: "Menu",
  columnMenuShowColumns: "Mostrar columnas",
  columnMenuFilter: "Filtrar",
  columnMenuHideColumn: "Ocultar",
  columnMenuUnsort: "No ordenar",
  columnMenuSortAsc: "Ord por asc",
  columnMenuSortDesc: "Ord por desc",

  // Column header text
  columnHeaderFiltersTooltipActive: (count) => `${count} active filter(s)`,
  columnHeaderFiltersLabel: "Show Filters",
  columnHeaderSortIconLabel: "Sort",

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} productos seleccionados`
      : `${count.toLocaleString()} producto seleccionado`,

  // Total rows footer text
  footerTotalRows: "Total Rows:",

  // Pagination footer text
  footerPaginationRowsPerPage: "Filas por página:",
  //labelRowsPerPage: "Filas por página:",
  //labelDisplayedRows: "Filas por página:",
};

export default dataGridTexts;
