import React, { useState, useEffect } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Paper } from "@material-ui/core";
import AccreditCouponForm from "pages/coupon_managment/Forms/AccreditCouponForm";

import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

import { dbGet, dbPost } from "utils/DBFetchers.js";
import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";

export default function AccreditCouponPage() {
  var classes = useStyles();
  const [medics, setMedics] = useState([]);
  const [medicsFiltered, setMedicsFiltered] = useState([]);
  const [program, setProgram] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCouponsMessage, setLoadingCouponsMessage] = useState("-");
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const history = useHistory();
  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  const blankMedicForm = {
    id: 0,
    name: "",
    firstName: "",
    lastName: "",
    rut: "",
    currentCouponCountRS: 0,
    currentCouponCountSM: 0,
    accreditCouponCount: "",
  };
  const [medicForm, updateMedicForm] = useState(blankMedicForm);
  const [couponsMaxAndRemaining, setCouponsMaxAndRemaining] = useState({
    total: 0,
    remaining: 0,
  });
  const [couponsError, setCouponsError] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);

  const handleChange = (event) => {
    updateMedicForm({
      ...medicForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleAccreditCouponChange = (event) => {
    if (event.target.value && medicForm.name) {
      setButtonIsDisabled(false);
    } else {
      setButtonIsDisabled(true);
    }
    if (event.target.value > couponsMaxAndRemaining.remaining) {
      setCouponsError(true);
    } else {
      setCouponsError(false);
    }
    updateMedicForm({
      ...medicForm,
      accreditCouponCount:
        !isNaN(event.target.value) && event.target.value < 300
          ? event.target.value
          : medicForm.accreditCouponCount,
    });
  };

  const handleMedicSelect = (event, value) => {
    if (!value) {
      updateMedicForm(blankMedicForm);
      setCouponsMaxAndRemaining({ total: 0, remaining: 0 });
      setLoadingCouponsMessage("-")
      return;
    }
    setLoadingCouponsMessage("Cargando cupones...")

    updateMedicForm({
      ...medicForm,
      name: value ? value.name : "",
      id: value ? value.id : 0,
      firstName: value ? value.firstName : "",
      lastName: value ? value.lastName : "",
      rut: value ? value.rut : "",
      currentCouponCountRS: value ? value.currentCouponCountRS : 0,
      currentCouponCountSM: value ? value.currentCouponCountSM : 0,
      accreditCouponCount: medicForm.accreditCouponCount,
    });

    if (value.rut) {
      dbGet(`/consultants/coupons_remaining/${value.rut}`).then((response) => {
        setCouponsMaxAndRemaining(response);
        setLoadingCouponsMessage("")
      });
    }
  };

  // update program ID
  useEffect(() => {
    setProgramId(localStorage.getItem("programId"));
  }, [programId]);

  useEffect(() => {
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);

  const getMedics = () => {
    setIsLoading(true);
    if (medicForm.rut) {
      dbGet(`/consultants/coupons_remaining/${medicForm.rut}`).then(
        (response) => {
          setCouponsMaxAndRemaining(response);
          setButtonIsDisabled(true);
        },
      );
    }

    dbGet(`consultants`)
      .then((data) => {
        let medicsData = data.medics.map((medic) => {
          return {
            id: medic.id,
            name: medic.name ? medic.name : "S/N" + " | " + medic.rut,
            firstName: medic.name,
            rut: medic.rut,
            programs: medic.programs,
            currentCouponCountRS: medic.available_coupons_rs,
            currentCouponCountSM: medic.available_coupons_sm,
            accreditCouponCount: 0,
          };
        });
        setMedics(medicsData);
        setIsLoading(false)
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al traer los datos",
        );
        setIsLoading(false)
   });

  };

  const filterMedics = () => {
    setMedicsFiltered(
      medics.filter((medic) => medic.programs?.includes(program)),
    );
  };

  // get medics
  useEffect(() => {
    setIsLoading(true)
    getMedics();
  }, []);

  useEffect(() => {
    filterMedics();
    localStorage.setItem("programId", program);
    window.dispatchEvent(new Event("storage"));
  }, [program, medics]);

  const handleSubmit = (event, value) => {
    event.preventDefault();
    updateMedicForm({
      ...medicForm,
      accreditCouponCount: value ? value.accreditCouponCount : "",
    });
    if (medicForm.name === "") {
      snackbarOpen("error", "Debe ingresar un nombre");
      return;
    }
    if (medicForm.accreditCouponCount === "") {
      snackbarOpen("error", "Debe ingresar una cantidad de cupones");
      return;
    }
    if (parseInt(medicForm.accreditCouponCount) <= 0) {
      snackbarOpen("error", "Debe ingresar una cantidad de cupones mayor a 0");
      return;
    }

    let completedForm = {
      name: medicForm.firstName,
      last_name: medicForm.lastName,
      rut: medicForm.rut,
      amount: medicForm.accreditCouponCount,
      program_id: programId,
    };

    dbPost(
      `medics/${medicForm.id}?program_id=${programId}&amount=${medicForm.accreditCouponCount}`,
      completedForm,
    )
      .then((data) => {
        snackbarOpen("success", "Cupones acreditados");
        updateMedicForm(blankMedicForm);
        getMedics();
        updateMedicForm({
          ...medicForm,
          currentCouponCountRS:
            medicForm.currentCouponCountRS +
            (programId === "1" ? parseInt(medicForm.accreditCouponCount) : 0),
          currentCouponCountSM:
            medicForm.currentCouponCountSM +
            (programId === "2" ? parseInt(medicForm.accreditCouponCount) : 0),
          accreditCouponCount: "",
        });
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al acreditar cupones",
        );
      });
  };

  return (
    <>
      <div>
        <PageTitle title="Acreditar cupones al médico" />
        <Paper elevation={3} style={{ borderRadius: "10px" }}>
          <AccreditCouponForm
            medics={medicsFiltered}
            currentCouponCount={
              programId === "1"
                ? medicForm.currentCouponCountRS
                : medicForm.currentCouponCountSM
            }
            isLoading={isLoading}
            loadingCouponsMessage={loadingCouponsMessage}
            setProgram={setProgram}
            program={program}
            accreditCouponCount={medicForm.accreditCouponCount}
            onMedicSelectChange={handleMedicSelect}
            onAccreditCouponChange={handleAccreditCouponChange}
            upload={handleSubmit}
            couponsMaxAndRemaining={couponsMaxAndRemaining}
            couponsError={couponsError}
            buttonIsDisabled={buttonIsDisabled}
          />
        </Paper>
      </div>
      <div>
        <CustomizedSnackbars
          severity={snackbarStatus.severity}
          mssg={snackbarStatus.message}
          open={snackbarStatus.open}
          onClose={onCloseSnackbar}
          duration={0}
        />
      </div>
    </>
  );
}
