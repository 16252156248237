import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, Chip, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import CachedIcon from "@material-ui/icons/Cached";

import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";
// styles
import useStyles from "./styles";

import { dbGet, dbDisableEnable } from "utils/DBFetchers.js";

import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import LoaderBar from "components/LoaderBar/LoaderBar";
import { getRoleName } from "utils/roleNameGetter";

export default function ABMPharmaciesPage() {
  // DISABLE/ENABLE PHARMACY
  const [disablePharmacyOpen, setDisablePharmacyOpen] = React.useState(false);
  const [hasPermissions, setHasPermissions] = React.useState(false);
  const [pharmacyToDisable, setPharmacyToDisable] = React.useState({
    id: "",
    name: "",
    command: "",
  });
  const handleDisablePharmacyOpen = (pharmacyId, pharmacyName, enabled) => {
    setDisablePharmacyOpen(true);
    setPharmacyToDisable({
      id: pharmacyId,
      name: pharmacyName,
      command: enabled ? "disable" : "enable",
    });
  };
  const handleDisablePharmacyClose = () => {
    setDisablePharmacyOpen(false);
  };
  const handleDisablePharmacy = () => {
    setDisablePharmacyOpen(false);
    dbDisableEnable(
      "pharmacies",
      pharmacyToDisable.id,
      pharmacyToDisable.command,
    );
    setTimeout(() => {
      getResults("pharmacies");
    }, 700);
  };

  const history = useHistory();
  // EDIT PHARMACY
  const RedirectToEditPharmacy = (id) => {
    history.push(routes.abmPharmaciesEdit, { pharmacyId: id });
  };
  // NEW PHARMACY
  const RedirectToNewPharmacy = () => {
    history.push(routes.abmPharmaciesNew);
  };

  // Table columns definition
  const columns = [
    hasPermissions
      ? {
          name: " ",
          options: {
            download: false,
            filter: false,
            sort: false,
            setCellHeaderProps: () => {
              return {
                style: { fontWeight: "bold", minWidth: 150, width: 150 },
              };
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                  <Tooltip title="Editar">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      size="small"
                      className={classes.onlyButtonSpacing}
                      onClick={() => {
                        RedirectToEditPharmacy(tableMeta.rowData[1]);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Activar/Desactivar">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      size="small"
                      className={classes.onlyButtonSpacing}
                      onClick={() => {
                        handleDisablePharmacyOpen(
                          tableMeta.rowData[1], // id
                          tableMeta.rowData[3], // name
                          tableMeta.rowData[13], // is_enabled
                        );
                      }}
                    >
                      <CachedIcon />
                    </IconButton>
                  </Tooltip>
                </>
              );
            },
          },
        }
      : { options: { sort: false } },
    {
      name: "Id",
      options: {
        setCellHeaderProps: () => {
          return { style: { fontWeight: "bold" } };
        },
        display: "excluded",

        filter: false,
      },
    },
    {
      name: "Estado",
      options: {
        display: hasPermissions ? true : "excluded",
        filter: hasPermissions,
        sort: hasPermissions,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={tableMeta.rowData[2]} // estado
              classes={{
                root: tableMeta.rowData[13] // is_enabled
                  ? classes.success
                  : classes.secondary,
              }}
            />
          );
        },
      },
    },
    {
      name: "Nombre",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Código de farmacia",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "RUT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Domicilio",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
        filter: false,
      },
    },
    {
      name: "Comuna",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Teléfono",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
        filter: false,
      },
    },
    {
      name: "Email",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
        filter: false,
      },
    },
    {
      name: "Grupo",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Distribuidor Receta Solidaria",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Distribuidor Solo Mujer",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    { name: "Disabled", options: { display: "excluded", filter: false } },
  ];

  var classes = useStyles();
  const [tableData, setTableData] = useState([]);

  /* Fills table data */
  const fillTable = (data) => {
    if (!data) return;
    var tableData = data.map((row) => [
      "",
      row.id,
      row.is_enabled ? "Activo" : "Inactivo",
      row.name,
      row.code,
      row.rut,
      row.address,
      row.commune.name,
      row.phone,
      row.email,
      row.group.name,
      row.distributors[0] && Object.keys(row.distributors[0]).length !== 0
        ? row.distributors[0].name
        : "",
      row.distributors[1] && Object.keys(row.distributors[1]).length !== 0
        ? row.distributors[1].name
        : "",
      row.is_enabled,
    ]);
    setTableData(tableData);
  };

  var [isLoading, setIsLoading] = useState(false);
  /* Get all data */
  const getResults = (category) => {
    setIsLoading(true);
    dbGet(category)
      .then((data) => {
        fillTable(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getResults(`pharmacies`);
    setHasPermissions(
      getRoleName() === "admin" || getRoleName() === "super_admin",
    );
  }, []);

  // Program switch logic
  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  const updateTableDataOnProgramIdChange = () => {
    setTableData([]);
    getResults(`pharmacies`);
  };
  React.useEffect(() => {
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);
  React.useEffect(() => {
    if (!tableData) return; // no fetch on page load
    updateTableDataOnProgramIdChange();
  }, [programId]);

  return (
    <>
      <Dialog open={disablePharmacyOpen} onClose={handleDisablePharmacyClose}>
        <DialogTitle>{`${
          pharmacyToDisable.command === "enable" ? "Activar" : "Desactivar"
        } la farmacia ${pharmacyToDisable.name}?`}</DialogTitle>
        <DialogActions className={classes.popupDivCenter}>
          <Button
            onClick={handleDisablePharmacyClose}
            color="primary"
            variant="contained"
            className={classes.popupButtonSpacing}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDisablePharmacy}
            variant="contained"
            color="secondary"
            autoFocus
            className={
              pharmacyToDisable.command === "enable"
                ? classes.popupButtonSpacingEnable
                : classes.popupButtonSpacing
            }
          >
            {pharmacyToDisable.command === "enable" ? "Activar" : "Desactivar"}
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <PageTitle
          title="Farmacias"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      {hasPermissions ? (
        <div>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={RedirectToNewPharmacy}
            startIcon={<AddIcon />}
          >
            Añadir
          </Button>
        </div>
      ) : (
        <></>
      )}

      {isLoading ? (
        <LoaderBar />
      ) : (
        <Grid
          style={{ display: "flex", margin: 0 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
        >
          <Grid container spacing={3} xs={12}>
            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <CustomMUIDataTable
                title="Farmacias"
                data={tableData}
                columns={columns}
                filterStart={2}
                filterEnd={13}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
