// /audit/uf /period

import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { dbGet, dbPost, dbPut } from "utils/DBFetchers.js";
import { useHistory } from "react-router-dom";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { getCurrentDate } from "utils/dateGetters";
import { format } from "date-fns";
import DateField from "components/CustomFields/DateField";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";

const getDateFormatted = (date) => {
  return format(date, "yyyy/MM");
};

const blankNewUFValue = {
  period: getDateFormatted(
    new Date(getCurrentDate().getFullYear(), getCurrentDate().getMonth(), 1),
  ),
  value: "",
};

export default function UFPage() {
  const columns = [
    {
      name: " ",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return { style: { fontWeight: "bold", width: 150 } };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    handleEditUFOpen(
                      tableMeta.rowData[1], // id
                      tableMeta.rowData[2], // value
                    );
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "Id",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "Valor",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Período",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
  ];

  var classes = useStyles();
  var [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [tableData, setTableData] = useState([]);
  const blankEditUFValue = { id: "", value: "" };
  var [isLoading, setIsLoading] = useState(false);
  const [editUFOpen, setEditUFOpen] = React.useState(false);
  const [UFToEdit, setUFToEdit] = React.useState(blankEditUFValue);
  const [newUFOpen, setAddUFOpen] = React.useState(false);
  const [UFToAdd, setUFToAdd] = React.useState(blankNewUFValue);
  const handleEditUFOpen = (UFId, newValue) => {
    setEditUFOpen(true);
    setUFToEdit({
      id: UFId,
      value: newValue,
    });
  };
  const handleEditUFClose = () => {
    setEditUFOpen(false);
    setUFToEdit(blankEditUFValue);
  };
  const handleEditUFValue = () => {
    setEditUFOpen(false);
    dbPut(`audit/uf/${UFToEdit.id}`, {
      value: UFToEdit.value,
    })
      .then((data) => {
        snackbarOpen("success", "Valor UF editado exitosamente.");
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0]
            ? error[0].description_es
            : "Error al añadir el nuevo valor UF",
        );
        return;
      });
    setTimeout(() => {
      setTableData([]);
      getResults("audit/uf");
    }, 700);
  };

  const handleDateChange = (date) => {
    var dateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setUFToAdd({
      ...UFToAdd,
      period: getDateFormatted(dateOnFirstDay),
    });
  };

  const getResults = (category) => {
    setIsLoading(true);
    dbGet(category)
      .then((data) => {
        data = data.map((row) => [
          "",
          row.id,
          row.value,
          row.period.toString().substring(4) +
            "/" +
            row.period.toString().substring(0, 4),
        ]);
        setIsLoading(false);
        setTableData(data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getResults("audit/uf");
  }, []);

  const handleNewUFOpen = (id, UFValue) => {
    setAddUFOpen(true);
  };
  const handleNewUFClose = () => {
    setAddUFOpen(false);
  };
  const handleAddUF = () => {
    setAddUFOpen(false);

    // Give adecuate format to send form to db
    let periodFormated = UFToAdd.period.split("/").join("");
    let completedForm = { period: periodFormated, value: UFToAdd.value };

    dbPost(`audit/uf`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Valor UF agregado exitosamente.");
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0]
            ? error[0].description_es
            : "Error al añadir el nuevo valor UF",
        );
        return;
      });

    setTimeout(() => {
      setTableData([]);
      getResults("audit/uf");
    }, 700);
  };

  return (
    <>
      <div>
        <PageTitle
          title={"Carga UF"}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>

      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Dialog open={editUFOpen} onClose={handleEditUFClose}>
        <DialogTitle>{`Cambiar valor del UF`}</DialogTitle>
        <DialogContent>
          <DialogContentText>Elija un nuevo valor UF.</DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="value"
            value={UFToEdit.value}
            label="Valor"
            className={classes.popupButtonSpacing}
            onChange={(e) => {
              setUFToEdit({
                ...UFToEdit,
                value: e.target.value,
              });
            }}
          />
        </DialogContent>
        <DialogActions className={classes.popupDivCenter}>
          <Button
            onClick={handleEditUFClose}
            color="secondary"
            variant="contained"
            className={classes.popupButtonSpacing}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleEditUFValue}
            color="primary"
            variant="contained"
            autoFocus
            className={classes.popupButtonSpacing}
          >
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={newUFOpen} onClose={handleNewUFClose}>
        <DialogTitle>{`Añadir nuevo UF`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Elija el período y el valor UF a añadir.
          </DialogContentText>
          <DateField
            views={["year", "month"]}
            minDate={new Date("2013-10-01")} // TODO: es esta la fecha?
            maxDate={getCurrentDate()}
            format={"MM/yyyy"}
            value={UFToAdd.period}
            onChange={handleDateChange}
          />
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="value"
            value={UFToAdd.value}
            label="Valor"
            className={classes.popupButtonSpacing}
            onChange={(e) => {
              setUFToAdd({
                ...UFToAdd,
                value: e.target.value,
              });
            }}
          />
        </DialogContent>
        <DialogActions className={classes.popupDivCenter}>
          <Button
            onClick={handleNewUFClose}
            color="secondary"
            variant="contained"
            className={classes.popupButtonSpacing}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleAddUF}
            color="primary"
            variant="contained"
            autoFocus
            className={classes.popupButtonSpacing}
          >
            Añadir
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
        xs={12}
      >
        <Grid container xs={12} spacing={3}>
          <Grid item xs={12}>
            <div>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={handleNewUFOpen}
                startIcon={<AddIcon />}
              >
                Añadir
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            {/*  <Typography variant="h5" className={classes.text}>
                Datos del UF
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  A continuación ingrese los parámetros del valor UF a añadir.
                </Typography>
              </div>
              <div style={{ marginTop: "4%" }}>
                <Field
                  labelName="Valor UF"
                  required={true}
                  id="name"
                  value={filters.name}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
              </div>
              <Grid item xs={4} className={classes.alignRight}>
                <Typography className={classes.alignedText} weight="bold">
                  Período
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.alignLeft}>
                <DateField
                  views={["year", "month"]}
                  minDate={new Date("2013-10-01")} // TODO: es esta la fecha?
                  maxDate={getCurrentDate()}
                  format={"MM/yyyy"}
                  value={filters.period ? filters.period : getCurrentDate()}
                  onChange={handleDateChange}
                  fieldClassName={classes.dateSelectionField}
                />
              </Grid> */}
            <CustomMUIDataTable
              title="UFs"
              data={tableData}
              columns={columns}
              filterStart={1}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
