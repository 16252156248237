import React, { useState, useEffect } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import { useHistory } from "react-router-dom";
import { getRoleName } from "utils/roleNameGetter";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";
// styles
import useStyles from "./styles";

import { dbGet } from "utils/DBFetchers.js";

import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import LoaderBar from "components/LoaderBar/LoaderBar";

// Agregar rutas de edit y new, borrar estado y enable/disable
export default function ABMMedicsPage() {
  // CONSTS
  const RUT_POS = 2;
  const ID_POS = 1

  const history = useHistory();

  // EDIT DISTRIBUTOR
  const RedirectToEditMedic = (id, rut) => {
    history.push(routes.abmMedicsEdit, { medicId: id, medicRut: rut });
  };

  // NEW DOCTOR
  const RedirectToNewMedic = () => {
    history.push(routes.abmMedicsNew);
  };

  // Table columns definition
  const columns = [
    {
      name: " ",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return { style: { fontWeight: "bold", width: 150 } };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {getRoleName() === "admin" || getRoleName() === "super_admin" ? (
                <Tooltip title="Editar">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size="small"
                    className={classes.onlyButtonSpacing}
                    onClick={() => {
                      RedirectToEditMedic(tableMeta.rowData[ID_POS], tableMeta.rowData[RUT_POS]);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
            </>
          );
        },
      },
    },
    {
      name: "Id",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "RUT",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Nombre",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
  ];

  var classes = useStyles();
  const [tableData, setTableData] = useState([]);

  /* Fills table data */
  const fillTable = (data) => {
    if (!data) return;
    var tableData = data.map((row) => [
      "",
      row.id,
      row.rut,
      row.name,
      row.last_name,
    ]);
    setTableData(tableData);
  };

  var [isLoading, setIsLoading] = useState(false);
  /* Get all data */
  const getResults = (category) => {
    setIsLoading(true);
    dbGet(category)
      .then((data) => {
        setIsLoading(false);
        fillTable(data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  /* On page load, get all data */
  useEffect(() => {
    getResults(`medics`);
  }, []);

  return (
    <>
      <div>
        <PageTitle
          title="Médicos"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        {getRoleName() === "admin" || getRoleName() === "super_admin" ? (
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={RedirectToNewMedic}
            startIcon={<AddIcon />}
          >
            Añadir
          </Button>
        ) : (
          <></>
        )}
      </div>

      {isLoading ? (
        <LoaderBar />
      ) : (
        <Grid
          style={{ display: "flex", margin: 0 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
        >
          <Grid container spacing={3} xs={12}>
            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <CustomMUIDataTable
                title="Médicos"
                data={tableData}
                columns={columns}
                filterStart={2}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
