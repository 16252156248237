import React, { useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { ROW_HEADERS, ROW_HEADERS_LIST } from "utils/generalDashboardConstants";
import { withStyles } from "@material-ui/core/styles";
import PageTitle from "components/PageTitle/PageTitle";
import { Typography as CustomTypography } from "components/Wrappers/Wrappers";
import { getCurrentDate } from "utils/dateGetters";
import DateField from "components/CustomFields/DateField";
import useStyles from "pages/dashboard/styles";
import { useEffect } from "react";
import PopupMessage from "components/PopupMessage/PopupMessage";
import { dbGet } from "utils/DBFetchers";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import InvertedTable from "components/Table/InvertedTable";

const FIRST_MONTH = new Date(getCurrentDate().getFullYear(), 0, 1);

const LAST_MONTH = new Date(
  getCurrentDate().getFullYear(),
  getCurrentDate().getMonth(),
  1,
);

const createColumnsHeaderName = (columns) => {
  return columns.map((column) => {
    const columnHeaderName =
      column.substring(4, 6) + "-" + column.substring(0, 4);
    return columnHeaderName.charAt(0).toUpperCase() + columnHeaderName.slice(1);
  });
};

// This component solves the issue of using the same code twice for the
// general dashboard RS and general dashboard SM. If they ask for a row
// appearing only in one of the dashboards, the constants ROW_HEADERS and
// ROW_HEADERS_LIST should be pass through props to the component.
export default function Dashboard({ programId, name }) {
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDownloadPeriod, setSelectedDownloadPeriod] = useState(null);
  const classes = useStyles();

  const firstPeriod = new Date(2023, 0, 1);
  const lastPeriod = new Date(2023, 10, 1);

  const [maxPeriod, setMaxPeriod] = useState(new Date(2023, 1, 10));
  const [filters, setFilters] = useState({
    fromDate: firstPeriod,
    toDate: lastPeriod,
    period: null,
  });
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [period, setPeriod] = useState(null);

  const generateRowsData = (data) => {
    if (!data) return;
    if (Object.keys(data).length === 0) {
      setColumns([]);
      setRows([]);
      return;
    }
    let columns = Object.keys(data);

    let firstValidIndex = -1;

    for (let i = 0; i < columns.length; i++) {
      if (data[columns[i]] && Object.keys(data[columns[i]]).length > 0) {
        firstValidIndex = i;
        break;
      }
    }

    if (firstValidIndex === -1) return;

    let rows = Object.keys(data[columns[firstValidIndex]]);
    let table_data = rows.map((row) => {
      return columns.map((column) => {
        let value = Object.keys(data[column]).includes(row)
          ? data[column][row]
          : 0;
        return value;
      });
    });
    setColumns(createColumnsHeaderName(columns));
    generateTableData(rows, table_data);
  };

  const handlePeriodChange = (date) => {
    setSelectedDownloadPeriod(date);
  };

  const handleToDateChange = (date) => {
    setPeriod(date);
    setFiltersDate("toDate", date);
  };
  const handleFromDateChange = (date) => {
    setFiltersDate("fromDate", date);
  };

  const setFiltersDate = (filterCategory, date) => {
    var dateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setFilters({
      ...filters,
      [filterCategory]: dateOnFirstDay,
    });
  };

  // Added the filteredTableData because of a previous issue this function had
  // where it would filter out the rows and then use table_data[index] which
  // hasn't be filtered.
  const generateTableData = (rows, table_data) => {
    // Filter out rows and their corresponding data from table_data
    const filteredTableData = table_data
      .filter((_, index) => ROW_HEADERS[rows[index]])
      .map((data) => data.filter((value, index) => ROW_HEADERS[rows[index]]));

    // Generate the final data array
    const data = rows
      .filter((row) => ROW_HEADERS[row])
      .map((row, index) => ({
        label: ROW_HEADERS[row]?.label,
        category: ROW_HEADERS[row]?.category,
        data: filteredTableData[index],
      }));

    // Sort the data based on ROW_HEADERS_LIST
    data.sort(
      (a, b) =>
        ROW_HEADERS_LIST.indexOf(a.label) - ROW_HEADERS_LIST.indexOf(b.label),
    );

    // Set the rows state with the filtered and processed data
    setRows(data);
  };

  // Receives a date and returns an integer representing the period in format YYYYMM
  const getPeriodFromDate = (date) => {
    return parseInt(
      date.toISOString().split("T")[0].substring(0, 7).replace("-", ""),
    );
  };

  useEffect(() => {
    if (filters.fromDate && filters.toDate) {
      let to_period = getPeriodFromDate(filters.toDate);
      let from_period = getPeriodFromDate(filters.fromDate);
      let url =
        `control-panel/old-summary?program_id=${programId}&from_period=` +
        from_period +
        "&to_period=" +
        to_period;
      dbGet(url).then((response) => {
        if (Object.keys(response.data)?.length === 0) {
          snackbarOpen(
            "warning",
            "No hay información generada sobre ninguno de estos períodos",
          );
        }
        generateRowsData(response.data);
      });
    }
  }, [filters.fromDate, filters.toDate]);

  useEffect(() => {
    if (filters.fromDate && filters.toDate) {
      let url = `control-panel/get_last_period?program_id=${programId}`;
      dbGet(url).then((response) => {
        if (response == null) {
          setMaxPeriod(getCurrentDate());
          setPeriod(getCurrentDate());
          return;
        }
        let year = parseInt(response.toString().substring(0, 4));
        let month = parseInt(response.toString().substring(4, 6));
        setMaxPeriod(new Date(year, month - 1, 1));
        setPeriod(new Date(year, month - 1, 1));
      });
    }
  }, []);

  const handleDownload = () => {
    let period = getPeriodFromDate(selectedDownloadPeriod);
    let url =
      `control-panel/get_zip_url?program_id=${programId}&period=` + period;
    dbGet(url)
      .then((response) => {
        let download_link = response.zip_file;
        if (download_link) {
          window.location.href = download_link;
          snackbarOpen("success", "Reportes descargados correctamente");
        } else {
          snackbarOpen(
            "warning",
            "Los reportes de este período aún no han sido generados",
          );
        }
      })
      .catch(() => {
        snackbarOpen("error", "Ocurrió un error en la generación de reportes");
      })
      .finally(() => {
        handlePopupClose();
      });
  };

  const executeButton = (
    <Button
      disabled={selectedDownloadPeriod === null}
      onClick={handleDownload}
      variant="contained"
      color="secondary"
      className={classes.popupButtonSpacing}
    >
      {" "}
      Descargar{" "}
    </Button>
  );

  const handlePopupClose = () => {
    setSelectedDownloadPeriod(null);
    setShowPopup(false);
  };

  return (
    <div>
      <PageTitle title={`Panel de control - General ${name}`} />
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <PopupMessage
        title={"Descargar reportes"}
        message={
          "Por favor, elija los datos que quiere descargar a continuación."
        }
        open={showPopup}
        handleClose={handlePopupClose}
        buttonClassName={classes.popupButtonSpacing}
        buttonDivClassName={classes.popupButtonDiv}
        executeButton={executeButton}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CustomTypography
            component={"span"}
            className={classes.text}
            weight="bold"
          >
            Período
          </CustomTypography>
          <DateField
            views={["month", "year"]}
            minDate={new Date("2020-01-01")}
            maxDate={maxPeriod}
            format={"MM/yyyy"}
            value={selectedDownloadPeriod}
            onChange={handlePeriodChange}
            placeholder="Fecha"
            fieldClassName={classes.generalPurposeField}
          />
        </div>
      </PopupMessage>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginBottom: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CustomTypography
              component={"span"}
              className={classes.text}
              weight="bold"
            >
              Fecha desde
            </CustomTypography>
            <DateField
              views={["year", "month"]}
              minDate={new Date("2020-01-02")}
              maxDate={getCurrentDate()}
              format={"MM/yyyy"}
              value={filters.fromDate}
              onChange={handleFromDateChange}
              placeholder="Fecha"
              fieldClassName={classes.generalPurposeField}
              // We want to set a date as a default value for the datefield component
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: "1rem",
            }}
          >
            <CustomTypography
              component={"span"}
              className={classes.text}
              weight="bold"
            >
              Fecha hasta
            </CustomTypography>
            <DateField
              views={["year", "month"]}
              minDate={new Date("2020-01-02")}
              maxDate={maxPeriod}
              format={"MM/yyyy"}
              value={filters.toDate}
              onChange={handleToDateChange}
              placeholder="Fecha"
              fieldClassName={classes.generalPurposeField}
            />
          </div>
        </div>
        <Button
          variant="contained"
          size="medium"
          onClick={() => setShowPopup(true)}
        >
          Descargar reportes
        </Button>
      </div>
      <Paper className={classes.paper}>
        <InvertedTable rows={rows} columns={columns} />
      </Paper>
    </div>
  );
}
