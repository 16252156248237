import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import Field from "components/CustomFields/Field.js";
import useStyles from "../benefits/styles";
import { dbGet, dbPost } from "utils/DBFetchers";
import Stack from "@mui/material/Stack";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { mapObservations } from "pages/audit/tablesUtils/Sales";
import RutField from "components/CustomFields/RutField";
import { verifyRut } from "utils/rutValidations";
import { DataGrid } from "@material-ui/data-grid";
import dataGridTexts from "components/DataGrid/dataGridTexts.js";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const BLANK_FORM = {
  patientRut: "",
  patientRutVerifier: "",
  product: [],
  informedUnits: 1,
  discount: 0,
};
const BURNABLE_PROGRAM_ID = 1000;
const availableProductsColumns = [
  { field: "name", headerName: "Producto", width: 500 },
];
const MAX_SELECTED_PRODUCTS = 1;

export default function NewSaleBurnablePage() {
  const location = useLocation();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [barcode, setBarcode] = useState(location.state?.barcode || "");
  const [form, setForm] = useState(BLANK_FORM);
  const [formErrors, setFormErrors] = useState(BLANK_FORM);
  const [isLoading, setIsLoading] = useState(false);
  const [saleInformation, setSaleInformation] = useState({});
  const shouldHideBarcode = location.state?.barcode ? true : false;
  const [availableProducts, setAvailableProducts] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");

  var classes = useStyles();

  const totalWidth = availableProductsColumns
    .map((x) => (x.width ? x.width : 0))
    .reduce((accumulator, currentValue) => accumulator + currentValue);

  const getSaleInformation = async (barcode) => {
    try {
      const res = await dbGet(`/burnable/information?barcode=${barcode}`);
      setForm({ ...res, patientRut: res.patient_rut, informedUnits: 1 });
      setSaleInformation(res);
      if (Object.keys(res).length === 0) {
        setForm(BLANK_FORM);
      }
    } catch (error) {
      setForm(BLANK_FORM);
    }
  };

  const handleBarcodeChange = async (e) => {
    setBarcode(e.target.value);
    getSaleInformation(e.target.value);
  };

  const checkIfValidInformedUnit = () => {
    if (form.informedUnits < 1) {
      setForm({ ...form, informedUnits: 1 });
    }
  };

  const handleSubmit = () => {
    checkIfValidInformedUnit();
    setIsLoading(true);
    dbPost("/burnable/sale", {
      barcode: barcode,
      informed_units: parseInt(form.informedUnits),
      patient_rut:
        form.is_physical && form.patientRut + form.patientRutVerifier,
      presentation_id:
        form.is_physical && form.product.length > 0 && form.product[0],
      discount: form.discount,
    })
      .then((response) => {
        snackbarOpen("success", "Venta realizada con éxito");
      })
      .catch((error) => {
        if (error instanceof Array) {
          snackbarOpen(
            "error",
            mapObservations.quemables[error[0]]?.label ||
              "Error al realizar la venta",
          );
        } else {
          snackbarOpen(
            "error",
            error.description_es || "Error al realizar la venta",
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
        setBarcode("");
        setForm(BLANK_FORM);
        window.history.replaceState(null, "");
      });
  };

  useEffect(() => {
    if (location.state?.barcode) {
      getSaleInformation(location.state.barcode);
    }
  }, [location.state?.barcode]);

  const fetchAvailableProducts = async (discount) => {
    const data = await dbGet(
      `presentations/burnable/${BURNABLE_PROGRAM_ID}?discount=${discount}`,
    );
    const dataWithoutDuplicates = new Set(data);
    const sortedData = Array.from(dataWithoutDuplicates).sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    setAvailableProducts(sortedData);
  };

  useEffect(() => {
    if (!form.is_physical) return;
    fetchAvailableProducts(form.discount);
  }, [form.is_physical, form.discount]);

  const handleRutChange = (fieldId, isVerifier, e) => {
    if (isVerifier && e.target?.value?.length > 1) return "";
    const rut = isVerifier ? form[fieldId] : e.target.value;
    const verifier = isVerifier ? e.target.value : form[`${fieldId}Verifier`];
    const fullRut = rut + verifier;
    const isValid = verifyRut(fullRut);
    if (isValid && !verifier)
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [fieldId]: "Ingrese el dígito verificador en el campo respectivo",
      }));
    else if (!isValid)
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [fieldId]: "RUT inválido",
      }));
    else
      setFormErrors((prevFormErrors) => ({ ...prevFormErrors, [fieldId]: "" }));
    setForm((prevForm) => ({ ...prevForm, [e.target.id]: e.target.value }));
    return fullRut;
  };

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <div>
        <PageTitle title="Nueva Venta Quemable" />
      </div>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="on">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos de la venta
              </Typography>
              <Field
                labelName="Código de barras"
                required={true}
                id="couponBarcode"
                value={barcode}
                error={Object.keys(saleInformation).length === 0}
                helperText={
                  Object.keys(saleInformation).length === 0 &&
                  "Código de barras inexistente o ya utilizado"
                }
                divClassType={classes.smallItems}
                fieldClassType={classes.smallFieldValidation}
                onChange={handleBarcodeChange}
                hidden={shouldHideBarcode}
              />
              {form.is_physical ? (
                <>
                  <RutField
                    labelName="RUT Paciente"
                    id="patientRut"
                    id2="patientRutVerifier"
                    divClassType={classes.smallItems2}
                    divClassType2={classes.generalPurposeItems2}
                    fieldClassType={classes.smallFieldValidation2}
                    fieldClassType2={classes.smallFieldValidation3}
                    onChange={(e) => handleRutChange("patientRut", false, e)}
                    onChange2={(e) => handleRutChange("patientRut", true, e)}
                    value={form.patientRut}
                    value2={form.patientRutVerifier}
                    error={formErrors.patientRut.length > 0}
                    helperText={formErrors.patientRut}
                    required
                  />
                  <Grid
                    item
                    xs={12}
                    xl={6}
                    style={{
                      marginTop: "32px",
                    }}
                  >
                    <div className={classes.generalPurposeItems}>
                      <Typography className={classes.text} weight="bold">
                        Seleccionar (máximo {MAX_SELECTED_PRODUCTS}) *
                      </Typography>
                    </div>
                    <div className={classes.generalPurposeField}>
                      <TextField
                        className={classes.searchField}
                        label="Filtrar"
                        onChange={(e) => {
                          setSearchFilter(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {formErrors.product.length > 0 && (
                        <Typography
                          className={classes.text}
                          style={{ color: "red" }}
                          weight="bold"
                          size="xl"
                        >
                          {formErrors.product}
                        </Typography>
                      )}

                      <DataGrid
                        style={{ height: 360, width: totalWidth + 100 }}
                        rows={
                          searchFilter
                            ? availableProducts.filter((x) =>
                                x.name
                                  .toLowerCase()
                                  .includes(searchFilter.toLowerCase()),
                              )
                            : availableProducts
                        }
                        columns={availableProductsColumns}
                        showToolbar={true}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                        disableColumnMenu={true}
                        rowsPerPageOptions={[]}
                        pageSize={100}
                        localeText={dataGridTexts}
                        onSelectionModelChange={(selectedRowIds) => {
                          setForm((prev) => ({
                            ...prev,
                            product: selectedRowIds,
                          }));
                          setFormErrors((prev) => ({
                            ...prev,
                            product:
                              selectedRowIds.length > MAX_SELECTED_PRODUCTS
                                ? "No puedes seleccionar más de 1 producto"
                                : "",
                          }));
                        }}
                        selectionModel={form.product}
                        checkboxSelection
                      />
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Field
                    labelName="RUT Paciente"
                    id="patientRut"
                    value={form.patientRut}
                    divClassType={classes.smallItems}
                    fieldClassType={classes.smallFieldValidation}
                    InputProps={{ readOnly: true }}
                  />
                  <Field
                    labelName="Producto"
                    id="productName"
                    value={form.product?.length > 0 ? form.product : ""}
                    divClassType={classes.generalPurposeItems}
                    fieldClassType={classes.generalPurposeField}
                    InputProps={{ readOnly: true }}
                  />
                </>
              )}

              <Stack direction="row">
                <Field
                  labelName="Unidades"
                  id="units"
                  value={form.product?.length > 0 ? form.informedUnits : ""}
                  onChange={(e) => {
                    if (e.target.value <= 10) {
                      setForm({ ...form, informedUnits: e.target.value });
                    }
                  }}
                  onBlur={checkIfValidInformedUnit}
                  divClassType={classes.verySmallItems}
                  fieldClassType={classes.verySmallFieldValidation}
                />
                <Field
                  labelName="Descuento"
                  id="discount"
                  value={form.product && `${form.discount} %`}
                  divClassType={classes.secondVerySmallItemInRow}
                  fieldClassType={classes.verySmallFieldValidation}
                  InputProps={{ readOnly: true }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              className={classes.alignLeft}
              style={{ marginTop: "2.5%" }}
            >
              {isLoading ? (
                <CircularProgress size={26} className={classes.submitLoader} />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={
                    form.product?.length === 0 ||
                    form.patientRut?.length === 0 ||
                    form.patientRutVerifier?.length === 0 ||
                    Object.values(formErrors).some((x) => x.length > 0)
                  }
                >
                  Realizar venta
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
