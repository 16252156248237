import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
  CartesianGrid,
  ReferenceLine,
  Legend,
  Tooltip,
  BarChart,
  Bar,
} from "recharts";
import { useTheme } from "@material-ui/styles";
import useStyles from "./styles";
import MUIDataTable from "mui-datatables";
import datatableTextLabels from "components/MUIDatatable/textLabels";
import Widget from "components/Widget/Widget";
import PageTitle from "components/PageTitle/PageTitle";
import logo from "images/logoPagesRS.jpg";
import domtoimage from "dom-to-image";
import fileDownload from "js-file-download";

const PieChartData = [{ name: "Group A", value: 100, color: "success" }];

function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

const chains = [
  {
    name: "Farmacias Ahumada",
    productCode: 1,
    product: 0,
    saleDate: 4,
    salesman: 0,
  },
  { name: "Cruz Verde", productCode: 2, product: 1, saleDate: 3, salesman: 0 },
  { name: "Salcobrand", productCode: 2, product: 0, saleDate: 4, salesman: 1 },
];

const rangeData = [
  {
    day: "05-01",
    temperature: [-1, 10],
  },
  {
    day: "05-02",
    temperature: [2, 15],
  },
  {
    day: "05-03",
    temperature: [3, 12],
  },
  {
    day: "05-04",
    temperature: [4, 12],
  },
  {
    day: "05-05",
    temperature: [12, 16],
  },
  {
    day: "05-06",
    temperature: [5, 16],
  },
  {
    day: "05-07",
    temperature: [3, 12],
  },
  {
    day: "05-08",
    temperature: [0, 8],
  },
  {
    day: "05-09",
    temperature: [-3, 5],
  },
];

const data = [
  {
    name: "2021-02",
    percentage: 98.2,
  },
  {
    name: "2021-03",
    percentage: 95,
  },
  {
    name: "2021-04",
    percentage: 97.5,
  },
  {
    name: "2021-05",
    percentage: 89.9,
  },
  {
    name: "2021-06",
    percentage: 100,
  },
  {
    name: "2021-07 ",
    percentage: 99.7,
  },
];

const barData = [
  {
    name: "Descuento Informado = 0% ",
    percentage: 0,
  },
  {
    name: "Precio ExLab incorrecto",
    percentage: 7997,
  },

  {
    name: "Precio Total incorrecto",
    percentage: 8000,
  },
  {
    name: "Descuento % incorrecto",
    percentage: 15001,
  },
  {
    name: "Descuento $ incorrecto",
    percentage: 15001,
  },
];

export default function ControlPanelPage() {
  var classes = useStyles();
  var theme = useTheme();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let data = [];
    chains.map((chain) => {
      let chainData = [];
      for (var category in chain) {
        chainData.push(chain[category]);
      }
      data.push(chainData);
    });
    setTableData(data);
  }, []);

  const columns = [
    {
      name: "Cadenas",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellProps: () => ({ align: "center" }),
        setCellHeaderProps: () => {
          return {
            style: {
              fontWeight: "bold",
              className: "centeredHeaderCell",
              justifyContent: "center",
              align: "center",
            },
          };
        },
      },
    },
    {
      name: "Unidades",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return {
            style: {
              fontWeight: "bold",
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ResponsiveContainer width="100%" height={144}>
              <PieChart>
                <Pie
                  data={PieChartData}
                  innerRadius={20}
                  outerRadius={30}
                  dataKey="value"
                  label
                >
                  {PieChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={theme.palette[entry.color].main}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          );
        },
      },
    },
    {
      name: "Pesos",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellProps: () => ({ align: "center" }),
        setCellHeaderProps: () => {
          return {
            style: {
              fontWeight: "bold",

              className: "centeredHeaderCell",
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ResponsiveContainer width="100%" height={144}>
              <PieChart>
                <Pie
                  data={PieChartData}
                  innerRadius={20}
                  outerRadius={30}
                  dataKey="value"
                  label
                >
                  {PieChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={theme.palette[entry.color].main}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          );
        },
      },
    },
  ];

  /*  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload[0].value}`}</p>

          <p className="desc">Anything you want can be displayed here.</p>
        </div>
      );
    }

    return null;
  }; */

  const handleSaveClick = (id) => {
    domtoimage.toBlob(document.getElementById(id)).then(function (blob) {
      fileDownload(blob, `${id}.png`);
    });
  };

  return (
    <>
      <div>
        <PageTitle
          title="Tablero de control"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <Grid container xs={12}>
        <Grid item container lg={12} xs={12} md={12} spacing={"4"}>
          <Grid item lg={6} xs={12} md={12}>
            <MUIDataTable
              title={"Performance por cadena"}
              columns={columns}
              data={tableData}
              options={{
                textLabels: datatableTextLabels(),
                print: false,
                selectableRows: "none",
                viewColumns: false,
                search: false,
                filter: false,
                download: false,
                rowsPerPageOptions: false,
                pagination: false,
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12} md={12}>
            <MUIDataTable
              title={"Performance por cadena"}
              columns={columns}
              data={tableData}
              options={{
                textLabels: datatableTextLabels(),
                print: false,
                selectableRows: "none",
                viewColumns: false,
                search: false,
                filter: false,
                download: false,
                rowsPerPageOptions: false,
                pagination: false,
              }}
            />
          </Grid>
          <Grid item lg={6} xs={12} md={12}>
            <Widget
              title="Distribución de observaciones"
              upperTitle
              bodyClass={classes.fullHeightBody}
              className={classes.card}
              downloadFunction={() => {
                handleSaveClick("distribucion-observaciones");
              }}
            >
              <div id="distribucion-observaciones">
                <ResponsiveContainer width={700} height={700}>
                  <BarChart width={400} height={250} data={barData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip

                    /* content={<CustomTooltip />} */
                    />
                    <Bar dataKey="percentage" fill="#8884d8" barSize={70} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Widget>
          </Grid>
          <Grid item lg={6} xs={12} md={12}>
            <Widget
              title="Evolución mensual"
              upperTitle
              bodyClass={classes.fullHeightBody}
              className={classes.card}
              downloadFunction={() => {
                handleSaveClick("evolucion-mensual");
              }}
            >
              <div id="evolucion-mensual">
                <ResponsiveContainer width={700} height={700}>
                  <AreaChart
                    data={data}
                    margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    dot={true}
                  >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Legend />
                    <Tooltip

                    /* content={<CustomTooltip />} */
                    />
                    <Area
                      type="monotone"
                      dataKey="percentage"
                      stroke="#8884d8"
                      dot={true}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </Widget>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
