import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { useHistory, withRouter } from "react-router-dom";
import { getRoleName } from "utils/roleNameGetter";
import classNames from "classnames";
import { cloneDeep } from "lodash";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "context/LayoutContext";

import sidebarStructure, { getSiderbarStructure } from "utils/sidebarStructure";
import routes from "routes";

const disabledAuthoritarianRoutes = [
  routes.benefitsNewCupon,
  routes.benefitsNewSale,
  routes.benefitsImport,
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const history = useHistory();

  // local
  var [isPermanent, setPermanent] = useState(true);
  var [structure, setStructure] = useState(null);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  // All of these below are needed to check which programs the pharmacy has authoritarian distributors
  // and need to display a read-only view
  const updateStructure = () => {
    let authoritarianProgramRender = false;
    try {
      authoritarianProgramRender =
        getRoleName() === "pharmacies" &&
        JSON.parse(localStorage.getItem("authoritarianPrograms")).includes(
          +localStorage.getItem("programId"),
        );
    } catch {
      authoritarianProgramRender = false;
    }

    const structureOG = sidebarStructure[getRoleName()]
      ? authoritarianProgramRender
        ? sidebarStructure.pharmacies_read_only
        : getSiderbarStructure(getRoleName(), localStorage.getItem("programId"))
      : sidebarStructure.unknown;

    setStructure(structureOG);

    if (
      authoritarianProgramRender &&
      disabledAuthoritarianRoutes.includes(history.location.pathname)
    ) {
      history.push(routes.home);
    }
  };

  React.useEffect(() => {
    updateStructure();
  }, []);

  // Program switch logic
  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  React.useEffect(() => {
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);
  React.useEffect(() => {
    if (!structure) return; // no fetch on page load
    updateStructure();
  }, [programId]);

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure
          ? structure.map((link) => (
              <SidebarLink
                key={link.id}
                location={location}
                isSidebarOpened={isSidebarOpened}
                {...link}
              />
            ))
          : null}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
