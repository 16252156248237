import React from "react";

// styles
import useStyles from "pages/control/styles";
import { Typography } from "../Wrappers/Wrappers";

import TextField from "@material-ui/core/TextField";
import { MenuItem } from "@material-ui/core";

export default function SelectField(props) {
  var classes = useStyles();
  return (
    <>
      <div className={props.divClassName}>
        {props.displayText && (
          <Typography className={classes.text} weight="bold">
            {props.displayText}
          </Typography>
        )}
        <TextField
          id={props.id}
          disabled={props.disabled}
          select
          className={props.fieldClassName}
          label={props.label}
          name={props.name}
          value={props.value ? props.value : ""}
          onChange={props.onChange}
          helperText={props.helperText}
          variant="outlined"
          margin="dense"
        >
          {props.allValues?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </>
  );
}

export function SimpleSelectField(props) {
  var classes = useStyles();

  return (
    <>
      <TextField
        id={props.id}
        disabled={props.disabled}
        select
        className={props.fieldClassName}
        label={props.label}
        name={props.name}
        value={props.value ? props.value : ""}
        onChange={props.onChange}
        helperText={props.helperText}
        variant="outlined"
        margin="dense"
      >
        {props.allValues.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}
