import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  buttonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    marginLeft: "15em",
    marginTop: "2%",
    marginBottom: "5%",
  },
  loginLoader: {
    marginTop: "3%",
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(6.8),
  },
  generalPurposeItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 700,
    justifyContent: "space-between",
  },
  smallItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 430,
    justifyContent: "space-between",
  },
  generalPurposeField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-1%",
    width: 500,
  },
  passwordItems: {
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 700,
  },
  passwordField: {
    marginRight: theme.spacing(1),
    marginTop: "-1%",
    width: 500,
  },
  smallField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  searchField: {
    width: 500,
    marginTop: "-1%",
    marginBottom: "2%",
  },
  smallFieldValidation: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:hover + fieldset": {
      borderColor: "green",
    },
    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },
  logotypeImage: {
    width: 157,
    height: 60,
    marginTop: "-1%",
  },
  rowField: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
  },
  selectionTextDisabled: {
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0, 0, 0, 1)",
      borderColor: "white",
      borderWidth: 4,
      border: "solid 1px",
      opacity: 0.4,
    },
    marginTop: "0%",
  },
  selectionTextEnabled: {
    "& input::placeholder": {
      background: "rgba(0, 0, 0, 0)",
      color: "rgba(0, 0, 0, 1)",
      borderColor: "white",
      borderWidth: 4,
      border: "solid 1px",
      opacity: 0.55,
    },
    marginTop: "0%",
  },
}));
