import React, { useEffect, useState } from "react";
import "./styles.css";
import { dbGet, dbPost } from "utils/DBFetchers";
import {
  TextField,
  Button,
  MenuItem,
  Paper,
  FormControlLabel,
  Stack,
  Checkbox,
  Grid,
  Typography,
  Link,
  CircularProgress,
} from "@mui/material";
import CheckboxForm from "./checkboxForm";
import { verifyRut } from "utils/rutValidations";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import logoAbbott from "images/logoAbbott.png";
import logoPagesRS from "images/logoPagesRS.png";
import ErrorDialog from "components/errorDialog";

const defaultFormValues = {
  name: "",
  last_name: "",
  patient_rut: "",
  patient_rut_dv: "",
  mail: "",
  product: 0,
  medic_name: "",
  medic_last_name: "",
  medic_rut: "",
  medic_rut_dv: "",
  terms: false,
  consent: false,
  newsletter: false,
  medic_consent: false,
};

export default function BurnableCouponForm() {
  const [isValidRut, setIsValidRut] = useState(false);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [allProducts, setAllProducts] = useState([]);
  const [patientRutError, setPatientRutError] = useState("");
  const [medicRutError, setMedicRutError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [finishedSendingForm, setFinishedSendingForm] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  // Error dialog logic
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  // By doing this splits I get the current "program" I have to render the form to.
  const parts = window.location.href.split("/");
  const lastPart = parts[parts.length - 1];
  const pageName = lastPart.split("?")[0];

  const displayedInfo = {
    acos: {
      text: "Anticonceptivos",
      program_id: 100,
    },
    horm: {
      text: "Terapia Reemplazo Hormonal",
      program_id: 101,
    },
    acuode: {
      text: "Acuode",
      program_id: 102,
    },
    quemables: {
      text: "Quemables",
      program_id: 1000,
    },
  };

  const handleOpen = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //

  const isValidEmail = (email) => {
    const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    validEmail ? setEmailError("") : setEmailError("Email inválido");
    return validEmail;
  };

  const areValidRuts = () => {
    const validPatientRut = verifyRut(
      formValues.patient_rut + formValues.patient_rut_dv,
    );
    const validMedicRut = verifyRut(
      formValues.medic_rut + formValues.medic_rut_dv,
    );

    validPatientRut
      ? setPatientRutError("")
      : setPatientRutError("RUT inválido");
    validMedicRut ? setMedicRutError("") : setMedicRutError("RUT inválido");
    return validPatientRut && validMedicRut;
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleRutChange = (event) => {
    const { name, value } = event.target;
    const number_pattern = /^[0-9]+$/;
    const number_and_k_pattern = /^[0-9kK]+$/;

    if (
      value === "" ||
      (name.includes("dv") &&
        value.length <= 1 &&
        number_and_k_pattern.test(value)) ||
      (!name.includes("dv") && number_pattern.test(value) && value.length <= 8)
    ) {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const validRuts = true;
    const validEmail = isValidEmail(formValues.mail);

    if (formValues.product === 0) {
      snackbarOpen("error", "Debe seleccionar un producto");
      setIsLoading(false);
      return;
    }

    if (validRuts && validEmail) {
      const urlParams = new URLSearchParams(window.location.search);
      const rut = urlParams.get("r");

      const body = {
        program_id: displayedInfo[pageName].program_id,
        name: formValues.name,
        last_name: formValues.last_name,
        patient_rut: formValues.patient_rut + formValues.patient_rut_dv,
        mail: formValues.mail,
        presentation_id: formValues.product,
        product: allProducts.filter(
          (product) => product.id === formValues.product,
        )[0].name,
        medic_name: formValues.medic_name,
        medic_last_name: formValues.medic_last_name,
        medic_rut: formValues.medic_rut + formValues.medic_rut_dv,
        consultant_rut: rut || "",
        newsletter: formValues.newsletter,
      };

      const url = pageName === "quemables" ? "burnable" : "burnable/internal";

      dbPost(url, body)
        .then((data) => {
          snackbarOpen("success", "Formulario enviado correctamente");
          setFinishedSendingForm(true);
          setFormValues(defaultFormValues);
          setIsLoading(false);
        })
        .catch((error) => {
          handleOpen(error.description_es);
          setIsLoading(false);
          return;
        });
    }
  };

  const handleSelectorChange = (event, beginFilter) => {
    setFormValues({
      ...formValues,
      [beginFilter]: event.target.value,
    });
  };

  const getProducts = () => {
    dbGet(`presentations/burnable/${displayedInfo[pageName].program_id}`).then(
      (data) => {
        setAllProducts(
          data
            .sort((a, b) => {
              const nameA = a.name.toUpperCase(); // Convert to uppercase for case-insensitive sorting
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0; // names are equal
            })
            .filter((product) => product.external_id !== undefined)
            .map((product) => {
              return {
                id: product.external_id,
                name: product.name,
              };
            }),
        );
      },
    );
  };

  useEffect(() => {
    // if (pageName === "quemables") {
    //   getProducts();
    //   setIsValidRut(true);
    //   return;
    // }
    const urlParams = new URLSearchParams(window.location.search);
    const rut = urlParams.get("r");

    window.location.replace(`https://recetasolidaria.cl/suscribir?r=${rut}`);
    // dbGet(
    //   `consultants/check_rut?program_id=${displayedInfo[pageName].program_id}&consultant_rut=${rut}&program_id=`,
    // )
    //   .then((response) => {
    //     if (response.exists) {
    //       setIsValidRut(true);
    //       getProducts();
    //     }
    //   })
    //   .catch((error) => {
    //     setIsValidRut(false);
    //   });
  }, []);

  return (
    <div className="container">
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      {/* <div
        style={{
          backgroundImage:
            "url('https://recetasolidaria.cl/wp-content/uploads/2020/04/registro-2.jpg')", // replace with your image URL
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left",
        }}
      > */}
      <div
        className="color-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ErrorDialog open={open} handleClose={handleClose} message={message} />
        {isValidRut && (
          <Paper
            className="paper-container"
            elevation={3}
            style={{
              width: "34vw",
            }}
          >
            <Grid
              container
              style={{
                marginTop: "2em",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={logoAbbott}
                alt="logo abbott"
                style={{
                  width: "auto",
                  height: "4em",
                }}
              />
              <img
                src={logoPagesRS}
                alt="logo receta solidaria"
                style={{
                  width: "auto",
                  height: "4em",
                  marginLeft: "2em",
                }}
              />
            </Grid>
            <Grid style={{ textAlign: "center" }}>
              <Typography variant="h6" mt={2}>
                {displayedInfo[pageName].text}
              </Typography>
            </Grid>
            {finishedSendingForm ? (
              <Grid style={{ textAlign: "center", padding: "2em" }}>
                <Typography>
                  Tu registro en Receta Solidaria {displayedInfo[pageName].text}{" "}
                  ha sido realizada correctamente, en breve recibiras en tu
                  email el primer cupón.
                  <br />
                  <b>
                    Si no recibes el cupón, por favor, comunícate a:
                    <br />
                    ayuda@soporte-abbott.info
                    <br />
                    +562 29790580
                  </b>
                </Typography>
              </Grid>
            ) : (
              <form onSubmit={handleSubmit}>
                <div style={{ padding: "1% 7% 7% 7%" }}>
                  <Stack
                    className="form"
                    direction="column"
                    style={{
                      width: "90%",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                    }}
                  >
                    <TextField
                      required
                      size="small"
                      fullWidth
                      label="Nombre"
                      name="name"
                      value={formValues.name}
                      onChange={handleInputChange}
                      margin="normal"
                    />
                    <TextField
                      required
                      size="small"
                      fullWidth
                      label="Apellido"
                      name="last_name"
                      value={formValues.last_name}
                      onChange={handleInputChange}
                      margin="normal"
                    />
                    <Grid container>
                      <Grid item xs={9}>
                        <TextField
                          required
                          size="small"
                          error={patientRutError !== ""}
                          helperText={patientRutError}
                          fullWidth
                          label="RUT"
                          name="patient_rut"
                          value={formValues.patient_rut}
                          onChange={handleRutChange}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          required
                          error={patientRutError !== ""}
                          size="small"
                          fullWidth
                          label="DV"
                          name="patient_rut_dv"
                          value={formValues.patient_rut_dv}
                          onChange={handleRutChange}
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      required
                      error={emailError !== ""}
                      helperText={emailError}
                      fullWidth
                      size="small"
                      label="Email"
                      name="mail"
                      value={formValues.mail}
                      onChange={handleInputChange}
                      margin="normal"
                    />

                    <TextField
                      required
                      id="product"
                      fullWidth
                      size="small"
                      select
                      label="Seleccione un producto"
                      name="product"
                      value={formValues.product}
                      onChange={(event) =>
                        handleSelectorChange(event, "product")
                      }
                      allValues={allProducts}
                      variant="outlined"
                      margin="normal"
                    >
                      {allProducts?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>

                    {/* <TextField
                      fullWidth
                      size="small"
                      required
                      label="Nombre médico"
                      name="medic_name"
                      value={formValues.medic_name}
                      onChange={handleInputChange}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      size="small"
                      required
                      label="Apellido médico"
                      name="medic_last_name"
                      value={formValues.medic_last_name}
                      onChange={handleInputChange}
                      margin="normal"
                    />
                    <Grid container>
                      <Grid item xs={9}>
                        <TextField
                          fullWidth
                          required
                          error={medicRutError !== ""}
                          helperText={medicRutError}
                          size="small"
                          label="RUT médico"
                          name="medic_rut"
                          value={formValues.medic_rut}
                          onChange={handleRutChange}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={3} pb={1}>
                        <TextField
                          required
                          error={medicRutError !== ""}
                          size="small"
                          fullWidth
                          label="DV"
                          name="medic_rut_dv"
                          value={formValues.medic_rut_dv}
                          onChange={handleRutChange}
                          margin="normal"
                        />
                      </Grid>
                    </Grid> */}
                    {/* <Grid>
                      <CheckboxForm
                        name="terms"
                        required
                        checked={formValues.terms}
                        handleInputChange={handleInputChange}
                        firstTypography="Acepto haber leído los"
                        linkTypography="Términos y Condiciones"
                        secondTypography="del Programa Receta Solidaria."
                        href="https://recetasolidaria.cl/wp-content/uploads/2020/04/Terminos-y-condiciones.pdf"
                      />
                      <CheckboxForm
                        name="consent"
                        required
                        checked={formValues.consent}
                        handleInputChange={handleInputChange}
                        firstTypography="Acepto haber leído el"
                        linkTypography="Consentimiento sobre el uso de mi información"
                        secondTypography="del Programa Receta Solidaria."
                      />
                      <CheckboxForm
                        name="newsletter"
                        checked={formValues.newsletter}
                        handleInputChange={handleInputChange}
                        firstTypography="Acepto suscribirme al newsletter"
                      />
                      <CheckboxForm
                        name="medic_consent"
                        required
                        checked={formValues.medic_consent}
                        handleInputChange={handleInputChange}
                        firstTypography="Consiento que tengo una prescripción médica que indica el uso del medicamento que estoy registrando"
                      />
                    </Grid> */}
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        style={{ marginTop: "1em" }}
                        disabled={
                          isLoading ||
                          // !formValues.consent ||
                          // !formValues.terms ||
                          // !formValues.medic_consent ||
                          !formValues.product ||
                          !formValues.name ||
                          !formValues.last_name ||
                          !formValues.patient_rut ||
                          !formValues.patient_rut_dv ||
                          !formValues.mail 
                          // !formValues.medic_name ||
                          // !formValues.medic_last_name ||
                          // !formValues.medic_rut ||
                          // !formValues.medic_rut_dv
                        }
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Enviar
                      </Button>
                    )}
                  </Stack>
                </div>
              </form>
            )}
          </Paper>
        )}
      </div>
    </div>
  );
}
