import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import {
  Button,
  Grid,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";
import InputAdornment from "@material-ui/core/InputAdornment";
import DescriptionIcon from "@material-ui/icons/Description";
import LinearProgressWithLabel from "components/LinearProgressWithLabel/LinearProgressWithLabel";
import { Link } from "@material-ui/core";

// styles
import useStyles from "../benefits/Import/styles";

import logo from "images/logoPagesRS.jpg";
import { dbGet, dbUploadFile } from "utils/DBFetchers";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import PopupMessage from "components/PopupMessage/PopupMessage";

const statusConvertion = {
  Pending: "Comenzando",
  Inscriptions: "Cargando empadronamientos",
  Sales: "Cargando ventas",
  SavingInscriptions: "Guardando empadronamientos",
  SavingSales: "Guardando ventas",
  Completed: "Terminado",
};

export default function ImportBurnablePage(props) {
  var classes = useStyles();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [isLoading, setIsLoading] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [uploadState, setUploadState] = useState(false);
  const [importId, setImportId] = useState("");
  const [uploadPopUp, setUploadPopup] = React.useState(false);
  const [showNewImportPopUp, setShowNewImportPopUp] = useState(true);
  const [progress, setProgress] = useState({ progress: 0, status: "" });

  const selectFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const updateProgress = (id, data) => {
    dbGet(`request_checker/status?id=${id}`)
      .then((data) => {
        setProgress({
          ...progress,
          progress: data.progress ? data.progress : 0,
          status: data.status,
        });
        if (data.status === "Completed") {
          snackbarOpen("success", "Archivo cargado correctamente!");
          setUploadState(true);
          setIsLoading(false);
          setImportId(data.id);
        } else if (!data.error_status && data.status !== "Failure") {
          setTimeout(updateProgress.bind(null, id, data), 2000);
        } else {
          snackbarOpen(
            "error",
            data.error_message
              ? data.error_message.description_es
              : "El archivo puede contener errores y no se pudo cargar.",
          );
          setUploadState(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error.error_message
            ? error.error_message.description_es
            : "El archivo puede contener errores y no se pudo cargar.",
        );
        setUploadState(true);
        setIsLoading(false);
      });
  };

  const upload = () => {
    setIsLoading(true);
    let mock = { progress: 0 };

    dbUploadFile(selectedFile, `upload/burnable/internal`)
      .then((response) => {
        handleUploadPopupClose();
        setTimeout(updateProgress.bind(null, response.id, mock), 3000);
      })
      .catch((error) => {
        handleUploadPopupClose();
        if (error.response) {
          snackbarOpen(
            "error",
            error.response.data
              ? error.response.data.description_es
              : "El archivo puede contener errores y no se pudo cargar.",
          );
          setUploadState(true);
          setIsLoading(false);
        }
      });
  };

  const handleSelectFileClick = () => {
    hiddenFileInput.current.click();
  };

  const handleUploadPopupClose = () => {
    setUploadPopup(false);
  };

  const handleOpenUploadPopUp = () => {
    setUploadPopup(true);
  };

  const handleNewImportPopUpClose = () => {
    setShowNewImportPopUp(false);
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    window.open(
      "https://cfr-fe-main.s3.sa-east-1.amazonaws.com/static/media/planilla_modelo_quemable.xlsx",
      "_blank",
    );
  };

  return (
    <>
      <PopupMessage
        title={"Recordatorio planilla modelo"}
        message={
          "Recordar que las planillas anteriores o diferentes no serán soportadas y producirán el rechazo en la carga.\
          Por favor, descargue y utilice la planilla actual en el link al final de esta página.\
          Ultima actualización: 11/09/2024"
        }
        open={showNewImportPopUp}
        handleClose={handleNewImportPopUpClose}
        buttonDivClassName={classes.popupDivCenter}
        buttonClassName={classes.buttonSpacing}
      />
      <Dialog open={uploadPopUp} onClose={handleUploadPopupClose}>
        <DialogTitle>{`Subir planilla de quemables`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Va a importar la planilla de quemables
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.popupDivCenter}>
          <Button
            onClick={handleUploadPopupClose}
            variant="contained"
            className={classes.popupButtonSpacing}
          >
            Cancelar
          </Button>
          <Button
            onClick={upload}
            variant="contained"
            color="primary"
            autoFocus
            className={classes.popupButtonSpacing}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        <PageTitle
          title="Importar ventas de quemables"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        <CustomizedSnackbars
          severity={snackbarStatus.severity}
          mssg={snackbarStatus.message}
          open={snackbarStatus.open}
          onClose={onCloseSnackbar}
          duration={0}
        />
      </div>

      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.generalPurposeItems}>
              <Typography variant="h4" className={classes.text}>
                Complete todas las columnas de la planilla
              </Typography>
            </div>

            <div className={classes.generalPurposeItems}>
              <Typography className={classes.text} weight="bold">
                Archivo (*.xls, *.xlsx)*
              </Typography>
              <TextField // TODO Pending: Cambiar y Remover buttons. Necessary?
                id="fileSelected"
                className={classes.generalPurposeField}
                disabled
                value={selectedFile ? selectedFile.name : ""}
                variant="outlined"
                margin="dense"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DescriptionIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                className={classes.selectionField}
                disableElevation
                variant="contained"
                color="primary"
                onClick={handleSelectFileClick}
                disabled={uploadState}
              >
                Seleccionar archivo
              </Button>
              <input
                type="file"
                onChange={selectFile}
                style={{ display: "none" }}
                ref={hiddenFileInput}
              />
            </div>
            {isLoading ? (
              <div className={classes.sendItem} style={{ marginBottom: "1%" }}>
                <LinearProgressWithLabel
                  value={progress.progress}
                  label={statusConvertion[progress.status]}
                  variant={
                    progress.status?.includes("Saving")
                      ? "indeterminate"
                      : "determinate"
                  }
                  className={classes.generalPurposeField}
                />
              </div>
            ) : (
              <></>
            )}

            <div className={classes.sendItem} style={{ marginBottom: "-3%" }}>
              {importId ? (
                <Typography variant="h5" className={classes.text}>
                  ID de importación del archivo: {importId}
                </Typography>
              ) : null}
            </div>
            <div className={classes.sendItem}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  className={classes.sendField}
                  disabled={!selectedFile || uploadState}
                  variant="contained"
                  color="primary"
                  onClick={handleOpenUploadPopUp}
                >
                  Enviar
                </Button>
              )}
              <Button
                className={classes.sendField}
                disabled={!uploadState}
                variant="contained"
                color="secondary"
                onClick={() => {
                  setSelectedFile(undefined);
                  setImportId("");
                  window.location.reload();
                }}
              >
                Limpiar
              </Button>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.smallItems}>
              <Typography variant="h4" className={classes.text}>
                Recomendaciones
              </Typography>
            </div>
            <div
              className={classes.generalPurposeItems}
              style={{ marginTop: "1%" }}
            >
              <Typography weight="medium">
                {/* Los archivos Excel deben contener la hoja de Incorporaciones y
                la hoja de Ventas. */}
              </Typography>
            </div>
            <ul style={{ fontSize: 15 }}>
              <li style={{ fontWeight: "bold" }}>
                Utilice la planilla modelo que se adjunta a continuación, otras
                versiones NO funcionarán.
              </li>
              <li style={{ fontWeight: "bold" }}>
                Complete todos las columnas de la planilla, en caso de no tener
                un dato, déjela en blanco.
              </li>
              <li style={{ fontWeight: "bold" }}>
                Ingresar los RUTs sin puntos y con el dígito verificador sin
                estar separado por un guión (ej. 88888888K).
              </li>
              <li style={{ fontWeight: "bold" }}>
                Verifique que las fechas se hayan ingresado de manera que Excel
                las reconozca como fecha y no como texto u otro formato. El
                formato correcto es DIA/MES/AÑO (ej. El 07 de Agosto de 2021, se
                traduce a: 07/08/2021).
              </li>
              <li style={{ fontWeight: "bold" }}>
                Ingresar el descuento sin el signo de porcentaje (ej. 10% se
                debe ingresar en la planilla como 10).
              </li>
              <li style={{ fontWeight: "bold" }}>
                Si la subida fue satisfactoria, por favor guarde el ID de
                importación.
              </li>
            </ul>
            <div
              className={classes.generalPurposeItems}
              style={{ marginTop: "4%" }}
            >
              <Typography variant="h4" weight="medium">
                Descarga planilla modelo
              </Typography>
            </div>
            <Button
              onClick={handleLinkClick}
              color="primary"
              style={{ textTransform: "none", marginLeft: 10, marginTop: 10 }}
            >
              {" "}
              planilla_modelo_quemable.xlsx (Excel 2007)
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
