import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, CircularProgress, Grid, Paper } from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";

import Field from "components/CustomFields/Field.js";
import RutField from "components/CustomFields/RutField.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { dbGet, dbPut } from "utils/DBFetchers.js";
import { verifyRut } from "utils/rutValidations";
import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";
import { cleanEmptyKeys } from "utils/cleanEmptyKeys";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

const blankEditMedicForm = {
  id: "",
  rut: "",
  rutVerifier: "",
  name: "",
  lastName: "",
};

export default function ABMMedicsEditPage(props) {
  var classes = useStyles();
  const history = useHistory();
  var [isLoading, setIsLoading] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const [editMedicForm, updateForm] = useState(blankEditMedicForm);
  const [allMedics, setAllMedics] = useState([]);
  const [isRutValid, setIsRutValid] = useState({ rut: false, message: "" });

  const handleChange = (event) => {
    updateForm({
      ...editMedicForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleRutVerification = () => {
    var currentRut = editMedicForm.rut + editMedicForm.rutVerifier;
    var rutValid = verifyRut(currentRut);
    var rutExists = allMedics.some((elem) => elem.rut === currentRut);

    if (rutExists && editMedicForm.rutVerifier)
      setIsRutValid({ rut: false, message: "Médico ya existente." });
    else if (
      (rutExists && !editMedicForm.rutVerifier) ||
      (rutValid && !editMedicForm.rutVerifier)
    )
      setIsRutValid({
        rut: false,
        message: "Ingrese el dígito verificador en el campo respectivo.",
      });
    else if (!rutValid) setIsRutValid({ rut: false, message: "RUT inválido." });
    else setIsRutValid({ rut: true, message: "" });
  };

  // Rut updaters
  const handleRutChange = (e) => {
    updateForm({
      ...editMedicForm,
      [e.target.id]: e.target.value.toUpperCase(),
    });
  };
  React.useEffect(() => {
    if (!editMedicForm.rut && !editMedicForm.rutVerifier) return; // no fetch on page load
    handleRutVerification();
  }, [editMedicForm.rut, editMedicForm.rutVerifier]);

  /* Get all data */
  const getMedicInfo = (medicId) => {
    dbGet(`medics/${medicId}`).then((data) => {
      updateForm({
        ...editMedicForm,
        id: data.id,
        rut: data.rut ? data.rut.slice(0, -1) : "",
        rutVerifier: data.rut ? data.rut.slice(-1)[0] : "",
        name: data.name ? data.name : "",
        lastName: data.last_name ? data.last_name : "",
      });
    });
  };

  /* Fills Medic data to check if exists */
  const getMedics = (medicRut) => {
    dbGet("medics").then((data) => {
      let mapped = data.map((item) => {
        return { id: item["id"], rut: item["rut"] };
      });
      setAllMedics(mapped.filter((x) => x.rut !== medicRut));
    });
  };

  /* Basic form check */
  const checkFormComplete = () => {
    if (
      !editMedicForm.name ||
      !editMedicForm.rut ||
      !editMedicForm.lastName ||
      !isRutValid.rut
    )
      return false;
    return true;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder editar el médico.",
      );
      return;
    }
    setIsLoading(true);

    // Give adecuate format to send form to db
    let completedForm = {
      rut: editMedicForm.rut + editMedicForm.rutVerifier,
      name: editMedicForm.name,
      lastName: editMedicForm.lastName,
    };

    completedForm = cleanEmptyKeys(completedForm);

    // Sends to db
    dbPut(`medics/${editMedicForm.id}`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Médico editado exitosamente");
        redirectToMedicsPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al editar el médico",
        );
        setIsLoading(false);
        return;
      });
  };

  /* Cancel editing distributor and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankEditMedicForm);
    history.goBack();
  };

  /* Redirect to pharmacies table page after editing correctly */
  const redirectToMedicsPage = (user) => {
    setTimeout(() => {
      updateForm(blankEditMedicForm);
      setIsLoading(false);
      history.push(routes.abmMedics);
    }, 3000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    if (props.props.location.state) {
      getMedics(props.props.location.state.medicRut);
      getMedicInfo(props.props.location.state.medicId);
    }
  }, []);

  const title = `Editar médico${editMedicForm.name ? ": " : ""}${
    editMedicForm.name ? editMedicForm.name : ""
  } ${editMedicForm.lastName ? editMedicForm.lastName : ""}`;

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Paper elevation={3} style={{ borderRadius: "10px" }}>
        <Grid
          style={{ display: "flex", margin: 50 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
        >
          <form autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  className={classes.text}
                  style={{ marginTop: "10px" }}
                >
                  Datos del médico
                </Typography>
                <div
                  className={classes.text}
                  style={{
                    marginTop: "1%",
                    display: "flex",
                    justifyContent: "space-between",
                    width: 700,
                  }}
                >
                  <Typography weight="medium">
                    A continuación edite los datos del médico.
                  </Typography>
                </div>
                <div style={{ marginTop: "4%" }}>
                  <Field
                    labelName="Nombre *"
                    required={true}
                    id="name"
                    value={editMedicForm.name}
                    divClassType={classes.generalPurposeItems}
                    fieldClassType={classes.generalPurposeField}
                    onChange={handleChange}
                  ></Field>
                  <Field
                    labelName="Apellidos *"
                    required={true}
                    id="lastName"
                    value={editMedicForm.lastName}
                    divClassType={classes.generalPurposeItems}
                    fieldClassType={classes.generalPurposeField}
                    onChange={handleChange}
                  ></Field>
                  <RutField
                    labelName="RUT * (sin guión)"
                    required={true}
                    id="rut"
                    id2="rutVerifier"
                    disabled
                    value={editMedicForm.rut}
                    divClassType={classes.smallItems2}
                    divClassType2={classes.generalPurposeItems2}
                    fieldClassType={classes.smallFieldValidation2}
                    fieldClassType2={classes.smallFieldValidation3}
                    onChange={handleRutChange}
                    helperText={isRutValid.message}
                    error={!isRutValid.rut}
                    value2={editMedicForm.rutVerifier}
                    onChange2={handleRutChange}
                  ></RutField>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={12}>
                <div className={classes.buttonSpacing}>
                  {isLoading ? (
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Guardar
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={cancelAndGoBack}
                  >
                    Cancelar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </>
  );
}
