import React, { useState, useEffect } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Typography } from "components/Wrappers/Wrappers";
import Field from "components/CustomFields/Field.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import RutField from "components/CustomFields/RutField";
import EmailField from "components/CustomFields/EmailField";
import { verifyEmail } from "utils/emailValidation";

// rut verification
import { verifyRut } from "utils/rutValidations.js";
import logo from "images/logoPagesRS.jpg";
import {
  getInscriptionsByPatientRut,
  dbGet,
  dbPost,
} from "utils/DBFetchers.js";

// styles
import useStyles from "./styles";
import dataGridTexts from "components/DataGrid/dataGridTexts.js";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

const maxAvailableSelectedProducts = 10; // Same as db (hardcoded)

const blankForm = {
  couponBarcode: "",
  patientRut: "",
  patientRutVerifier: "",
  patientEmail: "",
  doctorRut: "",
  doctorRutVerifier: "",
  productsSelected: [],
};

const availableProductsColumns = [
  { field: "name", headerName: "Producto", width: 600 },
];
// Row example
// [{ id: 1, name: "Ibuprofeno", presentation: "100mg" }];

const acquiredProductsColumns = [
  {
    field: "presentation",
    headerName: "Producto",
    width: 360,
    valueFormatter: (params) => params.row?.presentation?.name,
  },
  {
    field: "discount",
    headerName: "Descuento (%)",
    width: 155,
    align: "center",
    valueFormatter: (params) => params.row?.vademecum?.discount,
  },
  {
    field: "remaining_units",
    headerName: "Compras restantes",
    width: 180,
    align: "center",
  },
];
// Row example
// {id: 1, presentation: {id: 1, name: "Ibuprofeno1"}, vademecum: { discount: 10 }, remaining_units: 5}

export default function NewCuponPage() {
  var classes = useStyles();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const [itemCount, setItemCount] = useState(0);
  const [searchQuery, setSearch] = useState("");
  const [form, updateForm] = useState(blankForm);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [acquiredProductsRows, setAcquiredProducts] = useState([]);
  const [availableProductsRows, setAvailableProducts] = useState([]);
  const [isBarcodeValid, setIsBarcodeValid] = useState({
    barcode: false,
    message: "",
  });
  const [isRutValid, setIsRutValid] = useState({
    doctor: false,
    patient: false,
    doctorMessage: "",
    patientMessage: "",
  });

  const totalWidth = acquiredProductsColumns
    .map((x) => (x.width ? x.width : 0))
    .reduce((accumulator, currentValue) => accumulator + currentValue);

  const handleEmailChange = (event) => {
    setIsEmailValid(verifyEmail(event.target.value));
    updateForm({ ...form, [event.target.id]: event.target.value });
  };

  const updateFormOnSelection = (newSelect) => {
    updateForm({ ...form, productsSelected: newSelect });
  };

  const checkFormComplete = () => {
    if (
      !form.couponBarcode ||
      !isBarcodeValid.barcode ||
      !form.patientRut ||
      !form.doctorRut ||
      form.productsSelected.length === 0 ||
      form.productsSelected.length > maxAvailableSelectedProducts ||
      !isRutValid.doctor ||
      !isRutValid.patient ||
      !isEmailValid
    )
      return false;
    return true;
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    // Sends to db
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder enviar el cupón.",
      );
      setIsLoading(false);
      return;
    }

    // Give adecuate format to send form to db
    let completedForm = {
      barcode_value: form.couponBarcode.slice(0, -1),
      patient: {
        rut: form.patientRut + form.patientRutVerifier,
        email: form.patientEmail,
      },
      doctor_rut: form.doctorRut + form.doctorRutVerifier,
      presentations: form.productsSelected,
      program_id: localStorage.getItem("programId"),
    };

    // Sends to db
    dbPost("inscriptions", completedForm)
      .then((data) => {
        snackbarOpen("success", "Empadronamiento creado exitosamente");
        handleCleanForm();
        setIsLoading(false);
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0]
            ? error[0].description_es
            : "Error al añadir empadronamiento",
        );
        setIsLoading(false);
        return;
      });
  };

  const handleCleanForm = () => {
    updateForm(blankForm);
    setAcquiredProducts([]);
    setAvailableProducts([]);
  };

  // Doctor form updaters
  const fetchDoctor = () => {
    var currentRut = form.doctorRut + form.doctorRutVerifier;
    var currentRutValidation = verifyRut(currentRut);
    if (currentRutValidation && form.doctorRutVerifier) {
      setIsRutValid({
        ...isRutValid,
        doctor: currentRutValidation,
        doctorMessage: "",
      });
    } else if (currentRutValidation && !form.doctorRutVerifier) {
      setIsRutValid({
        ...isRutValid,
        doctor: false,
        doctorMessage: "Ingrese el dígito verificador en el campo respectivo.",
      });
    } else {
      setIsRutValid({
        ...isRutValid,
        doctor: currentRutValidation,
        doctorMessage: "RUT invalido",
      });
    }
  };

  // Patient form updaters
  // 3- Comparer and filter for products
  function presentationComparer(otherArray) {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return other.presentation.id === current.id ||
            (other.presentation.product_id && current.product_id)
            ? other.presentation.product_id === current.product_id
            : false;
        }).length === 0
      );
    };
  }
  // 2- Get presentations for table
  const setAvailableProductsFiltering = (productsAcquired) => {
    dbGet(`presentations/program/${localStorage.getItem("programId")}`).then(
      (data) => {
        data = data
          // first sort by date, then by name
          .sort((a, b) => {
            var dateSplitA = a.created_at.slice(0, 10).split("/");
            var dateSplitB = b.created_at.slice(0, 10).split("/");
            const dateA = new Date(dateSplitA[2], dateSplitA[1], dateSplitA[0]);
            const dateB = new Date(dateSplitB[2], dateSplitB[1], dateSplitB[0]);
            return dateA < dateB ? -1 : 1; // newest first
          }); // products are sorted by creation date, newest first
        let duplicates = [
          ...new Map(data.map((o) => [o.product_id, o])).values(),
        ]; // duplicates only
        data = data.filter((value) => !value.product_id);
        let final = [...duplicates, ...data];
        final = final
          .filter(presentationComparer(productsAcquired))
          .sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            // a must be equal to b
            return 0;
          });
        setAvailableProducts(final);
      },
    );
  };
  // 1- Set acquired and available products via get inscriptions
  const updateAllProducts = (rut) => {
    getInscriptionsByPatientRut(rut).then((data) => {
      setAcquiredProducts(data);
      setAvailableProductsFiltering(data); // Calls 2
    });
  };

  const updateFormPatient = (rut) => {
    dbGet(`patients/${rut}`).then((data) => {
      updateForm({ ...form, patientEmail: data.email ? data.email : "" });
      if (data.email) setIsEmailValid(true);
      updateAllProducts(rut); // Calls 1
    });
  };
  const fetchPatient = () => {
    var currentRut = form.patientRut + form.patientRutVerifier;
    var currentRutValidation = verifyRut(currentRut);
    if (currentRutValidation && form.patientRutVerifier) {
      updateFormPatient(currentRut);
      setIsRutValid({
        ...isRutValid,
        patient: currentRutValidation,
        patientMessage: "",
      });
    } else if (currentRutValidation && !form.patientRutVerifier) {
      setIsRutValid({
        ...isRutValid,
        patient: false,
        patientMessage: "Ingrese el dígito verificador en el campo respectivo.",
      });
    } else {
      updateForm({ ...form, patientEmail: "" });
      setAcquiredProducts([]);
      setAvailableProducts([]);
      setIsRutValid({
        ...isRutValid,
        patient: currentRutValidation,
        patientMessage: "RUT inválido",
      });
    }
  };

  // Barcode form updaters
  const verifyBarcode = (id) => {
    if (form.couponBarcode.length < 13) {
      setIsBarcodeValid({
        barcode: false,
        message:
          "Código de barras inexistente o no pertenece al programa seleccionado",
      });
      return;
    }
    dbGet(
      `barcodes?barcode=${id}&program_id=${localStorage.getItem("programId")}`,
    ).then((data) => {
      if (
        !data ||
        data.length === 0 ||
        Object.keys(data[0]).length === 0 ||
        !data[0].id
      ) {
        setIsBarcodeValid({
          barcode: false,
          message:
            "Código de barras inexistente o no pertenece al programa seleccionado",
        });
      } else {
        setIsBarcodeValid({ barcode: true, message: "" });
      }
    });
  };
  const onBarcodeChange = (e) => {
    var currentId = e.target.value;
    updateForm({ ...form, couponBarcode: currentId });
  };
  const fetchBarcode = (barcode) => {
    verifyBarcode(barcode.slice(0, -1)); //
  };

  // Rut updaters
  const onRutChange = (e) => {
    updateForm({ ...form, [e.target.id]: e.target.value.toUpperCase() });
  };
  useEffect(() => {
    if (!form.patientRut && !form.patientRutVerifier) return; // no fetch on page load
    fetchPatient();
  }, [form.patientRut, form.patientRutVerifier]);
  useEffect(() => {
    if (!form.doctorRut && !form.doctorRutVerifier) return; // no fetch on page load
    fetchDoctor();
  }, [form.doctorRut, form.doctorRutVerifier]);
  useEffect(() => {
    if (!form.couponBarcode) return; // no fetch on page load
    fetchBarcode(form.couponBarcode);
  }, [form.couponBarcode]);

  // Program switch logic
  localStorage.setItem("programId", 2); // Default program
  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  const updateFormPatientOnProgramIdChange = () => {
    fetchPatient();
    fetchBarcode(form.couponBarcode);
  };
  useEffect(() => {
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);
  useEffect(() => {
    if (!form.patientRut && !form.patientRutVerifier && !form.couponBarcode)
      return; // no fetch on page load
    updateFormPatientOnProgramIdChange();
  }, [programId]);

  return (
    <>
      <div>
        <PageTitle
          title="Nuevo Cupón Solo Mujer"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del cupón
              </Typography>
              <Field
                labelName="Código de barras *"
                required={true}
                id="couponBarcode"
                value={form.couponBarcode}
                error={!isBarcodeValid.barcode}
                helperText={isBarcodeValid.message}
                divClassType={classes.smallItems}
                fieldClassType={classes.smallFieldValidation}
                onChange={onBarcodeChange}
              ></Field>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del paciente
              </Typography>
              <RutField
                labelName="RUT * (sin guión)"
                required={true}
                id="patientRut"
                id2="patientRutVerifier"
                value={form.patientRut}
                divClassType={classes.smallItems2}
                divClassType2={classes.generalPurposeItems2}
                fieldClassType={classes.smallFieldValidation2}
                fieldClassType2={classes.smallFieldValidation3}
                onChange={onRutChange}
                helperText={isRutValid.patientMessage}
                error={!isRutValid.patient}
                value2={form.patientRutVerifier}
                onChange2={onRutChange}
              ></RutField>
              <EmailField
                labelName="Email *"
                required={true}
                id="patientEmail"
                value={form.patientEmail}
                onChange={handleEmailChange}
                divClassType={classes.generalPurposeItems}
                fieldClassType={classes.generalPurposeField}
                error={!verifyEmail(form.patientEmail)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del profesional
              </Typography>
              <RutField
                labelName="RUT * (sin guión)"
                required={true}
                id="doctorRut"
                id2="doctorRutVerifier"
                value={form.doctorRut}
                divClassType={classes.smallItems2}
                divClassType2={classes.generalPurposeItems2}
                fieldClassType={classes.smallFieldValidation2}
                fieldClassType2={classes.smallFieldValidation3}
                onChange={onRutChange}
                helperText={isRutValid.doctorMessage}
                error={!isRutValid.doctor}
                value2={form.doctorRutVerifier}
                onChange2={onRutChange}
              ></RutField>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Productos habilitados
              </Typography>
              <div
                className={classes.generalPurposeItems}
                style={{ marginTop: "3%", marginBottom: "4%" }}
              >
                <Typography className={classes.text} weight="bold">
                  Seleccionar (máximo {maxAvailableSelectedProducts}) *
                </Typography>
              </div>
              <div
                className={classes.generalPurposeField}
                style={{ height: 400, width: totalWidth + 20 }}
              >
                <TextField
                  className={classes.searchField}
                  label="Filtrar"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {itemCount > maxAvailableSelectedProducts && (
                  <Typography
                    className={classes.text}
                    style={{ color: "red" }}
                    variant="h6"
                    weight="bold"
                  >
                    No puedes seleccionar más de {maxAvailableSelectedProducts}
                  </Typography>
                )}
                <DataGrid
                  rows={
                    searchQuery
                      ? availableProductsRows.filter((x) =>
                          x.name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()),
                        )
                      : availableProductsRows
                  }
                  columns={availableProductsColumns}
                  checkboxSelection
                  showToolbar={true}
                  //hideFooter={true}
                  disableColumnSelector={true}
                  disableDensitySelector={true}
                  disableColumnMenu={true}
                  rowsPerPageOptions={[]}
                  pageSize={100}
                  localeText={dataGridTexts}
                  onSelectionModelChange={(GridRowSelectedParams) => {
                    let newSelected = GridRowSelectedParams;
                    if (newSelected.length <= maxAvailableSelectedProducts)
                      updateFormOnSelection(newSelected);
                    setItemCount(newSelected.length);
                  }}
                  onSelectionChange={(newSelection) => {
                    let newSelected = newSelection.rowIds.map((n) => +n);
                    if (newSelected.length <= maxAvailableSelectedProducts)
                      updateFormOnSelection(newSelected);
                    setItemCount(newSelected.length);
                  }}
                />
              </div>
              <div
                style={{ marginTop: "13%", marginBottom: "1%" }}
                className={classes.generalPurposeItems}
              >
                <Typography className={classes.text} weight="bold">
                  Ya habilitados ({acquiredProductsRows.length})
                </Typography>
              </div>
              <div
                className={classes.generalPurposeField}
                style={{ height: 350, width: totalWidth + 20 }}
              >
                <DataGrid
                  rows={acquiredProductsRows}
                  columns={acquiredProductsColumns}
                  disableColumnMenu={true}
                  pageSize={100}
                  checkboxSelection={false}
                  disableSelectionOnClick={true}
                  disableColumnSelector={true}
                  disableDensitySelector={true}
                  showToolbar={true}
                  localeText={dataGridTexts}
                  hideFooter={true}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6} className={classes.alignRight}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.submitLoader} />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  type="submit"
                >
                  Guardar
                </Button>
              )}
            </Grid>
            <Grid item xs={6} className={classes.alignLeft}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCleanForm}
              >
                Limpiar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
