import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(1),
  },
  buttonSpacing: {
    display: "flex",
    justifyContent: "center",
  },
  generalPurposeItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 700,
    justifyContent: "space-between",
  },
  smallItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "1%",
    width: 430,
    justifyContent: "space-between",
  },
  sendItem: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "1.5%",
    width: 640,
    justifyContent: "center",
  },
  generalPurposeField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: "-1.5%",
    width: 300,
  },
  selectionField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: "-1.5%",
    width: 210,
    height: 40,
  },
  loginLoader: {
    marginTop: "8%",
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(6.8),
  },
  sendField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "6%",
    width: 130,
    height: 40,
  },
  logotypeImage: {
    width: 157,
    height: 60,
    marginTop: "-1%",
  },
  popupDivCenter: {
    display: "flex",
    justifyContent: "center",
  },
}));
