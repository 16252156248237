import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, Grid, LinearProgress, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";
// styles
import useStyles from "./styles";

import { dbGet } from "utils/DBFetchers.js";
import moment from "moment";

import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";

export default function ABMPharmacyDistributorPage() {
  const history = useHistory();
  // NEW
  const RedirectToNewPharmacyDistributor = () => {
    history.push(routes.abmPharmacyDistributorsNew);
  };

  // Table columns definition
  const columns = [
    {
      name: "Id",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "Farmacia",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Código Farmacia",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Distribuidor actual",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Distribuidor actual desde",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        sortCompare: (order) => {
          return (a, b) => {
            var dateSplitA = a.data.split("/");
            var dateSplitB = b.data.split("/");
            const dateA = new Date(
              dateSplitA[2],
              dateSplitA[1],
              dateSplitA[0],
            ).getTime();
            const dateB = new Date(
              dateSplitB[2],
              dateSplitB[1],
              dateSplitB[0],
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === "desc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "Próximo distribuidor",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
  ];

  var classes = useStyles();
  const [tableData, setTableData] = useState([]);
  var [isLoading, setIsLoading] = useState(false);

  // Obtains the next distributor if exists for the current p-d relationship (mapped by id)
  const getNextDistributors = (data) => {
    let dictionary = Object.assign(
      {},
      ...data.map((x) => {
        let next_pd = data.filter(
          (pd) =>
            x.to_date === pd.from_date &&
            x.program_id === pd.program_id &&
            x.pharmacy_id === pd.pharmacy_id,
        );
        return {
          [x.id]:
            next_pd.length !== 0
              ? next_pd[0].distributor && Object.keys(next_pd[0]).length !== 0
                ? next_pd[0].distributor.name
                : ""
              : "",
        };
      }),
    );
    return dictionary;
  };

  /* Fills table data */
  const fillTable = (data) => {
    if (!data) return;
    let next_distributors = getNextDistributors(data);

    // Remove inactive distributors
    data = data.filter(
      (x) =>
        moment(x.from_date, "DD/MM/YY").toDate() < moment().toDate() &&
        (!x.to_date ||
          moment().toDate() < moment(x.to_date, "DD/MM/YY").toDate()),
    );

    var tableData = data.map((row) => [
      row.id,
      row.pharmacy && Object.keys(row.pharmacy).length !== 0
        ? row.pharmacy.name
        : "",
      row.pharmacy && Object.keys(row.pharmacy).length !== 0
        ? row.pharmacy.code
        : "",
      row.distributor && Object.keys(row.distributor).length !== 0
        ? row.distributor.name
        : "",
      row.from_date,
      next_distributors[row.id],
    ]);
    setTableData(tableData);
    setIsLoading(false);
  };

  const getResults = (category) => {
    setIsLoading(true);
    dbGet(category)
      .then((data) => {
        data = data.filter(
          (x) => +x.program_id === +localStorage.getItem("programId"),
        );
        fillTable(data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getResults(`pharmacies_distributors`);
  }, []);

  // Program switch logic
  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  const updateTableDataOnProgramIdChange = () => {
    setTableData([]);
    getResults(`pharmacies_distributors`);
  };
  React.useEffect(() => {
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);
  React.useEffect(() => {
    if (!tableData) return; // no fetch on page load
    updateTableDataOnProgramIdChange();
  }, [programId]);

  return (
    <>
      <div>
        <PageTitle
          title="Relación Farmacia-Distribuidores"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={RedirectToNewPharmacyDistributor}
          startIcon={<AddIcon />}
        >
          Añadir
        </Button>
      </div>
      {isLoading ? (
        <div>
          <Typography variant="h4" className={classes.loaderText}>
            Cargando datos
          </Typography>
          <LinearProgress size={40} className={classes.loader} />
        </div>
      ) : (
        <Grid
          style={{ display: "flex", margin: 0 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
        >
          <Grid container spacing={3} xs={12}>
            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <CustomMUIDataTable
                title="Relaciones"
                data={tableData}
                columns={columns}
                filterStart={1}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
