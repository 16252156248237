import React from "react";
import { FormControlLabel, Checkbox, Typography, Link } from "@mui/material";

export default function CheckboxForm({
  required,
  checked,
  handleInputChange,
  name,
  firstTypography = "",
  linkTypography = "",
  secondTypography = "",
  href = "",
  color = "primary",
}) {
  return (
    <FormControlLabel
      style={{ paddingBottom: 8 }}
      control={
        <Checkbox
          color={color}
          size="small"
          required={required}
          name={name}
          checked={checked}
          onChange={handleInputChange}
        />
      }
      label={
        <Typography variant="body2">
          {firstTypography}{" "}
          <Link href={href} color={color} underline="always">
            {linkTypography}
          </Link>{" "}
          {secondTypography}
        </Typography>
      }
    />
  );
}
