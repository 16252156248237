import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import sanitizeHtml from "sanitize-html";

export default function ErrorDialog({
  message,
  open,
  handleClose,
  hasCoupons,
}) {
  // Define the options for sanitizing the HTML
  const sanitizeOptions = {
    allowedTags: ["p", "br", "a", "b", "i", "em", "strong"],
    allowedAttributes: {
      a: ["href"],
    },
  };

  // Sanitize the HTML content
  const sanitizedMessage = sanitizeHtml(message, sanitizeOptions);

  return (
    <Dialog onClose={handleClose} aria-labelledby="error" open={open}>
      <DialogContent>
        <div
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}
