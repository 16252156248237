import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import {
  Button,
  Chip,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CachedIcon from "@material-ui/icons/Cached";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useHistory } from "react-router-dom";
import { getRoleName } from "utils/roleNameGetter";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";

import { format } from "date-fns";
import { dbDelete, dbDisableEnable, dbGet } from "utils/DBFetchers";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";

// roles
import { hidedRoles, roleTranslation } from "pages/users/roles/utils";

export default function UsersPage() {
  const [deleteUserOpen, setDeleteUserOpen] = React.useState(false);
  const [userToDelete, setUserToDelete] = React.useState({ id: "", name: "" });
  const handleDeleteUserOpen = (userId, username) => {
    setDeleteUserOpen(true);
    setUserToDelete({ id: userId, name: username });
  };
  const handleDeleteUserClose = () => {
    setDeleteUserOpen(false);
  };
  const handleDeleteUser = () => {
    setDeleteUserOpen(false);
    dbDelete(`users/${userToDelete.id}`);
    setTimeout(() => {
      setTableData([]);
      getResults("users");
    }, 700);
  };

  const [disableUserOpen, setDisableUserOpen] = React.useState(false);
  const [userToDisable, setUserToDisable] = React.useState({
    id: "",
    name: "",
    command: "",
  });
  const handleDisableUserOpen = (userId, username, enabled) => {
    setDisableUserOpen(true);
    setUserToDisable({
      id: userId,
      name: username,
      command: enabled ? "disable" : "enable",
    });
  };
  const handleDisableUserClose = () => {
    setDisableUserOpen(false);
  };
  const handleDisableUser = () => {
    setDisableUserOpen(false);
    dbDisableEnable("users", userToDisable.id, userToDisable.command);
    setTimeout(() => {
      getResults("users");
    }, 700);
  };

  const history = useHistory();
  // EDIT USER
  const RedirectToEditUser = (user) => {
    history.push(routes.usersEditUser, { userId: user });
  };
  // NEW USER
  const RedirectToNewUser = () => {
    history.push(routes.usersNewUser);
  };

  const USERNAME_POS = 2;
  const ROLE_POS = 3;
  const USER_ID_POS = 7;
  const IS_ENABLED_POS = 8;
  const IS_DISABLE_POS = 8;
  const IS_VISIBLE_POS = 9;

  const columns = [
    {
      name: " ",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return { style: { fontWeight: "bold", minWidth: 190, width: 190 } };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    RedirectToEditUser(tableMeta.rowData[USER_ID_POS]); // user_id
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    handleDeleteUserOpen(
                      tableMeta.rowData[USER_ID_POS], // user_id
                      tableMeta.rowData[USERNAME_POS], // username
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Activar/Desactivar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    handleDisableUserOpen(
                      tableMeta.rowData[USER_ID_POS], // user_id
                      tableMeta.rowData[USERNAME_POS], // username
                      tableMeta.rowData[IS_DISABLE_POS], // is_disabled
                    );
                  }}
                >
                  <CachedIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "Estado",
      options: {
        setCellHeaderProps: () => {
          return { style: { fontWeight: "bold" } };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={tableMeta.rowData[1]} // estado
              classes={{
                root: tableMeta.rowData[IS_ENABLED_POS] // is_enabled
                  ? classes.success
                  : classes.secondary,
              }}
            />
          );
        },
      },
    },
    {
      name: "Usuario",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Rol",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    // {
    //   name: "Farmacia",
    //   options: {
    //     filter: false,
    //     setCellHeaderProps: () => ({
    //       style: { fontWeight: "bold", whiteSpace: "nowrap" },
    //     }),
    //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    //   },
    // },

    // {
    //   name: "Grupo",
    //   options: {
    //     filter: false,
    //     setCellHeaderProps: () => ({
    //       style: { fontWeight: "bold", whiteSpace: "nowrap" },
    //     }),
    //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    //   },
    // },
    // {
    //   name: "Distribuidor",
    //   options: {
    //     filter: false,
    //     setCellHeaderProps: () => ({
    //       style: { fontWeight: "bold", whiteSpace: "nowrap" },
    //     }),
    //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    //   },
    // },
    // {
    //   name: "KAM",
    //   options: {
    //     filter: false,
    //     display: getRoleName() === "super_admin" ? true : false,
    //     setCellHeaderProps: () => ({
    //       style: { fontWeight: "bold", whiteSpace: "nowrap" },
    //     }),
    //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    //   },
    // },
    {
      name: "Último ingreso",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        sortCompare: (order) => {
          return (a, b) => {
            var timeA = a.data.slice(11).split(":");
            var timeB = b.data.slice(11).split(":");
            var dateSplitA = a.data.slice(0, 10).split("/");
            var dateSplitB = b.data.slice(0, 10).split("/");
            const dateA = new Date(
              dateSplitA[2],
              dateSplitA[1],
              dateSplitA[0],
              timeA[0],
              timeA[1],
            ).getTime();
            const dateB = new Date(
              dateSplitB[2],
              dateSplitB[1],
              dateSplitB[0],
              timeB[0],
              timeB[1],
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === "desc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "Email",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Apellido y nombre",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    { name: "Id", options: { display: "excluded", filter: false } },
    { name: "Disabled", options: { display: "excluded", filter: false } },
  ];

  var classes = useStyles();
  const [tableData, setTableData] = useState([]);

  const fillTable = (data) => {
    if (!data) return;
    setIsLoading(true);
    dbGet("roles")
      .then((roleData) => {
        var roles = {};

        roleData.forEach((role) => (roles[role.id] = role.name));
        var tableData = data.map((row) => [
          "",
          row.is_enabled ? "Activo" : "Inactivo",
          row.username,
          roles[row.role_id] in roleTranslation
            ? roleTranslation[roles[row.role_id]]
            : roles[row.role_id],
          // row.pharmacy && Object.keys(row.pharmacy).length !== 0
          //   ? `${row.pharmacy.name} - ${row.pharmacy.code}`
          //   : "",
          // row.pharma_group && Object.keys(row.pharma_group).length !== 0
          //   ? row.pharma_group.name
          //   : "",
          // row.distributor && Object.keys(row.distributor).length !== 0
          //   ? row.distributor.name
          //   : "",
          // row.kam && Object.keys(row.kam).length !== 0 ? row.kam.name : "",
          format(new Date(row.last_activity_at), "dd/MM/yyyy HH:mm"),
          row.email,
          `${row.lastname ? row.lastname : ""} ${row.name ? row.name : ""}`,
          row.id,
          row.is_enabled,
          row.is_visible,
        ]);
        setTableData(tableData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  var [isLoading, setIsLoading] = useState(false);
  const getResults = (category) => {
    setIsLoading(true);
    dbGet(category)
      .then((data) => {
        fillTable(data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getResults("users");
  }, []);

  return (
    <>
      <Dialog open={deleteUserOpen} onClose={handleDeleteUserClose}>
        <DialogTitle>{`¿Eliminar el usuario ${userToDelete.name}?`}</DialogTitle>
        <DialogActions className={classes.popupDivCenter}>
          <Button
            onClick={handleDeleteUserClose}
            color="primary"
            variant="contained"
            className={classes.popupButtonSpacing}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteUser}
            variant="contained"
            autoFocus
            className={classes.popupButtonSpacingDelete}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={disableUserOpen} onClose={handleDisableUserClose}>
        <DialogTitle>{`${
          userToDisable.command === "enable" ? "Activar" : "Desactivar"
        } el usuario ${userToDisable.name}?`}</DialogTitle>
        <DialogActions className={classes.popupDivCenter}>
          <Button
            onClick={handleDisableUserClose}
            color="primary"
            variant="contained"
            className={classes.popupButtonSpacing}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDisableUser}
            color="secondary"
            variant="contained"
            autoFocus
            className={
              userToDisable.command === "enable"
                ? classes.popupButtonSpacingEnable
                : classes.popupButtonSpacing
            }
          >
            {userToDisable.command === "enable" ? "Activar" : "Desactivar"}
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        <PageTitle
          title="Usuarios"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={RedirectToNewUser}
          startIcon={<AddIcon />}
        >
          Añadir
        </Button>
      </div>

      {isLoading ? (
        <div>
          <Typography variant="h4" className={classes.loaderText}>
            Cargando datos
          </Typography>
          <LinearProgress size={40} className={classes.loader} />
        </div>
      ) : (
        <Grid
          style={{ display: "flex", margin: 0 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <CustomMUIDataTable
                title="Usuarios"
                data={
                  getRoleName() !== "super_admin"
                    ? tableData.filter((x) => {
                        // BE CAREFULL, THIS HAS TO FILTER TRANSLATED ROLES
                        return (
                          !hidedRoles.has(x[ROLE_POS]) &&
                          x[IS_VISIBLE_POS] !== false
                        );
                      })
                    : tableData
                }
                columns={columns}
                filterStart={1}
                filterEnd={11}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
