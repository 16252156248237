import { Card, Paper } from "@mui/material";
import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import DonutChart from "components/Charts/donutChart";
import FullPieChart from "components/Charts/pieChart";
import { Height } from "@material-ui/icons";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BlockIcon from "@mui/icons-material/Block";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { Typography } from "components/Wrappers/Wrappers";
import WorkIcon from "@mui/icons-material/Work";
import { format } from "date-fns";
import useStyles from "../styles";
import { useTheme } from "@material-ui/styles";

const translateCategory = (category) => {
  const categories = {
    new_patients: "Pacientes nuevos",
    old_patients: "Pacientes viejos",
    Potenciales: "Potencial",
    "No Usan/No quieren": "No usuario",
  };
  if (!categories[category]) return category;
  return categories[category];
};

const DEFAULT_PIE_1_LABELS = ["Potencial", "Usuario", "No usa", "Sin status"];

const DEFAULT_GRAPHICS_DATA = {
  pie1Data: {
    series: [],
    labels: [],
    title: "Cupones entregados a médicos",
    colors: ["#B5A4A3", "#FFEE58", "#7FB77E", "#9ab7d3", "#bebdb8"],
  },
  pie2Data: {
    series: [],
    labels: [],
    title: "Conversiones por tipo de paciente",
    colors: ["#B5A4A3", "#9ab7d3"],
  },
  donut1Data: {
    series: [],
    labels: ["Entregados a médicos", "No entregados"],
    title: "Cupones entregados a consultores",
    colors: ["#FFEE58", "#e5e0b0"],
  },
  donut2Data: {
    series: [],
    labels: ["Convertidos", "No convertidos"],
    title: "Cupones entregados a médicos",
    colors: ["#7FB77E", "#B1D7B4"],
  },
  donutTotalKardexData: {
    series: [],
    labels: ["Médicos en Kardex", "Médicos no en Kardex"],
    title: "Médicos en Kardex",
    colors: ["#f2b279", "#FFDFC3"],
  },
  donutNewKardexData: {
    series: [],
    labels: ["Nuevos médicos en Kardex", "Médicos no en Kardex"],
    title: "Nuevos médicos en Kardex",
    colors: ["#7640B3", "#b49bd1"],
  },
};

export default function Graphs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [graphicsData, setGraphicsData] = useState(DEFAULT_GRAPHICS_DATA);

  const assembleGraphicsData = (information) => {
    var informationCopy = JSON.parse(JSON.stringify(information));
    const categories = [
      "No Usan/No quieren",
      "Nuevos",
      "Potenciales",
      "Sin Status",
      "Usuario",
    ];
    let totalConverted = informationCopy.converted.total;
    let notDelivered = informationCopy.not_delivered;
    let totalNotConverted = informationCopy.total_not_converted;
    let donut1Series = [
      informationCopy.coupons_to_medics.total.delivered,
      notDelivered,
    ];
    // Filtered categories to show in pie1.
    const couponsToMedicsFiltered = Object.keys(
      informationCopy.coupons_to_medics,
    )
      .filter((key) => categories.includes(key))
      .reduce((cur, key) => {
        return Object.assign(cur, {
          [key]: informationCopy.coupons_to_medics[key],
        });
      }, {});
    let pie1Series = Object.values(couponsToMedicsFiltered).map((dict) => {
      return dict.delivered;
    });
    let pie1Labels = Object.keys(couponsToMedicsFiltered).map((key) =>
      translateCategory(key),
    );
    pie1Labels = pie1Labels.length > 0 ? pie1Labels : DEFAULT_PIE_1_LABELS;
    const convertedFiltered = Object.keys(informationCopy.converted)
      .filter((key) => "total" !== key)
      .reduce((cur, key) => {
        return Object.assign(cur, {
          [key]: informationCopy.converted[key],
        });
      }, {});
    let pie2Series = Object.values(convertedFiltered);
    let pie2Labels = Object.keys(convertedFiltered).map((key) =>
      translateCategory(key),
    );
    let donut2Series = [totalConverted, totalNotConverted];
    let donutTotalKardexSeries = [
      informationCopy.medics_in_kardex,
      informationCopy.medics_total - informationCopy.medics_in_kardex,
    ];
    let donutNewKardexSeries = [
      informationCopy.new_medics_in_kardex,
      informationCopy.new_medics_total - informationCopy.new_medics_in_kardex,
    ];
    setGraphicsData({
      ...graphicsData,
      pie1Data: {
        ...graphicsData.pie1Data,
        series: pie1Series,
        labels: pie1Labels,
      },
      pie2Data: {
        ...graphicsData.pie2Data,
        series: pie2Series,
        labels: pie2Labels,
      },
      donut1Data: {
        ...graphicsData.donut1Data,
        series: donut1Series,
      },
      donut2Data: {
        ...graphicsData.donut2Data,
        series: donut2Series,
      },
      donutTotalKardexData: {
        ...graphicsData.donutTotalKardexData,
        series: donutTotalKardexSeries,
      },
      donutNewKardexData: {
        ...graphicsData.donutNewKardexData,
        series: donutNewKardexSeries,
      },
    });
  };

  useEffect(() => {
    assembleGraphicsData(props.information);
  }, []);

  return (
    <Grid item container lg={7} xs={12} md={7}>
      <Grid item lg={12} xs={12} md={12} style={{ marginBottom: "10px" }}>
        <Typography variant="h4" className={classes.alignedText}>
          Gráficos
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} style={{ padding: "2%", borderRadius: "10px" }}>
          <Grid container style={{ marginTop: "10px", marginBottom: "5px" }}>
            <Grid item lg={6} xs={12} md={6}>
              <FullPieChart new_data={graphicsData.pie1Data} />
            </Grid>
            <Grid item lg={6} xs={12} md={6}>
              <FullPieChart new_data={graphicsData.pie2Data} />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "10px", marginBottom: "5px" }}>
            <Grid item lg={6} xs={12} md={6}>
              <DonutChart new_data={graphicsData.donut1Data} />
            </Grid>
            <Grid item lg={6} xs={12} md={6}>
              <DonutChart new_data={graphicsData.donut2Data} />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "10px", marginBottom: "5px" }}>
            <Grid item lg={6} xs={12} md={6}>
              <DonutChart new_data={graphicsData.donutTotalKardexData} />
            </Grid>
            <Grid item lg={6} xs={12} md={6}>
              <DonutChart new_data={graphicsData.donutNewKardexData} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
