const getRoleName = () => {
  let data = localStorage.getItem("roleName");
  let buffer = Buffer.from(data, "base64");
  return buffer.toString("ascii");
};

const getUserName = () => {
  return localStorage.getItem("username");
};

const encodeRoleName = (roleName) => {
  let buffer = Buffer.from(roleName);
  return buffer.toString("base64");
};

export { getRoleName, encodeRoleName, getUserName };
