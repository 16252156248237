import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "../styles";
import TimerOffIcon from "@material-ui/icons/TimerOff";

// logo
import logo from "images/logoLoginRS.png";

// context
import { dbPut, dbGet } from "utils/DBFetchers.js";
import routes from "routes";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

function PasswordResetForm(props) {
  let classes = useStyles();

  // local
  let [isTokenValid, setIsTokenValid] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let [passwordValue1, setPasswordValue1] = useState("");
  let [passwordValue2, setPasswordValue2] = useState("");
  const [isFormValid, setIsFormValid] = useState({ valid: false, message: "" });
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const checkFormValid = () => {
    if (passwordValue1 !== passwordValue2) {
      setIsFormValid({
        valid: false,
        message: "Las constraseñas deben coincidir",
      });
      return false;
    } else if (passwordValue1.length < 6 || passwordValue1.length > 24) {
      setIsFormValid({
        valid: false,
        message: "La constraseña debe estar entre 6 y 24 caracteres",
      });
      return false;
    }
    setIsFormValid({
      valid: true,
      message: "",
    });
    return true;
  };

  const handlePageToken = () => {
    let token = props.location.pathname.split("cambiar-clave/")[1];
    dbGet(`/update-token-check?token=${token}`)
      .then((data) => {
        setIsTokenValid(true);
      })
      .catch((error) => {
        setIsTokenValid(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checkFormValid()) {
      return;
    }
    let token = props.location.pathname.split("cambiar-clave/")[1];
    setIsLoading(true);
    let passwordData = {
      password: passwordValue1,
    };
    dbPut(`users/reset-password?token=${token}`, passwordData)
      .then((data) => {
        snackbarOpen("success", "Contraseña editada exitosamente");
        setTimeout(() => {
          props.history.push(routes.login);
        }, 3000);
      })
      .catch((error) => {
        setIsLoading(false);
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al editar contraseña",
        );
        return;
      });
  };

  const handleGoLogin = (e) => {
    e.preventDefault();
    props.history.push(routes.login);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    handlePageToken();
  }, []);

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <CustomizedSnackbars
            severity={snackbarStatus.severity}
            mssg={snackbarStatus.message}
            open={snackbarStatus.open}
            onClose={onCloseSnackbar}
          />
          {isTokenValid ? (
            <>
              <form>
                <div>
                  <Typography
                    letiant="h3"
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    Nueva constraseña
                  </Typography>{" "}
                </div>
                <div className={classes.formDividerContainer}>
                  <div className={classes.formDivider} />
                </div>
                <Typography letiant="h6" align="center">
                  Ingresa una nueva contraseña entre 6 y 24 caracteres
                </Typography>
                <Fade in={!isFormValid.valid}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {isFormValid.message}
                  </Typography>
                </Fade>
                <TextField
                  id="password1"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={passwordValue1}
                  onChange={(e) => setPasswordValue1(e.target.value)}
                  margin="normal"
                  placeholder="Contraseña"
                  labelName="Nueva constraseña"
                  type="password"
                  fullWidth
                />
                <TextField
                  id="password2"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={passwordValue2}
                  onChange={(e) => setPasswordValue2(e.target.value)}
                  margin="normal"
                  placeholder="Repetir contraseña"
                  type="password"
                  labelName="Repetir nueva contraseña"
                  fullWidth
                />
                <div className={classes.formButtonsPassReset}>
                  {isLoading ? (
                    <CircularProgress
                      size={26}
                      className={classes.loginLoader}
                      style={{ marginLeft: "0%" }}
                    />
                  ) : (
                    <Button
                      disabled={passwordValue1.length === 0}
                      onClick={handleSubmit}
                      type="submit"
                      letiant="contained"
                      color="primary"
                      size="large"
                    >
                      Cambiar contraseña
                    </Button>
                  )}
                </div>
              </form>
            </>
          ) : (
            <div align="center">
              <TimerOffIcon
                fontSize="large"
                style={{ marginBottom: 20 }}
              ></TimerOffIcon>
              <Typography
                letiant="h4"
                align="center"
                style={{ marginBottom: 100 }}
              >
                El link de recuperación ha expirado
              </Typography>
              <Button
                onClick={handleGoLogin}
                type="submit"
                letiant="contained"
                color="primary"
                size="large"
                className={classes.backButtonsPassReset}
              >
                Volver
              </Button>
            </div>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          By Dynaxo Corp.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(PasswordResetForm);
