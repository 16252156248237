import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckIcon from "@material-ui/icons/Check";
import MUIDataTable from "mui-datatables";
import datatableTextLabels from "components/MUIDatatable/textLabels";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PageTitle from "components/PageTitle/PageTitle";
import logo from "images/logoPagesRS.jpg";
import "./styled.css";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

// const states = {
//   0: "Error",
//   1: "Ok",
//   2: "Ok months",
//   3: "Ok partialy",
//   4: "Ok registration",
// };

const chains = [
  {
    name: "Farmacias Ahumada",
    productCode: 1,
    product: 0,
    saleDate: 4,
    salesman: 0,
  },
  { name: "Cruz Verde", productCode: 2, product: 1, saleDate: 3, salesman: 0 },
  { name: "Salcobrand", productCode: 2, product: 0, saleDate: 4, salesman: 1 },
];

export default function DataQualityPage() {
  const classes = useStyles();
  var [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let data = [];
    chains.map((chain) => {
      let chainData = [];
      for (var category in chain) {
        if (category !== "name") chainData.push(chain[category]);
      }
      data.push(chainData);
    });
    setTableData(data);
  }, []);

  const Error = () => {
    return <CloseIcon />;
  };

  const Ok = () => {
    return <CheckIcon />;
  };

  const OkMonths = () => {
    return (
      <div>
        <CheckIcon />1
      </div>
    );
  };

  const OkPartialy = () => {
    return (
      <div>
        <CheckIcon />2
      </div>
    );
  };

  const OkRegistration = () => {
    return (
      <div>
        <CheckIcon />3
      </div>
    );
  };

  const states = {
    0: Error,
    1: Ok,
    2: OkMonths,
    3: OkPartialy,
    4: OkRegistration,
  };

  const columns = [
    {
      name: "Farmacias Ahumada",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellProps: () => ({ align: "center" }),
        setCellHeaderProps: () => {
          return {
            style: {
              fontWeight: "bold",
              minWidth: 150,
              width: 150,
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return states[tableMeta.rowData[0]];
        },
      },
    },
    {
      name: "Cruz Verde",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellProps: () => ({ align: "center" }),
        setCellHeaderProps: () => {
          return {
            style: {
              fontWeight: "bold",
              minWidth: 150,
              width: 150,
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return states[tableMeta.rowData[1]];
        },
      },
    },
    {
      name: "Salcobrand",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellProps: () => ({ align: "center" }),
        setCellHeaderProps: () => {
          return {
            style: {
              fontWeight: "bold",
              minWidth: 150,
              width: 150,
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return states[tableMeta.rowData[2]];
        },
      },
    },
  ];

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiTableCell: {
          head: {
            backgroundColor: "#dadaf6 !important",
            fontWeight: 900,
            position: "sticky",
            textAlign: "center",
            zIndex: 2,
          },
          body: {
            display: "table-cell",
            verticalAlign: "middle",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            height: 15,
            whiteSpace: "nowrap",
          },
        },
      },
    });
  return (
    <>
      <div>
        <PageTitle
          title="Calidad de datos"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <MuiThemeProvider theme={getMuiTheme}>
        <MUIDataTable
          title={"Calidad de datos"}
          columns={columns}
          data={tableData}
          options={{
            textLabels: datatableTextLabels(),
            print: false,
            selectableRows: "none",
            viewColumns: false,
            search: false,
            filter: false,
            download: false,
            rowsPerPageOptions: false,
            pagination: false,
          }}
        />
      </MuiThemeProvider>
    </>
  );
}
