import React, { useState } from "react";
import SelectField from "./SelectField";

import useStyles from "./styles";

export default function ZonesField(props) {

  return (
    <SelectField
      fieldClassName={props.fieldClassName}
      id="zones"
      label="Seleccione"
      name="zone"
      value={props.value}
      onChange={props.onChange}
      allValues={props.allValues}
    />
  );
}