import React from "react";

// styles
import useStyles from "./styles";
import { Typography } from "components/Wrappers/Wrappers";

import TextField from "@material-ui/core/TextField";

export default function Field(props) {
  var classes = useStyles();

  return (
    <>
      <div
        className={`${props.divClassType} ${
          props.hidden ? classes.hidden : ""
        }`}
      >
        <Typography className={classes.text} weight="bold">
          {props.labelName}
        </Typography>
        <TextField
          required={props.required ? props.required : false}
          id={props.id}
          name={props.id ? props.id : props.name}
          className={props.fieldClassType}
          variant="outlined"
          margin="dense"
          type={props.type}
          helperText={props.helperText}
          onChange={props.onChange}
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={props.onBlur}
          InputProps={props.InputProps}
          disabled={props.disabled}
          defaultValue={props.defaultValue}
          value={props.value}
          error={props.error}
          style={props.style}
          fullWidth={props.fullWidth}
        />
      </div>
    </>
  );
}
