import React, { useState, useEffect } from "react";
import { TextField, Grid, Divider, Typography } from "@material-ui/core";
import Field from "components/CustomFields/Field.js";

// styles
import useStyles from "./styles";

export default function MedicData(props) {
  var classes = useStyles();
  let fieldNameSpace = 5;
  let fieldValueSpace = 12 - fieldNameSpace;

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          lg={12}
          style={{ marginBottom: "15px", marginLeft: "2.5%" }}
        >
          <Typography variant="h4" className={classes.alignedText}>
            {props.title}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} className={classes.alignCenter}>
          <Field
            labelName={"Nombre"}
            disabled={true}
            value={props.name}
            style={{ minWidth: "280px" }}
          />
        </Grid>

        <Grid item xs={12} className={classes.alignCenter}>
          <Field
            labelName={"Cupones actuales"}
            disabled={true}
            defaultValue={"0"}
            value={props.couponsLeft}
            style={{ minWidth: "280px" }}
          />
        </Grid>
      </Grid>
    </>
  );
}
