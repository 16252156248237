import React, { useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Button, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from "@material-ui/icons/Add";

import TextField from "@material-ui/core/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";

import { dbGet, dbPut, dbPost } from "../../utils/DBFetchers.js";

import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import LoaderBar from "components/LoaderBar/LoaderBar";
import { Typography } from "components/Wrappers/Wrappers";

const emptySnackbarStatus = { open: false, severity: "", message: "" };

export default function ABMPharmaGroupsPage() {
  // EDIT PHARMAGROUP LOGIC
  const blankEditPharmaGroup = { id: "", name: "" };
  var [isLoading, setIsLoading] = useState(false);
  const [editPharmaGroupOpen, setEditPharmaGroupOpen] = React.useState(false);
  const [pharmaGroupToEdit, setPharmaGroupToEdit] =
    React.useState(blankEditPharmaGroup);
  const handleEditPharmaGroupOpen = (pharmaGroupId, newName) => {
    setEditPharmaGroupOpen(true);
    setPharmaGroupToEdit({
      id: pharmaGroupId,
      name: newName,
    });
  };
  const handleEditPharmaGroupClose = () => {
    setEditPharmaGroupOpen(false);
    setPharmaGroupToEdit(blankEditPharmaGroup);
  };
  const handleEditPharmaGroup = () => {
    setEditPharmaGroupOpen(false);
    dbPut(`/pharmagroups/${pharmaGroupToEdit.id}`, {
      name: pharmaGroupToEdit.name,
    })
      .then((data) => {
        setSnackbarStatus({
          open: true,
          severity: "success",
          message: "Grupo editado exitosamente",
        });
      })
      .catch((error) => {
        setSnackbarStatus({
          open: true,
          severity: "error",
          message: error[0].description_es,
        });
        return;
      });
    setTimeout(() => {
      setTableData([]);
      getResults("pharmagroups");
    }, 700);
  };

  const columns = [
    {
      name: " ",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return { style: { fontWeight: "bold", minWidth: 100, width: 100 } };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    handleEditPharmaGroupOpen(
                      tableMeta.rowData[1], // group_id
                      tableMeta.rowData[2], // name
                    );
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "Id",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "Nombre",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
  ];

  var classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [snackbarStatus, setSnackbarStatus] =
    React.useState(emptySnackbarStatus);

  const fillTable = (data) => {
    if (!data) return;
    var tableData = data.map((row) => ["", row.id, row.name]);
    setTableData(tableData);
  };

  const getResults = (category) => {
    setIsLoading(true);
    dbGet(category)
      .then((data) => {
        data = data.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          // a must be equal to b
          return 0;
        });
        setIsLoading(false);
        fillTable(data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getResults("pharmagroups");
  }, []);

  const onCloseSnackbars = (event, reason) => {
    setSnackbarStatus({ ...snackbarStatus, open: false });
  };

  // ADD NEW PHARMAGROUP LOGIC
  const [newPharmaGroupOpen, setAddPharmaGroupOpen] = React.useState(false);
  const [pharmaGroupToAdd, setPharmaGroupToAdd] = React.useState({
    name: "",
  });
  const handleNewPharmaGroupOpen = (id, pharmaGroupName) => {
    setAddPharmaGroupOpen(true);
  };
  const handleNewPharmaGroupClose = () => {
    setAddPharmaGroupOpen(false);
  };
  const handleAddPharmaGroup = () => {
    setAddPharmaGroupOpen(false);
    dbPost(`/pharmagroups`, pharmaGroupToAdd)
      .then((data) => {
        setSnackbarStatus({
          open: true,
          severity: "success",
          message: "Grupo añadido exitosamente",
        });
      })
      .catch((error) => {
        setSnackbarStatus({
          open: true,
          severity: "error",
          message: error[0].description_es,
        });
        return;
      });
    setTimeout(() => {
      setTableData([]);
      getResults("pharmagroups");
    }, 700);
  };

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbars}
      />

      <Dialog open={editPharmaGroupOpen} onClose={handleEditPharmaGroupClose}>
        <DialogTitle>{`Cambiar nombre del grupo`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Elija un nuevo nombre para el grupo.
          </DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="name"
            value={pharmaGroupToEdit.name}
            label="Nombre"
            className={classes.popupButtonSpacing}
            onChange={(e) => {
              setPharmaGroupToEdit({
                ...pharmaGroupToEdit,
                name: e.target.value,
              });
            }}
          />
        </DialogContent>
        <DialogActions className={classes.popupDivCenter}>
          <Button
            onClick={handleEditPharmaGroupClose}
            color="secondary"
            variant="contained"
            className={classes.popupButtonSpacing}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleEditPharmaGroup}
            color="primary"
            variant="contained"
            autoFocus
            className={classes.popupButtonSpacing}
          >
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={newPharmaGroupOpen} onClose={handleNewPharmaGroupClose}>
        <DialogTitle>{`Añadir nuevo grupo`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Elija un nombre para el grupo a añadir.
          </DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="name"
            value={pharmaGroupToAdd.name}
            label="Nombre"
            className={classes.popupButtonSpacing}
            onChange={(e) => {
              setPharmaGroupToAdd({
                ...pharmaGroupToAdd,
                name: e.target.value,
              });
            }}
          />
        </DialogContent>
        <DialogActions className={classes.popupDivCenter}>
          <Button
            onClick={handleNewPharmaGroupClose}
            color="secondary"
            variant="contained"
            className={classes.popupButtonSpacing}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleAddPharmaGroup}
            color="primary"
            variant="contained"
            autoFocus
            className={classes.popupButtonSpacing}
          >
            Añadir
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <PageTitle
          title="Grupos"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={handleNewPharmaGroupOpen}
          startIcon={<AddIcon />}
        >
          Añadir
        </Button>
      </div>
      {isLoading ? (
        <LoaderBar />
      ) : (
        <Grid
          style={{ display: "flex", margin: 0 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
          xs={12}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <CustomMUIDataTable
                title="Grupos"
                data={tableData}
                columns={columns}
                filterStart={2}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
