import { Card, Paper } from "@mui/material";
import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// material
import { alpha, styled } from "@mui/material/styles";

import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import DonutChart from "components/Charts/donutChart";
import FullPieChart from "components/Charts/pieChart";
import { Height } from "@material-ui/icons";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BlockIcon from "@mui/icons-material/Block";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { Typography } from "components/Wrappers/Wrappers";
import WorkIcon from "@mui/icons-material/Work";
import { format } from "date-fns";
import useStyles from "../styles";
import { useTheme } from "@material-ui/styles";

const assembleTableData = (chains) => {
  let tableData = chains.map((chain) => {
    return [chain.name, chain.value];
  });
  return tableData;
};

const assembleMedicTableData = (chains) => {
  let tableData = chains.map((chain) => {
    return [
      chain.name || "-",
      formatThousands(chain.values[0] || 0),
      formatThousands(chain.values[1] || 0),
      formatThousands(chain.values[2] || 0),
    ];
  });
  return tableData;
};

const assembleChains = (information) => {
  return [
    {
      name: "Pacientes nuevos",
      value: formatNumber(information.new_patients),
      color: "success",
      category: "subitem",
    },
    {
      name: "Pacientes viejos",
      value: formatNumber(information.old_patients),
      color: "success",
      category: "subitem",
    },
    {
      name: "Total",
      value: formatNumber(information.total || 0),
      color: "success",
    },
  ];
};

const assembleMedicTableChains = (information, total_coupons) => {
  return [
    {
      name: "Potenciales",
      values: [
        information["Potenciales"].received || "-",
        information["Potenciales"].assigned,
        information["Potenciales"].delivered,
      ],
      color: "success",
      category: "subitem",
    },
    {
      name: "Usuario",
      values: [
        information["Usuario"].received || "-",
        information["Usuario"].assigned,
        information["Usuario"].delivered,
      ],
      color: "success",
      category: "subitem",
    },
    {
      name: "No usuarios",
      values: [
        information["No Usan/No quieren"].received || "-",
        information["No Usan/No quieren"].assigned,
        information["No Usan/No quieren"].delivered,
      ],
      color: "success",
      category: "subitem",
    },
    {
      name: "Sin status",
      values: [
        information["Sin Status"].received || "-",
        information["Sin Status"].assigned,
        information["Sin Status"].delivered,
      ],
      color: "success",
      category: "subitem",
    },
    {
      name: "Total",
      values: [
        total_coupons || "-",
        information["total"].assigned,
        information["total"].delivered,
      ],
      color: "success",
    },
  ];
};

const assembleColumns = (chains) => {
  return [
    {
      name: "Categoria",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellProps: () => ({}),
        setCellHeaderProps: () => {
          return {
            style: {
              fontWeight: "bold",
              className: "centeredHeaderCell",
              justifyContent: "center",
              textAlign: "center",
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return chains[tableMeta.rowIndex].category === "subitem" ? (
            <div> {value}</div>
          ) : (
            <div style={{ fontWeight: "bold" }}> {value}</div>
          );
        },
      },
    },
    {
      name: "Cantidad",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return {
            style: {
              fontWeight: "bold",
              textAlign: "center",
            },
          };
        },
        setCellProps: () => ({ align: "center" }),
      },
    },
  ];
};

const assembleMedicTableColumns = (chains) => {
  return [
    {
      name: "Categoria",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return {
            style: {
              fontWeight: "bold",
              className: "centeredHeaderCell",
              justifyContent: "center",
              textAlign: "center",
            },
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return chains[tableMeta.rowIndex].category === "subitem" ? (
            <div> {value}</div>
          ) : (
            <div style={{ fontWeight: "bold" }}> {value}</div>
          );
        },
      },
    },
    {
      name: "Recibidos",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            textAlign: "center",
          },
        }),
        setCellHeaderProps: () => {
          return {
            style: {
              fontWeight: "bold",
              className: "centeredHeaderCell",
              justifyContent: "center",
              textAlign: "center",
            },
          };
        },
      },
    },
    {
      name: "Asignados",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            textAlign: "center",
          },
        }),
        setCellHeaderProps: () => {
          return {
            style: {
              fontWeight: "bold",
              className: "centeredHeaderCell",
              justifyContent: "center",
              textAlign: "center",
            },
          };
        },
      },
    },
    {
      name: "Entregados",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            textAlign: "center",
          },
        }),
        setCellHeaderProps: () => {
          return {
            style: {
              fontWeight: "bold",
              className: "centeredHeaderCell",
              justifyContent: "center",
              textAlign: "center",
            },
          };
        },
      },
    },
  ];
};

const colorsMap = {
  good: { background: "#E1FCE1", main: "#5CE65C" },
  warning: { background: "#F5EBB8", main: "#E6DD3E" },
  bad: { background: "#F5BAB8", main: "#E61008" },
};

const formatNumber = (value) => {
  return value
    ? Intl.NumberFormat("es-AR").format(Math.round(value * 100) / 100)
    : 0;
};

const formatThousands = (value) => {
  return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
};

export default function TraceabilityDashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [tableData, setTableData] = useState([]);
  const [chains, setChains] = useState(null);
  const [medicTableChains, setMedicTableChains] = useState(null);
  const [columns, setColumns] = useState([]);
  const [medicTableColumns, setMedicTableColumns] = useState([]);
  const [medicTableData, setMedicTableData] = useState([]);

  useEffect(() => {
    setChains(assembleChains(props.information.converted));
    setMedicTableChains(
      assembleMedicTableChains(
        props.information.coupons_to_medics,
        props.information.coupons_to_consultants,
      ),
    );
  }, []);

  useEffect(() => {
    if (!chains) return;
    // First table data assignation
    setColumns(assembleColumns(chains));
    setTableData(assembleTableData(chains));
  }, [chains]);

  useEffect(() => {
    if (!medicTableChains) return;
    // Second table data assignation
    setMedicTableColumns(assembleMedicTableColumns(medicTableChains));
    setMedicTableData(assembleMedicTableData(medicTableChains));
  }, [medicTableChains]);

  return (
    <>
      <Grid item lg={12} xs={12} md={12} style={{ marginTop: 20 }}>
        <Typography variant="h5" className={classes.alignedText}>
          Conversiones
        </Typography>
        <CustomMUIDataTable
          title={""}
          columns={columns}
          data={tableData}
          options={{
            print: false,
            viewColumns: false,
            search: false,
            filter: false,
            download: false,
            pagination: false,
          }}
        />
      </Grid>
      <Grid item lg={12} xs={12} md={12} style={{ marginTop: 20 }}>
        <Typography variant="h5" className={classes.alignedText}>
          Cupones a médicos
        </Typography>
        <CustomMUIDataTable
          title={""}
          columns={medicTableColumns}
          data={medicTableData}
          options={{
            print: false,
            viewColumns: false,
            search: false,
            filter: false,
            download: false,
            pagination: false,
          }}
        />
      </Grid>
    </>
  );
}
