import axios from "axios";

var apiUri = process.env.REACT_APP_API_URI;

// DATA FUNCTIONS
const getToken = () => {
  return localStorage.getItem("token");
};

const getAuthData = (type) => {
  const token = getToken();
  const requestData = {
    method: type,
    headers: { Authorization: `Bearer ${token}` },
  };
  return requestData;
};

const contentAuthData = (type, body) => {
  const token = getToken();
  const requestData = {
    method: type,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  if (body) requestData["body"] = JSON.stringify(body);
  return requestData;
};

async function getResponseData(response, data) {
  const { status } = response;
  if (status >= 200 && status < 300) {
    return data;
  }
  return Promise.reject(data);
}

// GENERIC DB FETCHERS
async function dbGet(category) {
  const requestData = getAuthData("GET");
  const response = await fetch(`${apiUri}/${category}`, requestData);
  const data = await response.json();

  return await getResponseData(response, data);
}

async function dbPut(category, putData) {
  const requestData = contentAuthData("PUT", putData);
  const response = await fetch(`${apiUri}/${category}`, requestData);
  const data = await response.json();

  return await getResponseData(response, data);
}

async function dbPost(category, postData) {
  const requestData = contentAuthData("POST", postData);
  const response = await fetch(`${apiUri}/${category}`, requestData);
  const data = await response.json();

  return await getResponseData(response, data);
}

async function dbDelete(category, deleteData) {
  const requestData = contentAuthData("DELETE", deleteData);
  const response = await fetch(`${apiUri}/${category}`, requestData);
  const data = await response.json();

  return await getResponseData(response, data);
}

async function dbUploadFile(file, endpoint) {
  let formData = new FormData();
  formData.append("file", file);
  const token = getToken();

  let toSend = axios.create({
    baseURL: apiUri,
    headers: {
      "Content-type": "application/json",
    },
  });
  let response = await toSend.post(endpoint, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
  const { data } = response;
  return await getResponseData(response, data);
}

// SPECIFIC DB FETCHERS

async function dbDisableEnable(endpoint, id, command) {
  const requestData = contentAuthData("PUT");
  const response = await fetch(
    `${apiUri}/${endpoint}/${id}/${command}`,
    requestData,
  );
  const data = await response.json();
  return await getResponseData(response, data);
}

/* This function exists because endpoint is long */
async function getInscriptionsByPatientRut(patient_rut) {
  const requestData = getAuthData("GET");
  const programId = localStorage.getItem("programId");
  const response = await fetch(
    `${apiUri}/inscriptions/presentations/${patient_rut}/${programId}`,
    requestData,
  );
  const data = await response.json();

  return await getResponseData(response, data);
}

async function Login(userData) {
  var encodedData = Buffer.from(
    userData.username + ":" + userData.password,
  ).toString("base64");
  const requestData = {
    method: "POST",
    headers: { Authorization: `Basic ${encodedData}` },
  };
  const response = await fetch(`${apiUri}/login`, requestData);
  const data = await response.json();

  return await getResponseData(response, data);
}

async function Reset(userData) {
  var currentURL = window.location.protocol + "//" + window.location.host;
  var encodedData = Buffer.from(userData.email + ":" + userData.user).toString(
    "base64",
  );
  const requestData = {
    method: "GET",
    headers: {
      Authorization: `Basic ${encodedData}`,
      "Content-Type": currentURL,
    },
  };
  const response = await fetch(`${apiUri}/password-reset`, requestData);
  const data = await response.json();

  return await getResponseData(response, data);
}

export {
  getInscriptionsByPatientRut,
  dbGet,
  dbPut,
  dbDelete,
  dbPost,
  dbDisableEnable,
  dbUploadFile,
  Login,
  Reset,
};
