import React, { useState } from "react";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import useStyles from "./styles";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import logo from "images/logoPagesRS.jpg";
import PageTitle from "components/PageTitle/PageTitle";

const blankInscriptionForm = {
  id: "",
  rut: "",
  code: "",
  period: null,
  company: "",
  //group_id: "",
  address: "",
  commune_id: "",
  email: "",
  phone: "",
  contact: "",
  programs: null,
};

export default function ApprovalWorkflowPage() {
  const [inscriptionForm, updateForm] = useState(blankInscriptionForm);
  var [isLoading, setIsLoading] = useState(false);
  var classes = useStyles();
  const [tableData, setTableData] = useState([]);

  const approve = (id) => {
    // dbPost(`inscriptions/${id}/approve`)
  };

  const reject = (id) => {
    // dbPost(`inscriptions/${id}/reject`)
  };

  const columns = [
    {
      name: "Import",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Cadena",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Programa",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Estado",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Período",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Ultima actualización",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Usuario",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Total",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Aprovar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    approve(tableMeta.rowData[1]);
                  }}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Rechazar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    reject(tableMeta.rowData[1]);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <div>
        <PageTitle
          title="Workflow de aprobación"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        alignItems="flex-start"
        spacing={4}
        xs={12}
      >
        <Grid item xs={12}>
          <CustomMUIDataTable
            title="Aprobación"
            data={tableData ? tableData : undefined}
            columns={columns}
            filterStart={1}
          />
        </Grid>
      </Grid>
    </>
  );
}
