import React from "react"

export default function HyperText(props) {
  return (
              <div
                className={props.divClassName}
                onClick={props.onClick}
                style={{ ...props.style, color: "blue", fontStyle: "italic", cursor: "pointer" }}
              >
                {props.text}
              </div>
  );
}
