import { Card, Paper } from "@mui/material";
import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// material
import { Typography } from "components/Wrappers/Wrappers";
import { format } from "date-fns";
import useStyles from "../styles";
import { useTheme } from "@material-ui/styles";

import SummaryCards from "../SummaryCards/SummaryCards";
import SummaryCardsVirtual from "../SummaryCards/SummaryCardsVirtual";
import GraphsVirtual from "../Graphs/GraphsVirtual";
import TableVirtual from "../Table/TableVirtual";

const getDateFormatted = (date) => {
  return format(date, "dd/MM/yy");
};

const assembleExcelData = (information) => {
  return [
    { data: ["Pacientes nuevos", information.converted.new_patients || 0] },
    { data: ["Pacientes viejos", information.converted.old_patients || 0] },
    { data: ["Total", information.converted.total || 0] },
    { data: [] },
    // Second table
    { data: ["Concepto", "Recibidos", "Entregados", ""] },
    {
      data: [
        "Potenciales",
        "-",
        information.coupons_to_medics["Potenciales"] || 0,
      ],
    },
    {
      data: [
        "Nuevos",
        "-",
        information.coupons_to_medics["Nuevos"] || 0,
      ],
    },
    {
      data: [
        "Usuario",
        "-",
        information.coupons_to_medics["Usuario"] || 0,
      ],
    },
    {
      data: [
        "No usuarios",
        "-",
        information.coupons_to_medics["No Usan/No quieren"] || 0,
      ],
    },
    {
      data: [
        "Sin status",
        "-",
        information.coupons_to_medics["Sin Status"] || 0,
      ],
    },
    {
      data: [
        "Total",
        information.coupons_to_patients || 0,
        information["total"] || 0,
      ],
    },
  ];
}

export default function TraceabilityDashboard(props) {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    props.setExcelData(assembleExcelData(props.information));
  }, []);

  return (
    <Grid container>
      <Grid item container xs={12} alignItems="stretch" spacing={2}>
        <Grid item xs={12} style={{ margin: "0 0 10px 10px" }}>
          <Typography variant="h4" className={classes.alignedText}>
            Resumen
          </Typography>
          <Divider />
        </Grid>
        <SummaryCardsVirtual information={props.information} />
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        flexDirection="column"
        spacing={2}
        style={{ marginTop: "10px" }}
      >
        {/* Left side */}
        <Grid item container lg={5} xs={12} md={5}>
          <Grid item lg={12} xs={12} md={12} style={{ marginLeft: "10px" }}>
            <Typography variant="h4" className={classes.alignedText}>
              Información global
            </Typography>
            <Divider />
          </Grid>
          <TableVirtual information={props.information}/>
        </Grid>
        <GraphsVirtual information={props.information} />
      </Grid>
    </Grid>
  );
}
