import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import {
  Button,
  CircularProgress,
  Grid,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";

import routes from "routes";
import logo from "images/logoPagesRS.jpg";

import useStyles from "./styles";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { dbGet, dbPost, dbPut } from "utils/DBFetchers";
import { useHistory } from "react-router-dom";
import Field from "components/CustomFields/Field";

const blankNewRoleForm = {
  name: "",
  permissions: "",
};
export default function RolesNewPage() {
  var classes = useStyles();
  const history = useHistory();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [newRoleForm, updateForm] = useState(blankNewRoleForm);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    updateForm({
      ...newRoleForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);

    // Give adecuate format to send form to db

    var permissions = newRoleForm.permissions.replace(/\s/g, "").split(",");

    let completedForm = {
      name: newRoleForm.name,
      permissions: permissions,
    };

    // Sends to db
    dbPost(`roles`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Rol creado exitosamente");
        history.push(routes.usersRoles);
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al crear el rol",
        );
        setIsLoading(false);
        return;
      });
  };

  /* Cancel editing role and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankNewRoleForm);
    history.goBack();
  };

  return (
    <>
      <div>
        <PageTitle
          title="Nuevo rol"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del rol
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  Los permisos van separados por una coma (Ejemplo:
                  role_edit,role_view).
                </Typography>
              </div>

              <Field
                labelName="Nombre"
                required={true}
                id="name"
                value={newRoleForm.name}
                divClassType={classes.generalPurposeItems}
                fieldClassType={classes.generalPurposeField}
                onChange={handleChange}
              />

              <div
                className={classes.generalPurposeItems}
                style={{ marginTop: "4%" }}
              >
                <Typography className={classes.text} weight="bold">
                  Permisos
                </Typography>
                <TextField
                  labelName="Permisos"
                  multiline
                  minRows="4"
                  required={true}
                  id="permissions"
                  name="permissions"
                  variant="outlined"
                  margin="dense"
                  defaultValue={newRoleForm.permissions}
                  className={classes.generalPurposeField}
                  fullwidth={true}
                  onChange={handleChange}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelAndGoBack}
                >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
