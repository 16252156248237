import { alpha, styled } from "@mui/material/styles";
import { Card } from "@mui/material";
import MainCard from "./MainCard";

const chart_width = "600px";
const chart_min_width = "400px";

const RootStyleGreen = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  borderRadius: "20px",
  minWidth: chart_min_width,
  width: chart_width,
  maxHeight: "200px",
  padding: theme.spacing(5, 20),
  color: "#006B04",
  backgroundColor: "#C9FCBD",
}));
const RootStyleBlue = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  borderRadius: "20px",
  minWidth: chart_min_width,
  width: chart_width,
  maxHeight: "200px",
  padding: theme.spacing(5, 20),
  color: "#3E62AD",
  backgroundColor: "#D0F2FF",
}));
const RootStyleOrange = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  borderRadius: "20px",
  minWidth: chart_min_width,
  width: chart_width,
  maxHeight: "200px",
  padding: theme.spacing(5, 20),
  color: "#87672A",
  backgroundColor: "#FFF7CD",
}));
const RootStyleRed = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  borderRadius: "20px",
  minWidth: chart_min_width,
  width: chart_width,
  maxHeight: "200px",
  padding: theme.spacing(5, 20),
  color: "#941A13",
  backgroundColor: "#FDD8D6",
}));

const IconWrapperStyleBlue = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "30%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0,
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));
const IconWrapperStyleGreen = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "30%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: "#008F04",
  backgroundImage: `linear-gradient(135deg, ${alpha("#008F04", 0)} 0%, ${alpha(
    "#008F04",
    0.24,
  )} 100%)`,
}));
const IconWrapperStyleOrange = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "30%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.warning.dark,
    0,
  )} 0%, ${alpha(theme.palette.warning.dark, 0.24)} 100%)`,
}));
const IconWrapperStyleRed = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "30%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: "#C22219",
  backgroundImage: `linear-gradient(135deg, ${alpha("#C22219", 0)} 0%, ${alpha(
    "#C22219",
    0.24,
  )} 100%)`,
}));

const CardWrapperInscriptions = styled(MainCard)(({ theme }) => ({
  backgroundColor: "#E4D1FC",
  color: "#1E4D22",
  overflow: "hidden",
  position: "relative",
  borderRadius: "20px",
  minWidth: chart_min_width,
  width: chart_width,
  "&>div": {
    position: "relative",
    zIndex: 5,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: "#BCB3E6",
    borderRadius: "50%",
    zIndex: 1,
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    zIndex: 1,
    width: 210,
    height: 210,
    background: "#BCB3E6",
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
}));
const CardWrapperSales = styled(MainCard)(({ theme }) => ({
  backgroundColor: "#FFD1E3",
  color: "#1E4D22",
  overflow: "hidden",
  position: "relative",
  borderRadius: "20px",
  minWidth: chart_min_width,
  width: chart_width,
  "&>div": {
    position: "relative",
    zIndex: 5,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: "#E3869C",
    borderRadius: "50%",
    zIndex: 1,
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    zIndex: 1,
    width: 210,
    height: 210,
    background: "#E3869C",
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
}));

export {
  RootStyleGreen,
  RootStyleBlue,
  RootStyleOrange,
  RootStyleRed,
  IconWrapperStyleBlue,
  IconWrapperStyleGreen,
  IconWrapperStyleOrange,
  IconWrapperStyleRed,
  CardWrapperInscriptions,
  CardWrapperSales,
};
