import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Autocomplete } from "@material-ui/lab";
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Divider,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";

import Field from "components/CustomFields/Field.js";
import SelectField from "components/CustomFields/SelectField.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";
import { cleanEmptyKeys } from "utils/cleanEmptyKeys";
import { dbGet, dbPut } from "utils/DBFetchers";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { getRoleName } from "utils/roleNameGetter";

// roles
import { hidedRoles, roleTranslation } from "pages/users/roles/utils";

const blankEditUserForm = {
  id: "",
  username: "",
  email: "",
  name: "",
  lastName: "",
  role: null,
  distributor: "",
  pharmaGroup: "",
  pharmacy: null,
  password: "",
  isVisible: null,
  kam: "",
  medic: "",
  consultant: "",
};

const blankAllResults = {
  distributors: [],
  pharmagroups: [],
  pharmacies: [],
  roles: [],
  kams: [],
  medics: [],
  consultants: [],
};

const visibleOptions = [
  { id: 1, name: "Si" },
  { id: 2, name: "No" },
];

export default function EditUserPage(props) {
  var classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [editUserForm, updateForm] = useState(blankEditUserForm);
  const [allResults, setAllResults] = useState(blankAllResults);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleChange = (event) => {
    updateForm({ ...editUserForm, [event.target.name]: event.target.value });
  };

  const handleAutoCompleteConsultantChange = (event, newValue) => {
    updateForm({
      ...editUserForm,
      consultant: newValue ? newValue.id : null,
    });
  };
  const handleAutoCompleteMedicChange = (event, newValue) => {
    updateForm({ ...editUserForm, medic: newValue ? newValue.id : null });
  };

  const handleSetCategory = (value) => {
    setSelectedCategory(value);
    var role = allResults.roles.find((x) => x.id === value);
    if (!role) return;
    var roleName =
      role.name === "kam" || role.name === "medic" || role.name === "consultant"
        ? role.name + "s"
        : role.name;
    if (role && allResults[roleName] && allResults[roleName].length === 0) {
      getResults(roleName);
    }
  };

  const getResults = (category) => {
    dbGet(category).then((data) => {
      if (!data.length) return;
      if (category === "roles" && getRoleName() !== "super_admin") {
        data = data.filter((role) => !hidedRoles.has(role.name));
      }
      if (category === "distributors") {
        data = data.filter((x) => x.works_with_independents && x.id !== 19);
      }
      data = data.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        // a must be equal to b
        return 0;
      });
      let mapped = data.map((item) => {
        let name = `${item["name"]}`;
        if (category === "pharmacies") {
          name = `${item["name"]} - ${item["code"]}`;
        } else if (category === "consultants" || category === "medics") {
          name = item["last_name"] ? name + item["last_name"] : name;
          name = item["rut"] ? name + ` | ${item["rut"]}` : name;
        }
        return {
          id: item["id"],
          name: name,
        };
      });
      setAllResults({ ...allResults, [category]: mapped });
    });
  };

  const getUserInfo = (userId) => {
    dbGet(`users/${userId}`).then((data) => {
      updateForm({
        ...editUserForm,
        id: userId,
        username: data.username,
        email: data.email,
        name: data.name,
        lastName: data.lastname,
        role: data.role?.id,
        isVisible: data.is_visible ? 1 : 2,
        distributor: data.distributor_id ? data.distributor_id : "",
        pharmaGroup: data.pharma_group_id ? data.pharma_group_id : "",
        pharmacy: data.pharmacy_id ? data.pharmacy_id : "",
        kam: data.kam_id ? data.kam_id : "",
        medic: data.medic_id ? data.medic_id : "",
        consultant: data.consultant_id ? data.consultant_id : "",
      });
      handleSetCategory(data.role?.id);
    });
  };
  const checkFormComplete = () => {
    if (
      !editUserForm.username ||
      !editUserForm.email ||
      !editUserForm.email.includes("@") ||
      !editUserForm.role ||
      !editUserForm.isVisible ||
      (editUserForm.password &&
        (!(editUserForm.password.length >= 6) ||
          !(editUserForm.password.length <= 20))) ||
      (editUserForm.role === 2 && !editUserForm.pharmacy) ||
      (editUserForm.role === 3 && !editUserForm.distributor) ||
      (editUserForm.role === 4 && !editUserForm.pharmaGroup) ||
      (editUserForm.role === 6 && !editUserForm.kam) ||
      (editUserForm.role === 9 && !editUserForm.medic) ||
      (editUserForm.role === 10 && !editUserForm.consultant)
    )
      return false;
    return true;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder editar el usuario.",
      );
      return;
    }
    if (
      (editUserForm.name && !editUserForm.lastName) ||
      (!editUserForm.name && editUserForm.lastName)
    ) {
      snackbarOpen(
        "error",
        "Debe ingresar ambos Nombre y Apellido. Por favor completelos para poder editar el usuario.",
      );
      return;
    }

    let completedForm = {
      username: editUserForm.username,
      email: editUserForm.email,
      password: editUserForm.password, // if password empty then db doesnt change
      role_id: editUserForm.role,
      name: editUserForm.name,
      lastname: editUserForm.lastName,
      pharmacy_id: editUserForm.pharmacy,
      distributor_id: editUserForm.distributor,
      pharma_group_id: editUserForm.pharmaGroup,
      kam_id: editUserForm.kam,
      medic_id: editUserForm.medic,
      consultant_id: editUserForm.consultant,
    };
    completedForm = cleanEmptyKeys(completedForm);
    completedForm["is_visible"] = editUserForm.isVisible === 1 ? true : false;

    setIsLoading(true);
    dbPut(`users/${props.props.location.state.userId}`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Usuario editado exitosamente");
        redirectToUsersPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0]
            ? error[0].description_es
            : "No se pudo editar el usuario. Intente nuevamente.",
        );
        setIsLoading(false);
        return;
      });
  };

  const cancelEditUser = () => {
    updateForm(blankEditUserForm);
    setSelectedCategory("");
    history.goBack();
  };

  const redirectToUsersPage = (user) => {
    setTimeout(() => {
      updateForm(blankEditUserForm);
      setSelectedCategory("");
      setIsLoading(false);
      history.push(routes.usersUsers);
    }, 1500);
  };

  React.useEffect(() => {
    getResults("roles");
  }, []);

  React.useEffect(() => {
    if (props.props.location.state) {
      getUserInfo(props.props.location.state.userId);
    }
  }, [allResults.roles]);

  const title = `Editar Usuario${editUserForm.username ? ": " : ""}${
    editUserForm.username ? editUserForm.username : ""
  }`;

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del usuario
              </Typography>
              <div style={{ marginTop: "4%" }}>
                <Field
                  labelName="Usuario *"
                  required={true}
                  id="username"
                  value={editUserForm.username}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  error={!editUserForm.username}
                ></Field>
                <Field
                  labelName="Email *"
                  required={true}
                  id="email"
                  value={editUserForm.email}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  error={!editUserForm.email}
                ></Field>
                <Field
                  labelName="Nombre"
                  id="name"
                  value={editUserForm.name}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <Field
                  labelName="Apellido"
                  id="lastName"
                  value={editUserForm.lastName}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <div className={classes.generalPurposeItems}>
                  <Typography className={classes.text} weight="bold">
                    Rol *
                  </Typography>
                  <TextField
                    id="role"
                    select
                    disabled
                    className={classes.generalPurposeField}
                    value={selectedCategory ? selectedCategory : ""}
                    error={!selectedCategory}
                    onChange={(e) => {
                      handleSetCategory(e.target.value);
                      updateForm({
                        ...editUserForm,
                        role: e.target.value,
                        distributor: "",
                        pharmaGroup: "",
                        pharmacy: "",
                      });
                    }}
                    label="Seleccione"
                    variant="outlined"
                    margin="dense"
                  >
                    {allResults.roles.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name in roleTranslation
                          ? roleTranslation[option.name]
                          : option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {getRoleName() === "super_admin" ? (
                  <SelectField
                    divClassName={classes.generalPurposeItems}
                    fieldClassName={classes.generalPurposeField}
                    displayText="Es visible"
                    id="isVisible"
                    label="Seleccione"
                    name="isVisible"
                    value={editUserForm.isVisible}
                    onChange={handleChange}
                    allValues={visibleOptions}
                  />
                ) : (
                  <></>
                )}
                {allResults.roles.some(
                  (role) =>
                    role.id === editUserForm.role && role.name === "kam",
                ) ? (
                  <SelectField
                    divClassName={classes.generalPurposeItems}
                    fieldClassName={classes.generalPurposeField}
                    displayText="KAM"
                    id="kam"
                    label="Seleccione"
                    name="kam"
                    value={editUserForm.kam}
                    onChange={handleChange}
                    allValues={allResults.kams}
                  />
                ) : (
                  <></>
                )}
                {allResults.roles.some(
                  (role) =>
                    role.id === editUserForm.role && role.name === "medic",
                ) ? (
                  <SelectField
                    divClassName={classes.generalPurposeItems}
                    fieldClassName={classes.generalPurposeField}
                    displayText="Médico"
                    id="medic"
                    label="Seleccione"
                    name="medic"
                    value={editUserForm.medic}
                    onChange={handleChange}
                    allValues={allResults.medics}
                  />
                ) : (
                  <></>
                )}
                {allResults.roles.some(
                  (role) =>
                    role.id === editUserForm.role && role.name === "consultant",
                ) ? (
                  <SelectField
                    divClassName={classes.generalPurposeItems}
                    fieldClassName={classes.generalPurposeField}
                    displayText="Visitador"
                    id="consultant"
                    label="Seleccione"
                    name="consultant"
                    value={editUserForm.consultant}
                    onChange={handleChange}
                    allValues={allResults.consultants}
                  />
                ) : (
                  <></>
                )}
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  disabled={
                    !allResults.roles.some(
                      (role) =>
                        role.id === editUserForm.role &&
                        role.name === "distributors",
                    )
                  }
                  displayText="Distribuidor"
                  id="distributor"
                  label="Seleccione"
                  name="distributor"
                  value={editUserForm.distributor}
                  onChange={handleChange}
                  allValues={allResults.distributors}
                />
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  disabled={
                    !allResults.roles.some(
                      (role) =>
                        role.id === editUserForm.role &&
                        role.name === "pharmagroups",
                    )
                  }
                  displayText="Grupo"
                  id="pharmaGroup"
                  label="Seleccione"
                  name="pharmaGroup"
                  value={editUserForm.pharmaGroup}
                  onChange={handleChange}
                  allValues={allResults.pharmagroups}
                />

                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  disabled={
                    !allResults.roles.some(
                      (role) =>
                        role.id === editUserForm.role &&
                        role.name === "pharmacies",
                    )
                  }
                  displayText="Farmacia"
                  id="pharmacy"
                  label="Seleccione"
                  name="pharmacy"
                  value={editUserForm.pharmacy}
                  onChange={handleChange}
                  allValues={allResults.pharmacies}
                />
              </div>
            </Grid>
          </Grid>

          <Divider style={{ marginTop: "3%" }} />
          <CardHeader title="Contraseña" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <div className={classes.passwordItems}>
                  {passwordOpen ? (
                    <Field
                      helperText="De 6 a 20 caracteres - Si no ingresa contraseña, esta no se modificará."
                      id="password"
                      name="password"
                      fieldClassType={classes.passwordField}
                      onChange={handleChange}
                      required
                      value={editUserForm.password}
                      variant="outlined"
                      type="password"
                      error={
                        editUserForm.password.length < 6 ||
                        editUserForm.password.length > 20
                      }
                    />
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setPasswordOpen(!passwordOpen)}
                    >
                      Modificar contraseña
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelEditUser}
                >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
