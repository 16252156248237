import React, { useState, useEffect } from "react";
// Components
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import PageTitle from "components/PageTitle/PageTitle";
import { Typography } from "components/Wrappers/Wrappers";
import Field from "components/CustomFields/Field.js";
import RutField from "components/CustomFields/RutField";
import dataGridTexts from "components/DataGrid/dataGridTexts.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import {
  Grid,
  CircularProgress,
  Divider,
  Checkbox,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
// Functions and helpers
import { verifyRut } from "utils/rutValidations.js";
import { dbGet } from "utils/DBFetchers.js";
import { salesColumns, inscriptionsColumns } from "./ConsultTables/tables";

// styles
import logo from "images/logoPagesRS.jpg";
import useStyles from "./styles";
import { getRoleName, getUserName } from "utils/roleNameGetter";
import { parseGetAllErrorsAndWarningsMerged as parseSalesAudit } from "pages/audit/tablesUtils/Sales";
import { parseGetAllErrorsAndWarnings as parseInscriptionsAudit } from "pages/audit/tablesUtils/Inscriptions";
import { parseGetAllErrors as parseSales } from "pages/control/SalesListPage";
import { parseGetAllErrors as parseInscriptions } from "pages/control/CouponsListPage";

const blankPatientData = {
  id: "",
  rut: "",
  rutVerifier: "",
  email: "",
  phone: "",
  name: "",
};

export default function ConsultPatientPage(props) {
  var classes = useStyles();
  const role = getRoleName();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [isLoading, setIsLoading] = useState(false);
  const [consultById, setConsultById] = useState(false);
  const [inscriptions, setInscriptions] = useState([]);
  const [sales, setSales] = useState([]);
  const [isRutValid, setIsRutValid] = useState({ patient: false, message: "" });
  const [patientData, setPatientData] = useState(blankPatientData);

  const mapInscriptions = (inscriptions, type) => {
    const isAudit = type === "Auditorias";

    return inscriptions.map((inscription) => {
      return {
        id: inscription.id,
        name: inscription.presentation
          ? inscription.presentation.name
            ? inscription.presentation.name
            : "-"
          : inscription.presentation_vrf
          ? inscription.presentation_vrf
          : "-",
        type: type,
        program: inscription.program_id,
        transaction_date: inscription.transaction_date,
        distributor: inscription.distributor
          ? inscription.distributor.name
          : "",
        pharmacy: inscription.pharmacy
          ? inscription.pharmacy.code
          : inscription.pharmacy_code,
        remaining_units: inscription.remaining_units
          ? inscription.remaining_units
          : "-",
        errors: isAudit
          ? parseInscriptionsAudit(inscription.errors)
          : parseInscriptions(inscription.errors),
      };
    });
  };

  const mapSales = (sales, type) => {
    const isAudit = type === "Auditorias";

    return sales.map((sale) => {
      return {
        id: sale.id,
        name: sale.presentation
          ? sale.presentation.name
            ? sale.presentation.name
            : "-"
          : sale.presentation_vrf
          ? sale.presentation_vrf
          : "-",
        type: type,
        program: sale.program_id,
        transaction_date: sale.transaction_date,
        distributor: sale.distributor ? sale.distributor.name : "",
        pharmacy: sale.pharmacy ? sale.pharmacy.code : sale.pharmacy_code,
        total_units: isAudit ? sale.calculated_total_units : sale.total_units,
        discount_percentage: isAudit
          ? sale.calculated_discount_percentage
          : sale.discount_percentage,
        errors: isAudit
          ? parseSalesAudit(sale.errors)
          : parseSales(sale.errors),
      };
    });
  };

  const fetchPatientData = (consultParam, consultByRut) => {
    if (!consultParam) return;
    let queryParam = consultByRut ? `patient_rut=` : `patient_id=`;
    queryParam += consultParam;
    setIsLoading(true);
    dbGet(`patients/info?${queryParam}`)
      .then((data) => {
        if (Object.keys(data).length === 0) {
          setIsRutValid({ patient: false, message: "Paciente no registrado" });
          return;
        }
        let myInscriptions = mapInscriptions(
          data.inscriptions,
          "Farmacias",
        ).concat(mapInscriptions(data.inscriptions_audit, "Auditorias"));

        let mySales = mapSales(data.sales, "Farmacias").concat(
          mapSales(data.sales_audit, "Auditorias"),
        );
        let myPatientData = {
          id: data.patient.id,
          email: data.patient.email ? data.patient.email : "",
          phone: data.patient.phone ? data.patient.phone : "",
          name: "",
        };
        let name = data.patient.name ? data.patient.name : "";
        let lastName1 = data.patient.last_name_1
          ? data.patient.last_name_1
          : "";
        let lastName2 = data.patient.last_name_2
          ? data.patient.last_name_2
          : "";
        let fullName = `${name} ${lastName1} ${lastName2}`;
        myPatientData.name = fullName;
        setInscriptions(myInscriptions);
        setSales(mySales);
        setPatientData({ ...patientData, ...myPatientData });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        snackbarOpen(
          "error",
          error.error
            ? "Paciente no encontrado"
            : "Error al cargar datos del paciente",
        );
      });
  };
  const updatePatientData = () => {
    var currentRut = patientData.rut + patientData.rutVerifier;
    var currentRutValidation = verifyRut(currentRut);
    if (currentRutValidation && patientData.rutVerifier) {
      fetchPatientData(currentRut, true);
      setIsRutValid({ patient: currentRutValidation, message: "" });
    } else if (currentRutValidation && !patientData.rutVerifier) {
      setIsRutValid({
        patient: false,
        message: "Ingrese el dígito verificador en el campo respectivo.",
      });
    } else {
      setSales([]);
      setInscriptions([]);
      setIsRutValid({ patient: currentRutValidation, message: "RUT invalido" });
    }
  };

  const handleIdChange = (e) => {
    setPatientData({ ...patientData, id: e.target.value });
  };

  const handleRutChange = (e) => {
    setPatientData({
      ...patientData,
      rut: e.target.value.toUpperCase(),
    });
  };

  const handleRutVerifierChange = (e) => {
    setPatientData({
      ...patientData,
      rutVerifier: e.target.value.toUpperCase(),
    });
  };

  useEffect(() => {
    if (!patientData.rut && !patientData.rutVerifier) return; // no fetch on page load
    updatePatientData();
  }, [patientData.rut, patientData.rutVerifier]);

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <div>
        <PageTitle
          title="Consulta de pacientes"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
        xs={12}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Paciente
              </Typography>
              <Divider
                style={{ marginTop: -10, marginBottom: 10, height: 2 }}
              />
              {role === "super_admin" || role === "asistente" ? (
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={(e) => {
                        setConsultById(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <>
                      <Typography>Consultar por ID</Typography>
                    </>
                  }
                  weight="bold"
                  labelPlacement="end"
                  className={classes.relationCheckbox}
                />
              ) : null}
              {role === "admin" ||
              (role === "full" && getUserName() !== "asistente") ||
              consultById ? (
                <>
                  <Field
                    labelName="ID Paciente"
                    required={false}
                    id="id"
                    value={patientData.id}
                    onChange={handleIdChange}
                    divClassType={classes.idItems}
                    fieldClassType={classes.idInputField}
                  />
                  <div className={classes.buttonSpacing}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => fetchPatientData(patientData.id, false)}
                    >
                      Buscar
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <RutField
                    labelName="RUT * (sin guión)"
                    required={true}
                    id="rut"
                    id2="rutVerifier"
                    value={patientData.rut}
                    value2={patientData.rutVerifier}
                    divClassType={classes.smallItems2}
                    divClassType2={classes.generalPurposeItems2}
                    fieldClassType={classes.smallFieldValidation2}
                    fieldClassType2={classes.smallFieldValidation3}
                    onChange={handleRutChange}
                    onChange2={handleRutVerifierChange}
                    helperText={isRutValid.message}
                    error={!isRutValid.patient}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </form>
        <Grid container spacing={3} xs={12} style={{ marginTop: "20px" }}>
          {!isLoading ? (
            <Grid
              item
              container
              xs={12}
              className={classes.generalPurposeItems}
              style={{
                minHeight: 1000,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "stretch",
                backgroundColor: "#f3f3ff",
                border: "1px solid #D1D7E8",
                borderRadius: "20px",
              }}
              spacing={2}
            >
              <Grid item xs={12} style={{ maxHeight: 200 }}>
                <Typography variant="h5" className={classes.text}>
                  Datos del Paciente
                </Typography>
                <Divider
                  style={{ marginTop: -10, marginBottom: 10, height: 2 }}
                />
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                    gridGap: 10,
                  }}
                >
                  <label htmlFor="patientName" className={classes.label}>
                    Nombre
                  </label>
                  <Field
                    required={false}
                    id="patientName"
                    disabled={true}
                    value={patientData.name}
                    divClassType={classes.generalPurposeItems}
                    fieldClassType={classes.generalPurposeField}
                  />
                  <label htmlFor="patientEmail" className={classes.label}>
                    Email
                  </label>
                  <Field
                    required={false}
                    id="patientEmail"
                    disabled={true}
                    value={patientData.email}
                    divClassType={classes.generalPurposeItems}
                    fieldClassType={classes.generalPurposeField}
                  />
                  <label htmlFor="patientPhone" className={classes.label}>
                    Teléfono
                  </label>
                  <Field
                    required={false}
                    id="patientPhone"
                    disabled={true}
                    value={patientData.phone}
                    divClassType={classes.generalPurposeItems}
                    fieldClassType={classes.generalPurposeField}
                  />
                </div>
              </Grid>
              <Grid item xs={12} style={{ minHeight: 200, marginTop: 20 }}>
                <Typography variant="h5" className={classes.text}>
                  Inscripciones
                </Typography>
                <Divider
                  style={{ marginTop: -10, marginBottom: 10, height: 2 }}
                />
                <div className={classes.grid}>
                  <DataGrid
                    autoHeight={true}
                    rows={inscriptions}
                    columns={inscriptionsColumns}
                    disableColumnMenu={true}
                    pageSize={100}
                    checkboxSelection={false}
                    disableSelectionOnClick={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    showToolbar={true}
                    localeText={dataGridTexts}
                    hideFooter={true}
                    className={classes.dataGrid}
                    getRowClassName={(params) =>
                      `super-app-theme--${params.row.type}`
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} style={{ minHeight: 200 }}>
                <Typography variant="h5" className={classes.text}>
                  Ventas
                </Typography>
                <Divider
                  style={{ marginTop: -10, marginBottom: 10, height: 2 }}
                />
                <div className={classes.grid}>
                  <DataGrid
                    autoHeight={true}
                    rows={sales}
                    columns={salesColumns}
                    disableColumnMenu={true}
                    pageSize={100}
                    checkboxSelection={false}
                    disableSelectionOnClick={true}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                    showToolbar={true}
                    localeText={dataGridTexts}
                    hideFooter={true}
                    className={classes.dataGrid}
                    getRowClassName={(params) => {
                      return `super-app-theme--${params.row.type}`;
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
