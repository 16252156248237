import React, { useState, Fragment } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Autocomplete } from "@material-ui/lab";
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";

import Field from "components/CustomFields/Field.js";
import SelectField from "components/CustomFields/SelectField.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import logo from "images/logoPagesRS.jpg";

import { dbGet, dbPost } from "utils/DBFetchers";
import { useHistory } from "react-router-dom";
import { getRoleName } from "utils/roleNameGetter";
import routes from "routes";

// styles
import useStyles from "./styles";
import { cleanEmptyKeys } from "utils/cleanEmptyKeys";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

// roles
import { hidedRoles, roleTranslation } from "pages/users/roles/utils";

const blankNewUserForm = {
  username: "",
  email: "",
  name: "",
  lastName: "",
  role: "",
  is_visible: "",
  distributor: "",
  pharmaGroup: "",
  pharmacy: null,
  password: "",
  kam: "",
  medic: "",
  consultant: "",
};

const blankAllResults = {
  distributors: [],
  pharmagroups: [],
  pharmacies: [],
  roles: [],
  kams: [],
  medics: [],
  consultants: [],
};
export default function NewUserPage() {
  var classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const history = useHistory();
  const [newUserForm, updateForm] = useState(blankNewUserForm);
  const [allResults, setAllResults] = useState(blankAllResults);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedVisibility, setSelectedVisibility] = useState("");

  const handleChange = (event) => {
    updateForm({ ...newUserForm, [event.target.name]: event.target.value });
  };

  const handleAutoCompleteVisitorChange = (event, newValue) => {
    updateForm({
      ...newUserForm,
      consultant: newValue ? newValue.id : null,
    });
  };
  const handleAutoCompletePharmacyChange = (event, newValue) => {
    updateForm({ ...newUserForm, pharmacy: newValue ? newValue.id : null });
  };
  const handleAutoCompleteMedicChange = (event, newValue) => {
    updateForm({ ...newUserForm, medic: newValue ? newValue.id : null });
  };

  const handleSetCategory = (value) => {
    setSelectedCategory(value);
    var role = allResults.roles.find((x) => x.id === value);
    var roleName =
      role.name === "kam" || role.name === "medic" || role.name === "consultant"
        ? role.name + "s"
        : role.name;
    if (allResults[roleName] && allResults[roleName].length === 0) {
      getResults(roleName);
    }
  };

  const getResults = (category) => {
    if (category === "medics" || category === "consultants") {
      dbGet(`${category}?only_without_user=true`).then((data) => {
        if (!data.length) return;
        data = data.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          // a must be equal to b
          return 0;
        });
        let mapped = data.map((item) => {
          let name = `${item["name"]}`;
          name = item["last_name"] ? name + item["last_name"] : name;
          name = item["rut"] ? name + ` | ${item["rut"]}` : name;
          return {
            id: item["id"],
            name: name,
          };
        });
        setAllResults({ ...allResults, [category]: mapped });
      });
    } else {
      dbGet(category).then((data) => {
        if (!data.length) return;
        if (category === "roles" && getRoleName() !== "super_admin") {
          data = data.filter((role) => !hidedRoles.has(role.name));
        }
        if (category === "distributors") {
          data = data.filter((x) => x.works_with_independents && x.id !== 19);
        }
        data = data.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          // a must be equal to b
          return 0;
        });
        let mapped = data.map((item) => {
          let name = `${item["name"]}`;
          if (category === "pharmacies") {
            name = `${item["name"]} - ${item["code"]}`;
          } else if (category === "consultants" || category === "medics") {
            name = item["last_name"] ? name + item["last_name"] : name;
            name = item["rut"] ? name + ` | ${item["rut"]}` : name;
          }
          return {
            id: item["id"],
            name: name,
          };
        });

        setAllResults({ ...allResults, [category]: mapped });
      });
    }
  };

  const checkFormComplete = () => {
    var isCompleted = true;
    if (!newUserForm.username) {
      isCompleted = false;
      snackbarOpen("error", "Complete el campo de usuario");
    } else if (!newUserForm.email) {
      isCompleted = false;
      snackbarOpen("error", "Complete el campo de mail");
    } else if (!newUserForm.email.includes("@")) {
      isCompleted = false;
      snackbarOpen("error", "El campo de mail es incorrecto");
    } else if (!newUserForm.role) {
      isCompleted = false;
      snackbarOpen("error", "Seleccione un rol para el usuario");
    } else if (!newUserForm.password) {
      isCompleted = false;
      snackbarOpen("error", "Complete el campo de contraseña");
    } else if (+newUserForm.role === 3 && !newUserForm.distributor) {
      isCompleted = false;
      snackbarOpen("error", "Seleccione el distribuidor del usuario");
    } else if (+newUserForm.role === 4 && !newUserForm.pharmaGroup) {
      isCompleted = false;
      snackbarOpen("error", "Seleccione el grupo del usuario");
    } else if (
      !(newUserForm.password.length >= 6) ||
      !(newUserForm.password.length <= 20)
    ) {
      isCompleted = false;
      snackbarOpen("error", "La contraseña debe tener entre 6 y 20 caracteres");
    } else if (+newUserForm.role === 2 && !newUserForm.pharmacy) {
      isCompleted = false;
      snackbarOpen("error", "Seleccione la farmacia del usuario");
    } else if (+newUserForm.role === 6 && !newUserForm.kam) {
      isCompleted = false;
      snackbarOpen("error", "Seleccione el KAM");
    } else if (+newUserForm.role === 9 && !newUserForm.medic) {
      isCompleted = false;
      snackbarOpen("error", "Seleccione el médico");
    } else if (+newUserForm.role === 10 && !newUserForm.consultant) {
      isCompleted = false;
      snackbarOpen("error", "Seleccione el visitador");
    }

    return isCompleted;
  };
  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (!checkFormComplete()) {
      setIsLoading(false);
      return;
    }
    let completedForm = {
      username: newUserForm.username,
      email: newUserForm.email,
      password: newUserForm.password,
      role_id: newUserForm.role,
      name: newUserForm.name,
      lastname: newUserForm.lastName,
      pharmacy_id: newUserForm.pharmacy,
      distributor_id: newUserForm.distributor,
      pharma_group_id: newUserForm.pharmaGroup,
      kam_id: newUserForm.kam,
      medic_id: newUserForm.medic,
      consultant_id: newUserForm.consultant,
    };

    completedForm = cleanEmptyKeys(completedForm);

    dbPost("signup", completedForm)
      .then((data) => {
        snackbarOpen("success", "Usuario creado exitosamente");
        handleCleanForm();
        redirectToUsersPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al crear usuario",
        );
        setIsLoading(false);
        return;
      });
  };

  /* Redirect to users table page after adding correctly */
  const redirectToUsersPage = (user) => {
    setTimeout(() => {
      updateForm(newUserForm);
      setIsLoading(false);
      history.push(routes.usersUsers);
    }, 1500);
  };

  const handleCleanForm = () => {
    updateForm(blankNewUserForm);
    setSelectedCategory("");
    setIsLoading(false);
  };

  const cancelNewUser = () => {
    updateForm(blankNewUserForm);
    setSelectedCategory("");
    history.goBack();
  };

  React.useEffect(() => {
    getResults("roles");
  }, []);

  return (
    <>
      <div>
        <PageTitle
          title="Nuevo Usuario"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del usuario
              </Typography>
              <Field
                labelName="Usuario *"
                required={true}
                id="username"
                value={newUserForm.username}
                divClassType={classes.generalPurposeItems}
                fieldClassType={classes.generalPurposeField}
                onChange={handleChange}
                error={!newUserForm.username}
              ></Field>
              <Field
                labelName="Email *"
                required={true}
                id="email"
                value={newUserForm.email}
                divClassType={classes.generalPurposeItems}
                fieldClassType={classes.generalPurposeField}
                onChange={handleChange}
                error={!newUserForm.email}
              ></Field>
              <Field
                labelName="Nombre"
                id="name"
                value={newUserForm.name}
                divClassType={classes.generalPurposeItems}
                fieldClassType={classes.generalPurposeField}
                onChange={handleChange}
              ></Field>
              <Field
                labelName="Apellido"
                id="lastName"
                value={newUserForm.lastName}
                divClassType={classes.generalPurposeItems}
                fieldClassType={classes.generalPurposeField}
                onChange={handleChange}
              ></Field>
              <div className={classes.generalPurposeItems}>
                <Typography className={classes.text} weight="bold">
                  Rol *
                </Typography>
                <TextField
                  id="role"
                  select
                  className={classes.generalPurposeField}
                  value={selectedCategory}
                  error={!selectedCategory}
                  onChange={(e) => {
                    handleSetCategory(e.target.value);
                    updateForm({
                      ...newUserForm,
                      role: e.target.value,
                      distributor: "",
                      pharmaGroup: "",
                      pharmacy: "",
                      medic: "",
                    });
                  }}
                  label="Seleccione"
                  variant="outlined"
                  margin="dense"
                >
                  {allResults.roles.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name in roleTranslation
                        ? roleTranslation[option.name]
                        : option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {getRoleName() === "super_admin" ? (
                <div className={classes.generalPurposeItems}>
                  <Typography className={classes.text} weight="bold">
                    Es visible
                  </Typography>
                  <TextField
                    id="is_visible"
                    select
                    className={classes.generalPurposeField}
                    value={selectedVisibility}
                    onChange={(e) => {
                      setSelectedVisibility(e.target.value);
                      updateForm({
                        ...newUserForm,
                        is_visible: e.target.value,
                      });
                    }}
                    label="Seleccione"
                    variant="outlined"
                    margin="dense"
                  >
                    <MenuItem key={"Si"} value={true}>
                      Si
                    </MenuItem>
                    <MenuItem key={"No"} value={false}>
                      No
                    </MenuItem>
                  </TextField>
                </div>
              ) : (
                <></>
              )}
              {allResults.roles.some(
                (role) => role.id === newUserForm.role && role.name === "kam",
              ) ? (
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  displayText="KAM"
                  id="kam"
                  label="Seleccione"
                  name="kam"
                  value={newUserForm.kam}
                  onChange={handleChange}
                  allValues={allResults.kams}
                />
              ) : (
                <></>
              )}
              {allResults.roles.some(
                (role) => role.id === newUserForm.role && role.name === "medic",
              ) ? (
                <div className={classes.generalPurposeItems}>
                  <Typography className={classes.text} weight="bold">
                    Médico
                  </Typography>
                  <Autocomplete
                    freeSolo
                    id="medic"
                    name="medic"
                    className={classes.generalPurposeField}
                    options={allResults.medics ? allResults.medics : []}
                    onChange={handleAutoCompleteMedicChange}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        className={
                          params.disabled
                            ? classes.selectionTextDisabled
                            : classes.selectionTextEnabled
                        }
                        {...params}
                        placeholder="Seleccione"
                        margin="dense"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              ) : (
                <></>
              )}
              {allResults.roles.some(
                (role) =>
                  role.id === newUserForm.role && role.name === "consultant",
              ) ? (
                <div className={classes.generalPurposeItems}>
                  <Typography className={classes.text} weight="bold">
                    Visitador
                  </Typography>
                  <Autocomplete
                    freeSolo
                    id="consultant"
                    name="consultant"
                    className={classes.generalPurposeField}
                    options={
                      allResults.consultants ? allResults.consultants : []
                    }
                    onChange={handleAutoCompleteVisitorChange}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        className={
                          params.disabled
                            ? classes.selectionTextDisabled
                            : classes.selectionTextEnabled
                        }
                        {...params}
                        placeholder="Seleccione"
                        margin="dense"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              ) : (
                <></>
              )}

              {allResults.roles.some(
                (role) =>
                  role.id === newUserForm.role && role.name === "distributors",
              ) ? (
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  disabled={
                    !allResults.roles.some(
                      (role) =>
                        role.id === newUserForm.role &&
                        role.name === "distributors",
                    )
                  }
                  displayText="Distribuidor"
                  id="distributor"
                  label="Seleccione"
                  name="distributor"
                  value={newUserForm.distributor}
                  onChange={handleChange}
                  allValues={allResults.distributors}
                />
              ) : (
                <></>
              )}
              {allResults.roles.some(
                (role) =>
                  role.id === newUserForm.role && role.name === "pharmagroups",
              ) ? (
                <SelectField
                  divClassName={classes.generalPurposeItems}
                  fieldClassName={classes.generalPurposeField}
                  disabled={
                    !allResults.roles.some(
                      (role) =>
                        role.id === newUserForm.role &&
                        role.name === "pharmagroups",
                    )
                  }
                  displayText="Grupo"
                  id="pharmaGroup"
                  label="Seleccione"
                  name="pharmaGroup"
                  value={newUserForm.pharmaGroup}
                  onChange={handleChange}
                  allValues={allResults.pharmagroups}
                />
              ) : (
                <></>
              )}
              {allResults.roles.some(
                (role) =>
                  role.id === newUserForm.role && role.name === "pharmacies",
              ) ? (
                <div className={classes.generalPurposeItems}>
                  <Typography className={classes.text} weight="bold">
                    Farmacia
                  </Typography>
                  <Autocomplete
                    freeSolo
                    name="pharmacy"
                    disabled={
                      !allResults.roles.some(
                        (role) =>
                          role.id === newUserForm.role &&
                          role.name === "pharmacies",
                      )
                    }
                    className={classes.generalPurposeField}
                    options={allResults.pharmacies ? allResults.pharmacies : []}
                    onChange={handleAutoCompletePharmacyChange}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        className={
                          params.disabled
                            ? classes.selectionTextDisabled
                            : classes.selectionTextEnabled
                        }
                        {...params}
                        placeholder="Seleccione"
                        margin="dense"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Contraseña
              </Typography>
              <Field
                labelName="Contraseña *"
                required={true}
                id="password"
                type="password"
                value={newUserForm.password}
                divClassType={classes.generalPurposeItems}
                fieldClassType={classes.generalPurposeField}
                helperText="De 6 a 20 caracteres"
                onChange={handleChange}
                error={
                  newUserForm.password.length < 6 ||
                  newUserForm.password.length > 20
                }
              ></Field>
            </Grid>
          </Grid>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelNewUser}
                >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
