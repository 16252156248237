import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(1),
  },
  buttonSpacing: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    marginLeft: "15em",
    marginTop: "15%",
  },
  generalPurposeItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 700,
    justifyContent: "space-between",
  },
  generalPurposeItemsData: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "3%",
    width: 475,
    justifyContent: "space-between",
  },
  smallItems: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "1%",
    width: 430,
    justifyContent: "space-between",
  },
  sendItem: {
    display: "flex",
    marginLeft: "1em",
    marginTop: "1%",
    width: 700,
    justifyContent: "center",
  },
  generalPurposeField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: "-1.5%",
    width: 300,
  },
  selectionField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: "-1.5%",
    width: 200,
    height: 40,
  },
  smallField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "-2%",
    width: 230,
  },

  sendField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "6%",
    width: 130,
    height: 40,
  },
  logotypeImage: {
    width: 155,
    height: 55,
    marginTop: "-1%",
  },
}));
