import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";

import Field from "components/CustomFields/Field.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { dbGet, dbPost } from "utils/DBFetchers.js";
import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";
import SKUFormatter from "components/CustomFields/SKUFormatter";
import NumberFormatter from "components/CustomFields/NumberFormatter";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

const blankNewProductForm = {
  name: "",
  sku: "",
  salcoCode: "",
  ahumadaCode: "",
  cruzVerdeCode: "",
  listPrice: "",
  gesPrice: "",
};

export default function ABMPresentationsNewPage(props) {
  var classes = useStyles();
  const history = useHistory();
  var [isLoading, setIsLoading] = useState(false);
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();

  const [allProducts, setAllProducts] = useState([]);
  const [isSkuInvalid, setIsSkuInvalid] = useState({ sku: false, message: "" });
  const [newProductForm, updateForm] = useState(blankNewProductForm);

  const handleChange = (event) => {
    updateForm({
      ...newProductForm,
      [event.target.name]: event.target.value,
    });
  };
  const handleSkuChange = (event) => {
    updateForm({
      ...newProductForm,
      sku: event.target.value,
    });
    var skuExists = allProducts.some(
      (elem) => +elem.sku === +event.target.value,
    );
    if (skuExists)
      setIsSkuInvalid({
        sku: skuExists,
        message: "Producto ya existente.",
      });
    else if (event.target.value.length !== 13)
      setIsSkuInvalid({ sku: true, message: "SKU debe tener 13 dígitos." });
    else setIsSkuInvalid({ sku: skuExists, message: "" });
  };

  /* Get all data */
  const getProducts = () => {
    dbGet("presentations").then((data) => {
      let mapped = data.map((item) => {
        return {
          id: item["id"],
          name: item["name"],
          sku: item["sku"],
        };
      });
      setAllProducts(mapped);
    });
  };

  /* Basic form check */
  const checkFormComplete = () => {
    if (
      !newProductForm.name ||
      !newProductForm.sku ||
      !newProductForm.listPrice ||
      isSkuInvalid.sku
    )
      return false;
    return true;
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      snackbarOpen(
        "error",
        "Hay campos sin completar o incorrectos. Por favor completelos para poder añadir el producto.",
      );
      return;
    }
    setIsLoading(true);

    // Give adecuate format to send form to db
    let completedForm = {
      name: newProductForm.name,
      sku: +newProductForm.sku,
      list_price: +newProductForm.listPrice,
      salcobrand_code: newProductForm.salcoCode,
      cruz_verde_code: newProductForm.cruzVerdeCode,
      ahumada_code: newProductForm.ahumadaCode,
    };
    if (newProductForm.gesPrice) {
      completedForm["ges_price"] = +newProductForm.gesPrice;
    }

    // Sends to db
    dbPost(`presentations`, completedForm)
      .then((data) => {
        snackbarOpen("success", "Producto añadido exitosamente");
        redirectToProductsPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al añadir producto",
        ); // translate pending
        setIsLoading(false);
        return;
      });
  };

  /* Cancel adding Product and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankNewProductForm);
    history.goBack();
  };

  /* Redirect to Products table page after adding correctly */
  const redirectToProductsPage = (user) => {
    setTimeout(() => {
      updateForm(blankNewProductForm);
      setIsLoading(false);
      history.push(routes.abmProducts);
    }, 2000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getProducts();
  }, []);

  const title = `Nuevo Producto${newProductForm.name ? ": " : ""}${
    newProductForm.name ? newProductForm.name : ""
  }`;

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del producto
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  A continuación ingrese los datos requeridos para añadir un
                  nuevo producto.
                </Typography>
              </div>
              <div style={{ marginTop: "4%" }}>
                <Field
                  labelName="Nombre *"
                  required={true}
                  id="name"
                  value={newProductForm.name}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                ></Field>
                <Field
                  labelName="SKU *"
                  required={true}
                  id="sku"
                  value={newProductForm.sku}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalFieldValidation}
                  error={isSkuInvalid.sku}
                  helperText={isSkuInvalid.message}
                  onChange={handleSkuChange}
                  InputProps={{
                    inputComponent: SKUFormatter,
                  }}
                ></Field>
                <Field
                  labelName="Código interno Salcobrand"
                  required={false}
                  id="salcoCode"
                  value={newProductForm.salcoCode}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  // error={isSkuInvalid.sku}
                  // helperText={isSkuInvalid.message}
                  InputProps={{
                    inputComponent: NumberFormatter,
                  }}
                ></Field>
                <Field
                  labelName="Código interno Ahumada"
                  required={false}
                  id="ahumadaCode"
                  value={newProductForm.ahumadaCode}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  // error={isSkuInvalid.sku}
                  // helperText={isSkuInvalid.message}
                  InputProps={{
                    inputComponent: NumberFormatter,
                  }}
                ></Field>
                <Field
                  labelName="Código interno Cruz Verde"
                  required={false}
                  id="cruzVerdeCode"
                  value={newProductForm.cruzVerdeCode}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  // error={isSkuInvalid.sku}
                  // helperText={isSkuInvalid.message}
                  InputProps={{
                    inputComponent: NumberFormatter,
                  }}
                ></Field>
                <Field
                  labelName="Precio lista ($) *"
                  id="listPrice"
                  value={newProductForm.listPrice}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: NumberFormatter,
                  }}
                ></Field>
                <Field
                  labelName="Precio GES ($)"
                  id="gesPrice"
                  value={newProductForm.gesPrice}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: NumberFormatter,
                  }}
                ></Field>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelAndGoBack}
                >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
