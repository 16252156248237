import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";
import InputAdornment from "@material-ui/core/InputAdornment";
import DescriptionIcon from "@material-ui/icons/Description";

// styles
import useStyles from "./stylesUpload";

import logo from "images/logoPagesRS.jpg";
import { dbGet, dbUploadFile } from "utils/DBFetchers";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { DataGrid } from "@material-ui/data-grid";
import DateField from "components/CustomFields/DateField";
import {
  getCurrentDate,
  getCurrentMonthDate,
  getLastMonthDate,
} from "utils/dateGetters";
import { format } from "date-fns";
import dataGridTexts from "components/DataGrid/dataGridTexts";
import LinearProgressWithLabel from "components/LinearProgressWithLabel/LinearProgressWithLabel";

const getDateFormatted = (date) => {
  return format(date, "MM/yyyy");
};
const blankFilters = {
  date: getCurrentMonthDate(),
  dateFormatted: getDateFormatted(getCurrentMonthDate()),
  areOnline: false,
};

const statusConvertion = {
  Pending: "Comenzando",
  Completed: "Terminado",
};

export default function ABMBarcodeUploadPage(props) {
  var classes = useStyles();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [tableData, setTableData] = useState([]);
  const [importData, setImportData] = useState(blankFilters);
  const hiddenFileInput = React.useRef(null);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [uploadState, setUploadState] = useState(false);
  const [progress, setProgress] = useState({ progress: 0, status: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDistributors, setSelectedDistributors] = useState([]);

  const selectFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDateChange = (date) => {
    var dateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setImportData({
      ...importData,
      date: dateOnFirstDay,
      dateFormatted: getDateFormatted(dateOnFirstDay),
    });
  };

  const handleAreOnline = (event) => {
    setImportData({ ...importData, areOnline: event.target.checked });
  };

  const handleSelectFileClick = () => {
    hiddenFileInput.current.click();
  };

  const updateProgress = (id, data) => {
    dbGet(`request_checker/status?id=${id}`)
      .then((data) => {
        setProgress({
          ...progress,
          progress: data.progress ? data.progress : 0,
          status: data.status,
        });
        if (data.status === "Completed") {
          snackbarOpen("success", "Archivo cargado correctamente!");
          setIsLoading(false);
          setUploadState(true);
        } else if (!data.error_status && data.status !== "Failure") {
          setTimeout(updateProgress.bind(null, id, data), 2000);
        } else {
          snackbarOpen(
            "error",
            data.error_message
              ? data.error_message.description_es
              : "El archivo puede contener errores y no se pudo cargar.",
          );
          setIsLoading(false);
          setUploadState(true);
        }
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error.error_message
            ? error.error_message.description_es
            : "El archivo puede contener errores y no se pudo cargar.",
        );
        setIsLoading(false);
        setUploadState(true);
      });
  };

  const upload = () => {
    const is_online = importData.areOnline
      ? `&is_online=${importData.areOnline}`
      : "";
    const mock = { progress: 0 };
    setIsLoading(true);
    dbUploadFile(
      selectedFile,
      `/upload/barcodes?month=${
        importData.date.getMonth() + 1
      }&year=${importData.date.getFullYear()}&program_id=${localStorage.getItem(
        "programId",
      )}${is_online}&barcode_types=${selectedDistributors}`,
    )
      .then((response) => {
        setTimeout(updateProgress.bind(null, response.id, mock), 3000);
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error && error.response && error.response.data
            ? error.response.data.description_es
            : "El archivo puede contener errores y no se pudo cargar.",
        );
        setIsLoading(false);
        setUploadState(true);
      });
  };

  const generateRow = (key, value) => {
    return {
      id: key,
      distributors: value,
    };
  };
  /* Fills table data */
  const fillTable = (data) => {
    if (!data) return;
    const tableData = Object.entries(data).map(([key, value]) => generateRow(key, value));
    setTableData(tableData);
  };

  /* Get all data */
  const getResults = (category) => {
    dbGet(category).then((data) => {
      fillTable(data);
    });
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getResults(`/upload/barcodes/types`);
  }, []);

  return (
    <>
      <div>
        <PageTitle
          title="Cargar códigos de barra"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        <CustomizedSnackbars
          severity={snackbarStatus.severity}
          mssg={snackbarStatus.message}
          open={snackbarStatus.open}
          onClose={onCloseSnackbar}
        />
      </div>

      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.smallItems}>
              <Typography variant="h4" className={classes.text}>
                Datos de importación
              </Typography>
            </div>
            <div className={classes.smallItems}>
              <Typography weight="medium" className={classes.text}>
                Seleccione en la parte superior el programa al cual aplicarán
                los códigos de barra. Luego seleccione un ciclo para el cual
                para el cual comenzarán a funcionar los códigos de barra y
                finalmente cargue el archivo.
              </Typography>
            </div>
            <div className={classes.generalPurposeItemsData}>
              <Typography className={classes.text} weight="bold">
                Período
              </Typography>
              <DateField
                helperText="Fecha desde (mes y año)"
                name="begin"
                views={["year", "month"]}
                minDate={new Date("2013-10-01")}
                maxDate={getCurrentDate()}
                format={"MM/yyyy"}
                value={importData.date ? importData.date : getLastMonthDate()}
                onChange={handleDateChange}
                fieldClassName={classes.generalPurposeField}
              />
            </div>

            <FormControlLabel
              value="end"
              control={<Checkbox color="primary" onChange={handleAreOnline} />}
              label={
                <>
                  <Typography>
                    Los códigos de barra son
                    <strong> online</strong>
                  </Typography>
                </>
              }
              weight="bold"
              labelPlacement="end"
              className={classes.relationCheckbox}
            />
            <div className={classes.generalPurposeItems}>
              <Typography className={classes.text} weight="bold">
                Archivo (*.xls, *.xlsx)*
              </Typography>
              <TextField // TODO Pending: Cambiar y Remover buttons. Necessary?
                id="fileSelected"
                className={classes.generalPurposeField}
                disabled
                value={selectedFile ? selectedFile.name : ""}
                variant="outlined"
                margin="dense"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DescriptionIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                className={classes.selectionField}
                disableElevation
                variant="contained"
                color="primary"
                onClick={handleSelectFileClick}
              >
                Seleccione archivo
              </Button>
              <input
                type="file"
                onChange={selectFile}
                style={{ display: "none" }}
                ref={hiddenFileInput}
              />
            </div>
            {isLoading ? (
              <div className={classes.sendItem} style={{ marginBottom: "1%" }}>
                <LinearProgressWithLabel
                  value={progress.progress}
                  label={statusConvertion[progress.status]}
                  variant={
                    progress.status?.includes("Saving")
                      ? "indeterminate"
                      : "determinate"
                  }
                  className={classes.generalPurposeField}
                />
              </div>
            ) : (
              <></>
            )}
            <div className={classes.sendItem}>
              <Button
                className={classes.sendField}
                disabled={!uploadState}
                variant="contained"
                color="secondary"
                onClick={() => {
                  setSelectedFile(undefined);
                  window.location.reload();
                }}
              >
                Limpiar
              </Button>
              <Button
                className={classes.sendField}
                disabled={!selectedFile || uploadState}
                variant="contained"
                color="primary"
                onClick={upload}
              >
                Enviar
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.smallItems}>
              <Typography variant="h4" className={classes.text}>
                Planilla modelo
              </Typography>
            </div>
            <div
              className={classes.generalPurposeItems}
              style={{ marginTop: "1%" }}
            >
              <Typography weight="medium">
                Los archivos Excel deben contener la planilla de códigos de
                barra.
              </Typography>
            </div>
            <ul>
              <li>
                Verifique que todas los nombres de los Distribuidores en la
                planilla coincidan con los ya existentes descriptos abajo.
              </li>
              <li>
                La columna de "Ahumada cod interno" será ignorada tomando la de
                "Ahumada".
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div
              className={classes.generalPurposeItems}
              style={{ height: 350, width: 360 }}
            >
              <DataGrid
                rows={tableData}
                columns={[
                  {
                    field: "id",
                    hide: true,
                    type: "number",
                  },
                  {
                    field: "distributors",
                    headerName: "Distribuidores",
                    width: 300,
                  },
                ]}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectedDistributors(newSelectionModel);
                }}
                selectionModel={selectedDistributors}
                disableColumnMenu={true}
                pageSize={25}
                checkboxSelection={true}
                disableSelectionOnClick={true}
                localeText={dataGridTexts}
                showToolbar={true}
                disableColumnSelector={true}
                hideFooter={true}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
