import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
} from "@material-ui/core";
import useStyles from "./styles";
import jsPDF from "jspdf";
import "jspdf-autotable";

function SummaryTable(props) {
  const classes = useStyles();
  const { rows, columns, detailedFeesColumns, detailedFeesRows } = props;

  return (
    <>
      <Typography variant="h4" className={classes.alignedText}>
        Resumen
      </Typography>
      <Divider style={{ marginBottom: "50px" }} />
      <TableContainer component={Table}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.header}>
              {columns.map(({ title, align }) => (
                <TableCell key={title} className={classes.header} align={align}>
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ marginBottom: 200 }}>
            {rows.map((row) => {
              return (
                <TableRow className={classes.body}>
                  {row.map((field) => (
                    <TableCell
                      // Align every cell but the first one to the right
                      align={row.indexOf(field) === 0 ? "left" : "right"}
                      className={classes.cell}
                    >
                      {field}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
          {detailedFeesColumns && detailedFeesRows && (
            <>
              <br />
              <TableHead>
                <TableRow className={classes.header}>
                  {detailedFeesColumns
                    .slice(0, detailedFeesRows.length)
                    .map(({ title, align }, index) => {
                      if (index >= detailedFeesRows.length) return <></>;
                      return (
                        <TableCell
                          key={title}
                          className={classes.header}
                          align={align}
                        >
                          {title}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody style={{ marginBottom: 200 }}>
                <TableRow className={classes.body}>
                  {detailedFeesRows.map((value) => {
                    return (
                      <TableCell align={"center"} className={classes.cell}>
                        {value.toString().replace("-", "")}%
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

// Export a SummaryTable component to PDF
function exportToPDF(props) {
  const doc = new jsPDF();
  doc.text(props.title, 10, 10);
  doc.autoTable({
    head: props.header,
    body: props.data,
  });
  let fileName = props.name.replace(/ /g, "_");
  fileName = fileName.replace("/", "-");
  fileName += ".pdf";
  doc.save(fileName);
}

export { SummaryTable, exportToPDF };
