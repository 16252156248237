import { alpha } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => {
  return {
    grid: {
      "& .super-app-theme--Header": {
        backgroundColor: alpha("#B559EB", 0.05),
      },
      "& .super-app-theme--Auditorias": {
        backgroundColor: alpha("#EB598C", 0.2),
        "&:hover": {
          backgroundColor: alpha("#EB598C", 0.1),
        },
      },
      "& .super-app-theme--Farmacias": {
        backgroundColor: alpha("#161AA3", 0.2),
        "&:hover": {
          backgroundColor: alpha("#161AA3", 0.1),
        },
      },
    },
    generalPurposeItemsCycle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "100%",
    },
    yearSelectionField: {
      marginBottom: "3%",
      width: 160,
      marginLeft: "2em",
      //maxWidth: 135,
      //minWidth: 115,
      "& input::placeholder": {
        background: "rgba(0, 0, 0, 0)",
        color: "rgba(0,0,0, 1)",
      },
    },
    text: {
      marginBottom: theme.spacing(2),
    },
    buttonSpacing: {
      "& > *": {
        margin: theme.spacing(1),
      },
      display: "flex",
      marginLeft: "15em",
      marginTop: "2%",
      marginBottom: "3%",
    },
    popupButtonSpacing: {
      margin: theme.spacing(1),
    },
    generalPurposeItems: {
      display: "flex",
      width: 600,
      marginTop: "1%",
      marginLeft: "1em",
      justifyContent: "space-between",
    },
    idItems: {
      display: "flex",
      width: 275,
      marginTop: "1%",
      marginLeft: "1em",
      justifyContent: "space-between",
    },
    generalPurposeItemsAlt: {
      marginTop: "1%",
      display: "flex",
      width: 500,
      justifyContent: "space-between",
    },
    generalSelectionField: {
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      marginBottom: "3%",
      width: 350,
      "& input::placeholder": {
        background: "rgba(0, 0, 0, 0)",
        color: "rgba(0,0,0, 1)",
      },
    },
    gridItemSelector: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "1%",
    },
    smallItems: {
      display: "flex",
      marginLeft: "1em",
      marginTop: "1%",
      width: 350,
      justifyContent: "space-between",
    },
    generalPurposeFieldAlt: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: "-1%",
      width: 450,
      "& input::placeholder": {
        background: "rgba(0, 0, 0, 0)",
        color: "rgba(0,0,0, 1)",
      },
    },
    generalPurposeField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: "-1%",
      width: "100%",
      "& input::placeholder": {
        background: "rgba(0, 0, 0, 0)",
        color: "rgba(0,0,0, 1)",
      },
    },
    idInputField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: "-1%",
      width: 275,
      "& input::placeholder": {
        background: "rgba(0, 0, 0, 0)",
        color: "rgba(0,0,0, 1)",
      },
    },
    smallField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: "-2%",
      width: 275,
    },
    selectorField: {
      marginRight: theme.spacing(1),
      marginTop: "-1%",
      width: 170,
    },
    logotypeImage: {
      width: 157,
      height: 60,
      marginTop: "-1%",
    },
    // NEW RUT VERIFICATION STYLES
    generalPurposeItems2: {
      display: "flex",
      width: 310,
      justifyContent: "flex-start",
    },
    smallItems2: {
      display: "flex",
      marginLeft: "1em",
      marginTop: "5%",
      width: 502,
      justifyContent: "space-between",
    },
    smallFieldValidation2: {
      "& input:valid + fieldset": {
        borderColor: "green",
        borderWidth: 2,
      },
      "& input:invalid + fieldset": {
        borderColor: "red",
        borderWidth: 2,
      },
      "& input:hover + fieldset": {
        borderColor: "green",
      },
      "&.Mui-focused fieldset": {
        borderColor: "yellow",
      },
      marginTop: "-2%",
      width: 230,
    },
    smallFieldValidation3: {
      "& input:valid + fieldset": {
        borderColor: "green",
        borderWidth: 2,
      },
      "& input:invalid + fieldset": {
        borderColor: "red",
        borderWidth: 2,
      },
      "& input:hover + fieldset": {
        borderColor: "green",
      },
      "&.Mui-focused fieldset": {
        borderColor: "yellow",
      },
      marginTop: "-2%",
      width: 80,
    },
    // DataGrid styling
    dataGrid: {
      "&.MuiDataGrid-row.Mui-odd": {
        backgroundColor: "255, 0, 0",
      },
    },
    linearProgressField: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      marginTop: "0.5%",
      width: "100%",
    },
    label: {
      alignSelf: "center",
      fontWeight: "bold",
    }
  };
});
