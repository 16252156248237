import React, { Fragment } from "react";
import { DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import esLocale from "date-fns/locale/es";
// import enLocale from "date-fns/locale/en-US"; // Used when language change is implemented

export default function DateField(props) {
  return (
    <>
      <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
        <Fragment>
          <DatePicker
            helperText={props.helperText}
            minDate={props.minDate}
            maxDate={props.maxDate}
            views={props.views}
            disabled={props.disabled}
            name={props.name}
            format={props.format}
            value={props.value}
            onChange={props.onChange}
            inputVariant="outlined"
            margin="dense"
            autoOk={true}
            className={props.fieldClassName}
            error={props.error}
            placeholder={props.placeholder}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
          />
        </Fragment>
      </MuiPickersUtilsProvider>
    </>
  );
}
