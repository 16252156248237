import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  table: {
    minWidth: "100%",
    maxWidth: "100%",
  },
  header: {
    backgroundColor: "#e3e3f3",
    fontWeight: "bold",
    height: 12,
    padding: "10px 16px",
  },
  body: {
    backgroundColor: "#f3f3ff",
    height: 10,
  },
  cell: {
    lineHeight: 1,
    padding: "10px 16px",
    maxHeight: "10px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    verticalAlign: "center",
    borderBottom: "1px solid #ddd",
    borderTop: "1px solid #ddd",
  },
}));
