import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loaderText: {
    marginTop: "5%",
    marginBottom: "-1%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loaderBar: {
    marginTop: "3.5%",
    marginBottom: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "350%",
  },
}));
