import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

// styles
import useStyles from "./styles";
import dataGridTexts from "components/DataGrid/dataGridTexts.js";

export default function ProductPicker(props) {
  var classes = useStyles();

  const [itemCount, setItemCount] = useState(0);
  const [filterModel, setFilterModel] = useState({ items: [] });

  const availableProductsColumns = [
    { field: "name", headerName: "Producto", width: 600 },
  ];

  return (
    <div
      className={classes.generalPurposeField}
      style={{ height: 400, width: props.isMobile ? "100%" : 520 }}
    >
      <Typography className={classes.text} style={{ fontWeight: "bold" }}>
        Productos (máximo {props.maxAvailableSelectedOptions})
      </Typography>
      <TextField
        className={classes.productPickerField}
        label={"Filtrar"}
        onChange={(e) => {
          setFilterModel({
            items: [
              {
                columnField: "name",
                operatorValue: "contains",
                value: e.target.value,
              },
            ],
          });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {itemCount > props.maxAvailableSelectedOptions && (
        <Typography
          className={classes.text}
          style={{ color: "red" }}
          variant="h6"
          weight="bold"
        >
          No puedes seleccionar más de {props.maxAvailableSelectedOptions}
        </Typography>
      )}
      <DataGrid
        rows={props.availableProducts}
        columns={availableProductsColumns}
        checkboxSelection={props.checkboxSelection}
        showToolbar={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        disableColumnMenu={true}
        rowsPerPageOptions={[]}
        pageSize={100}
        localeText={dataGridTexts}
        filterModel={filterModel}
        disableColumnFilter
        onSelectionModelChange={(GridRowSelectedParams) => {
          let newSelected = GridRowSelectedParams;
          if (newSelected.length <= props.maxAvailableSelectedOptions)
            props.updateFormOnSelection(newSelected);
          setItemCount(newSelected.length);
        }}
        onSelectionChange={(newSelection) => {
          let newSelected = newSelection.rowIds.map((n) => +n);
          if (newSelected.length <= props.maxAvailableSelectedOptions)
            props.updateFormOnSelection(newSelected);
          setItemCount(newSelected.length);
        }}
      />
    </div>
  );
}
