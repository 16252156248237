const roleTranslation = {
  admin: "Administrador",
  pharmacies: "Farmacias",
  pharmagroups: "Grupos",
  distributors: "Distribuidores",
  super_admin: "Super admin",
  commertial: "Comercial",
  asistente: "Asistente",
  kam: "KAM",
  ges: "GES",
  medic: "Medico",
  consultant: "Visitador",
  full: "Full",
  traceability: "Trazabilidad",
};

// Roles that the admin cant see
const hidedRoles = new Set([
  "super_admin",
  "kam",
  "commertial",
  "medic",
  "consultant",
  "ges",
  "full",
  "asistente",
  "Super admin",
  "Comercial",
  "asistente",
  "KAM",
  "GES",
  "Medico",
  "Visitador",
  "Full",
]);

export { roleTranslation, hidedRoles };
