import {
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Autocomplete } from "@material-ui/lab";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";
import DateField from "components/CustomFields/DateField";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";
import { Stack } from "@mui/material";
import { dbGet } from "utils/DBFetchers";
import { getCurrentDate } from "utils/dateGetters.js";
// styles
import useStyles from "./styles";

// material

const FIRST_MONTH = new Date(getCurrentDate().getFullYear(), 0, 1);

const LAST_MONTH = new Date(
  getCurrentDate().getFullYear(),
  getCurrentDate().getMonth(),
  1,
);

const getDateFormatted = (date) => {
  return format(date, "yyyy/MM");
};

const FILTERS = {
  fromDate: getCurrentDate(),
  toDate: getCurrentDate(),
  program: null,
  distributor: null,
  brand: null,
  presentation: null,
  businessArea: null,
};

const SELECT_INFORMATION = {
  programs: [],
  distributors: [],
  brands: [],
  presentations: [],
  businessAreas: [],
};

export default function DashboardSelectionForm(props) {
  var classes = useStyles();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [filters, setFilters] = useState(FILTERS);
  const [selectInformation, setSelectInformation] =
    useState(SELECT_INFORMATION);

  useEffect(() => {
    getInitialInformation();
  }, []);

  const validDateFilters = () => {
    let validDate =
      filters.fromDate && filters.toDate && filters.toDate >= filters.fromDate;
    return validDate;
  };

  const handleFilterChange = (filter, value) => {
    setFilters({
      ...filters,
      [filter]: value,
    });
  };

  const getPeriodFromDate = (date) => {
    return parseInt(
      date.toISOString().split("T")[0].substring(0, 7).replace("-", ""),
    );
  };

  const getInitialInformation = () => {
    dbGet("kpi-control-panel/audit/filters")
      .then((data) => {
        setSelectInformation({
          programs: data.programs,
          distributors: data.distributors,
          brands: data.brands,
          presentations: data.presentations,
          businessAreas: data.business_areas,
        });
      })
      .catch((error) => {});
  };

  const handleApplyFilters = () => {
    if (!validDateFilters()) return;
    let fromPeriod = `?from_period=${getPeriodFromDate(filters.fromDate)}`;
    let toPeriod = `&to_period=${getPeriodFromDate(filters.toDate)}`;
    let program = filters.program ? `&program_id=${filters.program}` : "";
    let distributor = filters.distributor
      ? `&distributor_ids=${filters.distributor}`
      : "";
    let brand = filters.brand ? `&product_brand=${filters.brand}` : "";
    let presentation = filters.presentation
      ? `&presentation_id=${filters.presentation}`
      : "";
    let businessArea = filters.businessArea
      ? `&business_area=${filters.businessArea}`
      : "";
    let url = `kpi-control-panel/audit${fromPeriod}${toPeriod}${program}${distributor}${brand}${presentation}${businessArea}`;
    props.setIsLoading(true);
    dbGet(url)
      .then((response) => {
        setTimeout(updateProgress.bind(null, response.id), 3000);
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los datos");
        props.setIsLoading(false);
      });
  };

  const updateProgress = (id) => {
    dbGet(`request_checker/status?id=${id}`)
      .then((response) => {
        if (response.status === "Completed") {
          let data = response.data;
          props.generateRowsData(data.Values, data.Objectives);
          props.setIsLoading(false);
          props.setIsInitialState(false);
        } else if (!response.error_status && response.status !== "Failure") {
          setTimeout(updateProgress.bind(null, id, response), 2000);
        } else {
          snackbarOpen("error", "Error al obtener los datos de la consulta");
          props.setIsLoading(false);
        }
      })
      .catch((error) => {
        snackbarOpen("error", "Error al obtener los datos de la consulta");
        props.setIsLoading(false);
      });
  };

  const handleProgramChange = (event, newValue) => {
    handleFilterChange("program", newValue ? newValue.id : newValue);
  };

  const handleDistributorChange = (event, newValue) => {
    let newValueIds = [];
    if (newValue) {
      newValueIds = newValue.map((distributor) => distributor.id);
    }
    handleFilterChange("distributor", newValue ? newValueIds : newValue);
  };

  const handlePresentationChange = (event, newValue) => {
    handleFilterChange("presentation", newValue ? newValue.id : newValue);
  };

  const handleBusinessAreaChange = (event, newValue) => {
    handleFilterChange("businessArea", newValue ? newValue.id : newValue);
  };

  const handleBrandChange = (event, newValue) => {
    handleFilterChange("brand", newValue ? newValue.id : newValue);
  };

  const handleToPeriodChange = (date) => {
    var dateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    if (dateOnFirstDay < filters.fromDate) {
      snackbarOpen("error", "El período final no puede ser menor al inicial");
      return;
    }
    setFilters({
      ...filters,
      toDate: dateOnFirstDay,
    });
  };

  const handleFromPeriodChange = (date) => {
    var dateOnFirstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    if (dateOnFirstDay > filters.toDate) {
      snackbarOpen("error", "El período final no puede ser menor al inicial");
      return;
    }
    setFilters({
      ...filters,
      fromDate: dateOnFirstDay,
    });
  };

  return (
    <>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        item
        xs={12}
        lg={12}
        style={{ marginBottom: "10px", marginLeft: "2.5%" }}
      >
        <Typography variant="h4" className={classes.alignedText}>
          Selección
        </Typography>
        <Divider />
      </Grid>

      <Grid container justifyContent="center">
        <Grid item container xs={12} justifyContent="center">
          <Grid item container xs={4}>
            <Grid item xs={5} className={classes.alignRight}>
              <Typography className={classes.alignedText} weight="bold">
                Período inicial
              </Typography>
            </Grid>
            <Grid item xs={7} className={classes.alignLeft}>
              <DateField
                views={["year", "month"]}
                minDate={new Date("2013-10-01")}
                maxDate={getCurrentDate()}
                format={"MM/yyyy"}
                value={filters.fromDate}
                onChange={handleFromPeriodChange}
                fieldClassName={classes.dateSelectionField}
              />
            </Grid>
          </Grid>

          <Grid item container xs={4}>
            <Grid item xs={3} className={classes.alignRight}>
              <Typography className={classes.alignedText} weight="bold">
                Período final
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.alignLeft}>
              <DateField
                views={["year", "month"]}
                minDate={new Date("2013-10-01")}
                maxDate={getCurrentDate()}
                format={"MM/yyyy"}
                value={filters.toDate}
                onChange={handleToPeriodChange}
                fieldClassName={classes.dateSelectionField}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          style={{ marginTop: "5px" }}
        >
          <Grid item container xs={4}>
            <Grid item xs={5} className={classes.alignRight}>
              <Typography
                className={classes.alignedTextWithPadding}
                weight="bold"
              >
                Programa
              </Typography>
            </Grid>
            <Grid item xs={7} className={classes.alignLeft}>
              <Autocomplete
                className={classes.simpleSelectionField}
                id="program"
                autoHighlight
                onChange={handleProgramChange}
                options={selectInformation.programs || []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccione" />
                )}
              />
            </Grid>
          </Grid>

          <Grid item container xs={4}>
            <Grid item xs={3} className={classes.alignRight}>
              <Typography
                className={classes.alignedTextWithPadding}
                weight="bold"
              >
                Cadena
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.alignLeft}>
              <Autocomplete
                multiple
                className={classes.simpleSecondColumnSelectionField}
                id="distributor"
                autoHighlight
                onChange={handleDistributorChange}
                options={selectInformation.distributors || []}
                getOptionLabel={(option) => option.name}
                sx={{ width: 700 }}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccione" />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          style={{ marginTop: "5px" }}
        >
          <Grid item container xs={4}>
            <Grid item xs={5} className={classes.alignRight}>
              <Typography
                className={classes.alignedTextWithPadding}
                weight="bold"
              >
                Marca
              </Typography>
            </Grid>
            <Grid item xs={7} className={classes.alignLeft}>
              <Autocomplete
                className={classes.simpleSelectionField}
                autoHighlight
                id="brand"
                options={selectInformation.brands || []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Tipee para buscar" />
                )}
                onChange={handleBrandChange}
              />
            </Grid>
          </Grid>
          <Grid item container xs={4} alignItems="center">
            <Grid item xs={3} className={classes.alignRight}>
              <Typography
                className={classes.alignedTextWithPadding}
                weight="bold"
              >
                Presentación
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.alignLeft}>
              <Autocomplete
                className={classes.simpleSecondColumnSelectionField}
                autoHighlight
                id="presentation"
                options={selectInformation.presentations || []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Tipee para buscar" />
                )}
                onChange={handlePresentationChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "5px" }}
        >
          <Grid item container xs={4} alignItems="center">
            <Grid item xs={5} className={classes.alignRight}>
              <Typography
                className={classes.alignedTextWithPadding}
                weight="bold"
              >
                Área de negocio
              </Typography>
            </Grid>
            <Grid item xs={7} className={classes.alignLeft}>
              <Autocomplete
                className={classes.simpleSelectionField}
                autoHighlight
                id="businessArea"
                options={selectInformation.businessAreas || []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccione" />
                )}
                onChange={handleBusinessAreaChange}
              />
            </Grid>
          </Grid>
          <Grid item container xs={4} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ marginTop: "20px", marginBottom: "10px" }}
        spacing={2}
        className={classes.uploadButton}
      >
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyFilters}
          >
            Aplicar
          </Button>
          <Button
            disabled={props.isLoading || props.isInitialState}
            variant="contained"
            color="secondary"
            onClick={props.exportFunction}
          >
            Exportar
          </Button>
        </Stack>
      </Grid>
    </>
  );
}
