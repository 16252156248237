import React from "react";

import { InputAdornment, TextField } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// styles
import useStyles from "../Header/styles";

export default function Searchbar(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  var classes = useStyles();
  return (
    <>
      {!isMobile && (
        <Autocomplete
          freeSolo
          disableClearable
          options={Object.entries(props.options).map(([key, value]) => key)}
          onChange={props.onChange}
          classes={{ popper: classes.popper }}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Buscar..."
              margin="dense"
              variant="outlined"
              className={classes.input}
              InputProps={{
                ...params.InputProps,
                type: "search",
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      )}
    </>
  );
}
