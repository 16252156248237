import React from "react";
import { CircularProgress, Button } from "@material-ui/core";

export default function SubmitButton(props) {
  return (
    <>
      <div className={props.divClassName}>
        {props.isLoading ? (
          <CircularProgress size={26} className={props.loadingClassName} />
        ) : (
          <Button
            className={props.buttonClassName}
            disabled={props.disabled}
            variant="contained"
            color="primary"
            onClick={props.onClick}
          >
            {props.buttonText}
          </Button>
        )}
      </div>
    </>
  );
}
