import React from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  useHistory,
} from "react-router-dom";
import sanitizeHtml from "sanitize-html";
// components
import Layout from "./Layout/Layout";

// pages
import Login from "pages/login/Login";
import PasswordResetRequest from "pages/login/password_reset/PasswordResetRequest";
import PasswordResetForm from "pages/login/password_reset/PasswordResetForm";
import Error from "pages/error/Error";
import BurnableCouponForm from "pages/burnableCouponForm/index.js";
import SoloMujerForm from "pages/soloMujerForm/index.js";

// context
import { useUserState, useUserDispatch, signOut } from "context/UserContext";

import routes from "routes";
import { dbGet } from "utils/DBFetchers";

export default function App() {
  var userDispatch = useUserDispatch();
  const history = useHistory();
  var { isAuthenticated } = useUserState();

  React.useEffect(() => {
    dbGet("logged_in")
      .then((response) => {})
      .catch((error) => {
        signOut(userDispatch, history);
      });
  }, []);

  return (
    <BrowserRouter forceRefresh={true}>
      <Switch>
        <Route
          exact
          path={routes.root}
          render={() => <Redirect to={routes.login} />}
        />
        <Route
          exact
          path={routes.app}
          render={() => <Redirect to={routes.home} />}
        />
        <Route path={routes.notFound404} component={Error} />
        <PublicRoute path={routes.login} component={Login} />
        <PublicRoute
          path={routes.passwordResetRequest}
          component={PasswordResetRequest}
        />
        <PublicRoute
          path={routes.passwordResetForm}
          component={PasswordResetForm}
        />
        <PrivateRoute path={routes.app} component={Layout} />
        <Route
          exact
          path={routes.soloMujerForm}
          component={SoloMujerForm}
        />
        <Route
          exact
          path={routes.acosBurnableCouponForm}
          component={BurnableCouponForm}
        />
        <Route
          exact
          path={routes.hormBurnableCouponForm}
          component={BurnableCouponForm}
        />
        <Route
          exact
          path={routes.acuodeBurnableCouponForm}
          component={BurnableCouponForm}
        />
      </Switch>
    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component props={props} /> // React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: routes.login,
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: routes.app,
              }}
            />
          ) : (
            <Component props={props} /> //React.createElement(sanitizeHtml(component), props)
          )
        }
      />
    );
  }
}
