import { Card, Paper } from "@mui/material";
import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// material
import { Typography } from "components/Wrappers/Wrappers";
import { format } from "date-fns";
import useStyles from "../styles";
import { useTheme } from "@material-ui/styles";

import SummaryCardsDigital from "../SummaryCards/SummaryCardsDigital";
import GraphsDigital from "../Graphs/GraphsDigital";
import TableDigital from "../Table/TableDigital";

const getDateFormatted = (date) => {
  return format(date, "dd/MM/yy");
};

// const assembleExcelData = (information) => {
// }

export default function TraceabilityDashboardDigital(props) {
  const classes = useStyles();
  const theme = useTheme();

//   useEffect(() => {
//     props.setExcelData(assembleExcelData(props.information));
//   }, []);

  return (
    <Grid container>
      <Grid item container xs={12} alignItems="stretch" spacing={2}>
        <Grid item xs={12} style={{ margin: "0 0 10px 10px" }}>
          <Typography variant="h4" className={classes.alignedText}>
            Resumen
          </Typography>
          <Divider />
        </Grid>
        <SummaryCardsDigital information={props.information} />
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        flexDirection="column"
        spacing={2}
        style={{ marginTop: "10px" }}
      >
        {/* Left side */}
        <Grid item container lg={5} xs={12} md={5}>
          <Grid item lg={12} xs={12} md={12} style={{ marginLeft: "10px" }}>
            <Typography variant="h4" className={classes.alignedText}>
              Información global
            </Typography>
            <Divider />
          </Grid>
          <TableDigital information={props.information}/>
        </Grid>
        <GraphsDigital information={props.information} />
      </Grid>
    </Grid>
  );
}
