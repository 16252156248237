export const ROW_HEADERS_LIST = [
  "CUPONES DIGITALES ",
  "Total descargas",
  "Clientes únicos con descarga",
  "BASE CLIENTES ",
  "Total Clientes",
  "Clientes Nuevos",
  "Contactables",
  "USO CLIENTES PACIENTES ",
  "Inscripciones Totales",
  "Inscripciones Cupones Fisicos",
  "Inscripciones Cupones Digitales",
  "Clientes únicos inscritos",
  "Clientes únicos inscritos Cupón físico",
  "Clientes únicos inscritos Cupón digital",
  "Clientes que compran cada mes",
  "Clientes con 1 compra en 12 meses",
  "Clientes con 2 compras en 12 meses",
  "Clientes con 3 compras en 12 meses",
  "Clientes con 4 compras en 12 meses",
  "Clientes con más de 4 compras en 12 meses",
  "Clientes nuevos",
  "Clientes con beneficio vigente",
  "Clientes + sku con beneficio vigente",
  "Compras promedio por cliente",
  "Unidades compradas promedio por cliente",
  "USO CLIENTES MEDICOS ",
  "Médicos usuarios",
  "Médicos usuarios en kardex (%)",
  "Médicos nuevos",
  "Médicos nuevos en kardex (%)",
  "VENTAS ",
  "Venta unidades informado",
  "Venta Valorizada ExLab informado",
  "Descuento informado",
  "Transacciones informadas",
  "Venta unidades según auditoria",
  "Venta valorizada ExLab según auditoria",
  "Descuento Calculado auditoria",
  "Transacciones según auditoria",
];

export const ROW_HEADERS = {
  sales_divider: { label: "CUPONES DIGITALES ", category: "divider" },
  total_downloads: {
    label: "Total descargas",
    category: "data",
  },
  clients_with_downloads: {
    label: "Clientes únicos con descarga",
    category: "data",
  },
  client_divider: { label: "BASE CLIENTES ", category: "divider" },
  clients_total: {
    label: "Total Clientes",
    category: "data",
  },
  new_clients: {
    label: "Clientes Nuevos",
    category: "data",
  },
  clients_reachable: {
    label: "Contactables",
    category: "data",
  },
  patient_divider: { label: "USO CLIENTES PACIENTES ", category: "divider" },
  clients_enrolled_total: {
    label: "Inscripciones Totales",
    category: "data",
  },
  clients_enrolled_with_physical_coupon: {
    label: "Inscripciones Cupones Fisicos",
    category: "data",
  },
  clients_enrolled_with_digital_coupon: {
    label: "Inscripciones Cupones Digitales",
    category: "data",
  },
  clients_unique_enrolled_total: {
    label: "Clientes únicos inscritos",
    category: "data",
  },
  clients_unique_enrolled_with_physical_coupon: {
    label: "Clientes únicos inscritos Cupón físico",
    category: "data",
  },
  clients_unique_enrolled_with_digital_coupon: {
    label: "Clientes únicos inscritos Cupón digital",
    category: "data",
  },
  clients_with_purchase_total: {
    label: "Clientes que compran cada mes",
    category: "data",
  },
  clients_with_purchase_one_in_year: {
    label: "Clientes con 1 compra en 12 meses",
    category: "data",
  },
  clients_with_purchase_two_in_year: {
    label: "Clientes con 2 compras en 12 meses",
    category: "data",
  },
  clients_with_purchase_three_in_year: {
    label: "Clientes con 3 compras en 12 meses",
    category: "data",
  },
  clients_with_purchase_four_in_year: {
    label: "Clientes con 4 compras en 12 meses",
    category: "data",
  },
  clients_with_purchase_more_than_four_in_year: {
    label: "Clientes con más de 4 compras en 12 meses",
    category: "data",
  },
  clients_with_active_benefit: {
    label: "Clientes con beneficio vigente",
    category: "data",
  },
  clients_with_active_benefit_sku: {
    label: "Clientes + sku con beneficio vigente",
    category: "data",
  },
  clients_avg_purchases: {
    label: "Compras promedio por cliente",
    category: "data",
  },
  clients_avg_purchased_units: {
    label: "Unidades compradas promedio por cliente",
    category: "data",
  },
  medic_divider: { label: "USO CLIENTES MEDICOS ", category: "divider" },
  user_medics: { label: "Médicos usuarios", category: "data" },
  user_medics_in_kardex_percentage: {
    label: "Médicos usuarios en kardex (%)",
    category: "data",
  },
  new_medics: { label: "Médicos nuevos", category: "data" },
  new_medics_in_kardex_percentage: {
    label: "Médicos nuevos en kardex (%)",
    category: "data",
  },
  coupons_divider: { label: "VENTAS ", category: "divider" },
  sales_informed_units: { label: "Venta unidades informado", category: "data" },
  sales_informed_exlab: {
    label: "Venta Valorizada ExLab informado",
    category: "data",
  },
  sales_discounts: { label: "Descuento informado", category: "data" },
  sales_informed_transactions: {
    label: "Transacciones informadas",
    category: "data",
  },
  sales_units_audit: {
    label: "Venta unidades según auditoria",
    category: "data",
  },
  sales_audit_exlab: {
    label: "Venta valorizada ExLab según auditoria",
    category: "data",
  },
  sales_calculated_discount: {
    label: "Descuento Calculado auditoria",
    category: "data",
  },
  sales_audit_transactions: {
    label: "Transacciones según auditoria",
    category: "data",
  },
};

export const NEW_ROW_HEADERS_LIST = [
  "CUPONES DIGITALES ",
  "Total descargas",
  "Clientes únicos con descarga",
  "BASE CLIENTES ",
  "Total Clientes",
  "Clientes Nuevos",
  "Contactables",
  "USO CLIENTES PACIENTES ",
  "Inscripciones Totales",
  "Inscripciones Cupones Fisicos",
  "Inscripciones Cupones Digitales",
  "Clientes únicos inscritos",
  "Clientes únicos inscritos Cupón físico",
  "Clientes únicos inscritos Cupón digital",
  "Clientes que compran cada mes",
  "Clientes con 1 compra en 12 meses",
  "Clientes con 2 compras en 12 meses",
  "Clientes con 3 compras en 12 meses",
  "Clientes con 4 compras en 12 meses",
  "Clientes con más de 4 compras en 12 meses",
  "Clientes nuevos",
  "Clientes con beneficio vigente",
  "Compras promedio por cliente",
  "Unidades compradas promedio por cliente",
  "USO CLIENTES MEDICOS ",
  "Médicos usuarios",
  "Médicos usuarios en kardex (%)",
  "Médicos nuevos",
  "Médicos nuevos en kardex (%)",
  "VENTAS ",
  "Venta unidades informado",
  "Venta Valorizada ExLab informado",
  "Descuento informado",
  "Transacciones informadas",
  "Venta unidades según auditoria",
  "Venta valorizada ExLab según auditoria",
  "Descuento Calculado auditoria",
  "Transacciones según auditoria",
];

export const NEW_ROW_HEADERS = {
  sales_divider: { label: "CUPONES DIGITALES ", category: "divider" },
  total_downloads: {
    label: "Total descargas",
    category: "data",
  },
  clients_with_downloads: {
    label: "Clientes únicos con descarga",
    category: "data",
  },
  client_divider: { label: "BASE CLIENTES ", category: "divider" },
  clients_total: {
    label: "Total Clientes",
    category: "data",
  },
  new_clients: {
    label: "Clientes Nuevos",
    category: "data",
  },
  clients_reachable: {
    label: "Contactables",
    category: "data",
  },
  patient_divider: { label: "USO CLIENTES PACIENTES ", category: "divider" },
  clients_enrolled_total: {
    label: "Inscripciones Totales",
    category: "data",
  },
  clients_enrolled_with_physical_coupon: {
    label: "Inscripciones Cupones Fisicos",
    category: "data",
  },
  clients_enrolled_with_digital_coupon: {
    label: "Inscripciones Cupones Digitales",
    category: "data",
  },
  clients_unique_enrolled_total: {
    label: "Clientes únicos inscritos",
    category: "data",
  },
  clients_unique_enrolled_with_physical_coupon: {
    label: "Clientes únicos inscritos Cupón físico",
    category: "data",
  },
  clients_unique_enrolled_with_digital_coupon: {
    label: "Clientes únicos inscritos Cupón digital",
    category: "data",
  },
  clients_with_purchase_total: {
    label: "Clientes que compran cada mes",
    category: "data",
  },
  clients_with_purchase_one_in_year: {
    label: "Clientes con 1 compra en 12 meses",
    category: "data",
  },
  clients_with_purchase_two_in_year: {
    label: "Clientes con 2 compras en 12 meses",
    category: "data",
  },
  clients_with_purchase_three_in_year: {
    label: "Clientes con 3 compras en 12 meses",
    category: "data",
  },
  clients_with_purchase_four_in_year: {
    label: "Clientes con 4 compras en 12 meses",
    category: "data",
  },
  clients_with_purchase_more_than_four_in_year: {
    label: "Clientes con más de 4 compras en 12 meses",
    category: "data",
  },
  clients_with_active_benefit: {
    label: "Clientes con beneficio vigente",
    category: "data",
  },
  clients_avg_purchases: {
    label: "Compras promedio por cliente",
    category: "data",
  },
  clients_avg_purchased_units: {
    label: "Unidades compradas promedio por cliente",
    category: "data",
  },
  medic_divider: { label: "USO CLIENTES MEDICOS ", category: "divider" },
  user_medics: { label: "Médicos usuarios", category: "data" },
  user_medics_in_kardex_percentage: {
    label: "Médicos usuarios en kardex (%)",
    category: "data",
  },
  new_medics: { label: "Médicos nuevos", category: "data" },
  new_medics_in_kardex_percentage: {
    label: "Médicos nuevos en kardex (%)",
    category: "data",
  },
  coupons_divider: { label: "VENTAS ", category: "divider" },
  sales_informed_units: { label: "Venta unidades informado", category: "data" },
  sales_informed_exlab: {
    label: "Venta Valorizada ExLab informado",
    category: "data",
  },
  sales_discounts: { label: "Descuento informado", category: "data" },
  sales_informed_transactions: {
    label: "Transacciones informadas",
    category: "data",
  },
  sales_units_audit: {
    label: "Venta unidades según auditoria",
    category: "data",
  },
  sales_audit_exlab: {
    label: "Venta valorizada ExLab según auditoria",
    category: "data",
  },
  sales_calculated_discount: {
    label: "Descuento Calculado auditoria",
    category: "data",
  },
  sales_audit_transactions: {
    label: "Transacciones según auditoria",
    category: "data",
  },
};
