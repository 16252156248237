import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  Input,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Typography } from "components/Wrappers/Wrappers";

import Field from "components/CustomFields/Field.js";
import CustomizedSnackbars from "components/CustomizedSnackbars/CustomizedSnackbars";

import { dbGet, dbPost } from "utils/DBFetchers.js";
import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";

// styles
import useStyles from "./styles";
import { SnackbarHandler } from "components/Handlers/SnackbarHandler";

const blankNewKamForm = {
  id: "",
  name: "",
  email: "",
  distributors: null,
};

const DISTRIBUTORS_MAP = {
  "Ramirez y Sanchez": 1,
  "Farma 7": 2,
  Farmagestion: 3,
  Mediven: 4,
  Globalpharma: 6,
  Recalcine: 8,
  "Schubert Spa": 10,
  Salcobrand: 13,
  "Cruz Verde": 15,
  Ahumada: 18,
};

export default function ABMKamNewPage(props) {
  var classes = useStyles();
  var [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [snackbarStatus, snackbarOpen, onCloseSnackbar] = SnackbarHandler();
  const [newKamForm, updateForm] = useState(blankNewKamForm);
  const [allDistributors, setAllDistributors] = useState([]);
  const [distributorsName, setKamsName] = useState([]);
  const [isNameValid, setIsNameValid] = useState({ name: false, message: "" });

  const handleChange = (event) => {
    updateForm({
      ...newKamForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleNameVerification = () => {
    var nameExists = allDistributors.some(
      (elem) => elem.name === newKamForm.name,
    );
    if (nameExists)
      setIsNameValid({
        name: nameExists,
        message: "KAM ya existente.",
      });
    else setIsNameValid({ name: nameExists, message: "" });
  };

  const handleKamChange = (event) => {
    setKamsName(event.target.value);
    let distributorsId = event.target.value.map((value) => {
      return DISTRIBUTORS_MAP[value];
    });
    setKamsName(event.target.value);
    updateForm({
      ...newKamForm,
      [event.target.name]: distributorsId,
    });
  };

  React.useEffect(() => {
    if (!newKamForm.name) return;
    handleNameVerification();
  }, [newKamForm.name]);

  /* Fills distributor name options */
  const getDistributors = () => {
    dbGet("distributors").then((data) => {
      data = data.filter((x) => x.id !== 19);
      let mapped = data.map((item) => {
        return {
          id: item["id"],
          name: item["name"],
        };
      });
      setAllDistributors(mapped);
    });
  };

  /* Submit handling */
  const handleSubmit = () => {
    if (!checkFormComplete()) {
      return;
    }
    setIsLoading(true);

    // Give adecuate format to send form to db
    let completedForm = {
      name: newKamForm.name,
      email: newKamForm.email,
      distributors: newKamForm.distributors.join(","),
    };

    // Sends to db
    dbPost(`kams`, completedForm)
      .then((data) => {
        snackbarOpen("success", "KAM añadido exitosamente");
        redirectToKamsPage();
      })
      .catch((error) => {
        snackbarOpen(
          "error",
          error[0] ? error[0].description_es : "Error al añadir KAM",
        );
        setIsLoading(false);
        return;
      });
  };

  const checkFormComplete = () => {
    var isCompleted = true;
    if (!newKamForm.name) {
      isCompleted = false;
      snackbarOpen("error", "Complete el campo de nombre");
    } else if (!newKamForm.email) {
      isCompleted = false;
      snackbarOpen("error", "Complete el campo de email");
    } else if (!newKamForm.email.includes("@")) {
      isCompleted = false;
      snackbarOpen("error", "El campo de mail es incorrecto");
    } else if (newKamForm.distributors.length === 0) {
      isCompleted = false;
      snackbarOpen("error", "Selecciones los distribuidores");
    }
    return isCompleted;
  };

  /* Cancel editing kam and go one page backwards */
  const cancelAndGoBack = () => {
    updateForm(blankNewKamForm);
    history.goBack();
  };

  /* Redirect to kam table page after adding correctly */
  const redirectToKamsPage = (user) => {
    setTimeout(() => {
      updateForm(blankNewKamForm);
      setIsLoading(false);
      history.push(routes.abmKam);
    }, 2000);
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getDistributors();
  }, []);

  const title = `Nuevo KAM${newKamForm.name ? ": " : ""}${
    newKamForm.name ? newKamForm.name : ""
  }`;

  return (
    <>
      <div>
        <PageTitle
          title={title}
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <CustomizedSnackbars
        severity={snackbarStatus.severity}
        mssg={snackbarStatus.message}
        open={snackbarStatus.open}
        onClose={onCloseSnackbar}
      />
      <Grid
        style={{ display: "flex", margin: 0 }}
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
      >
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.text}>
                Datos del KAM
              </Typography>
              <div
                className={classes.text}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: 700,
                }}
              >
                <Typography weight="medium">
                  A continuación ingrese los datos del KAM a añadir.
                </Typography>
              </div>
              <div style={{ marginTop: "4%" }}>
                <Field
                  labelName="Nombre *"
                  required={true}
                  id="name"
                  value={newKamForm.name}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  error={isNameValid.name}
                  helperText={isNameValid.message}
                />
                <Field
                  labelName="Email *"
                  required={true}
                  id="email"
                  value={newKamForm.email}
                  divClassType={classes.generalPurposeItems}
                  fieldClassType={classes.generalPurposeField}
                  onChange={handleChange}
                  error={!newKamForm.email}
                />
                <div className={classes.generalPurposeItems}>
                  <Typography className={classes.text} weight="bold">
                    Distribuidor/es
                  </Typography>
                  <Select
                    id="distributors"
                    name="distributors"
                    className={classes.generalPurposeField}
                    multiple
                    value={distributorsName}
                    onChange={handleKamChange}
                    input={<Input id="select-multiple-chip" />}
                    margin="dense"
                    variant="outlined"
                    renderValue={(selected) => (
                      <div>
                        {selected.map((key, value) => {
                          return (
                            <Chip
                              key={value}
                              label={key}
                              className={classes.chip}
                              variant="outlined"
                            />
                          );
                        })}
                      </div>
                    )}
                  >
                    {allDistributors.map((distributor) => {
                      return (
                        <MenuItem key={distributor.id} value={distributor.name}>
                          {distributor.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <div className={classes.buttonSpacing}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={cancelAndGoBack}
                >
                  Cancelar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
}
