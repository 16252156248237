import { format } from "date-fns";

const getDateFormatted = (date) => {
  return format(date, "dd/MM/yy"); // Not working when called from outside
};
const getDateMonthYear = (date) => {
  return format(date, "yyyy/MM");
};

const getCurrentDate = () => {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
};

const getCurrentDateToday = () => {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};

const getLastMonthDate = () => {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth() - 1, now.getDate() + 1);
};

const getCurrentMonthDate = () => {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
};

const getNextMonthDate = () => {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, now.getDate() + 1);
};

const getNextNextMonthDate = () => {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 2, now.getDate() + 1);
};

export {
  getDateFormatted,
  getDateMonthYear,
  getCurrentDate,
  getLastMonthDate,
  getCurrentDateToday,
  getCurrentMonthDate,
  getNextMonthDate,
  getNextNextMonthDate,
};
