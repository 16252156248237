import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import {
  Button,
  DialogContent,
  DialogContentText,
  Grid,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import SyncDisabledIcon from "@material-ui/icons/SyncDisabled";
import moment from "moment";

import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";
// styles
import useStyles from "./styles";

import { dbGet, dbPut } from "utils/DBFetchers.js";
import DateField from "components/CustomFields/DateField";
import { format } from "date-fns";
import { getCurrentDate } from "utils/dateGetters";
import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import LoaderBar from "components/LoaderBar/LoaderBar";

const blankVademecumToDelete = {
  id: "",
  name: "",
  discount: "",
  dateToDisable: null,
  error: false,
  message: "",
};

export default function ABMVademecumsPage() {
  const PRESENTATION_ID_POS = 1;
  const VADEMECUM_ID_POS = 2;
  const NAME_POS = 3;
  const DISCOUNT_POS = 4;

  // DELETE VADEMECUM
  const [deleteVademecumOpen, setDeleteVademecumOpen] = React.useState(false);
  const [vademecumToDelete, setVademecumToDelete] = React.useState(
    blankVademecumToDelete,
  );
  const setDateToDisable = (date) => {
    setVademecumToDelete({
      ...vademecumToDelete,
      dateToDisable: date,
    });
  };
  const handleDeleteVademecumOpen = (id, vademecumName, vademecumDiscount) => {
    setVademecumToDelete({
      ...vademecumToDelete,
      id: id,
      name: vademecumName,
      discount: vademecumDiscount,
    });
    setDeleteVademecumOpen(true);
  };
  const handleDeleteUserClose = () => {
    setVademecumToDelete(blankVademecumToDelete);
    setDeleteVademecumOpen(false);
  };
  const handleDeleteVademecum = () => {
    if (!vademecumToDelete.dateToDisable) {
      setVademecumToDelete({
        ...vademecumToDelete,
        error: true,
        message: "Por favor seleccione una fecha",
      });
      return;
    }
    setDeleteVademecumOpen(false);
    dbPut(`/presentations/disable_discount/${vademecumToDelete.id}`, {
      on_date: format(new Date(vademecumToDelete.dateToDisable), "dd/MM/yy"),
    });
    setTimeout(() => {
      setTableData([]);
      setVademecumToDelete(blankVademecumToDelete);
      getResults(`presentations/program/${localStorage.getItem("programId")}`);
    }, 700);
  };

  const history = useHistory();
  // EDIT VADEMECUM
  const RedirectToEditVademecum = (id) => {
    history.push(routes.abmVademecumsEdit, {
      presentationId: id,
      programId: localStorage.getItem("programId"),
    });
  };
  // NEW VADEMECUM
  const RedirectToNewVademecum = () => {
    history.push(routes.abmVademecumsNew);
  };

  // DATE FILTER
  const [dateFilter, setDateFilter] = React.useState(null);
  const handleApplyDateFilter = () => {
    if (!dateFilter) return;
    setTableData([]);
    getResults(
      `presentations/program/${localStorage.getItem(
        "programId",
      )}?on_date=${format(new Date(dateFilter), "dd/MM/yy")}`,
    );
  };

  // Table columns definition
  const columns = [
    {
      name: " ",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", minWidth: 150, width: 150 },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    RedirectToEditVademecum(
                      tableMeta.rowData[PRESENTATION_ID_POS],
                    );
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Deshabilitar en fecha">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    handleDeleteVademecumOpen(
                      tableMeta.rowData[VADEMECUM_ID_POS], // id
                      tableMeta.rowData[NAME_POS], // name
                      tableMeta.rowData[DISCOUNT_POS], // discount
                    );
                  }}
                >
                  <SyncDisabledIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "Presentation id",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "Vademecum id",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "Producto",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "SKU",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Descuento (%)",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Descuento válido hasta",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        sortCompare: (order) => {
          return (a, b) => {
            var dateSplitA = a.data.split("/");
            var dateSplitB = b.data.split("/");
            const dateA = new Date(
              dateSplitA[2],
              dateSplitA[1],
              dateSplitA[0],
            ).getTime();
            const dateB = new Date(
              dateSplitB[2],
              dateSplitB[1],
              dateSplitB[0],
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === "desc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "Próximo Descuento (%)",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "Próx. Descuento válido hasta",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        sortCompare: (order) => {
          return (a, b) => {
            var dateSplitA = a.data.split("/");
            var dateSplitB = b.data.split("/");
            const dateA = new Date(
              dateSplitA[2],
              dateSplitA[1],
              dateSplitA[0],
            ).getTime();
            const dateB = new Date(
              dateSplitB[2],
              dateSplitB[1],
              dateSplitB[0],
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === "desc" ? 1 : -1);
          };
        },
      },
    },
  ];

  var classes = useStyles();
  const [tableData, setTableData] = useState([]);

  /* Fills table data */
  const fillTable = (data) => {
    if (!data) return;
    data = data.filter((x) => x.program_1_vademecum || x.program_2_vademecum); //TODO: filter in back
    var tableData = data.map((row) => [
      "",
      row[`program_${localStorage.getItem("programId")}_vademecum`]
        ? row[`program_${localStorage.getItem("programId")}_vademecum`]
            .presentation_id
        : null,
      row[`program_${localStorage.getItem("programId")}_vademecum`]
        ? row[`program_${localStorage.getItem("programId")}_vademecum`].id
        : null,
      `${row.name}${row.notes ? ` - ${row.notes}` : ""}`,
      row.sku ? row.sku : "", // SKU
      row[`program_${localStorage.getItem("programId")}_vademecum`]
        ? row[`program_${localStorage.getItem("programId")}_vademecum`].discount
        : "", // today discount

      row[`program_${localStorage.getItem("programId")}_vademecum`]
        ? row[`program_${localStorage.getItem("programId")}_vademecum`].to_date
          ? format(
              moment(
                row[`program_${localStorage.getItem("programId")}_vademecum`]
                  .to_date,
                "DD/MM/YY",
              )
                .subtract(1, "days")
                .toDate(),
              "dd/MM/yyyy",
            )
          : "Sin expiración"
        : "", // today to_date
      row[`program_${localStorage.getItem("programId")}_next_vademecum`]
        ? row[`program_${localStorage.getItem("programId")}_next_vademecum`]
            .discount
        : "", // next discount
      row[`program_${localStorage.getItem("programId")}_next_vademecum`]
        ? row[`program_${localStorage.getItem("programId")}_next_vademecum`]
            .to_date
          ? format(
              moment(
                row[
                  `program_${localStorage.getItem("programId")}_next_vademecum`
                ].to_date,
                "DD/MM/YY",
              )
                .subtract(1, "days")
                .toDate(),
              "dd/MM/yyyy",
            )
          : "Sin expiración"
        : "", // next to_date
    ]);
    setTableData(tableData);
  };

  var [isLoading, setIsLoading] = useState(false);
  /* Get all data */
  const getResults = (category) => {
    setIsLoading(true);
    dbGet(category)
      .then((data) => {
        setIsLoading(false);
        fillTable(data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getResults(`presentations/program/${localStorage.getItem("programId")}`);
  }, []);

  // Program switch logic
  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  const updateTableDataOnProgramIdChange = () => {
    setTableData([]);
    getResults(`presentations/program/${localStorage.getItem("programId")}`);
  };
  React.useEffect(() => {
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);
  React.useEffect(() => {
    if (!tableData) return; // no fetch on page load
    updateTableDataOnProgramIdChange();
  }, [programId]);

  return (
    <>
      <Dialog open={deleteVademecumOpen} onClose={handleDeleteUserClose}>
        <DialogTitle>{`Deshabilitar descuento de producto ${vademecumToDelete.name}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione la fecha en la que se deshabilitará el descuento.
          </DialogContentText>
          <DateField
            name="dateToDisable"
            format={"dd/MM/yyyy"}
            value={vademecumToDelete.dateToDisable}
            minDate={getCurrentDate()}
            onChange={setDateToDisable}
            fieldClassName={classes.dateFilterField}
            placeholder="Fecha"
            error={vademecumToDelete.error}
            helperText={vademecumToDelete.message}
          />
        </DialogContent>
        <DialogActions className={classes.popupDivCenter}>
          <Button
            onClick={handleDeleteUserClose}
            color="primary"
            variant="contained"
            className={classes.popupButtonSpacing}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteVademecum}
            variant="contained"
            autoFocus
            className={classes.popupButtonSpacingDelete}
          >
            Deshabilitar
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <PageTitle
          title="Vademecums"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={RedirectToNewVademecum}
          startIcon={<AddIcon />}
        >
          Añadir
        </Button>
      </div>
      <div className={classes.divDateFilter}>
        <DateField
          name="dateFilter"
          format={"dd/MM/yyyy"}
          value={dateFilter}
          onChange={setDateFilter}
          fieldClassName={classes.dateFilterField}
          placeholder="Fecha"
        />
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonDateFilter}
          onClick={handleApplyDateFilter}
        >
          Ver en fecha
        </Button>
        <Button
          color="secondary"
          variant="contained"
          className={classes.buttonDateFilter}
          onClick={() => {
            setDateFilter(null);
            setTableData([]);
            setVademecumToDelete(blankVademecumToDelete);
            getResults(
              `presentations/program/${localStorage.getItem("programId")}`,
            );
          }}
        >
          Limpiar
        </Button>
      </div>
      {isLoading ? (
        <LoaderBar />
      ) : (
        <Grid
          style={{ display: "flex", margin: 0 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
        >
          <Grid container spacing={3} xs={12}>
            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <CustomMUIDataTable
                title="Vademecums"
                data={tableData}
                columns={columns}
                filterStart={1}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
