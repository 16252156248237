import React, { useState } from "react";
import PageTitle from "components/PageTitle/PageTitle";
import { Button, Chip, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import CachedIcon from "@material-ui/icons/Cached";

import { useHistory } from "react-router-dom";
import routes from "routes";
import logo from "images/logoPagesRS.jpg";
// styles
import useStyles from "./styles";

import { dbGet, dbDisableEnable } from "utils/DBFetchers.js";

import CustomMUIDataTable from "components/MUIDatatable/CustomMUIDataTable";
import LoaderBar from "components/LoaderBar/LoaderBar";

export default function ABMDistributorsPage() {
  // DISABLE/ENABLE DISTRIBUTOR
  const [disableDistributorOpen, setDisableDistributorOpen] =
    React.useState(false);
  const [distributorToDisable, setDistributorToDisable] = React.useState({
    id: "",
    name: "",
    command: "",
  });
  const handleDisableDistributorOpen = (
    distributorId,
    distributorName,
    enabled,
  ) => {
    setDisableDistributorOpen(true);
    setDistributorToDisable({
      id: distributorId,
      name: distributorName,
      command: enabled ? "disable" : "enable",
    });
  };
  const handleDisableDistributorClose = () => {
    setDisableDistributorOpen(false);
  };
  const handleDisableDistributor = () => {
    setDisableDistributorOpen(false);
    dbDisableEnable(
      "distributors",
      distributorToDisable.id,
      distributorToDisable.command,
    );
    setTimeout(() => {
      getResults("distributors");
    }, 700);
  };

  const history = useHistory();
  // EDIT DISTRIBUTOR
  const RedirectToEditDistributor = (id) => {
    history.push(routes.abmDistributorsEdit, { distributorId: id });
  };
  // NEW DISTRIBUTOR
  const RedirectToNewDistributor = () => {
    history.push(routes.abmDistributorsNew);
  };

  // Table columns definition
  const columns = [
    {
      name: " ",
      options: {
        download: false,
        filter: false,
        sort: false,
        setCellHeaderProps: () => {
          return { style: { fontWeight: "bold", minWidth: 150, width: 150 } };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    RedirectToEditDistributor(tableMeta.rowData[1]);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Activar/Desactivar">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  className={classes.onlyButtonSpacing}
                  onClick={() => {
                    handleDisableDistributorOpen(
                      tableMeta.rowData[1], // id
                      tableMeta.rowData[3], // name
                      tableMeta.rowData[7], // is_enabled
                    );
                  }}
                >
                  <CachedIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "Id",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "Estado",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            fontWeight: "bold",
            whiteSpace: "nowrap",
            minWidth: 150,
            width: 150,
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            display: "table-cell",
            verticalAlign: "middle",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              label={tableMeta.rowData[2]} // estado
              classes={{
                root: tableMeta.rowData[7] // is_enabled
                  ? classes.success
                  : classes.secondary,
              }}
            />
          );
        },
      },
    },
    {
      name: "Nombre",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "¿Es autoritario?",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Descuento (%)",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    {
      name: "Programas",
      options: {
        setCellHeaderProps: () => ({
          style: { fontWeight: "bold", whiteSpace: "nowrap" },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", marginBottom: "5%" },
        }),
      },
    },
    { name: "Disabled", options: { display: "excluded", filter: false } },
  ];

  var classes = useStyles();
  const [tableData, setTableData] = useState([]);

  /* Fills table data */
  const fillTable = (data) => {
    if (!data) return;
    data = data.filter((x) => x.works_with_independents && x.id !== 19);
    var tableData = data.map((row) => [
      "",
      row.id,
      row.is_enabled ? "Activo" : "Inactivo",
      row.name,
      row.is_authoritarian ? "Si" : "No",
      row.discount,
      row.programs.map((x) => x.name).join(" | "),
      row.is_enabled,
    ]);
    setTableData(tableData);
  };

  var [isLoading, setIsLoading] = useState(false);
  /* Get all data */
  const getResults = (category) => {
    setIsLoading(true);
    dbGet(category)
      .then((data) => {
        fillTable(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  /* On page load, get all data */
  React.useEffect(() => {
    getResults(`distributors`);
  }, []);

  // Program switch logic
  const [programId, setProgramId] = useState(localStorage.getItem("programId"));
  const updateTableDataOnProgramIdChange = () => {
    setTableData([]);
    getResults(`distributors`);
  };
  React.useEffect(() => {
    function checkProgramId() {
      const item = localStorage.getItem("programId");
      setProgramId(item);
    }
    window.addEventListener("storage", checkProgramId);
    return () => {
      window.removeEventListener("storage", checkProgramId);
    };
  }, []);
  React.useEffect(() => {
    if (!tableData) return; // no fetch on page load
    updateTableDataOnProgramIdChange();
  }, [programId]);

  return (
    <>
      <Dialog
        open={disableDistributorOpen}
        onClose={handleDisableDistributorClose}
      >
        <DialogTitle>{`${
          distributorToDisable.command === "enable" ? "Activar" : "Desactivar"
        } el distribuidor ${distributorToDisable.name}?`}</DialogTitle>
        <DialogActions className={classes.popupDivCenter}>
          <Button
            onClick={handleDisableDistributorClose}
            color="primary"
            variant="contained"
            className={classes.popupButtonSpacing}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDisableDistributor}
            color="secondary"
            variant="contained"
            autoFocus
            className={
              distributorToDisable.command === "enable"
                ? classes.popupButtonSpacingEnable
                : classes.popupButtonSpacing
            }
          >
            {distributorToDisable.command === "enable"
              ? "Activar"
              : "Desactivar"}
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <PageTitle
          title="Distribuidores"
          // button={
          //   <img src={logo} alt="logo" className={classes.logotypeImage} />
          // }
        />
      </div>
      <div>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={RedirectToNewDistributor}
          startIcon={<AddIcon />}
        >
          Añadir
        </Button>
      </div>

      {isLoading ? (
        <LoaderBar />
      ) : (
        <Grid
          style={{ display: "flex", margin: 0 }}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
          xs={12}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ marginTop: "2%" }}>
              <CustomMUIDataTable
                title="Distribuidores"
                data={tableData}
                columns={columns}
                filterStart={2}
                filterEnd={7}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
